// React
import { Link } from 'react-router-dom';

// Elements
import Button from 'components/atoms/Button/Button';

// Styles
import styled from 'styled-components';

export const StyledButton = styled(Button)`
  && {
    width: auto;
    margin: 1rem 0;
  }
`;
export const StyledLink = styled(Link)`
  && {
    color: ${({ theme }) => theme.color.darkGray};
  }
`;
