// Elements
import { TableCell, DialogActions } from '@material-ui/core';
import Button from 'components/atoms/Button/Button';
import Input from 'components/atoms/Input/Input';
import Heading from 'components/atoms/Heading/Heading';

// Styles
import styled, { css } from 'styled-components';

export const StyledButton = styled(Button)`
  && {
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    font-size: ${({ theme }) => theme.fontSize.overline};
    color: ${({ theme }) => theme.color.gray};
    transition: 0.3s;

    &:hover {
      color: ${({ theme }) => theme.color.darkGray};
    }
  }
`;

export const StyledTableCell = styled(TableCell)`
  && {
    ${({ title }) =>
      title &&
      css`
        max-width: 15rem;
      `}
    ${({ number }) =>
      number &&
      css`
        max-width: 8rem;
      `}
  }
`;
export const StyledDialogActions = styled(DialogActions)`
  && {
    margin-top: 1rem;
  }
`;

export const StyledTooltip = styled.div`
  && {
    padding: 1.5rem 1rem;
    line-height: 1.1;

    > * {
      margin-bottom: 1rem;
    }

    p {
      &:first-child {
        margin-bottom: 1.5rem;
      }

      &:last-child {
        margin-top: 1rem;
      }
    }
  }
`;
export const StyledInput = styled(Input)`
  && {
    width: auto;

    input {
      background-color: rgba(0, 0, 0, 0);
      padding: 1rem;
    }
  }
`;

export const StyledHeadingCaption2 = styled(Heading.Caption2)`
&& {
  color: ${({ theme }) => theme.color.blue}`;
