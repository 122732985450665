// React
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';

// Services
import { removeBeneficiaryGuardian as removeBeneficiaryGuardianService } from 'services/beneficiaryServices';

// Assets
import HomeIcon from '@material-ui/icons/Home';
import MapIcon from '@material-ui/icons/Map';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import PhoneIcon from '@material-ui/icons/Phone';
import LocalLibraryRoundedIcon from '@material-ui/icons/LocalLibraryRounded';
import MailIcon from '@material-ui/icons/Mail';
import ShareRoundedIcon from '@material-ui/icons/ShareRounded';

// Elements
import { IconButton, CardContent } from '@material-ui/core';
import Heading from 'components/atoms/Heading/Heading';
import BeneficiaryContactDetailsForm from 'views/Dashboard/Beneficiary/BeneficiaryBindings/BeneficiaryContactDetailsForm';
import BeneficiaryGuardianForm from 'views/Dashboard/Beneficiary/BeneficiaryBindings/BeneficiaryGuardianForm';
import BeneficiaryEducationalFacilityForm from 'views/Dashboard/Beneficiary/BeneficiaryBindings/BeneficiaryEducationalFacilityForm';
import BeneficiaryContactDetailsCopy from 'views/Dashboard/Beneficiary/BeneficiaryBindings/BeneficiaryContactDetailsCopy';
import BeneficiaryGuardianCopy from 'views/Dashboard/Beneficiary/BeneficiaryBindings/BeneficiaryGuardianCopy';
import BeneficiaryEducationalFacilityCopy from 'views/Dashboard/Beneficiary/BeneficiaryBindings/BeneficiaryEducationalFacilityCopy';

// Styles
import {
  StyledCard,
  StyledButton,
  StyledTitle,
  StyledCardActions,
  StyledStreetContainer,
  StyledStreet,
} from './DashCardContact.styles';

// Component
const DashCardContact = ({
  family,
  sub_name,
  shadows,
  buttons,
  program_organization,
  organizations,
  osoba,
  szkola,
  osoba_org_pro_name,
  connectionWithProgram,
  connectionWithOrganization,
  removeBeneficiaryGuardian,
  dataBeneficiaries,
  educationalFacilitiesList,
  dataOrgProg,
  programId,
  programName,
  organizationId,
  organizationName,
}) => {
  const { id } = useParams();
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogCopy, setOpenDialogCopy] = useState(false);

  const takeFirstLetters = (x) => {
    return x
      .match(/\b(\w)/g)
      .join('')
      .slice(0, 3)
      .toUpperCase();
  };

  return (
    <StyledCard shadows={shadows}>
      <StyledTitle>
        <div
          className="shortContainer"
          style={{
            backgroundColor: (program_organization && program_organization.color) || '#757575',
          }}
        >
          <Heading.Subtitle1 className="shortHeader">
            {!osoba &&
              !szkola &&
              program_organization &&
              program_organization.name &&
              takeFirstLetters(program_organization.name)}
            {osoba &&
              (osoba.name || osoba.surname) &&
              takeFirstLetters(`${osoba.name} ${osoba.surname}`)}
            {szkola &&
              szkola.educationalFacilty &&
              takeFirstLetters(`${szkola.educationalFacilty.name}`)}
          </Heading.Subtitle1>
        </div>
        <div className="titleContainer">
          {program_organization &&
            program_organization.start_date &&
            program_organization.end_date && (
              <Heading.FormatDate>
                {program_organization.start_date} - {program_organization.end_date}
              </Heading.FormatDate>
            )}
          <Heading.H6 style={{ marginLeft: '2rem' }}>
            {!osoba && !szkola && program_organization && <>{program_organization.name}</>}
            {osoba && (
              <>
                {osoba.name} {osoba.surname}
              </>
            )}
            {szkola && szkola.educationalFacilty && <>{szkola.educationalFacilty.name}</>}
          </Heading.H6>
          <Heading.Body1 style={{ marginLeft: '2rem', marginTop: '1rem' }}>
            {sub_name}
          </Heading.Body1>
        </div>
      </StyledTitle>
      <CardContent>
        <>
          {program_organization && program_organization.data !== null && (
            <>
              {program_organization.data.street && (
                <div className="conticon icodomek">
                  <HomeIcon className="domek" />
                  <StyledStreetContainer>
                    <StyledStreet>
                      <Heading.Body1>{`${program_organization.data.street}   ${program_organization.data.houseNumber}/${program_organization.data.apartmentNumber}`}</Heading.Body1>
                    </StyledStreet>
                    <StyledStreet>
                      <Heading.Body1>{`${program_organization.data.zipCode}  ${program_organization.data.city}`}</Heading.Body1>
                    </StyledStreet>
                  </StyledStreetContainer>
                </div>
              )}

              {program_organization.data.district && (
                <div className="conticon icodomek">
                  <MapIcon className="mapka" />
                  <StyledStreet>
                    <Heading.Body1>{program_organization.data.district}</Heading.Body1>
                  </StyledStreet>
                </div>
              )}
              {program_organization.data.role && (
                <div className="conticon icodomek">
                  <AccountBoxIcon className="domek" />
                  <Heading.Body1>{program_organization.data.role}</Heading.Body1>
                </div>
              )}

              {program_organization.data.phone && (
                <div className="conticon icodomek">
                  <PhoneIcon className="mapka" />
                  <Heading.Body1>{program_organization.data.phone}</Heading.Body1>
                </div>
              )}
              {program_organization.data.email && (
                <div className="conticon icodomek">
                  <MailIcon className="mapka" />
                  <Heading.Body1>{program_organization.data.email}</Heading.Body1>
                </div>
              )}
            </>
          )}
          {osoba && (
            <>
              {osoba.isBeneficiary !== null && (
                <div className="conticon icodomek">
                  <AccountBoxIcon className="domek" />
                  <Heading.Body1>
                    {osoba.isBeneficiary ? 'Beneficjent' : 'Nie jest beneficjentem'}
                  </Heading.Body1>
                </div>
              )}
              {osoba.phone && (
                <div className="conticon icodomek">
                  <PhoneIcon className="mapka" />
                  <Heading.Body1>{osoba.phone}</Heading.Body1>
                </div>
              )}
              {osoba.email && (
                <div className="conticon icodomek">
                  <MailIcon className="mapka" />
                  <Heading.Body1>{osoba.email}</Heading.Body1>
                </div>
              )}
            </>
          )}
          {szkola && (
            <>
              {szkola.class !== null && (
                <div className="conticon icodomek">
                  {szkola.class && (
                    <>
                      <LocalLibraryRoundedIcon className="mapka" />
                      <Heading.Body1>klasa {szkola.class}</Heading.Body1>
                    </>
                  )}
                </div>
              )}
              {szkola.educationalFacilty && szkola.educationalFacilty.phone !== null && (
                <div className="conticon icodomek">
                  {szkola.educationalFacilty.phone && (
                    <>
                      <PhoneIcon className="mapka" />
                      <Heading.Body1> {szkola.educationalFacilty.phone}</Heading.Body1>
                    </>
                  )}
                </div>
              )}
              {szkola.educationalFacilty && szkola.educationalFacilty.email !== null && (
                <div className="conticon icodomek">
                  {szkola.educationalFacilty.email && (
                    <>
                      <MailIcon className="mapka" />
                      <Heading.Body1> {szkola.educationalFacilty.email}</Heading.Body1>
                    </>
                  )}
                </div>
              )}
            </>
          )}
        </>
      </CardContent>
      <StyledCardActions>
        <div>
          {buttons.remove && (
            <StyledButton
              color="secondary"
              onClick={() => {
                if (osoba) {
                  removeBeneficiaryGuardian(id, osoba.id);
                }
              }}
            >
              USUŃ
            </StyledButton>
          )}
          {buttons.edit && (
            <>
              <StyledButton color="primary" onClick={() => setOpenDialog(true)}>
                EDYCJA
              </StyledButton>
              {program_organization && (
                <BeneficiaryContactDetailsForm
                  openDialog={openDialog}
                  setOpenDialog={setOpenDialog}
                  program_organization={program_organization}
                />
              )}
              {osoba && (
                <BeneficiaryGuardianForm
                  family={!!family}
                  openDialog={openDialog}
                  setOpenDialog={setOpenDialog}
                  osoba={osoba}
                  osoba_org_pro_name={osoba_org_pro_name}
                  connectionWithProgram={connectionWithProgram}
                  connectionWithOrganization={connectionWithOrganization}
                  dataBeneficiaries={dataBeneficiaries}
                  programId={programId}
                  programName={programName}
                  organizationId={organizationId}
                  organizationName={organizationName}
                />
              )}
              {szkola && (
                <BeneficiaryEducationalFacilityForm
                  openDialog={openDialog}
                  setOpenDialog={setOpenDialog}
                  szkola={szkola}
                  firstTime={!szkola.educationalFacilty}
                  osoba_org_pro_name={osoba_org_pro_name}
                  connectionWithProgram={connectionWithProgram}
                  connectionWithOrganization={connectionWithOrganization}
                  educationalFacilitiesList={educationalFacilitiesList}
                />
              )}
            </>
          )}
        </div>
        {buttons.share && (
          <>
            <IconButton
              onClick={() => {
                setOpenDialogCopy(true);
              }}
            >
              <ShareRoundedIcon style={{ fontSize: 20 }} />
            </IconButton>
            {dataOrgProg && !osoba && !szkola && (
              <BeneficiaryContactDetailsCopy
                openDialog={openDialogCopy}
                setOpenDialog={setOpenDialogCopy}
                program_organization={program_organization}
                organizations={organizations}
                dataOrgProg={dataOrgProg}
              />
            )}
            {dataOrgProg && osoba && (
              <BeneficiaryGuardianCopy
                openDialog={openDialogCopy}
                setOpenDialog={setOpenDialogCopy}
                program_organization={program_organization}
                organizations={organizations}
                dataOrgProg={dataOrgProg}
                osoba={osoba}
              />
            )}
            {dataOrgProg && szkola && (
              <BeneficiaryEducationalFacilityCopy
                openDialog={openDialogCopy}
                setOpenDialog={setOpenDialogCopy}
                program_organization={program_organization}
                organizations={organizations}
                dataOrgProg={dataOrgProg}
                szkola={szkola}
              />
            )}
          </>
        )}
      </StyledCardActions>
    </StyledCard>
  );
};

DashCardContact.propTypes = {
  removeBeneficiaryGuardian: PropTypes.func,

  osoba_org_pro_name: PropTypes.string,
  family: PropTypes.bool,
  osoba: PropTypes.shape({
    id: PropTypes.number,
    programId: PropTypes.number,
    name: PropTypes.string,
    surname: PropTypes.string,
    isBeneficiary: PropTypes.bool,
    phone: PropTypes.string,
    email: PropTypes.string,
  }),
  programId: PropTypes.number,
  programName: PropTypes.string,
  organizationId: PropTypes.number,
  organizationName: PropTypes.string,
  program_organization: PropTypes.shape({
    contactDetailsId: PropTypes.number,
    programId: PropTypes.number,
    organizationId: PropTypes.number,
    houseNumber: PropTypes.string,
    zipCode: PropTypes.string,
    district: PropTypes.string,
    city: PropTypes.string,
    apartmentNumber: PropTypes.string,
    name: PropTypes.string,
    start_date: PropTypes.string,
    end_date: PropTypes.string,
    color: PropTypes.string,
    data: PropTypes.oneOfType([
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        street: PropTypes.string,
        houseNumber: PropTypes.string,
        apartmentNumber: PropTypes.string,
        city: PropTypes.string,
        zipCode: PropTypes.string,
        district: PropTypes.string,
        role: PropTypes.string,
        phone: PropTypes.string,
        email: PropTypes.string,
      }),
      PropTypes.arrayOf(PropTypes.any),
    ]).isRequired,
  }),
  organizations: PropTypes.arrayOf(PropTypes.any),
  szkola: PropTypes.shape({
    class: PropTypes.string,
    educationalFacilty: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      city: PropTypes.string,
      zipCode: PropTypes.string,
      street: PropTypes.string,
      houseNumber: PropTypes.string,
      district: PropTypes.string,
      email: PropTypes.string,
      phone: PropTypes.string,
    }),
    typeOfChange: PropTypes.string,
    reason_of_update: PropTypes.number,
  }),
  connectionWithProgram: PropTypes.number,
  connectionWithOrganization: PropTypes.number,
  sub_name: PropTypes.string,
  shadows: PropTypes.string,
  buttons: PropTypes.shape({
    edit: PropTypes.bool,
    remove: PropTypes.bool,
    share: PropTypes.bool,
  }),
  dataBeneficiaries: PropTypes.arrayOf(PropTypes.any),
  educationalFacilitiesList: PropTypes.arrayOf(PropTypes.any),
  dataOrgProg: PropTypes.arrayOf(PropTypes.any),
};

DashCardContact.defaultProps = {
  removeBeneficiaryGuardian: null,
  sub_name: '',
  family: null,
  shadows: 'rgba(0, 0, 0, 0.072)',
  buttons: [],
  program_organization: null,
  organizations: [],
  osoba: null,
  szkola: null,
  programId: null,
  programName: null,
  organizationId: null,
  organizationName: null,
  osoba_org_pro_name: null,
  connectionWithProgram: null,
  connectionWithOrganization: null,
  dataBeneficiaries: [],
  educationalFacilitiesList: [],
  dataOrgProg: [],
};

const mapDispatchToProps = (dispatch) => ({
  removeBeneficiaryGuardian: (id, guardianId) =>
    dispatch(removeBeneficiaryGuardianService(id, { guardianId })),
});

export default connect(null, mapDispatchToProps)(DashCardContact);
