// React
import React, { useEffect, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Services
import { fetchProgramDiagnosis } from 'services/programServices';

// Actions
import { clearProgramDiegnosis as clearProgramDiegnosisAction } from 'actions/programActions';

// Elements
import TableTemplate from 'templates/TableTemplate';
import FloatingButton from 'components/atoms/FloatingButton/FloatingButton';

// Data
const headCells = [
  { type: 'beneficiary_name', label: 'Imię' },
  { type: 'beneficiary_surname', label: 'Nazwisko' },
  { type: 'title', label: 'Nazwa' },
  { type: 'status', label: 'Diagnoza przeprowadzona' },
  { type: 'execution_date', label: 'Data przeprowadzenia' },
  { type: 'author', label: 'Osoba przeprowadzająca' },
];

// Components
const ProgramDiagnosis = ({ clearProgramDiegnosis, fetchDiagnosis, data }) => {
  const history = useHistory();
  const { id } = useParams();
  const { diagnosis } = data;

  useEffect(() => {
    clearProgramDiegnosis();
  }, []);

  const triggerFetchDiagnosis = useCallback((myid) => fetchDiagnosis(myid), [fetchDiagnosis]);

  useEffect(() => {
    triggerFetchDiagnosis(id);
  }, [id, triggerFetchDiagnosis]);

  return (
    <>
      <TableTemplate
        headCells={headCells}
        data={diagnosis}
        isnav
        link="/diagnoza"
        tableName="Diagnozy"
      />
      <FloatingButton onClick={() => history.push(`/diagnoza-dodaj/program/${id}`)} />
    </>
  );
};

ProgramDiagnosis.propTypes = {
  clearProgramDiegnosis: PropTypes.func,
  fetchDiagnosis: PropTypes.func,
  data: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    diagnosis: PropTypes.arrayOf(
      PropTypes.shape({
        beneficiary_first_name: PropTypes.string,
        beneficiary_last_name: PropTypes.string,
        date_execution: PropTypes.string,
        diagnosis_author: PropTypes.string,
        is_conducted: PropTypes.bool,
      }),
    ),
  }),
};

ProgramDiagnosis.defaultProps = {
  clearProgramDiegnosis: null,
  fetchDiagnosis: null,
  data: {
    program_id: null,
    program_name: '',
    program_diagnosis: [],
  },
};

const mapStateToProps = ({ programReducer }) => ({
  data: programReducer.diagnosis,
});

const mapDispatchToProps = (dispatch) => ({
  clearProgramDiegnosis: () => dispatch(clearProgramDiegnosisAction()),
  fetchDiagnosis: (id) => dispatch(fetchProgramDiagnosis(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProgramDiagnosis);
