// React
import React from 'react';
import PropTypes from 'prop-types';

// Elements
import { Switch } from '@material-ui/core';

// Styles
import { StyledOption, StyledFormControlLabel } from './SwitchButton.styles';

// Component
const SwitchButton = ({ checked, setChecked, label, place, disabled, name }) => (
  <StyledOption>
    <StyledFormControlLabel
      control={<Switch checked={checked} color="primary" />}
      onChange={() => {
        setChecked(!checked);
      }}
      name={name}
      label={label || 'Edycja'}
      labelPlacement={place || 'start'}
      disabled={disabled || false}
    />
  </StyledOption>
);

SwitchButton.propTypes = {
  checked: PropTypes.bool,
  setChecked: PropTypes.func,
  label: PropTypes.string,
  place: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string,
};

SwitchButton.defaultProps = {
  label: '',
  place: '',
  disabled: false,
  checked: false,
  setChecked: null,
  name: '',
};

export default SwitchButton;
