// React SVG
import { ReactSVG } from 'react-svg';

// Elements
import { TableCell, TableBody, Button, TableHead, TableRow, Grid } from '@material-ui/core';
import Heading from 'components/atoms/Heading/Heading';

// Styles
import styled from 'styled-components';
import { device } from 'theme/mainTheme';

export const StyledButton = styled(Button)`
  && {
    padding: 1rem 2rem;
    @media ${device.laptopL} {
      transition: 0.3s;
      opacity: 0.5;
      &:hover {
        opacity: 1;
      }
    }
  }
`;

export const StyledHeading = styled(Heading.Body2)`
  && {
    text-align: center;
    white-space: pre;
    color: ${({ status, theme }) => (status ? theme.color.darkGray : theme.color.gray)};
  }
`;

export const StyledTableBody = styled(TableBody)`
  && {
    & .MuiTableRow-root {
      td {
        background-color: ${({ theme }) => theme.color.white};
      }
      &:nth-child(even) {
        td {
          background-color: ${({ theme }) => theme.color.gray};
        }
      }
    }
  }
`;

export const StyledTableHead = styled(TableHead)`
  && {
    th {
      background-color: ${({ theme }) => theme.color.white};
    }
  }
`;

export const StyledTableRow = styled(TableRow)`
  && {
    .MuiTableCell-root {
      background-color: ${({ theme }) => theme.color.white};
    }
  }
`;

export const StyledWrapper = styled(Grid)`
  && {
    margin-top: 4rem;
  }
`;

export const StyledTableCell = styled(TableCell)`
  && {
    border-bottom: 0px;
    min-width: 120px;
  }
`;

export const StyledTableCellSticky = styled(StyledTableCell)`
  && {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    z-index: 2;
    min-width: 200px;
  }
`;

export const StyledTableCellStickyRight = styled(StyledTableCell)`
  && {
    position: -webkit-sticky;
    position: sticky;
    right: 0;
    z-index: 2;
    min-width: 100px;
  }
`;

export const StyledTableText = styled.div`
  && {
    font-size: 1.4rem;
    font-weight: 400;
  }
`;

export const StyledReactSVG = styled(ReactSVG)`
  && {
    opacity: 0.15;
    margin-left: 1rem;
  }
`;
