// React
import React from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Service
import {
  // saveAdvancedrodoConfigProgram as saveAdvancedrodoConfigProgramService,
  removeEntrusting as removeEntrustingService,
} from 'services/programServices';

import { COORDINATOR } from 'constants/roles';

// Elements
import { Grid, FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';
import Button from 'components/atoms/Button/Button';
import GuardedComponent from 'components/molecules/GuardedComponent/GuardedComponent';

import ProgramRODOKatalog from './ProgramRODOKatalog';

import './ProgramSurvey.scss';

// Component
const ProgramRODODetails = ({
  details,
  values,
  // saveAdvancedrodoConfigProgram,
  handleChange,
  setFieldValue,
  // handleSubmit,
  onlyShow,
  group,
  currentOrganizationIndex,
  availableOrganizations,
  removeEntrusting,
}) => {
  return (
    <>
      <FormGroup className="radioGrupa" component="fieldset" style={{ paddingTop: '1rem' }}>
        {availableOrganizations &&
          availableOrganizations.map((el, index) =>
            el ? (
              <React.Fragment key={el.id}>
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={el.organization.id.toString()}
                        defaultChecked={
                          (group
                            ? values.group.selectedToEntrust[index].checked
                            : values.organizations[currentOrganizationIndex].selectedToEntrust[
                                index
                              ].checked) || false
                        }
                        onChange={(e, v) => {
                          setFieldValue(
                            group
                              ? `group.selectedToEntrust[${index}].checked`
                              : `organizations[${currentOrganizationIndex}].selectedToEntrust[${index}].checked`,
                            v,
                          );
                        }}
                        name={
                          group
                            ? `group.selectedToEntrust[${index}].checked`
                            : `organizations[${currentOrganizationIndex}].selectedToEntrust[${index}].checked`
                        }
                        id={el.organization.id.toString()}
                        color="default"
                        disabled={onlyShow}
                      />
                    }
                    label={el.organization.name}
                  />
                  <GuardedComponent allowed_user_roles={[COORDINATOR]}>
                    <>
                      {(group
                        ? values.group.selectedToEntrust[index].checked
                        : values.organizations[currentOrganizationIndex].selectedToEntrust[index]
                            .checked) &&
                        onlyShow && (
                          <Button
                            variant="text"
                            onClick={() => {
                              setFieldValue(
                                group
                                  ? `group.selectedToEntrust[${index}].checked`
                                  : `organizations[${currentOrganizationIndex}].selectedToEntrust[
                                ${index}
                                ].checked`,
                                false,
                              );
                              return removeEntrusting(details.id, {
                                group,
                                org1: group
                                  ? values.group.selectedToEntrust[index].id
                                  : values.organizations[currentOrganizationIndex]
                                      .selectedToEntrust[index].id,
                                org2: values.organizations[currentOrganizationIndex]?.id,
                              });
                            }}
                          >
                            cofnij powierzenie przetwarzania
                          </Button>
                        )}
                    </>
                  </GuardedComponent>
                  {(group
                    ? values.group.selectedToEntrust[index].checked
                    : values.organizations[currentOrganizationIndex] &&
                      values.organizations[currentOrganizationIndex].selectedToEntrust[index]
                        .checked) && (
                    <Grid
                      item
                      xs={12}
                      style={{
                        backgroundColor: '#F3F3F3',
                        marginTop: '0.5rem',
                        marginBottom: '2.5rem',
                        padding: '1rem',
                      }}
                    >
                      <ProgramRODOKatalog
                        groupBeginningName={
                          group
                            ? `group.selectedToEntrust[${index}]`
                            : `organizations[${currentOrganizationIndex}].selectedToEntrust[${index}]`
                        }
                        groupBeginningObject={
                          group
                            ? values.group.selectedToEntrust[index]
                            : values.organizations[currentOrganizationIndex].selectedToEntrust[
                                index
                              ]
                        }
                        currentOrganizationIndex={currentOrganizationIndex}
                        details={details}
                        values={values}
                        // saveAdvancedrodoConfigProgram={saveAdvancedrodoConfigProgram}
                        handleChange={handleChange}
                        setFieldValue={setFieldValue}
                        // handleSubmit={handleSubmit}
                        onlyShow={onlyShow}
                      />
                    </Grid>
                  )}
                </div>
              </React.Fragment>
            ) : null,
          )}
      </FormGroup>
    </>
  );
};

ProgramRODODetails.propTypes = {
  values: PropTypes.shape({
    organizations: PropTypes.arrayOf(
      PropTypes.shape({
        coAdministrator: PropTypes.bool,
        dataAdministrator: PropTypes.bool,
        checked: PropTypes.bool,
        beneficiary: PropTypes.string,
        data: PropTypes.string,
        id: PropTypes.number,
        selectedToEntrust: PropTypes.arrayOf(
          PropTypes.shape({
            beneficiary: PropTypes.string,
            data: PropTypes.string,
            checked: PropTypes.bool,
            id: PropTypes.number,
            customData: PropTypes.shape({
              beneficiary_data: PropTypes.shape({
                name: PropTypes.string,
              }),
            }),
          }),
        ),
      }),
    ),
    group: PropTypes.shape({
      coAdministrator: PropTypes.bool,
      dataAdministrator: PropTypes.bool,
      checked: PropTypes.bool,
      beneficiary: PropTypes.string,
      data: PropTypes.string,
      selectedToEntrust: PropTypes.arrayOf(
        PropTypes.shape({
          beneficiary: PropTypes.string,
          data: PropTypes.string,
          checked: PropTypes.bool,
          id: PropTypes.number,
          customData: PropTypes.shape({
            beneficiary_data: PropTypes.shape({
              name: PropTypes.string,
            }),
          }),
        }),
      ),
    }),
  }),
  availableOrganizations: PropTypes.arrayOf(
    PropTypes.shape({
      coAdministrator: PropTypes.bool,
      dataAdministrator: PropTypes.bool,
      checked: PropTypes.bool,
      beneficiary: PropTypes.string,
      data: PropTypes.string,
    }),
  ),
  currentOrganizationIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  group: PropTypes.bool,
  details: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,

    type: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    organizations: PropTypes.arrayOf(
      PropTypes.shape({
        organization_id: PropTypes.number,
        organization_name: PropTypes.string,
      }),
    ),
    program_organizations: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
      }),
    ),
  }),
  handleChange: PropTypes.func,
  setFieldValue: PropTypes.func,
  // handleSubmit: PropTypes.func,
  // saveAdvancedrodoConfigProgram: PropTypes.func,
  removeEntrusting: PropTypes.func,
  onlyShow: PropTypes.bool,
};

ProgramRODODetails.defaultProps = {
  values: null,
  availableOrganizations: [],
  group: null,
  currentOrganizationIndex: null,
  details: {
    id: null,
    name: '',
    type: null,
    organizations: [],
    program_organizations: [],
  },
  handleChange: null,
  setFieldValue: null,
  // handleSubmit: null,
  //  saveAdvancedrodoConfigProgram: null,
  onlyShow: false,
  removeEntrusting: null,
};

const mapStateToProps = ({ programReducer }) => ({
  details: programReducer.consortium,
});

const mapDispatchToProps = (dispatch) => ({
  /* saveAdvancedrodoConfigProgram: (program_id, data) =>
    dispatch(saveAdvancedrodoConfigProgramService(program_id, data)), */
  removeEntrusting: (program_id, data) => dispatch(removeEntrustingService(program_id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProgramRODODetails);
