// React
import React, { useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';

// Services
import { fetchIndicatorList } from 'services/indicatorServices';

// Actions
import { clearIndicators as clearIndicatorsAction } from 'actions/indicatorActions';

// Data
import { filterIndicators } from 'constants/tableFilters';
import {
  COORDINATOR,
  PROGRAM_COORDINATOR,
  TASK_COORDINATOR,
  ORGANIZATION_ADMINISTRATOR,
  PROGRAM_GUARDIAN,
} from 'constants/roles';

// Elements
import TableTemplate from 'templates/TableTemplate';
import FloatingButton from 'components/atoms/FloatingButton/FloatingButton';
import PropTypes from 'prop-types';
import GuardedComponent from 'components/molecules/GuardedComponent/GuardedComponent';

// Data
const headCells = [
  { type: 'indicator_name', label: 'Nazwa wskaźnika' },
  { type: 'indicator_kind', label: 'Rodzaj wskaźnika' },
  { type: 'indicator_type', label: 'Rodzaj pomiaru' },
  { type: 'indicator_time_period', label: 'Przedział czasowy' },
  { type: 'assigned_to', label: 'Przypisanie' },
  { type: 'program_name', label: 'Program' },
  { type: 'planned_number_of_measurements', label: 'Planowana liczba pomiarów' },
];

const Indicators = ({ clearIndicators, fetchList, indicators }) => {
  const history = useHistory();

  useEffect(() => {
    clearIndicators();
  }, []);

  const triggerFetchList = useCallback(() => fetchList(), [fetchList]);

  useEffect(() => {
    triggerFetchList();
  }, [triggerFetchList]);

  const actionsTable = [
    {
      name: 'Przejdź do Raportów',
      action: () => history.push('/raporty'),
    },
  ];

  return (
    <>
      <TableTemplate
        headCells={headCells}
        data={indicators}
        filters={filterIndicators}
        isnav
        tableName="Wskaźniki"
        actions={actionsTable}
      />
      <GuardedComponent
        allowed_user_roles={[
          COORDINATOR,
          PROGRAM_COORDINATOR,
          TASK_COORDINATOR,
          ORGANIZATION_ADMINISTRATOR,
          PROGRAM_GUARDIAN,
        ]}
      >
        <FloatingButton onClick={() => history.push('/wskaznik-dodaj')} />
      </GuardedComponent>
    </>
  );
};

Indicators.propTypes = {
  clearIndicators: PropTypes.func,
  fetchList: PropTypes.func,
  indicators: PropTypes.arrayOf(PropTypes.any),
};

Indicators.defaultProps = {
  clearIndicators: null,
  fetchList: null,
  indicators: [],
};

const mapStatoToProps = (state) => ({
  indicators: state.indicatorReducer.indicators,
});

const mapDispatchToProps = (dispatch) => ({
  clearIndicators: () => dispatch(clearIndicatorsAction()),
  fetchList: () => dispatch(fetchIndicatorList()),
});

export default connect(mapStatoToProps, mapDispatchToProps)(Indicators);
