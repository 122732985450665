// Elements
import { Drawer } from '@material-ui/core';

// Assets
import CloseIcon from '@material-ui/icons/Close';
import Autocomplete from '@material-ui/lab/Autocomplete';

// Styles
import styled, { css } from 'styled-components';

const iconStyles = css`
  && {
    font-size: 2.5rem;
    fill: ${({ theme }) => theme.color.darkGray};
  }
`;

export const StyledAutocomplete = styled(Autocomplete)`
  && {
    label,
    input {
      color: ${({ theme }) => theme.color.darkGray};
    }
    .MuiOutlinedInput-notchedOutline,
    .MuiOutlinedInput-notchedOutline:hover {
      border-color: ${({ theme }) => theme.color.darkGray};
    }
    .MuiSvgIcon-root {
      fill: ${({ theme }) => theme.color.darkGray};
    }
  }
`;

export const StyledDrawer = styled(Drawer)`
  && {
    & > div {
      margin-top: 10rem;
      padding-top: 0.5rem;
      width: 330px;
      transition: 0.4s;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      height: calc(100% - 10rem);
      background-color: ${({ theme }) => theme.color.white};
      ${({ hide }) =>
        hide &&
        css`
          transform: translateX(100%);
        `}
    }
  }
`;

export const StyledTitle = styled.div`
  && {
    margin-top: 1rem;
    margin-bottom: 0rem;
    padding: 0 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;
export const StyledCloseIcon = styled(CloseIcon)`
  ${iconStyles}
`;
