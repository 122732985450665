// React
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';

// Services
import {
  fetchBeneficiaryNotes as fetchBeneficiaryNotesService,
  removeBeneficiaryNote as removeBeneficiaryNoteService,
} from 'services/beneficiaryServices';

// Actions
import { clearBeneficiaryNotes as clearBeneficiaryNotesAction } from 'actions/beneficiaryActions';

// PDF
import { generateBeneficiaryNotesPDF } from 'utils/functions';

// Assets
import NoteAddIcon from '@material-ui/icons/NoteAdd';

// Elements
import { Grid } from '@material-ui/core';
import Heading from 'components/atoms/Heading/Heading';
import TableTemplate from 'templates/TableTemplate';
import Checkbox from 'components/atoms/Checkbox/Checkbox';
import MyAccordion from 'components/atoms/Accordion/Accordion';
import DialogBeneficiaryNotes from 'components/templates/DialogBeneficiaryNotes/DialogBeneficiaryNotes';
import DialogConfirm from 'components/templates/DialogConfirm/DialogConfirm';
import { showAlert as showAlertAction } from 'actions/appActions';

import { ZANONIMIZOWANO_ZAKAZ_EDYCJI } from 'constants/alerts';

// Styles
import { StyledWrapper, StyledButton, StyledBox } from './BeneficiaryNotes.styles';
import './Notes.css';

// Data
const headCells = [
  { type: 'who_added', label: 'Dodana przez' },
  { type: 'date', label: 'Data sporządzenia' },
  { type: 'name', label: 'Nazwa' },
  { type: 'type_name', label: 'Typ notatki' },
  { type: 'action', label: 'Działanie' },
];

// Component
const BeneficiaryNotes = ({
  clearBeneficiaryNotes,
  fetchNotes,
  notes,
  removeBeneficiaryNote,
  beneficiary,
  showAlert,
}) => {
  const { id } = useParams();
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogConfirm, setOpenDialogConfirm] = useState(false);
  const [cwpID, setCwpID] = useState(null);
  const [isActiveProgram, setActiveProgram] = useState(false);
  const [isEndProgram, setEndProgram] = useState(false);
  const [currentItem, setCurrentItem] = useState({});
  const [onlyRead, setOnlyRead] = useState(false);

  useEffect(() => {
    clearBeneficiaryNotes();
  }, []);

  const triggerFetchNotes = useCallback((myid) => fetchNotes(myid), [fetchNotes]);

  const actionCells = [
    {
      column: 'Pobierz',
      action: async (row) => {
        generateBeneficiaryNotesPDF(row);
      },
      button: 'pdf',
    },
  ];

  useEffect(() => {
    triggerFetchNotes(id);
  }, [id, triggerFetchNotes]);

  const showAccordion = (ended) =>
    !((!isActiveProgram && !isEndProgram) || (isActiveProgram && !ended) || (isEndProgram && ended))
      ? 'true'
      : 'false';

  return (
    <>
      <StyledWrapper className="notes" container spacing={2}>
        <Grid item xs={2}>
          <Checkbox
            label="Aktualne"
            isEditable={!!true}
            value={isActiveProgram}
            onChange={() => setActiveProgram(!isActiveProgram)}
          />
        </Grid>
        <Grid item xs={2}>
          <Checkbox
            label="Zakończone"
            isEditable={!!true}
            value={isEndProgram}
            onChange={() => setEndProgram(!isEndProgram)}
          />
        </Grid>
      </StyledWrapper>
      {notes && notes.length ? (
        notes.map(({ cwp_id: itemProgramID, name, ended, notes: itemData }) => {
          return (
            <MyAccordion key={itemProgramID} title={name} hide={showAccordion(ended)}>
              <StyledBox>
                <TableTemplate
                  headCells={headCells}
                  data={itemData}
                  noFilter
                  actionCells={actionCells}
                  rowActions={[
                    {
                      name: 'zobacz',
                      action: (row) => {
                        setOpenDialog(true);
                        setCwpID(itemProgramID);
                        setCurrentItem(row);
                        setOnlyRead(true);
                      },
                    },
                    {
                      name: 'edytuj',
                      action: (row) => {
                        if (beneficiary && beneficiary.name === 'anonimizacja') {
                          showAlert(ZANONIMIZOWANO_ZAKAZ_EDYCJI);
                        } else {
                          setOpenDialog(true);
                          setCwpID(itemProgramID);
                          setCurrentItem(row);
                          setOnlyRead(false);
                        }
                      },
                    },
                    {
                      name: 'usuń',
                      action: (row) => {
                        if (beneficiary && beneficiary.name === 'anonimizacja') {
                          showAlert(ZANONIMIZOWANO_ZAKAZ_EDYCJI);
                        } else {
                          setCurrentItem(row);
                          setOpenDialogConfirm(true);
                        }
                      },
                    },
                  ]}
                />
                {(!beneficiary || beneficiary.name !== 'anonimizacja') && (
                  <StyledButton
                    startIcon={<NoteAddIcon />}
                    onClick={() => {
                      setOpenDialog(true);
                      setCwpID(itemProgramID);
                      setCurrentItem({});
                      setOnlyRead(false);
                    }}
                  >
                    Dodaj notatkę
                  </StyledButton>
                )}
              </StyledBox>
            </MyAccordion>
          );
        })
      ) : (
        <Grid item xs={12} container justifyContent="center">
          <Heading.Subtitle1>Brak danych</Heading.Subtitle1>
        </Grid>
      )}
      <DialogBeneficiaryNotes
        beneficiaryId={parseInt(id, 10)}
        cwpid={cwpID}
        item={currentItem}
        open={openDialog}
        setOpenFn={setOpenDialog}
        onlyRead={onlyRead}
      />
      {currentItem && (
        <DialogConfirm
          item={currentItem}
          removeFunction={() => removeBeneficiaryNote(currentItem.id, id)}
          title={`Potwierdź usunięcie notatki "${currentItem.name}", dane zostaną utracone!`}
          open={openDialogConfirm}
          setOpenFn={setOpenDialogConfirm}
        />
      )}
    </>
  );
};

BeneficiaryNotes.propTypes = {
  clearBeneficiaryNotes: PropTypes.func,
  fetchNotes: PropTypes.func,
  notes: PropTypes.arrayOf(PropTypes.any),
  removeBeneficiaryNote: PropTypes.func,
  showAlert: PropTypes.func,
  beneficiary: PropTypes.shape({ name: PropTypes.string, surname: PropTypes.string }),
};

BeneficiaryNotes.defaultProps = {
  clearBeneficiaryNotes: null,
  fetchNotes: null,
  notes: [],
  removeBeneficiaryNote: null,
  showAlert: null,
  beneficiary: null,
};

const mapStateToProps = ({ beneficiaryReducer }) => ({
  notes: beneficiaryReducer.notes,
  beneficiary: beneficiaryReducer.beneficiary,
});

const mapDispatchToProps = (dispatch) => ({
  clearBeneficiaryNotes: () => dispatch(clearBeneficiaryNotesAction()),
  fetchNotes: (id) => dispatch(fetchBeneficiaryNotesService(id)),
  removeBeneficiaryNote: (id, beneficiary_id) =>
    dispatch(removeBeneficiaryNoteService(id, beneficiary_id)),

  showAlert: (data) => dispatch(showAlertAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BeneficiaryNotes);
