import appReducer from 'reducers/appReducer';
import beneficiaryReducer from 'reducers/beneficiaryReducer';
import applicationReducer from 'reducers/applicationReducer';
import organizationReducer from 'reducers/organizationReducer';
import selectReducer from 'reducers/selectReducer';
import programReducer from 'reducers/programReducer';
import taskReducer from 'reducers/taskReducer';
import userReducer from 'reducers/userReducer';
import actionReducer from 'reducers/actionReducer';
import indicatorReducer from 'reducers/indicatorReducer';
import configReducer from 'reducers/configReducer';
import messageReducer from 'reducers/messageReducer';
import { combineReducers } from 'redux';

const rootReducer = combineReducers({
  appReducer,
  beneficiaryReducer,
  applicationReducer,
  organizationReducer,
  programReducer,
  taskReducer,
  userReducer,
  actionReducer,
  indicatorReducer,
  selectReducer,
  configReducer,
  messageReducer,
});

export default rootReducer;
