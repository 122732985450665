import {
  FETCH_USER,
  CLEAR_USER,
  FETCH_USERS_LOGIN_STATISTICS,
  CLEAR_USERS_LOGIN_STATISTICS,
  SHOW_USERS,
  CLEAR_USERS,
  FETCH_ME,
  CLEAR_ME,
  FETCH_USER_PERMISSIONS,
  CLEAR_USER_PERMISSIONS,
  FETCH_USER_NOTIFICATIONS,
} from 'constants/actionTypes';

const initialMe = {
  id: null,
  name: '',
  surname: '',
  user_settings: {},
  user_role: [],
  user_programs: [],
  user_organizations: [],
  organization_programs: [],
};

const me = () => {
  try {
    const localMe = window.localStorage.getItem('me');
    if (localMe) {
      return JSON.parse(localMe);
    }
    return initialMe;
  } catch (e) {
    return initialMe;
  }
};

const initialState = {
  users: [],
  user: {
    id: null,
    add_date: '',
    email: '',
    gender: 'male',
    last_modified: '',
    name: '',
    pesel: '',
    phone: '',
    second_name: '',
    status: 0,
    surname: '',
    system: '',
  },
  notifications: {
    notifications: [],
    invitations: [],
    unread_messages: 0,
    session_seconds_left: 600,
  },
  login_statistics: {},
  permissions: null,
  me: me(),
};

const userReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SHOW_USERS:
      return { ...state, users: payload.data };
    case CLEAR_USERS:
      return { ...state, users: initialState.users };
    case FETCH_USERS_LOGIN_STATISTICS:
      return { ...state, login_statistics: payload.data };
    case CLEAR_USERS_LOGIN_STATISTICS:
      return { ...state, login_statistics: initialState.login_statistics };
    case FETCH_USER:
      return { ...state, user: payload.data };
    case CLEAR_USER:
      return { ...state, user: initialState.user };
    case FETCH_USER_PERMISSIONS:
      return { ...state, permissions: payload.data };
    case CLEAR_USER_PERMISSIONS:
      return { ...state, permissions: initialState.permissions };
    case FETCH_USER_NOTIFICATIONS:
      return { ...state, notifications: payload.data };
    case FETCH_ME:
      return { ...state, me: payload.data };
    case CLEAR_ME:
      return {
        ...state,
        me: initialMe,
      };
    default:
      return state;
  }
};

export default userReducer;
