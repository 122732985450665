import axiosInstance from 'utils/axios';
import { fetchRequest, fetchSuccess, fetchFailure } from 'actions/appActions';
import {
  showTasks,
  fetchTaskAction,
  fetchTaskActionsAction,
  showTaskDiagnosisAction,
  showTaskSurveysAction,
  showTaskIPPAction,
  fetchTaskBeneficiaryAction,
  fetchTaskUsersAction,
  fetchTaskIndicatorsAction,
  fetchTaskProgramIdAction,
  fetchTaskCoordinatorsAction,
} from 'actions/taskActions';
import { checkStatusCode } from 'utils/checkStatusCode';

export const fetchTaskList = () => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .get('/zadania/nowa_lista')
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(showTasks(res.data));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
        }),
      );
    });
};

export const fetchTaskDiagnosisList = () => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .get('/zadania/programy/diagnozy')
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(showTaskDiagnosisAction(res.data));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
        }),
      );
    });
};

export const fetchTaskSurveysList = () => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .get('/zadania/programy/ankiety')
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(showTaskSurveysAction(res.data));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
        }),
      );
    });
};

export const fetchTaskIPPList = () => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .get('/zadania/programy/plany_pracy')
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(showTaskIPPAction(res.data));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
        }),
      );
    });
};

export const fetchTask = (id) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .get(`/zadania/zadanie/info/${id}`)
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(fetchTaskAction(res.data));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
        }),
      );
    });
};

export const fetchProgramIdByTask = (id) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .get(`/zadania/zadanie/programId/${id}`)
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(fetchTaskProgramIdAction(res.data));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
        }),
      );
    });
};

export const fetchTaskActions = (id) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .get(`/zadania/${id}/dzialania`)
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(fetchTaskActionsAction(res.data));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
        }),
      );
    });
};

export const fetchTaskBeneficiary = (id) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .get(`/zadania/${id}/beneficjenci`)
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(fetchTaskBeneficiaryAction(res.data));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
        }),
      );
    });
};

export const addBeneficiaries = (id, data) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .post(`/zadania/${id}/dodaj_beneficjentow`, data)
    .then((result) => {
      dispatch(
        fetchSuccess({
          type: 'success',
          message: result.data.status,
          isActive: true,
        }),
      );
      dispatch(fetchTaskBeneficiary(id));
      return result;
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err, 'Nie udało się dodać beneficjentów'),
          isActive: true,
        }),
      );
    });
};

export const fetchTaskUsers = (id) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .get(`/zadania/${id}/uzytkownicy`)
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(fetchTaskUsersAction(res.data));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
        }),
      );
    });
};

export const fetchTaskCoordinators = (id) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .get(`/zadania/${id}/uzytkownicy`) // koordynatorzy
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(fetchTaskCoordinatorsAction(res.data));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
        }),
      );
    });
};

export const fetchTaskIndicators = (id) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .get(`/zadania/${id}/wskazniki`)
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(fetchTaskIndicatorsAction(res.data));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
        }),
      );
    });
};

export const saveTaskDescription = (id, data) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .post(`/zadania/${id}/edytuj_zadanie`, data)
    .then((result) => {
      dispatch(
        fetchSuccess({
          type: 'success',
          message: result.data.status,
          isActive: true,
        }),
      );
      // dispatch(fetchTask(id)); CZY TO JEST POTRZEBNE?
      return result;
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err, 'Nie udało się edytować zadania'),
          isActive: true,
        }),
      );
    });
};

export const removeTask = (id) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .post(`/zadania/${id}/usun_zadanie`)
    .then((result) => {
      dispatch(
        fetchSuccess({
          type: 'success',
          message: result.data.status,
          isActive: true,
        }),
      );

      return result;
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err, 'Nie udało się usunąć zadania'),
          isActive: true,
        }),
      );
    });
};
