// React
import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Services
import {
  fetchOrganizationList as fetchOrganizationListService,
  fetchOrganizationProgramsSimple as fetchOrganizationsProgramsListService,
} from 'services/organizationServices';
import { fetchProgramsList as fetchProgramsListService } from 'services/programServices';
import {
  manageUserRole as manageUserRoleService,
  manageUserRoleGuardian as manageUserRoleGuardianService,
} from 'services/userServices';

// Formik
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';

// Data
import { organizationRoles, programRoles } from 'constants/selectLists';
import {
  COORDINATOR,
  ORGANIZATION_ADMINISTRATOR,
  PROGRAM_COORDINATOR,
  PROGRAM_GUARDIAN,
} from 'constants/roles';

// Elements
import { Box, Grid, DialogActions } from '@material-ui/core';
import Heading from 'components/atoms/Heading/Heading';
import Button from 'components/atoms/Button/Button';
import Select from 'components/atoms/Select/Select';
import Dialog from 'components/organisms/Dialog/Dialog';

// Styles
import { StyledWrapper } from './DialogUserPermissions.styles';

// Component
const DialogUserPermissions = ({
  user,
  item,
  open,
  setOpenFn,
  fetchOrganizationList,
  fetchOrganizationsProgramsList,
  organizationProgramsList,
  organizations,
  fetchProgramsList,
  programs,
  manageUserRole,
  manageUserRoleGuardian,
  permissions,
  me,
}) => {
  const [organizationList, setOrganizationList] = useState([]);
  const [programList, setProgramList] = useState([]);
  const triggerFetchOrganizationList = useCallback(() => {
    return fetchOrganizationList();
  }, [fetchOrganizationList]);

  useEffect(() => {
    triggerFetchOrganizationList();
  }, [triggerFetchOrganizationList]);

  const triggerFetchProgramsList = useCallback(() => {
    return fetchProgramsList();
  }, [fetchProgramsList]);

  useEffect(() => {
    if (item && item.organization_user_id && permissions && permissions.organizations) {
      const orgObj = permissions.organizations.filter(
        (org) => org.id === item.organization_user_id,
      );
      if (orgObj.length > 0) {
        fetchOrganizationsProgramsList(orgObj[0].organization_id);
      }
    }
  }, [item]);

  useEffect(() => {
    triggerFetchProgramsList();
  }, [triggerFetchProgramsList]);

  useEffect(() => {
    const newData = [];

    organizations.map((el) => {
      if (
        me.user_role.includes(COORDINATOR) ||
        me.user_organizations.some(
          (org) => org.id === el.id && org.role === ORGANIZATION_ADMINISTRATOR,
        )
      ) {
        newData.push({ name: el.name, value: el.id });
      }
      return true;
    });

    setOrganizationList(newData);
  }, [organizations, me]);

  useEffect(() => {
    const newData = [];
    programs.map((el) => {
      if (
        me.user_role.includes(COORDINATOR) ||
        me.user_programs.some(
          (prog) => prog.program_id === el.id && prog.role === PROGRAM_COORDINATOR,
        ) ||
        me.organization_programs.some((org) =>
          org.programs.some((prog) => prog.program_id === el.id),
        )
      ) {
        newData.push({ name: el.name, value: el.id });
      }
      return true;
    });

    setProgramList(newData);
  }, [programs, me]);

  return (
    <Dialog
      size="md"
      open={open}
      setOpen={setOpenFn}
      title={item && Object.keys(item).length > 0 ? 'Zmień uprawnienia' : 'Dodaj uprawnienia'}
    >
      {(!item || (item && Object.keys(item).length > 0 && item.type === 'organizacja')) &&
        organizationList &&
        organizationList.length > 0 && (
          <>
            <Heading.Subtitle3>Organizacja</Heading.Subtitle3>
            <Formik
              initialValues={{
                id: (item && Object.keys(item).length > 0 && item.permission_id) || null,
                organization: (item && Object.keys(item).length > 0 && item.id) || null,
                role: (item && Object.keys(item).length > 0 && item.role) || '',
              }}
              validationSchema={Yup.object({
                organization: Yup.number().required('pole wymagane!'),
                role: Yup.string().required('pole wymagane!'),
              })}
              enableReinitialize
              onSubmit={(values, { resetForm }) => {
                manageUserRole(user, 'organizacja', values);

                if (item && Object.keys(item).length > 0) {
                  setOpenFn(false);
                }

                resetForm({ organization: null, role: '' });
              }}
            >
              {({ handleSubmit, setFieldValue, values }) => (
                <>
                  <StyledWrapper container alignItems="center" spacing={2} style={{ marginTop: 0 }}>
                    <Grid item xs={6}>
                      <Select
                        disabled={item && Object.keys(item).length > 0}
                        label="Organizacja"
                        data={organizationList}
                        name="organization"
                        text="organization"
                        selectValue={values.organization}
                        variant="filled"
                        onChange={setFieldValue}
                      />
                      <ErrorMessage name="organization">
                        {(msg) => <div className="error-txt">{msg}</div>}
                      </ErrorMessage>
                    </Grid>
                    <Grid item xs={4}>
                      <Select
                        label="Rola"
                        data={organizationRoles}
                        name="role"
                        text="role"
                        selectValue={values.role}
                        variant="filled"
                        onChange={setFieldValue}
                      />
                      <ErrorMessage name="role">
                        {(msg) => <div className="error-txt">{msg}</div>}
                      </ErrorMessage>
                    </Grid>
                    <Grid item xs={2}>
                      <Button onClick={handleSubmit} color="primary" variant="contained">
                        {item && Object.keys(item).length > 0 ? 'Zmień' : 'Dodaj'}
                      </Button>
                    </Grid>
                  </StyledWrapper>
                </>
              )}
            </Formik>
          </>
        )}

      {(!item || (item && Object.keys(item).length > 0 && item.type === 'program')) &&
        permissions &&
        permissions.organizations &&
        permissions.organizations.length > 0 && (
          <>
            <Heading.Subtitle3>Role w Programach</Heading.Subtitle3>
            <Formik
              initialValues={{
                id: (item && Object.keys(item).length > 0 && item.permission_id) || null,
                organization_user_id:
                  (item && Object.keys(item).length > 0 && item.organization_user_id) || null,
                program_organization_id:
                  (item && Object.keys(item).length > 0 && item.program_organization_id) || null,
                role: (item && Object.keys(item).length > 0 && item.role) || '',
              }}
              validationSchema={Yup.object({
                organization_user_id: Yup.number().required('pole wymagane!'),
                program_organization_id: Yup.number().required('pole wymagane!'),
                role: Yup.string().required('pole wymagane!'),
              })}
              enableReinitialize
              onSubmit={(values, { resetForm }) => {
                manageUserRole(user, 'program', values);

                if (item && Object.keys(item).length > 0) {
                  setOpenFn(false);
                }

                resetForm({ organization: null, role: '' });
              }}
            >
              {({ handleSubmit, setFieldValue, values }) => (
                <>
                  <StyledWrapper container alignItems="center" spacing={2} style={{ marginTop: 0 }}>
                    <Grid item xs={6}>
                      <Select
                        disabled={item && Object.keys(item).length > 0}
                        label="Organizacja"
                        data={permissions.organizations.map((org) => ({
                          name: org.organization_name,
                          value: org.id,
                        }))}
                        name="organization_user_id"
                        text="organization_user_id"
                        selectValue={values.organization_user_id}
                        variant="filled"
                        onChange={(e, v) => {
                          const orgObj = permissions.organizations.filter((org) => org.id === v);
                          fetchOrganizationsProgramsList(orgObj[0].organization_id);
                          setFieldValue(e, v);
                        }}
                      />
                      <ErrorMessage name="organization">
                        {(msg) => <div className="error-txt">{msg}</div>}
                      </ErrorMessage>
                    </Grid>
                    <Grid item xs={6}>
                      <Select
                        disabled={item && Object.keys(item).length > 0}
                        label="Program"
                        data={organizationProgramsList.map((prog) => ({
                          name: prog.program_name,
                          value: prog.id,
                        }))}
                        name="program_organization_id"
                        text="program_organization_id"
                        selectValue={values.program_organization_id}
                        variant="filled"
                        onChange={setFieldValue}
                      />
                      <ErrorMessage name="program">
                        {(msg) => <div className="error-txt">{msg}</div>}
                      </ErrorMessage>
                    </Grid>
                    <Grid item xs={4}>
                      <Select
                        label="Rola"
                        data={programRoles}
                        name="role"
                        text="role"
                        selectValue={values.role}
                        variant="filled"
                        onChange={setFieldValue}
                      />
                      <ErrorMessage name="role">
                        {(msg) => <div className="error-txt">{msg}</div>}
                      </ErrorMessage>
                    </Grid>
                    <Grid item xs={2}>
                      <Button onClick={handleSubmit} color="primary" variant="contained">
                        {item && Object.keys(item).length > 0 ? 'Zmień' : 'Dodaj'}
                      </Button>
                    </Grid>
                  </StyledWrapper>
                </>
              )}
            </Formik>
          </>
        )}

      {(!item || (item && Object.keys(item).length > 0 && item.type === 'opiekun')) &&
        me.user_role.includes(COORDINATOR) &&
        programList &&
        programList.length > 0 && (
          <Box mt={2}>
            <Heading.Subtitle3>Opiekun Programu</Heading.Subtitle3>
            <Formik
              initialValues={{
                id: (item && Object.keys(item).length > 0 && item.permission_id) || null,
                program: (item && Object.keys(item).length > 0 && item.id) || null,
                role: (item && Object.keys(item).length > 0 && item.role) || PROGRAM_GUARDIAN,
              }}
              validationSchema={Yup.object({
                program: Yup.number().required('pole wymagane!'),
                role: Yup.string().required('pole wymagane!'),
              })}
              enableReinitialize
              onSubmit={(values, { resetForm }) => {
                manageUserRoleGuardian(user, values);
                if (item && Object.keys(item).length > 0) {
                  setOpenFn(false);
                }
                resetForm({ program: null, role: '' });
              }}
            >
              {({ handleSubmit, setFieldValue, values }) => (
                <>
                  <StyledWrapper container alignItems="center" spacing={2} style={{ marginTop: 0 }}>
                    <Grid item xs={6}>
                      <Select
                        disabled={item && Object.keys(item).length > 0}
                        label="Program"
                        data={programList}
                        name="program"
                        text="program"
                        selectValue={values.program}
                        variant="filled"
                        onChange={setFieldValue}
                      />
                      <ErrorMessage name="program">
                        {(msg) => <div className="error-txt">{msg}</div>}
                      </ErrorMessage>
                    </Grid>
                    <Grid item xs={4}>
                      <Select
                        label="Rola"
                        data={[
                          {
                            value: PROGRAM_GUARDIAN,
                            name: 'opiekun programu',
                          },
                        ]}
                        name="role"
                        text="role"
                        selectValue={values.role}
                        variant="filled"
                        onChange={setFieldValue}
                      />
                      <ErrorMessage name="role">
                        {(msg) => <div className="error-txt">{msg}</div>}
                      </ErrorMessage>
                    </Grid>
                    <Grid item xs={2}>
                      <Button onClick={handleSubmit} color="primary" variant="contained">
                        {item && Object.keys(item).length > 0 ? 'Zmień' : 'Dodaj'}
                      </Button>
                    </Grid>
                  </StyledWrapper>
                </>
              )}
            </Formik>
          </Box>
        )}
      <DialogActions>
        <Button onClick={() => setOpenFn(false)} color="primary">
          Zamknij
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DialogUserPermissions.propTypes = {
  user: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  setOpenFn: PropTypes.func.isRequired,
  fetchOrganizationList: PropTypes.func,
  organizations: PropTypes.arrayOf(PropTypes.any),
  fetchProgramsList: PropTypes.func,
  fetchOrganizationsProgramsList: PropTypes.func,
  programs: PropTypes.arrayOf(PropTypes.any),
  organizationProgramsList: PropTypes.arrayOf(PropTypes.any),
  item: PropTypes.shape({
    permission_id: PropTypes.number,
    id: PropTypes.number,
    organization_user_id: PropTypes.number,
    program_organization_id: PropTypes.number,
    role: PropTypes.string,
    type: PropTypes.string,
  }),
  me: PropTypes.shape({
    user_role: PropTypes.arrayOf(PropTypes.string),
    user_organizations: PropTypes.arrayOf(PropTypes.any),
    user_programs: PropTypes.arrayOf(PropTypes.any),
    organization_programs: PropTypes.arrayOf(PropTypes.any),
  }),
  manageUserRole: PropTypes.func,
  manageUserRoleGuardian: PropTypes.func,
  permissions: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.object]),
};

DialogUserPermissions.defaultProps = {
  fetchOrganizationList: null,
  organizations: [],
  organizationProgramsList: [],
  fetchProgramsList: null,
  fetchOrganizationsProgramsList: null,
  programs: [],
  item: null,
  manageUserRole: null,
  manageUserRoleGuardian: null,
  me: {
    user_role: [],
    user_programs: [],
    user_organizations: [],
    organization_programs: [],
  },
  permissions: null,
};

const mapStateToProps = ({ organizationReducer, programReducer, userReducer }) => ({
  organizations: organizationReducer.organizations,
  organizationProgramsList: organizationReducer.programs,
  programs: programReducer.programs,
  me: userReducer.me,
});

const mapDispatchToProps = (dispatch) => ({
  fetchOrganizationList: () => dispatch(fetchOrganizationListService()),
  fetchProgramsList: () => dispatch(fetchProgramsListService()),
  fetchOrganizationsProgramsList: (organization_id) =>
    dispatch(fetchOrganizationsProgramsListService(organization_id)),
  manageUserRole: (id, type, data) => dispatch(manageUserRoleService(id, type, data)),
  manageUserRoleGuardian: (id, data) => dispatch(manageUserRoleGuardianService(id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DialogUserPermissions);
