// React
import React, { useEffect, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
// Redux
import { connect } from 'react-redux';

// Services
import {
  fetchProgramActions,
  fetchActionGroups as fetchActionGroupsService,
  addActionGroup as addActionGroupService,
  removeActionGroup as removeActionGroupService,
} from 'services/programServices';

// Actions
import { clearProgramActions as clearProgramActionsAction } from 'actions/programActions';

// Data
import { filterProgramsAction } from 'constants/tableFilters';
import { getActionsList } from 'constants/other';
import { PROGRAM_COORDINATOR, TASK_COORDINATOR, COORDINATOR } from 'constants/roles';

// Elements
import TableTemplate from 'templates/TableTemplate';
import Input from 'components/atoms/Input/Input';
import Button from 'components/atoms/Button/Button';
import Dialog from 'components/organisms/Dialog/Dialog';
import FloatingButton from 'components/atoms/FloatingButton/FloatingButton';
import GuardedComponent from 'components/molecules/GuardedComponent/GuardedComponent';
import { Grid, DialogActions } from '@material-ui/core';

// Data
// const headCells = [
//   { type: 'action_name', label: 'Nazwa Działania' },
//   { type: 'action_description', label: 'Opis Działania' },
//   { type: 'lecturer', label: 'Prowadzący Działanie' },
//   { type: 'date_start', label: 'Data rozpoczęcia' },
//   { type: 'date_end', label: 'Data zakończenia' },
//   { type: 'modified_by', label: 'Modyfikowane przez' },
// ];
// Data
const headCells = [
  { type: 'action_name', label: 'Nazwa działania', nav: true },
  { type: 'action_type', label: 'Typ działania' },
  { type: 'task_name', label: 'Zadanie' },
  { type: 'description', label: 'Opis działania' },
  { type: 'lecturer', label: 'Prowadzący' },
  { type: 'beneficiaries_number', label: 'Ilość beneficjentów' },
  { type: 'hours_number', label: 'Ilość godzin' },
  { type: 'start_date', label: 'Pierwsze zajęcia' },
  { type: 'end_date', label: 'Ostatnie zajęcia' },
  { type: 'created_by', label: 'Twórca działania' },
];

// Component
const ProgramActions = ({
  clearProgramActions,
  fetchActions,
  fetchActionGroups,
  addActionGroup,
  removeActionGroup,
  data,
  actionGroups,
}) => {
  const { id } = useParams();
  const { program_actions } = data;
  const [groups, setGroups] = useState([]);
  // const [groupsValues, setGroupsValues] = useState([]);

  useEffect(() => {
    clearProgramActions();
    fetchActionGroups(id);
  }, []);

  useEffect(() => {
    if (actionGroups) {
      setGroups(actionGroups);
      // setGroupsValues(actionGroups);
    }
  }, [actionGroups]);

  const triggerFetchActions = useCallback((myid) => fetchActions(myid), [fetchActions]);

  useEffect(() => {
    triggerFetchActions(id);
  }, [id, triggerFetchActions]);

  const handleRemoveGroup = (groupId) => {
    removeActionGroup(id, groupId);
  };

  const [open, setOpen] = useState(false);
  const [newGroupName, setNewGroupName] = useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddNewGroup = () => {
    if (newGroupName) {
      addActionGroup(id, newGroupName);
      setNewGroupName('');
      handleClose();
    }
  };

  return (
    <>
      <TableTemplate
        headCells={headCells}
        data={program_actions}
        filters={filterProgramsAction}
        isnav
        link="/dzialania"
        tableName="Działania"
      />
      <GuardedComponent
        allowed_user_roles={[COORDINATOR]}
        program_id={parseInt(id, 10)}
        allowed_program_roles={[PROGRAM_COORDINATOR, TASK_COORDINATOR]}
      >
        <div>
          <h4>Zarządzanie grupami działań</h4>
          {groups &&
            groups.map((group) => (
              <Grid container spacing={2} key={group} alignItems="center">
                <Grid item xs={6}>
                  <Input
                    label="Nazwa grupy"
                    variant="filled"
                    name="action_group"
                    type="text"
                    disabled
                    readonly
                    value={group.name}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Button onClick={() => handleRemoveGroup(group.id)}>Usuń</Button>
                </Grid>
              </Grid>
            ))}
          <Button onClick={handleClickOpen}>Dodaj grupę</Button>
          <Dialog open={open} onClose={handleClose} title="Dodaj nową grupę">
            <>
              <Input
                autoFocus
                margin="dense"
                label="Nazwa grupy"
                type="text"
                fullWidth
                value={newGroupName}
                onChange={(e) => setNewGroupName(e.target.value)}
              />
              <DialogActions>
                <Button onClick={handleClose}>Anuluj</Button>
                <Button onClick={handleAddNewGroup}>Dodaj</Button>
              </DialogActions>
            </>
          </Dialog>
        </div>

        <FloatingButton actions={getActionsList} root program_id={parseInt(id, 10)} />
      </GuardedComponent>
    </>
  );
};

ProgramActions.propTypes = {
  clearProgramActions: PropTypes.func,
  fetchActions: PropTypes.func,
  fetchActionGroups: PropTypes.func,
  addActionGroup: PropTypes.func,
  removeActionGroup: PropTypes.func,
  data: PropTypes.shape({
    program_id: PropTypes.number,
    program_name: PropTypes.string,
    program_actions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        action_description: PropTypes.string,
        action_name: PropTypes.string,
        date_end: PropTypes.string,
        date_start: PropTypes.string,
        modified_by: PropTypes.string,
        lecturers: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
          }),
        ),
      }),
    ),
  }),
  actionGroups: PropTypes.arrayOf(PropTypes.string),
};

ProgramActions.defaultProps = {
  clearProgramActions: null,
  fetchActions: null,
  fetchActionGroups: null,
  addActionGroup: null,
  removeActionGroup: null,
  data: {
    program_id: null,
    program_name: '',
    program_actions: [],
  },
  actionGroups: [],
};

const mapStateToProps = ({ programReducer }) => ({
  data: programReducer.actions,
  actionGroups: programReducer.actionGroups,
});

const mapDispatchToProps = (dispatch) => ({
  clearProgramActions: () => dispatch(clearProgramActionsAction()),
  fetchActions: (id) => dispatch(fetchProgramActions(id)),
  fetchActionGroups: (id) => dispatch(fetchActionGroupsService(id)),
  addActionGroup: (id, groups) => dispatch(addActionGroupService(id, groups)),
  removeActionGroup: (id, groupId) => dispatch(removeActionGroupService(id, groupId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProgramActions);
