// React
import React, { useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Services
import { fetchTaskUsers } from 'services/taskServices';

// Actions
import { clearTaskUsers as clearTaskUsersAction } from 'actions/taskActions';

// Utils
import { isGuardianInProgram } from 'utils/functions';

// Elements
import TableTemplate from 'templates/TableTemplate';

// Data
const headCells = [
  { type: 'user_name', label: 'Imię' },
  { type: 'user_last_name', label: 'Nazwisko' },
  { type: 'phone', label: 'Telefon' },
  { type: 'email', label: 'E-mail' },
  { type: 'user_organizations', label: 'Organizacja' },
  { type: 'user_roles', label: 'Rola w programie' },
  { type: 'last_login', label: 'Data ostatniego logowania' },
  { type: 'modified_by', label: 'Modyfikowany przez' },
];

// Component
const TaskUsers = ({ clearTaskUsers, me, details, fetchList, users }) => {
  const { id } = useParams();
  const triggerFetchList = useCallback((myid) => fetchList(myid), [fetchList]);

  useEffect(() => {
    clearTaskUsers();
  }, []);

  useEffect(() => {
    triggerFetchList(id);
  }, [id, triggerFetchList]);

  return (
    <>
      {details.program && (
        <TableTemplate
          headCells={headCells}
          data={users}
          isnav={!isGuardianInProgram(me, parseInt(details.program.id, 10))}
          link="/uzytkownicy"
          tableName="Użytkownicy"
        />
      )}
    </>
  );
};

TaskUsers.propTypes = {
  clearTaskUsers: PropTypes.func,
  fetchList: PropTypes.func,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      user_gender: PropTypes.string,
      user_last_name: PropTypes.string,
      user_name: PropTypes.string,
      date_added: PropTypes.string,
      date_modified: PropTypes.string,
      modified_by: PropTypes.string,
    }),
  ),
  details: PropTypes.shape({
    program: PropTypes.shape({ id: PropTypes.number }),
  }),
  me: PropTypes.shape({
    program_roles: PropTypes.arrayOf(PropTypes.any),
  }),
};

TaskUsers.defaultProps = {
  clearTaskUsers: null,
  fetchList: null,
  users: [],
  me: null,
  details: null,
};

const mapStateToProps = ({ taskReducer, userReducer }) => ({
  users: taskReducer.users,
  details: taskReducer.task,
  me: userReducer.me,
});

const mapDispatchToProps = (dispatch) => ({
  clearTaskUsers: () => dispatch(clearTaskUsersAction()),
  fetchList: (id) => dispatch(fetchTaskUsers(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskUsers);
