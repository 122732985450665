import {
  SHOW_ORGANIZATIONS,
  CLEAR_ORGANIZATIONS,
  FETCH_ORGANIZATION,
  CLEAR_ORGANIZATION,
  FETCH_REPRESENTATIVE_LIST,
  CLEAR_REPRESENTATIVE_LIST,
  FETCH_ORGANIZATION_BENEFICIARIES_LIST,
  CLEAR_ORGANIZATION_BENEFICIARIES_LIST,
  FETCH_ORGANIZATION_PROGRAMS_LIST,
  CLEAR_ORGANIZATION_PROGRAMS_LIST,
  FETCH_ORGANIZATION_COORDINATORS_LIST,
  CLEAR_ORGANIZATION_COORDINATORS_LIST,
  FETCH_ORGANIZATION_FOR_PROGRAM,
} from 'constants/actionTypes';

export function showOrganizationsAction(payload) {
  return { type: SHOW_ORGANIZATIONS, payload };
}

export function clearOrganizations(payload) {
  return { type: CLEAR_ORGANIZATIONS, payload };
}

export function fetchOrganizationAction(payload) {
  return { type: FETCH_ORGANIZATION, payload };
}

export function fetchOrganizationsForProgram(payload) {
  return { type: FETCH_ORGANIZATION_FOR_PROGRAM, payload };
}

export function fetchRepresentativeListAction(payload) {
  return { type: FETCH_REPRESENTATIVE_LIST, payload };
}

export function clearRepresentativeList(payload) {
  return { type: CLEAR_REPRESENTATIVE_LIST, payload };
}

export function fetchOrganizationBeneficiariesAction(payload) {
  return { type: FETCH_ORGANIZATION_BENEFICIARIES_LIST, payload };
}

export function clearOrganizationBeneficiaries(payload) {
  return { type: CLEAR_ORGANIZATION_BENEFICIARIES_LIST, payload };
}

export function fetchOrganizationProgramsAction(payload) {
  return { type: FETCH_ORGANIZATION_PROGRAMS_LIST, payload };
}

export function clearOrganizationPrograms(payload) {
  return { type: CLEAR_ORGANIZATION_PROGRAMS_LIST, payload };
}

export function fetchCoordinatorsListAction(payload) {
  return { type: FETCH_ORGANIZATION_COORDINATORS_LIST, payload };
}

export function clearCoordinatorsList(payload) {
  return { type: CLEAR_ORGANIZATION_COORDINATORS_LIST, payload };
}

export function clearOrganizationAction(payload) {
  return { type: CLEAR_ORGANIZATION, payload };
}
