// Elements
import { Tabs as MatTabs, Tab, Grid } from '@material-ui/core';

// Styles
import styled, { css } from 'styled-components';

export const StyledTab = styled(Tab)`
  && {
    text-transform: none;
    min-width: 0;
    ${({ theme }) => css`
      font-size: ${theme.fontSize.body};
      font-weight: ${theme.fontWeight.regular};
      color: ${theme.color.darkGray};
      &.Mui-selected {
        color: ${theme.color.darkGray};
      }
    `}

    span {
      flex-direction: row;

      svg {
        &:first-child {
          margin-bottom: 0;
          margin-right: 1rem;
          width: 2rem;
          height: 2rem;
        }
      }
    }
  }
`;

export const StyledTabs = styled(MatTabs)`
  && {
    .MuiTabs-indicator {
      background-color: ${({ theme }) => theme.color.blue};
    }
  }
`;

export const StyledWrapper = styled(Grid)`
  && {
    margin-top: 4rem;
  }
`;
