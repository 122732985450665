// React
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';

// Services
import { saveBeneficiaryGuardianDetails as saveBeneficiaryGuardianDetailsService } from 'services/beneficiaryServices';

// Formik
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';

// Elements
import { DialogActions, Grid } from '@material-ui/core';
import Dialog from 'components/organisms/Dialog/Dialog';
import Heading from 'components/atoms/Heading/Heading';
import Select from 'components/atoms/Select/Select';

// Styles
import { StyledButton, StyledWrapper } from './BeneficiaryGuardianCopy.styles';

// Component
const BeneficiaryGuardianCopy = ({
  openDialog,
  setOpenDialog,
  organizations,
  program_organization,
  saveBeneficiaryGuardianDetails,
  dataOrgProg,
  osoba,
}) => {
  const { id } = useParams();
  const [dataOrganizations, setDataOrganizations] = useState([]);
  const [dataPrograms, setDataPrograms] = useState([]);

  useEffect(() => {
    const newDataOrg = [];
    const newDataProg = [];

    dataOrgProg.map((el) => {
      if (
        el.connectionWithOrganization &&
        el.connectionWithOrganization !== program_organization.connectionWithOrganization
      ) {
        newDataOrg.push({
          value: el.organizationId,
          name: el.name,
        });
      }

      if (
        el.connectionWithProgram &&
        el.connectionWithProgram !== program_organization.connectionWithProgram
      ) {
        newDataProg.push({
          value: el.programId,
          name: el.name,
        });
      }

      return true;
    });

    setDataOrganizations(newDataOrg);
    setDataPrograms(newDataProg);
  }, [dataOrgProg, program_organization, organizations]);

  return (
    <>
      <Dialog open={openDialog} setOpen={setOpenDialog} size="md">
        <Formik
          initialValues={{
            id: osoba && osoba.id ? osoba.id : null,
            isBeneficiaryId: osoba && osoba.isBeneficiaryId ? osoba.isBeneficiaryId : null,
            name: osoba && osoba.name ? osoba.name : '',
            surname: osoba && osoba.surname ? osoba.surname : '',
            type: osoba && osoba.type ? osoba.type.value : '',
            email: osoba && osoba.email ? osoba.email : '',
            phone: osoba && osoba.phone ? osoba.phone : '',
            legalGuardian: osoba && osoba.legalGuardian ? osoba.legalGuardian : false,
            fromOrganizationId: program_organization.organizationId,
            connectionWithProgram: null,
            connectionWithOrganization: null,
            kopia: true,
          }}
          enableReinitialize
          validationSchema={Yup.object().shape(
            {
              programId: Yup.string()
                .nullable()
                .when('organizationId', {
                  is: null,
                  then: Yup.string().nullable().required('jedno z pól jest wymagane!'),
                  otherwise: Yup.string().nullable(),
                }),
              organizationId: Yup.string()
                .nullable()
                .when('programId', {
                  is: null,
                  then: Yup.string().nullable().required('jedno z pól jest wymagane!'),
                  otherwise: Yup.string().nullable(),
                }),
            },
            [['programId', 'organizationId']],
          )}
          onSubmit={(values) => {
            saveBeneficiaryGuardianDetails(id, values);
            setOpenDialog(false);
          }}
        >
          {({ values, setFieldValue, handleSubmit }) => (
            <StyledWrapper container spacing={2}>
              <Grid item xs={12}>
                <Heading.H6>
                  Kopiowanie kontaktu: {program_organization && program_organization.name}
                </Heading.H6>
              </Grid>
              {dataOrganizations && dataOrganizations.length > 0 && (
                <>
                  <Grid item xs={8}>
                    <Select
                      label="Kopiuj do organizacji"
                      text="organizationId"
                      selectValue={values.organizationId}
                      name="organizationId"
                      data={dataOrganizations}
                      variant="filled"
                      onChange={(_, newValue) => {
                        const connectionId = dataOrgProg.find(
                          (el) => el.organizationId === newValue,
                        );

                        setFieldValue('organizationId', newValue);
                        setFieldValue(
                          'connectionWithOrganization',
                          connectionId.connectionWithOrganization,
                        );
                      }}
                    />
                    <ErrorMessage name="organizationId">
                      {(msg) => <div className="error-txt">{msg}</div>}
                    </ErrorMessage>
                  </Grid>
                  <Grid item xs={4}>
                    <StyledButton variant="contained" onClick={handleSubmit}>
                      Kopiuj
                    </StyledButton>
                  </Grid>
                </>
              )}
              {dataPrograms && dataPrograms.length > 0 && (
                <>
                  <Grid item xs={8}>
                    <Select
                      label="Kopiuj do programu"
                      text="programId"
                      selectValue={values.programId}
                      name="programId"
                      data={dataPrograms}
                      variant="filled"
                      onChange={(_, newValue) => {
                        const connectionId = dataOrgProg.find((el) => el.programId === newValue);

                        setFieldValue('programId', newValue);
                        setFieldValue('connectionWithProgram', connectionId.connectionWithProgram);
                      }}
                    />
                    <ErrorMessage name="programId">
                      {(msg) => <div className="error-txt">{msg}</div>}
                    </ErrorMessage>
                  </Grid>
                  <Grid item xs={4}>
                    <StyledButton variant="contained" onClick={handleSubmit}>
                      Kopiuj
                    </StyledButton>
                  </Grid>
                </>
              )}
              <Grid item xs={12} style={{ justifyContent: 'flex-end', display: 'flex' }}>
                <StyledButton variant="outlined" onClick={() => setOpenDialog(false)}>
                  Anuluj
                </StyledButton>
              </Grid>
            </StyledWrapper>
          )}
        </Formik>
        <DialogActions />
      </Dialog>
    </>
  );
};

BeneficiaryGuardianCopy.propTypes = {
  openDialog: PropTypes.bool,
  setOpenDialog: PropTypes.func,
  program_organization: PropTypes.shape({
    contactDetailsId: PropTypes.number,
    connectionWithProgram: PropTypes.number,
    connectionWithOrganization: PropTypes.number,
    programId: PropTypes.number,
    organizationId: PropTypes.number,
    houseNumber: PropTypes.string,
    zipCode: PropTypes.string,
    district: PropTypes.string,
    city: PropTypes.string,
    apartmentNumber: PropTypes.string,
    name: PropTypes.string,
    data: PropTypes.oneOfType([
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        street: PropTypes.string,
        houseNumber: PropTypes.string,
        apartmentNumber: PropTypes.string,
        city: PropTypes.string,
        zipCode: PropTypes.string,
        district: PropTypes.string,
        role: PropTypes.string,
        phone: PropTypes.string,
        email: PropTypes.string,
      }),
      PropTypes.arrayOf(PropTypes.any),
    ]).isRequired,
  }),
  osoba: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    surname: PropTypes.string,
    type: PropTypes.shape({ value: PropTypes.number }),
    phone: PropTypes.string,
    email: PropTypes.string,
    legalGuardian: PropTypes.bool,
    isBeneficiaryId: PropTypes.number,
  }),
  dataOrgProg: PropTypes.arrayOf(PropTypes.any),
  organizations: PropTypes.arrayOf(PropTypes.any),
  saveBeneficiaryGuardianDetails: PropTypes.func,
};

BeneficiaryGuardianCopy.defaultProps = {
  openDialog: false,
  setOpenDialog: null,
  program_organization: null,
  osoba: null,
  dataOrgProg: [],
  organizations: [],
  saveBeneficiaryGuardianDetails: null,
};

const mapDispatchToProps = (dispatch) => ({
  saveBeneficiaryGuardianDetails: (id, data) =>
    dispatch(saveBeneficiaryGuardianDetailsService(id, data)),
});

export default connect(null, mapDispatchToProps)(BeneficiaryGuardianCopy);
