// React
import React, { useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Services
import { fetchTaskActions as fetchTaskActionsService } from 'services/taskServices';

// Actions
import { clearTaskActions as clearTaskActionsAction } from 'actions/taskActions';

// Data
import { getActionsList } from 'constants/other';
import { COORDINATOR, PROGRAM_COORDINATOR, TASK_COORDINATOR } from 'constants/roles';

// Elements
import TableTemplate from 'templates/TableTemplate';
import FloatingButton from 'components/atoms/FloatingButton/FloatingButton';
import GuardedComponent from 'components/molecules/GuardedComponent/GuardedComponent';

// Data
const headCells = [
  { type: 'name', label: 'Nazwa Działania' },
  { type: 'build_in_type', label: 'Typ Działania' },
  { type: 'description', label: 'Opis Działania' },
  { type: 'owner_organization', label: 'Właściciel Działania' },
  { type: 'date_start', label: 'Data rozpoczęcia' },
  { type: 'date_end', label: 'Data zakończenia' },
  { type: 'modified_by', label: 'Modyfikowane przez' },
];

// Component
const TaskActions = ({ clearTaskActions, fetchActions, actions, task }) => {
  const { id } = useParams();

  useEffect(() => {
    clearTaskActions();
  }, []);

  const triggerFetchActions = useCallback((myid) => fetchActions(myid), [fetchActions]);

  useEffect(() => {
    triggerFetchActions(id);
  }, [id, triggerFetchActions]);

  return (
    <>
      <TableTemplate
        headCells={headCells}
        data={actions}
        isnav
        link="/dzialania"
        tableName="Działania"
      />
      {task && task.program && task.program.id && (
        <GuardedComponent
          allowed_user_roles={[COORDINATOR]}
          program_id={task && task.program && task.program.id}
          allowed_program_roles={[PROGRAM_COORDINATOR, TASK_COORDINATOR]}
        >
          <FloatingButton actions={getActionsList} root task_id={parseInt(id, 10)} />
        </GuardedComponent>
      )}
    </>
  );
};

TaskActions.propTypes = {
  clearTaskActions: PropTypes.func,
  fetchActions: PropTypes.func,
  actions: PropTypes.arrayOf(PropTypes.any),
  task: PropTypes.shape({
    program: PropTypes.shape({ id: PropTypes.number }),
  }),
};

TaskActions.defaultProps = {
  clearTaskActions: null,
  fetchActions: null,
  actions: [],
  task: null,
};

const mapStateToProps = ({ taskReducer }) => ({
  actions: taskReducer.actions,
  task: taskReducer.task,
});

const mapDispatchToProps = (dispatch) => ({
  clearTaskActions: () => dispatch(clearTaskActionsAction()),
  fetchActions: (id) => dispatch(fetchTaskActionsService(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskActions);
