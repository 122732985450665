// React
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Services
import { fetchProgram as fetchProgramService } from 'services/programServices';
import { fetchBeneficiaryList as fetchBeneficiaryListService } from 'services/beneficiaryServices';

// Actions
import { clearProgramAction } from 'actions/programActions';

// Elements
import { Box } from '@material-ui/core';
import Dialog from 'components/organisms/Dialog/Dialog';
import Select from 'components/atoms/Select/Select';
import AddNewBeneficiary from 'components/molecules/AddNewBeneficiary/AddNewBeneficiary';
import AddToProgram from 'components/molecules/AddToProgram/AddToProgram';

// Component
const DialogBeneficiaryProgram = ({
  clearProgram,
  actionCallback,
  beneficiaryId,
  programId,
  fetchProgram,
  program,
  open,
  setOpenFn,
  programBeneficiaries,
  fetchBeneficiaryList,
  beneficiaries,
  currentBeneficiary,
  newProgramList,
  refreshBeneficieryList,
  refreshBeneficieryPrograms,
  stopClear,
  stopFetch,
}) => {
  const [beneficiaryIDLocal, setBeneficiaryIDLocal] = useState(beneficiaryId);
  const [newBeneficiary, setNewBeneficiary] = useState(null);
  const [beneficiariesList, setBeneficiariesList] = useState([]);
  const [newProgramID, setNewProgramID] = useState(null);

  useEffect(() => {
    if (currentBeneficiary) {
      setNewBeneficiary(currentBeneficiary);
    }
  }, [currentBeneficiary]);

  useEffect(() => {
    // if (!beneficiaryId) {
    const newData = [{ name: 'Dodaj nowego', value: 0 }];
    const assignedBeneficiaries = programBeneficiaries && programBeneficiaries.map((el) => el.id);

    beneficiaries.map((el) => {
      if (programBeneficiaries && !assignedBeneficiaries.includes(el.id)) {
        newData.push({ name: `${el.name} ${el.surname}`, value: el.id });
      } else if (!programBeneficiaries && !assignedBeneficiaries) {
        newData.push({ name: `${el.name} ${el.surname}`, value: el.id });
      }

      return true;
    });
    setBeneficiariesList(newData);
    /// }
  }, [programBeneficiaries, beneficiaries, beneficiaryId]);

  useEffect(() => {
    if (!beneficiaryId) {
      fetchBeneficiaryList();
    }
  }, [beneficiaryId]);

  useEffect(() => {
    if (beneficiaryId) {
      setBeneficiaryIDLocal(beneficiaryId);
    }
  }, [beneficiaryId]);

  useEffect(() => {
    if ((programId || newProgramID) && !stopFetch) {
      fetchProgram(programId || newProgramID);
    }
  }, [newProgramID, programId]);

  return (
    <Dialog
      size="lg"
      open={open}
      setOpen={setOpenFn}
      title={`Przypisz beneficjenta do programu ${programId > 0 && program ? program.name : ''}`}
      onClose={() => {
        if (!stopClear) {
          setNewProgramID(null);
          clearProgram();
        }
        setOpenFn(false);
      }}
    >
      <>
        {!beneficiaryId && !currentBeneficiary && (
          <Box mb={3}>
            <Select
              label="Wybierz beneficjenta"
              data={beneficiariesList}
              variant="filled"
              name="beneficiary_id"
              text="beneficiary_id"
              selectValue={beneficiaryIDLocal}
              onChange={(e, newValue) => {
                setBeneficiaryIDLocal(newValue);
              }}
            />
          </Box>
        )}
        {beneficiaryIDLocal === 0 && (
          <AddNewBeneficiary setOpenFn={setOpenFn} setNewBeneficiary={setNewBeneficiary} />
        )}
        {!programId && (
          <Box mb={3}>
            <Select
              label="Wybierz program"
              data={newProgramList}
              variant="filled"
              name="program_id"
              text="program_id"
              selectValue={newProgramID}
              onChange={(e, newValue) => {
                setNewProgramID(newValue);
              }}
            />
          </Box>
        )}
        {((beneficiaryIDLocal && beneficiaryIDLocal !== 0) ||
          (newBeneficiary && Object.keys(newBeneficiary).length > 0)) &&
        (programId || newProgramID) ? (
          <AddToProgram
            actionCallback={actionCallback}
            beneficiaryId={beneficiaryIDLocal}
            newBeneficiary={newBeneficiary}
            program={program}
            setOpenFn={setOpenFn}
            refreshBeneficieryList={refreshBeneficieryList}
            refreshBeneficieryPrograms={refreshBeneficieryPrograms}
            setNewProgramID={setNewProgramID}
            onClose={() => {
              if (!stopClear) {
                clearProgram();
              }

              setOpenFn(false);
            }}
          />
        ) : null}
      </>
      <></>
    </Dialog>
  );
};

DialogBeneficiaryProgram.propTypes = {
  clearProgram: PropTypes.func,
  actionCallback: PropTypes.func,
  beneficiaryId: PropTypes.number,
  programId: PropTypes.number,
  fetchProgram: PropTypes.func,
  program: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  open: PropTypes.bool.isRequired,
  setOpenFn: PropTypes.func.isRequired,
  programBeneficiaries: PropTypes.arrayOf(PropTypes.any),
  fetchBeneficiaryList: PropTypes.func,
  beneficiaries: PropTypes.arrayOf(PropTypes.any),
  currentBeneficiary: PropTypes.shape({
    beneficiary_id: PropTypes.number,
    beneficiary_name: PropTypes.string,
    beneficiary_last_name: PropTypes.string,
    beneficiary_gender: PropTypes.string,
    pwd: PropTypes.number,
    educational_facility: PropTypes.string,
    date_modified: PropTypes.string,
    modified_by: PropTypes.string,
  }),
  newProgramList: PropTypes.arrayOf(PropTypes.any),
  refreshBeneficieryList: PropTypes.bool,
  refreshBeneficieryPrograms: PropTypes.bool,
  stopClear: PropTypes.bool,
  stopFetch: PropTypes.bool,
};

DialogBeneficiaryProgram.defaultProps = {
  clearProgram: null,
  actionCallback: null,
  beneficiaryId: null,
  programId: null,
  fetchProgram: null,
  program: null,
  programBeneficiaries: null,
  fetchBeneficiaryList: null,
  beneficiaries: null,
  currentBeneficiary: null,
  newProgramList: null,
  refreshBeneficieryList: false,
  refreshBeneficieryPrograms: false,
  stopClear: false,
  stopFetch: false,
};

const mapStateToProps = (state) => ({
  program: state.programReducer.program,
  beneficiaries: state.beneficiaryReducer.beneficiaries,
});

const mapDispatchToProps = (dispatch) => ({
  clearProgram: () => dispatch(clearProgramAction()),
  fetchProgram: (id) => dispatch(fetchProgramService(id)),
  fetchBeneficiaryList: () => dispatch(fetchBeneficiaryListService()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DialogBeneficiaryProgram);
