// React
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  forwardRef,
  cloneElement,
  isValidElement,
} from 'react';
import PropTypes from 'prop-types';
import { VariableSizeList } from 'react-window';

// Elements
import { TextField, Typography, useMediaQuery, ListSubheader } from '@material-ui/core';

// Styles
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { StyledAutocomplete, StyledFormControl, StyledFormHelperText } from './Autocomplete.styles';

// Data
const LISTBOX_PADDING = 8;
const renderRow = ({ data, index, style }) =>
  cloneElement(data[index], {
    style: {
      ...style,
      top: style.top + LISTBOX_PADDING,
    },
  });
const useResetCache = (data) => {
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);

  return ref;
};
const OuterElementContext = createContext({});
const OuterElementType = forwardRef((props, ref) => (
  <div ref={ref} {...props} {...useContext(OuterElementContext)} />
));

const ListboxComponent = forwardRef(function ListboxComponent(props, ref) {
  // eslint-disable-next-line react/prop-types
  const { children, ...other } = props;
  const itemData = React.Children.toArray(children);
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;
  const getChildSize = (child) => {
    if (isValidElement(child) && child.type === ListSubheader) {
      return 48;
    }

    return itemSize;
  };
  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }

    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };
  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

const renderGroup = (params) => [
  <ListSubheader key={params.key} component="div">
    {params.group}
  </ListSubheader>,
  params.children,
];

const useStyles = makeStyles({
  listbox: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});

// Component
const Autocomplete = ({
  label,
  options,
  variant,
  disabled,
  helperText,
  selectValue,
  setSelectValue,
  city,
  noborder,
  title,
}) => {
  const optionList = city
    ? options
        .map((obj) => obj)
        .sort((a, b) =>
          (a.city.toUpperCase() + a.name.toUpperCase()).localeCompare(
            b.city.toUpperCase() + b.name.toUpperCase(),
          ),
        )
    : options
        .map((obj) => obj)
        .sort((a, b) => a.name.toUpperCase().localeCompare(b.name.toUpperCase()));
  const classes = useStyles();

  return (
    <StyledFormControl disabled={disabled || false} variant={variant} noborder={noborder ? 1 : 0}>
      <StyledAutocomplete
        options={optionList}
        disableListWrap
        classes={classes}
        ListboxComponent={ListboxComponent}
        renderGroup={renderGroup}
        renderInput={(params) => <TextField {...params} variant={variant} label={label} />}
        renderOption={(option) => (
          <Typography noWrap>
            {city ? option.city : ''} {option.name}
          </Typography>
        )}
        getOptionLabel={(option) => {
          return option.name ? option.name : 'brak nazwy';
        }}
        disabled={disabled || false}
        onChange={(e, option) => {
          setSelectValue(title, option);
        }}
        value={selectValue}
      />
      <StyledFormHelperText>{helperText || null}</StyledFormHelperText>
    </StyledFormControl>
  );
};

Autocomplete.propTypes = {
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
    }),
  ),
  variant: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  helperText: PropTypes.string,
  setSelectValue: PropTypes.func,
  selectValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
    }),
  ]),
  noborder: PropTypes.bool,
  title: PropTypes.string,
  city: PropTypes.bool,
};

Autocomplete.defaultProps = {
  label: '',
  variant: 'standard',
  type: '',
  disabled: false,
  options: [],
  helperText: '',
  setSelectValue: null,
  selectValue: '',
  noborder: false,
  title: '',
  city: false,
};

export default Autocomplete;
