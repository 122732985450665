// React
import React, { useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Services
import { fetchOrganizationList } from 'services/organizationServices';

// Actions
import { clearOrganizations as clearOrganizationsAction } from 'actions/organizationActions';

// Data
import { COORDINATOR } from 'constants/roles';
import { filterOrganizations } from 'constants/tableFilters';

// Elements
import TableTemplate from 'templates/TableTemplate';
import FloatingButton from 'components/atoms/FloatingButton/FloatingButton';
import GuardedComponent from 'components/molecules/GuardedComponent/GuardedComponent';

// Data
const headCells = [
  { type: 'name', label: 'Nazwa organizacji' },
  { type: 'business_id', label: 'Identyfikator organizacji' },
  { type: 'status', label: 'Status' },
  { type: 'representative', label: 'Reprezentant' },
  { type: 'representative_phone', label: 'Telefon' },
  { type: 'representative_email', label: 'E-mail' },
];

// Component
const Organizations = ({ clearOrganizations, fetchList, organizations }) => {
  const history = useHistory();

  useEffect(() => {
    clearOrganizations();
  }, []);

  const triggerFetchList = useCallback(() => fetchList(), [fetchList]);

  useEffect(() => {
    triggerFetchList();
  }, [triggerFetchList]);

  return (
    <>
      <TableTemplate
        headCells={headCells}
        data={organizations}
        filters={filterOrganizations}
        isnav
        tableName="Organizacje"
      />
      <GuardedComponent allowed_user_roles={[COORDINATOR]}>
        <FloatingButton onClick={() => history.push('/organizacje-dodaj')} />
      </GuardedComponent>
    </>
  );
};

Organizations.propTypes = {
  clearOrganizations: PropTypes.func,
  fetchList: PropTypes.func,
  organizations: PropTypes.arrayOf(PropTypes.any),
};

Organizations.defaultProps = {
  clearOrganizations: null,
  fetchList: null,
  organizations: [],
};

const mapStateToProps = ({ organizationReducer }) => ({
  organizations: organizationReducer.organizations,
});

const mapDispatchToProps = (dispatch) => ({
  clearOrganizations: () => dispatch(clearOrganizationsAction()),
  fetchList: () => dispatch(fetchOrganizationList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Organizations);
