// React
import React from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Breadcrumbs
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';

// Data
import { taskTabs } from 'constants/tabs';

// Elements
import Tabs from 'components/molecules/Tabs/Tabs';

// Component
const Task = ({ details }) =>
  details ? (
    <>
      {details.program ? (
        <BreadcrumbsItem to={`/programy/${details.program.id}`} order={2}>
          Program: {details.program.name}
        </BreadcrumbsItem>
      ) : null}
      {details.id ? (
        <BreadcrumbsItem
          to={`/zadania/${details.id}`}
          order={4}
        >{`Zadanie: ${details.name}`}</BreadcrumbsItem>
      ) : null}

      <Tabs renderViewArray={taskTabs} program_id={details.program.id} />
    </>
  ) : null;

Task.propTypes = {
  details: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    program: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  }),
};

Task.defaultProps = {
  details: null,
};

const mapStateToProps = ({ taskReducer }) => ({
  details: taskReducer.task,
});

export default connect(mapStateToProps)(Task);
