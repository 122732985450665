// React
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Services
import {
  fetchApplicationList,
  changeApplicationStatus as changeApplicationStatusService,
} from 'services/applicationServices';

// Actions
import { clearApplications as clearApplicationsAction } from 'actions/applicationActions';

// Data
import { filterApplications } from 'constants/tableFilters';

// Elements
import { DialogActions } from '@material-ui/core';
import TableTemplate from 'templates/TableTemplate';
import Button from 'components/atoms/Button/Button';
import Dialog from 'components/organisms/Dialog/Dialog';
import Heading from 'components/atoms/Heading/Heading';
import Select from 'components/atoms/Select/Select';
import Input from 'components/atoms/Input/Input';

// Styles
import { StyledAddCircleIcon, StyledCheckCircleIcon } from './Applications.styles';
import './Applications.scss';

// Data
const headCells = [
  { type: 'creation_date', label: 'Data wnioskowania' },
  { type: 'organization', label: 'Organizacja' },
  { type: 'email', label: 'E-mail' },
  { type: 'app_type', label: 'Rodzaj wniosku' },
  { type: 'status', label: 'Status' },
];

// Component
const Applications = ({ clearApplications, fetchList, applications, changeApplicationStatus }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [openDetailsDialog, setOpenDetailsDialog] = useState(false);
  const [detailsRow, setDetailsRow] = useState(null);
  const [allowType, setAllowType] = useState(null);
  const [reason, setReason] = useState(null);
  const [comment, setComment] = useState('');
  const [clickedApplication, setClickedApplication] = useState(0);

  const triggerFetchList = useCallback(() => fetchList(), [fetchList]);

  useEffect(() => {
    clearApplications();
  }, []);

  useEffect(() => {
    triggerFetchList();
  }, [triggerFetchList]);

  const showDialog = (row, currentAllowType) => {
    setClickedApplication(row);
    setAllowType(currentAllowType);
    setReason('');
    setComment('');
    setOpenDialog(true);
  };

  const showDetailsDialog = (row) => {
    setDetailsRow(row);
    setOpenDetailsDialog(true);
  };

  const actionCells = [
    {
      column: 'Zatwierdź',
      icon: <StyledCheckCircleIcon />,
      action: (row) => showDialog(row, true),
      hideIfDisabled: true,
    },
    {
      column: 'Odrzuć',
      icon: <StyledAddCircleIcon />,
      action: (row) => showDialog(row, false),
      hideIfDisabled: true,
    },
  ];

  return (
    <>
      <TableTemplate
        headCells={headCells}
        data={applications}
        actionCells={actionCells}
        filters={filterApplications}
        tableName="Wnioski"
        rowActions={[{ name: 'zobacz szczegóły', action: (row) => showDetailsDialog(row) }]}
      />
      <Dialog
        open={openDialog}
        setOpen={setOpenDialog}
        title={
          allowType
            ? `Akceptacja wniosku:  ${clickedApplication.app_type}`
            : `Odrzucenie wniosku:  ${clickedApplication.app_type}`
        }
        statusy={clickedApplication.status}
        size="sm"
      >
        {clickedApplication.form_data !== undefined && clickedApplication !== undefined && (
          <>
            <Heading.Body2 className="heading-margin">
              <b>Imię i Nazwisko: </b>
              {clickedApplication.form_data.name} {clickedApplication.form_data.surname}
            </Heading.Body2>

            <Heading.Body2 className="heading-margin">
              <b>Email: </b> {clickedApplication.form_data.email}
            </Heading.Body2>

            <Heading.Body2 className="heading-margin">
              <b>Telefon: </b> {clickedApplication.form_data.phone}
            </Heading.Body2>

            <Heading.Body2 className="heading-margin">
              <b>Pesel: </b> {clickedApplication.form_data.pesel}
            </Heading.Body2>

            <Heading.Body2 className="heading-margin">
              <b>Data utworzenia: </b> {clickedApplication.creation_date}
            </Heading.Body2>

            {clickedApplication.app_type === 'ORGANIZATION_EXIST' && (
              <>
                <Heading.Body2 className="heading-margin">
                  <b>Organizacja: </b> {clickedApplication.organization}
                </Heading.Body2>
              </>
            )}
          </>
        )}
        <Heading.Body2 className="heading-margin">
          {allowType ? <b>Zatwierdź </b> : <b>ODRZUĆ </b>}
          <b> wniosek wybranej osoby, aby założyć konto</b>
          {clickedApplication.organization
            ? ` z ramienia Organizacji : ${clickedApplication.organization}`
            : ''}
        </Heading.Body2>
        {allowType === false && (
          <div style={{ marginTop: '2rem' }}>
            <Select
              label="Powód odrzucenia"
              variant="filled"
              data={[
                { value: '', name: 'wybierz' },
                { value: 'Niepoprawne dane', name: 'Niepoprawne dane' },
                { value: 'Powody formalne', name: 'Powody formalne' },
              ]}
              selectValue={reason}
              onChange={(name, value) => {
                setReason(value);
              }}
            />
            <Input
              style={{ margin: '2rem 0' }}
              label="Komentarz"
              variant="filled"
              type="text"
              name="comment"
              onChange={(e) => setComment(e.target.value)}
              value={comment}
            />
          </div>
        )}
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Anuluj
          </Button>
          <Button
            onClick={() => {
              changeApplicationStatus(clickedApplication.id, {
                status: allowType,
                reason,
                comment,
              });
              setOpenDialog(false);
              setOpenDetailsDialog(false);
            }}
            color="primary"
            autoFocus
            variant="outlined"
          >
            {allowType ? 'Zatwierdź' : 'Odrzuć'}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDetailsDialog}
        setOpen={setOpenDetailsDialog}
        title={detailsRow !== null && `Szczegóły wniosku:  ${detailsRow.app_type}`}
        statusy={detailsRow !== null && detailsRow.status}
        size="sm"
      >
        {detailsRow !== null ? (
          <div>
            <Heading.Body2 className="heading-margin">
              <b>Imię i Nazwisko:</b> {detailsRow.form_data.name} {detailsRow.form_data.surname}
            </Heading.Body2>
            <Heading.Body2 className="heading-margin">
              <b>Email: </b> {detailsRow.form_data.email}
            </Heading.Body2>
            <Heading.Body2 className="heading-margin">
              <b>Telefon: </b> {detailsRow.form_data.phone}
            </Heading.Body2>
            <Heading.Body2 className="heading-margin">
              <b>Pesel: </b> {detailsRow.form_data.pesel}
            </Heading.Body2>
            <Heading.Body2 className="heading-margin">
              <b>Data utworzenia: </b> {detailsRow.creation_date}
            </Heading.Body2>
            {detailsRow.reason && (
              <Heading.Body2 className="heading-margin">
                <b>Powód: </b> {detailsRow.reason}
              </Heading.Body2>
            )}
            {detailsRow.comment && (
              <Heading.Body2 className="heading-margin">
                <b>Komentarz: </b> {detailsRow.comment}
              </Heading.Body2>
            )}
            {detailsRow.app_type === 'ORGANIZATION_EXIST' && (
              <>
                <Heading.Body2 className="heading-margin">
                  <b>Organizacja: </b> {detailsRow.organization}
                </Heading.Body2>
              </>
            )}
          </div>
        ) : (
          ''
        )}
        <DialogActions className="actions-cont">
          {detailsRow !== null && detailsRow.status === 'oczekuje' && (
            <div className="buttons-cont">
              <StyledCheckCircleIcon className="yep" onClick={() => showDialog(detailsRow, true)} />
              <StyledAddCircleIcon className="nope" onClick={() => showDialog(detailsRow, false)} />
            </div>
          )}
          <Button
            onClick={() => {
              setOpenDetailsDialog(false);
            }}
            color="primary"
            autoFocus
            variant="outlined"
          >
            Zamknij
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

Applications.propTypes = {
  clearApplications: PropTypes.func,
  fetchList: PropTypes.func,
  applications: PropTypes.arrayOf(PropTypes.any),
  changeApplicationStatus: PropTypes.func,
};

Applications.defaultProps = {
  clearApplications: null,
  fetchList: null,
  applications: [],
  changeApplicationStatus: null,
};

const mapStateToProps = ({ applicationReducer }) => ({
  applications: applicationReducer.applications,
});

const mapDispatchToProps = (dispatch) => ({
  clearApplications: () => dispatch(clearApplicationsAction()),
  fetchList: () => dispatch(fetchApplicationList()),
  changeApplicationStatus: (id, data) => dispatch(changeApplicationStatusService(id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Applications);
