// React
import React, { useEffect, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Services
import { fetchTaskIndicators } from 'services/taskServices';

// Actions
import { clearTaskIndicators as clearTaskIndicatorsAction } from 'actions/taskActions';

// Elements
import TableTemplate from 'templates/TableTemplate';
import FloatingButton from 'components/atoms/FloatingButton/FloatingButton';

// Data

// rodzaj wskaźnika, rodzaj pomiaru, przedział czasowy, przypisanie, działanie, planowana liczba pomiarów

const headCells = [
  { type: 'indicator_name', label: 'Nazwa wskaźnika' },
  { type: 'indicator_kind', label: 'Rodzaj wskaźnika' },
  { type: 'indicator_type', label: 'Rodzaj pomiaru' },
  { type: 'indicator_time_period', label: 'Przedział czasowy' },
  { type: 'assigned_to', label: 'Przypisanie' },
  { type: 'action', label: 'Działanie' },
  { type: 'planned_number_of_measurements', label: 'Planowana liczba pomiarów' },
];

// Component
const TaskIndicators = ({ clearTaskIndicators, fetchList, indicators }) => {
  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    clearTaskIndicators();
  }, []);

  const triggerFetchList = useCallback((myid) => fetchList(myid), [fetchList]);

  useEffect(() => {
    triggerFetchList(id);
  }, [id, triggerFetchList]);

  return (
    <>
      <TableTemplate
        headCells={headCells}
        data={indicators}
        isnav
        link="/wskazniki"
        tableName="Wskaźniki"
      />
      <FloatingButton onClick={() => history.push(`/wskaznik-dodaj/zadanie/${id}`)} />
    </>
  );
};

TaskIndicators.propTypes = {
  clearTaskIndicators: PropTypes.func,
  fetchList: PropTypes.func,
  indicators: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      indicator_kind: PropTypes.string,
      indicator_name: PropTypes.string,
      indicator_status: PropTypes.string,
      beneficiary_name: PropTypes.string,
      indicator_time_period: PropTypes.string,
      indicator_type: PropTypes.string,
    }),
  ),
};

TaskIndicators.defaultProps = {
  clearTaskIndicators: null,
  fetchList: null,
  indicators: [],
};

const mapStateToProps = ({ taskReducer }) => ({
  indicators: taskReducer.indicators,
});

const mapDispatchToProps = (dispatch) => ({
  clearTaskIndicators: () => dispatch(clearTaskIndicatorsAction()),
  fetchList: (id) => dispatch(fetchTaskIndicators(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskIndicators);
