// React
import React, { useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Actions
import {
  closeSnackbar as closeSnackbarAction,
  clearRedirect as clearRedirectAction,
} from 'actions/appActions';

// Elements
import { Snackbar as MatSnackbar } from '@material-ui/core';

// Styles
import { StyledAlert } from './Snackbar.styles';

// Component

const CustomAlert = ({ url, children, ...props }) => {
  const handleClick = () => {
    if (url) {
      window.open(url, '_blank');
    }
  };

  return (
    <StyledAlert onClick={handleClick} {...props}>
      {children}
    </StyledAlert>
  );
};

CustomAlert.propTypes = {
  url: PropTypes.string,
  children: PropTypes.node.isRequired,
};

CustomAlert.defaultProps = {
  url: '',
};

const Snackbar = ({ snack, closeSnackbar, redirectUrl, onClose, clearRedirect }) => {
  const history = useHistory();

  useEffect(() => {
    const timeId = setTimeout(() => {
      closeSnackbar();
    }, 5000);

    return () => {
      clearTimeout(timeId);
    };
  }, []);

  const goDashboard = useCallback(() => {
    history.push('/');
  }, [history]);

  useEffect(() => {
    if (snack.code === 404) {
      goDashboard();
    }
    if (
      typeof snack.message === 'string' &&
      snack.message === 'Zaloguj się, aby wyświetlić dane.'
    ) {
      window.location.assign(`${process.env.REACT_APP_API_URL}/idp-init`);
    }
  }, [snack, goDashboard]);

  useEffect(() => {
    if (redirectUrl) {
      history.push(redirectUrl);
      clearRedirect();
    }
  }, [redirectUrl]);

  return (
    <MatSnackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={snack.isActive}
      onClose={onClose}
    >
      {snack.message ===
      'Wystąpił błąd. Zgłoś go <u>Administratorowi</u>. Podaj datę i opis zdarzenia.' ? (
        <CustomAlert url="/kontakt-z-administratorem" severity="info">
          {snack.message}
        </CustomAlert>
      ) : (
        <StyledAlert variant="filled" severity={snack.type}>
          {snack.message}
        </StyledAlert>
      )}
    </MatSnackbar>
  );
};

Snackbar.propTypes = {
  snack: PropTypes.shape({
    type: PropTypes.string,
    message: PropTypes.node || PropTypes.string,
    isActive: PropTypes.bool,
    code: PropTypes.number,
  }),
  redirectUrl: PropTypes.string,
  onClose: PropTypes.func,
  closeSnackbar: PropTypes.func,
  clearRedirect: PropTypes.func,
};

Snackbar.defaultProps = {
  snack: {
    type: '',
    message: '',
    isActive: false,
    code: 0,
  },
  redirectUrl: null,
  onClose: null,
  closeSnackbar: null,
  clearRedirect: null,
};

const mapStateToProps = ({ appReducer }) => ({
  snack: appReducer.snack,
  redirectUrl: appReducer.redirectUrl,
});

const mapDispatchToProps = (dispatch) => ({
  onClose: () => dispatch(closeSnackbarAction()),
  clearRedirect: () => dispatch(clearRedirectAction()),
  closeSnackbar: () => dispatch(closeSnackbarAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Snackbar);
