// Elements
import { Checkbox as MatCheckbox, FormControlLabel, Radio } from '@material-ui/core';

// Styles
import styled, { css } from 'styled-components';

export const StyledCheckbox = styled(MatCheckbox)`
  && {
    svg {
      width: 2rem;
      height: 2rem;
    }
  }
`;

export const StyledRadio = styled(Radio)`
  && {
    svg {
      width: 2rem;
      height: 2rem;
    }
  }
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
  && {
    margin: ${({ removemargin }) => (removemargin ? '0 !important' : '')};

    span {
      font-size: ${({ theme }) => theme.fontSize.body2};
      color: ${({ theme, color }) => (color ? theme.color[color] : theme.color.darkGray)};

      ${({ disabled }) =>
        disabled &&
        css`
          color: ${({ theme }) => theme.color.blue};
        `}
    }
  }
`;
