import axios from 'axios';

const baseURL = process.env.REACT_APP_API_URL;
const headers = {};

const axiosInstance = axios.create({
  baseURL,
  headers,
});

export default axiosInstance;
