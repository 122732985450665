// Elements
import { Grid, AccordionDetails, RadioGroup } from '@material-ui/core';
import Button from 'components/atoms/Button/Button';

// Styles
import styled from 'styled-components';

export const StyledAccordionDetails = styled(AccordionDetails)`
  flex-direction: column;
`;

export const StyledGrid = styled(Grid)`
  && {
    max-width: ${({ fullWidth }) => (fullWidth ? '100%' : '70%')};
    margin-top: ${({ margin }) => margin || '0rem'};

    &.MuiGrid-spacing-xs-2 {
      margin-top: ${({ margin }) => margin || '6rem'};
    }
  }
`;

export const StyledButton = styled(Button)`
  && {
    margin-left: ${({ margin }) => margin || '0rem'};
    padding: ${({ padding }) => padding || '1rem 2.5rem'};
    &.Mui-disabled {
      opacity: 0.5;
    }
  }
`;

export const StyledRadioGroup = styled(RadioGroup)`
  display: grid !important;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1.5rem;
`;

export const StyledText = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.base};
  font-size: ${({ theme }) => theme.fontSize.body};
  color: ${({ theme, color }) => color || theme.color.darkGray};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  text-align: ${({ align }) => align || 'left'};
  margin: 0;
`;

export const StyledEditWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 3rem;
  margin-bottom: 1rem;
`;
