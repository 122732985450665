// Elements
import { DialogActions } from '@material-ui/core';

// Styles
import styled from 'styled-components';

export const StyledDialogActions = styled(DialogActions)`
  && {
    width: 50%;
    margin-left: auto;
    margin-top: 2rem;
  }
`;
