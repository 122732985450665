// React
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Dropzone
import { useDropzone } from 'react-dropzone';

// Formik
import { Formik } from 'formik';

// Services
import {
  editMe as editMeService,
  editUser as editUserService,
  suspendMyAccount as suspendMyAccountService,
  initMyPasswordChange as initMyPasswordChangeService,
} from 'services/userServices';

// Data
import { gender } from 'constants/selectLists';

// Assets
import WarningIcon from '@material-ui/icons/Warning';

// Elements
import { Grid, DialogActions } from '@material-ui/core';
import Heading from 'components/atoms/Heading/Heading';
import Input from 'components/atoms/Input/Input';
import Select from 'components/atoms/Select/Select';
import Dialog from 'components/organisms/Dialog/Dialog';
import Button from 'components/atoms/Button/Button';
import EditButton from 'components/atoms/EditButton/EditButton';

// Styles
import {
  StyledGrid,
  StyledButton,
  DropDownWrapper,
  StyledPhotoLibraryOutlinedIcon,
  StyledTitle,
  StyledImage,
} from './UserInfo.styles';

// Component
const UserInfo = ({ editMe, initMyPasswordChange, suspendMyAccount, me }) => {
  const [isEditable, setIsEditable] = useState(false);
  const [imageDialog, setImageDialog] = useState(false);
  const [suspendAccountDialog, setSuspendAccountDialog] = useState(false);
  const [files, setFiles] = useState([]);

  const onDrop = useCallback((acceptedFiles) => {
    setFiles(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      ),
    );
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  // ??? CZEMU W TEN SPOSÓB JEST TO ZAPISANE?
  useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files],
  );

  return (
    <>
      <StyledTitle>
        <Heading.Subtitle1>Dane podstawowe</Heading.Subtitle1>
        <EditButton isEditable={isEditable || false} setIsEditable={setIsEditable} />
      </StyledTitle>
      {me.id > 0 && (
        <Formik
          initialValues={{
            name: me.name ? me.name : '',
            surname: me.surname ? me.surname : '',
            secondName: me.second_name ? me.second_name : '',
            pesel: me.pesel ? me.pesel : '',
            gender: me.gender ? me.gender : 1,
            dateAdd: me.add_date ? me.add_date : '',
            lastModified: me.last_modified ? me.last_modified : '',
            email: me.email ? me.email : '',
            phone: me.phone ? me.phone : '',
          }}
          enableReinitialize
          onSubmit={(values) => {
            editMe(values);
          }}
        >
          {({ values, handleChange, handleSubmit, setFieldValue }) => (
            <StyledGrid container spacing={2}>
              <Grid item xs={4}>
                <Input
                  disabled={!isEditable || false}
                  label="Imię"
                  helperText="Pole obowiązkowe"
                  variant="filled"
                  type="text"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4}>
                <Input
                  disabled={!isEditable || false}
                  label="Nazwisko"
                  helperText="Pole obowiązkowe"
                  variant="filled"
                  type="text"
                  name="surname"
                  value={values.surname}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4}>
                <Input
                  disabled={!isEditable || false}
                  label="Drugie imię"
                  variant="filled"
                  type="text"
                  name="secondName"
                  value={values.secondName}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4}>
                <Input
                  disabled={true || !isEditable || false}
                  label="Pesel"
                  variant="filled"
                  type="text"
                  name="pesel"
                  value={values.pesel}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4}>
                <Select
                  disabled={!isEditable || false}
                  label="Płeć"
                  data={gender}
                  selectValue={values.gender}
                  text="gender"
                  onChange={setFieldValue}
                  variant="filled"
                />
              </Grid>
              <Grid item xs={4} />
              <Grid item xs={4}>
                <Input
                  disabled={!isEditable || false}
                  label="email"
                  variant="filled"
                  type="email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4}>
                <Input
                  disabled={!isEditable || false}
                  label="Telefon kontaktowy"
                  variant="filled"
                  type="text"
                  name="phone"
                  value={values.phone}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4} />
              <Grid item xs={4}>
                <StyledButton
                  disabled={!isEditable || false}
                  variant="outlined"
                  onClick={handleSubmit}
                >
                  Zapisz
                </StyledButton>
              </Grid>
            </StyledGrid>
          )}
        </Formik>
      )}
      <StyledGrid container spacing={2}>
        <Grid item xs={12}>
          <Heading.Subtitle1>Zmień hasło</Heading.Subtitle1>
        </Grid>
        <Grid item xs={4}>
          <StyledButton
            disabled={!isEditable || false}
            variant="outlined"
            onClick={() => {
              initMyPasswordChange();
            }}
          >
            ZMIEŃ HASŁO
          </StyledButton>
        </Grid>
      </StyledGrid>
      <StyledGrid container spacing={2}>
        <Grid item xs={12}>
          <Heading.Subtitle1>Zaawansowane</Heading.Subtitle1>
        </Grid>
        <Grid item xs={5}>
          <StyledButton
            disabled={!isEditable || false}
            variant="outlined"
            color="secondary"
            onClick={() => setSuspendAccountDialog(true)}
            startIcon={<WarningIcon />}
          >
            Zawieś konto
          </StyledButton>
        </Grid>
      </StyledGrid>
      <Dialog
        open={imageDialog}
        setOpen={() => setImageDialog(false)}
        title="Wybierz zdjęcie profilowe"
      >
        <DropDownWrapper {...getRootProps()}>
          <input {...getInputProps()} />
          {isDragActive ? (
            <Grid container spacing={2} justify="center">
              <Grid item>
                {files.length === 0 ? (
                  <StyledPhotoLibraryOutlinedIcon />
                ) : (
                  <StyledImage src={files[0].preview} alt={files[0].name} />
                )}
              </Grid>
              <Grid item xs={12}>
                <Heading.Body1 align="center">Upuść zdjęcie tutaj</Heading.Body1>
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={2} justify="center">
              <Grid item>
                {files.length === 0 ? (
                  <StyledPhotoLibraryOutlinedIcon />
                ) : (
                  <StyledImage src={files[0].preview} alt={files[0].name} />
                )}
              </Grid>
              <Grid item xs={12}>
                <Heading.Body1 align="center">Przeciągnij zdjęcie profilowe tutaj</Heading.Body1>
              </Grid>
              <Grid item xs={12}>
                <Heading.Body1 align="center"> - lub - </Heading.Body1>
              </Grid>
              <Grid item>
                <Button variant="outlined">Wybierz zdjęcie z komputera</Button>
              </Grid>
            </Grid>
          )}
        </DropDownWrapper>
        <DialogActions>
          <Button onClick={() => setImageDialog(false)} color="primary">
            Anuluj
          </Button>
          <Button
            onClick={() => {
              setImageDialog(false);
              editMe(files[0].preview);
            }}
            color="primary"
            autoFocus
          >
            Zatwierdź
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={suspendAccountDialog}
        setOpen={setSuspendAccountDialog}
        title="Czy na pewno chcesz zawiesić konto?"
      >
        <Heading.Body1>
          Uwaga ! Zmiana statusu.
          <br /> <br />
          Zawieszenie konta jest procedurą nieodwracalną. Adres e-mail nie będzie mógł być użyty w
          celu ponownej rejestracji. Dane osobowe pozostaną zanonimizowane.
        </Heading.Body1>
        <DialogActions>
          <Button
            onClick={() => {
              suspendMyAccount();
              setSuspendAccountDialog(false);
            }}
            color="primary"
          >
            Tak
          </Button>
          <Button
            variant="outlined"
            onClick={() => setSuspendAccountDialog(false)}
            color="primary"
            autoFocus
          >
            Nie
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

UserInfo.propTypes = {
  editMe: PropTypes.func,
  editUser: PropTypes.func,
  suspendMyAccount: PropTypes.func,
  initMyPasswordChange: PropTypes.func,
  me: PropTypes.shape({
    id: PropTypes.number,
    add_date: PropTypes.string,
    email: PropTypes.string,
    gender: PropTypes.string,
    last_modified: PropTypes.string,
    name: PropTypes.string,
    pesel: PropTypes.string,
    phone: PropTypes.string,
    second_name: PropTypes.string,
    status_value: PropTypes.number,
    surname: PropTypes.string,
  }),
};

UserInfo.defaultProps = {
  editMe: null,
  editUser: null,
  suspendMyAccount: null,
  initMyPasswordChange: null,
  me: {
    id: null,
    add_date: '',
    email: '',
    gender: '',
    last_modified: '',
    name: '',
    pesel: '',
    phone: '',
    second_name: '',
    status_value: 0,
    surname: '',
  },
};

const mapStateToProps = ({ userReducer }) => ({
  me: userReducer.me,
});

const mapDispatchToProps = (dispatch) => ({
  initMyPasswordChange: () => dispatch(initMyPasswordChangeService()),
  suspendMyAccount: () => dispatch(suspendMyAccountService()),
  editMe: (id, data) => dispatch(editMeService(id, data)),
  editUser: (id, data) => dispatch(editUserService(id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserInfo);
