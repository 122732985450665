// React
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Elements
import { CardContent } from '@material-ui/core';
import Heading from 'components/atoms/Heading/Heading';
import BeneficiaryGuardianForm from 'views/Dashboard/Beneficiary/BeneficiaryBindings/BeneficiaryGuardianForm';

// Styles
import { StyledCard, StyledPlus } from './DashAdd.styles';

// Component
const DashAdd = ({
  connectionWithProgram,
  connectionWithOrganization,
  osoba_org_pro_name,
  shadows,
  family,
  dataBeneficiaries,
  programId,
  programName,
  organizationId,
  organizationName,
}) => {
  const [openDialog, setOpenDialog] = useState(false);

  return (
    <StyledCard shadows={shadows}>
      <CardContent>
        <StyledPlus
          onClick={() => {
            setOpenDialog(true);
          }}
        />
        <Heading.Subtitle3>Dodaj kontakt</Heading.Subtitle3>
      </CardContent>
      <BeneficiaryGuardianForm
        family={!!family}
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        osoba_org_pro_name={osoba_org_pro_name}
        connectionWithProgram={connectionWithProgram}
        connectionWithOrganization={connectionWithOrganization}
        dataBeneficiaries={dataBeneficiaries}
        programId={programId}
        programName={programName}
        organizationId={organizationId}
        organizationName={organizationName}
      />
    </StyledCard>
  );
};

DashAdd.propTypes = {
  osoba_org_pro_name: PropTypes.string,
  connectionWithProgram: PropTypes.number,
  connectionWithOrganization: PropTypes.number,
  programId: PropTypes.number,
  programName: PropTypes.string,
  organizationId: PropTypes.number,
  organizationName: PropTypes.string,
  family: PropTypes.bool,
  program: PropTypes.shape({
    programId: PropTypes.number,
    name: PropTypes.string,
  }),
  dataBeneficiaries: PropTypes.arrayOf(PropTypes.any),
  shadows: PropTypes.string,
};

DashAdd.defaultProps = {
  osoba_org_pro_name: '',
  connectionWithProgram: null,
  connectionWithOrganization: null,
  programId: null,
  programName: null,
  organizationId: null,
  organizationName: null,
  family: null,
  dataBeneficiaries: [],
  shadows: 'rgba(0, 0, 0, 0.072)',
  program: null,
};

export default DashAdd;
