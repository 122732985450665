// React
import React from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Component
const GuardedComponent = ({
  children,
  me,
  allowed_user_roles,
  allowed_organization_roles,
  organization_id,
  parent_organization_id,
  allowed_program_roles,
  program_id,
  message,
}) => {
  if (
    (allowed_user_roles &&
      me.user_role &&
      allowed_user_roles.some((r) => me.user_role.includes(r))) ||
    (allowed_organization_roles &&
      organization_id &&
      me.user_organizations &&
      me.user_organizations.some(
        (org) => allowed_organization_roles.includes(org.role) && org.id === organization_id,
      )) ||
    (allowed_organization_roles &&
      parent_organization_id &&
      me.user_organizations &&
      me.user_organizations.some(
        (org) => allowed_organization_roles.includes(org.role) && org.id === parent_organization_id,
      )) ||
    (allowed_program_roles &&
      program_id &&
      me.user_programs &&
      me.user_programs.some(
        (prog) => allowed_program_roles.includes(prog.role) && prog.program_id === program_id,
      ))
  ) {
    return <>{children}</>;
  }

  return <>{message}</>;
};

GuardedComponent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  allowed_user_roles: PropTypes.arrayOf(PropTypes.string),
  allowed_organization_roles: PropTypes.arrayOf(PropTypes.string),
  allowed_program_roles: PropTypes.arrayOf(PropTypes.string),
  organization_id: PropTypes.number,
  parent_organization_id: PropTypes.number,
  program_id: PropTypes.number,
  message: PropTypes.string,
  me: PropTypes.shape({
    user_role: PropTypes.arrayOf(PropTypes.string),
    user_organizations: PropTypes.arrayOf(PropTypes.any),
    user_programs: PropTypes.arrayOf(PropTypes.any),
  }),
};

GuardedComponent.defaultProps = {
  allowed_user_roles: [],
  allowed_organization_roles: [],
  allowed_program_roles: [],
  organization_id: null,
  parent_organization_id: null,
  program_id: null,
  message: null,
  me: {
    user_role: [],
    user_programs: [],
    user_organizations: [],
  },
};

const mapStateToProps = ({ userReducer }) => ({
  me: userReducer.me,
});

export default connect(mapStateToProps)(GuardedComponent);
