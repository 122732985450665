// Elements
import { DialogActions } from '@material-ui/core';

// Styles
import styled from 'styled-components';

export const StyledWrapper = styled.div`
  && {
    > * {
      &:not(:last-child) {
        margin-bottom: 2rem;
      }
    }
  }
`;

export const StyledDialogActions = styled(DialogActions)`
  && {
    width: 50%;
    margin-left: auto;
    margin-top: 2rem;
  }
`;
