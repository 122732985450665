// Styles
import styled from 'styled-components';

// Elements
import { Grid, Button } from '@material-ui/core';

export const StyledWrapper = styled(Grid)`
  && {
    margin-top: 4rem;
  }
`;

export const StyledButton = styled(Button)`
  && {
    padding: 1rem 2rem;
  }
`;
