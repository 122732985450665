// Elements
import { Dialog as MatDialog } from '@material-ui/core';

// Styles
import styled from 'styled-components';

export const StyledDialog = styled(MatDialog)`
  && {
    .MuiPaper-root {
      background-color: ${({ theme }) => theme.color.white};
    }
  }
`;
