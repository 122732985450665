// React
import React from 'react';
import PropTypes from 'prop-types';

// Date FNS
// import { format } from 'date-fns';
// import pl from 'date-fns/locale/pl';

// eslint-disable-next-line import/no-extraneous-dependencies
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'dayjs/locale/pl';

// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
// eslint-disable-next-line import/no-extraneous-dependencies
import { LocalizationProvider } from '@mui/x-date-pickers';
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// eslint-disable-next-line import/no-extraneous-dependencies
import dayjs from 'dayjs';
// Styles
import { StyledDatePicker, StyledTextField } from './Calendar.styles';
import './Calendar.scss';

// Component
const Calendar = ({
  label,
  // variant,
  disabled,
  disableFuture,
  onChange,
  value,
  name,
  // calendarToolbar,
  // autoOk,
  // placeholder,
  minDate,
  // clearable,
}) => {
  // dayjs.locale('pl');

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pl">
      <StyledDatePicker
        // disableToolbar={calendarToolbar}
        // autoOk={autoOk}
        variant="inline"
        // clearable={clearable}
        // clearText="wyczyść"
        // inputVariant={variant}
        // placeholder={placeholder}
        // inputFormat="yyyy-MM-dd"
        minDate={minDate ? dayjs(minDate) : null}
        name={name}
        label={label}
        // invalidDateMessage="niepoprawny format daty"
        // margin="normal"
        // type="text"
        // mask="____-__-__"
        disableFuture={disableFuture || null}
        value={dayjs(value) || null}
        disabled={disabled}
        disableOpenPicker={disabled}
        onChange={(e) => {
          // if (Object.prototype.toString.call(e) === '[object Date]') {
          // if (!Number.isNaN(e.getTime())) {
          // console.log('Calendar', e, dayjs(e).format('YYYY-MM-DD')); // yyyy-MM-dd
          // const date = format(;
          onChange(name, dayjs(e).format('YYYY-MM-DD'));
          // }
          // }
        }}
        // KeyboardButtonProps={{
        //   'aria-label': 'change date',
        // }}
        renderInput={(props) => (
          <StyledTextField label={label} helperText="" {...props} disabled={disabled || false} />
        )}
      />
    </LocalizationProvider>
  );
};

Calendar.propTypes = {
  label: PropTypes.string,
  calendarToolbar: PropTypes.bool,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  disableFuture: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.string,
  name: PropTypes.string,
  autoOk: PropTypes.bool,
  clearable: PropTypes.bool,
  placeholder: PropTypes.string,
  minDate: PropTypes.string,
};

Calendar.defaultProps = {
  label: 'Data',
  calendarToolbar: true,
  variant: 'standard',
  disabled: false,
  disableFuture: false,
  onChange: null,
  value: '',
  minDate: null,
  autoOk: true,
  name: '',
  clearable: false,
  placeholder: '',
};

export default Calendar;
