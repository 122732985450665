// Elements
import Button from 'components/atoms/Button/Button';
import { Grid } from '@material-ui/core';

// Styles
import styled from 'styled-components';

export const StyledButton = styled(Button)`
  && {
    padding: ${({ padding }) => padding || '1rem 2.5rem'};
    margin: 1rem;
  }
`;

export const StyledGridItem = styled(Grid)`
  && {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`;
