// Assets
import SearchIcon from '@material-ui/icons/Search';

// Elements
import { Grid, TextField } from '@material-ui/core';

// Styles
import styled, { css } from 'styled-components';

const iconStyles = css`
  && {
    font-size: 2.5rem;
    fill: ${({ theme }) => theme.color.darkGray};
  }
`;

export const StyledTextSearch = styled(Grid)`
  && {
    width: 0;
    overflow: hidden;
    transition: 0.4s;
    ${({ show }) =>
      show &&
      css`
        width: 15rem;
      `}
  }
`;

export const StyledGridWrapper = styled(Grid)`
  && {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-end;
    width: auto;

    & > .MuiGrid-item {
      padding: 0;
    }
  }
`;

export const StyledTextField = styled(TextField)`
  && {
    input {
      font-size: ${({ theme }) => theme.fontSize.body2};
      padding: 10px;
    }
  }
`;

export const StyledSearchIcon = styled(SearchIcon)`
  ${iconStyles}
`;
