// React
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Elements
import { MenuItem, IconButton } from '@material-ui/core';
import Heading from 'components/atoms/Heading/Heading';

// Styles
import { StyledMoreVertIcon, StyledMenu } from './MoreIcon.styles';

// Component
const MoreIcon = ({ size, actions, row, index }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <>
      <IconButton edge="start" aria-label="menu" onClick={(e) => setAnchorEl(e.currentTarget)}>
        <StyledMoreVertIcon size={size} />
      </IconButton>
      <StyledMenu
        id="dotsMenu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {actions.map(({ name, action }) =>
          name && action ? (
            <MenuItem
              key={name}
              onClick={() => {
                action(row, index);
                setAnchorEl(null);
              }}
            >
              <Heading.Body2>{name}</Heading.Body2>
            </MenuItem>
          ) : null,
        )}
      </StyledMenu>
    </>
  );
};

MoreIcon.propTypes = {
  size: PropTypes.string,
  actions: PropTypes.arrayOf(PropTypes.any),
  row: PropTypes.objectOf(PropTypes.any),
  index: PropTypes.number,
};

MoreIcon.defaultProps = {
  size: '',
  index: null,
  actions: [],
  row: null,
};

export default MoreIcon;
