// import { guardianTypeAdm, guardianTypeFamily } from 'constants/selectLists';

export const surveyQuestions = [
  {
    value: 'beneficiary_data',
    name: 'Dane uczestnika',
    isRow: true,
    radioLabels: [
      { value: 'name_surname', name: 'Imię i nazwisko', required: true },
      { value: 'pesel', name: 'Pesel / paszport', required: true },
      { value: 'gender', name: 'Płeć' },
    ],
  },
  {
    value: 'contact',
    name: 'Kontakty',
    isRow: false,
    radioLabels: [
      {
        value: 'contactDetails',
        name: 'Dane kontaktowe',
      },
      {
        value: 'guardianTypeFamily',
        name: 'Powiązania rodzinne',
        // subLabels: guardianTypeFamily,
      },
      {
        value: 'guardianTypeAdm',
        name: 'Powiązania administracyjne',
        // subLabels: guardianTypeAdm,
      },
      {
        value: 'current_edu_facility',
        name: 'Placówka edukacyjna',
      },
      // {
      //   value: 'previous_facility',
      //   name: 'Poprzednie placówki edukacyjne',
      // },
    ],
  },
  {
    value: 'programs_and_actions',
    name: 'Programy i działania',
    isRow: true,
    radioLabels: [
      { value: 'all', name: 'Udostępnij wszystkie działania i zadania' },
      {
        value: 'actions',
        name: 'Udostępniaj tylko działania i zadania przypisanie do organizacji',
      },
    ],
  },
  {
    value: 'supported_subjects',
    name: 'Przedmioty wspierane',
    isRow: true,
    onlyOne: true,
    radioLabels: [{ value: 'supported_subjects', name: 'Przedmioty Wspierany' }],
  },
  {
    value: 'identificatorKind',
    name: 'Wskaźniki',
    isRow: true,
    radioLabels: [
      { value: 'identificatorKind1', name: 'Wskaźniki konkursowe' }, // ??? ZMIENIONE VALUE Z 1 na identificatorKind1 - problem z czyszczeniem formika
      { value: 'identificatorKind2', name: 'Wskaźniki własne organizacji' }, // ??? ZMIENIONE VALUE Z 2 na identificatorKind2 - problem z czyszczeniem formika
    ],
  },
  {
    value: 'diagnosis',
    name: 'Diagnozy',
    isRow: true,
    radioLabels: [
      { value: 'diagnosis', name: 'Diagnozy' },
      { value: 'surveys', name: 'Ankiety diagnostyczno-ewaluacyjnych' },
    ],
  },
  {
    value: 'ipp',
    name: 'Indywidualne Plany Pracy',
    isRow: true,
    onlyOne: true,
    radioLabels: [{ value: 'ipp', name: 'Indywidualne Plany Pracy' }],
  },
  {
    value: 'documents',
    name: 'Dokumenty',
    isRow: true,
    onlyOne: true,
    radioLabels: [{ value: 'documents', name: 'Dokumenty' }],
  },
  {
    value: 'notes',
    name: 'Notatki',
    isRow: true,
    onlyOne: true,
    radioLabels: [{ value: 'notes', name: 'Notatki' }],
  },
];
