// React
import React from 'react';
import ReactDOM from 'react-dom';

// Through React
import { ThroughProvider } from 'react-through';

// Language
import { plPL } from '@material-ui/core/locale';

// Elements
import Root from 'views/Root/Root';

// Styles
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import ContextProvider from './context';
import './index.scss';

// Theme
const theme = createTheme(
  {
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 750,
        lg: 1280,
        xl: 1920,
      },
    },
    palette: {
      color: {
        red: {
          main: '#E3191B',
        },
        blue: {
          main: '#076BB3',
        },
        yellow: {
          main: '#F8B227',
        },
      },
    },
  },
  plPL,
);

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <ContextProvider>
      <ThroughProvider>
        <Root />
      </ThroughProvider>
    </ContextProvider>
  </MuiThemeProvider>,
  document.getElementById('root'),
);
