// Elements
import { Grid, Chip, DialogActions } from '@material-ui/core';
import Button from 'components/atoms/Button/Button';

// Styles
import styled from 'styled-components';

export const StyledWrapper = styled.div`
  && {
    margin-top: 4rem;
  }
  label {
    color: yellow;
  }
`;

export const StyledGrid = styled(Grid)`
  && {
    label {
      color: black;
    }
  }
`;

export const StyledBox = styled.div`
  && {
    margin-top: 2rem;
  }
`;

export const StyledItem = styled.div`
  && {
    margin-top: 1rem;
    & > * {
      margin-right: 1rem;
    }
  }
`;

export const StyledDialogActions = styled(DialogActions)`
  && {
    margin-top: 1rem;
  }
`;

export const StyledChip = styled(Chip)`
  && {
    font-size: ${({ theme }) => theme.fontSize.overline};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    color: ${({ theme }) => theme.color.white};
    background-color: ${({ theme }) => theme.color.blue};
  }
`;
export const StyledButton = styled(Button)`
  && {
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    font-size: ${({ theme }) => theme.fontSize.overline};
    color: ${({ theme }) => theme.color.gray};
    transition: 0.3s;

    &:hover {
      color: ${({ theme }) => theme.color.darkGray};
    }
  }
`;
