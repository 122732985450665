// React
import React from 'react';
import PropTypes from 'prop-types';

import ReactExport from 'react-data-export';

import { Grid } from '@material-ui/core';
import { StyledButton } from './AddReport.styles';

// Component
const ReportExcel = ({ data, filename }) => {
  const { ExcelFile } = ReactExport;
  const { ExcelSheet } = ReactExport.ExcelFile;
  const { ExcelColumn } = ReactExport.ExcelFile;

  return (
    <ExcelFile
      hideElement
      filename={filename}
      element={
        <Grid item xs={12}>
          <StyledButton variant="outlined">Pobierz raport</StyledButton>
        </Grid>
      }
    >
      <ExcelSheet data={data.data} name="Raport">
        {data.columns.map((el) => (
          <ExcelColumn label={el.title} value={el.name} key={el.name} />
        ))}
      </ExcelSheet>
    </ExcelFile>
  );
};

ReportExcel.propTypes = {
  data: PropTypes.shape({
    columns: PropTypes.arrayOf(PropTypes.any),
    data: PropTypes.arrayOf(PropTypes.any),
  }),
  filename: PropTypes.string,
};

ReportExcel.defaultProps = {
  data: null,
  filename: null,
};

export default ReportExcel;
