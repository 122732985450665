// Elements
import { Grid } from '@material-ui/core';
import Button from 'components/atoms/Button/Button';

// Styles
import styled from 'styled-components';

export const StyledGrid = styled(Grid)`
  && {
    max-width: 40%;
    &.MuiGrid-spacing-xs-2 {
      margin-top: 1rem;
    }
  }
`;

export const StyledTitle = styled.div`
  && {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 3rem;
    padding-right: 1px;
  }
`;

export const StyledButton = styled(Button)`
  && {
    padding: 1rem 2.5rem;
  }
`;

export const StyledActions = styled(Grid)`
  && {
    margin-top: 2rem;
  }
`;
