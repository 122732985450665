import {
  FETCH_BENEFICIARY,
  CLEAR_BENEFICIARY,
  FETCH_BENEFICIARY_ADDED,
  FETCH_BENEFICIARY_INDICATORS,
  CLEAR_BENEFICIARY_INDICATORS,
  SHOW_BENEFICIARIES,
  CLEAR_BENEFICIARIES,
  FETCH_BENEFICIARY_BINDINGS,
  CLEAR_BENEFICIARY_BINDINGS,
  FETCH_BENEFICIARY_RODO,
  CLEAR_BENEFICIARY_RODO,
  FETCH_BENEFICIARY_PROGRAMS,
  CLEAR_BENEFICIARY_PROGRAMS,
  FETCH_BENEFICIARY_PROGRAMS_SIMPLE,
  CLEAR_BENEFICIARY_PROGRAMS_SIMPLE,
  FETCH_BENEFICIARY_SUPPORT,
  CLEAR_BENEFICIARY_SUPPORT,
  FETCH_BENEFICIARY_DIAGNOSIS,
  CLEAR_BENEFICIARY_DIAGNOSIS,
  FETCH_BENEFICIARY_IPP,
  CLEAR_BENEFICIARY_IPP,
  FETCH_BENEFICIARY_DOCUMENTS,
  CLEAR_BENEFICIARY_DOCUMENTS,
  FETCH_BENEFICIARY_NOTES,
  CLEAR_BENEFICIARY_NOTES,
  FETCH_NEW_BENEFICIARY_PROGRAMS,
  FETCH_CWP_INFO,
  CLEAR_CWP_INFO,
  FETCH_SINGLE_DIAGNOSE,
  CLEAR_SINGLE_DIAGNOSE,
  FETCH_SINGLE_SURVEY,
  CLEAR_SINGLE_SURVEY,
  FETCH_CWP_LIST,
  CLEAR_CWP_LIST,
  FETCH_SINGLE_IPP,
  CLEAR_SINGLE_IPP,
  FETCH_BENEFICIARY_ORGANIZATIONS,
  CLEAR_BENEFICIARY_ORGANIZATIONS,
} from 'constants/actionTypes';

const initialState = {
  beneficiaries: [],
  beneficiariesLoaded: false,
  beneficiary: {
    id: null,
    name: '',
    surname: '',
    dob: '',
    pesel: '',
    passport: '',
    gender: 'male',
    participant_type: null,
    date_add: '',
    date_modified: '',
    pwd: '',
    pwd_id: null,
    who_added_beneficiary_to_program: '',
    date_added_to_program: '',
    contact_details: {
      street: '',
      house_number: '',
      apartment_number: null,
      zip_code: '',
      district: '',
      city: '',
      province: '',
    },
    current_edu_facility: {
      name: '',
      street: '',
      house_number: '',
      apartment_number: null,
      zip_code: '',
      city: '',
    },
  },
  indicators: {
    details: {
      beneficiary_id: null,
      beneficiary_full_name: '',
      frequencies: {
        time_periods: [],
        values: [],
      },
      degrees: {
        time_periods: [],
        subjects: [],
      },
    },
    indicators: [],
  },
  bindings: {
    contactDetails: null,
    family: null,
    administrative: null,
    educational: null,
  },
  programs: [],
  rodo: { administrators: [], coadministrators: [], processors: [] },
  supportedSubjects: {
    id: null,
    name: '',
    supported_subjects: [],
  },
  diagnosis: [],
  ipps: null,
  documents: null,
  notes: null,
  cwp_info: {},
  single_ipp: {},
};

const beneficiaryReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SHOW_BENEFICIARIES:
      return { ...state, beneficiaries: payload.data, beneficiariesLoaded: true };
    case CLEAR_BENEFICIARIES:
      return {
        ...state,
        beneficiaries: initialState.beneficiaries,
        beneficiariesLoaded: initialState.beneficiariesLoaded,
      };
    case FETCH_BENEFICIARY:
      return { ...state, beneficiary: payload.data };
    case CLEAR_BENEFICIARY:
      return { ...state, beneficiary: initialState.beneficiary };
    case FETCH_BENEFICIARY_ADDED:
      return {
        ...state,
        beneficiary: payload.data.beneficiary,
      };
    case FETCH_BENEFICIARY_INDICATORS:
      return { ...state, indicators: payload.data };
    case CLEAR_BENEFICIARY_INDICATORS:
      return { ...state, indicators: initialState.indicators };
    case FETCH_BENEFICIARY_BINDINGS:
      return { ...state, bindings: payload.data };
    case CLEAR_BENEFICIARY_BINDINGS:
      return { ...state, bindings: initialState.bindings };
    case FETCH_BENEFICIARY_RODO:
      return { ...state, rodo: payload.data };
    case CLEAR_BENEFICIARY_RODO:
      return { ...state, rodo: initialState.rodo };
    case FETCH_BENEFICIARY_PROGRAMS:
      return { ...state, programs: payload.data };
    case CLEAR_BENEFICIARY_PROGRAMS:
      return { ...state, programs: initialState.programs };
    case FETCH_BENEFICIARY_PROGRAMS_SIMPLE:
      return { ...state, programs_simple: payload.data };
    case CLEAR_BENEFICIARY_PROGRAMS_SIMPLE:
      return { ...state, programs_simple: initialState.programs_simple };
    case FETCH_BENEFICIARY_ORGANIZATIONS:
      return { ...state, organizations: payload.data };
    case CLEAR_BENEFICIARY_ORGANIZATIONS:
      return { ...state, organizations: initialState.organizations };
    case FETCH_BENEFICIARY_SUPPORT:
      return { ...state, supportedSubjects: payload.data };
    case CLEAR_BENEFICIARY_SUPPORT:
      return { ...state, supportedSubjects: initialState.supportedSubjects };
    case FETCH_BENEFICIARY_DIAGNOSIS:
      return { ...state, diagnosis: payload.data };
    case CLEAR_BENEFICIARY_DIAGNOSIS:
      return { ...state, diagnosis: initialState.diagnosis };
    case FETCH_BENEFICIARY_IPP:
      return { ...state, ipps: payload.data };
    case CLEAR_BENEFICIARY_IPP:
      return { ...state, ipps: initialState.ipps };
    case FETCH_BENEFICIARY_DOCUMENTS:
      return { ...state, documents: payload.data };
    case CLEAR_BENEFICIARY_DOCUMENTS:
      return { ...state, documents: initialState.documents };
    case FETCH_BENEFICIARY_NOTES:
      return { ...state, notes: payload };
    case CLEAR_BENEFICIARY_NOTES:
      return { ...state, notes: initialState.notes };
    case FETCH_NEW_BENEFICIARY_PROGRAMS:
      return {
        ...state,
        newBeneficiary: payload.data.beneficiary,
        connections: payload.data.connections,
      };
    case FETCH_CWP_INFO:
      return { ...state, cwp_info: payload };
    case CLEAR_CWP_INFO:
      return { ...state, cwp_info: initialState.cwp_info };
    case FETCH_SINGLE_DIAGNOSE:
      return { ...state, single_diagnose: payload.data };
    case CLEAR_SINGLE_DIAGNOSE:
      return { ...state, single_diagnose: initialState.single_diagnose };
    case FETCH_SINGLE_SURVEY:
      return { ...state, single_survey: payload.data };
    case CLEAR_SINGLE_SURVEY:
      return { ...state, single_survey: initialState.single_survey };
    case FETCH_SINGLE_IPP:
      return { ...state, single_ipp: payload.data };
    case CLEAR_SINGLE_IPP:
      return { ...state, single_ipp: initialState.single_ipp };
    case FETCH_CWP_LIST:
      return { ...state, cwp_list: payload.data };
    case CLEAR_CWP_LIST:
      return { ...state, cwp_list: initialState.cwp_list };
    default:
      return state;
  }
};

export default beneficiaryReducer;
