// React
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Services
import { fetchEducationalFacilitiesList as fetchEducationalFacilitiesListService } from 'services/selectServices';
import {
  saveNewBeneficiary as saveNewBeneficiaryService,
  fetchBeneficiary as fetchBeneficiaryService,
  fetchBeneficiaryProgramsSimple as fetchBeneficiaryProgramsSimpleService,
  fetchBeneficiaryOrganizations as fetchBeneficiaryOrganizationsService,
} from 'services/beneficiaryServices';
import {
  fetchProgramBeneficiaries as fetchProgramBeneficiariesService,
  fetchProgramDataAdministrators as fetchProgramDataAdministratorsService,
  fetchProgram as fetchProgramService,
} from 'services/programServices';

// Data
import { beneficiaryType } from 'constants/selectLists';

// Formik
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';

// Date FNS
import { format } from 'date-fns';

// Elements
import { Box, Grid } from '@material-ui/core';
import Button from 'components/atoms/Button/Button';
import Select from 'components/atoms/Select/Select';
import Checkbox from 'components/atoms/Checkbox/Checkbox';
import Calendar from 'components/atoms/Calendar/Calendar';

// Styles
import Heading from 'components/atoms/Heading/Heading';
import { StyledTitle, StyledBorder } from './AddToProgram.styles';

// Component
const AddToProgram = ({
  actionCallback,
  saveNewBeneficiary,
  fetchBeneficiary,
  beneficiary,
  beneficiaryId,
  setOpenFn,
  program,
  fetchEducationalFacilitiesList,
  educationalFacilitiesList,
  fetchProgramBeneficiaries,
  refreshBeneficieryList,
  refreshBeneficieryPrograms,
  fetchBeneficiaryProgramsSimple,
  fetchBeneficiaryOrganizations,
  newBeneficiary,
  headerTitle,
  setNewProgramID,
  addToProgramId,
  onClose,
  updateBeneficiaryId,
  fetchProgramDataAdministrators,
  fetchProgram,
  dataAdministrators,
}) => {
  useEffect(() => {
    if (beneficiaryId) {
      fetchBeneficiary(beneficiaryId);
    }
  }, [beneficiaryId]);

  useEffect(() => {
    fetchEducationalFacilitiesList();
  }, []);
  useEffect(() => {
    if (program && program.id) {
      fetchProgramDataAdministrators(program.id);
    } else if (addToProgramId) {
      fetchProgram(addToProgramId);
      fetchProgramDataAdministrators(addToProgramId);
    }
  }, [program, addToProgramId]);

  const initialVal = {
    id: addToProgramId || program.id,
    dateAddedToProgram: format(new Date(), 'yyyy-MM-dd'),
    beneficiaryType: '',
    assignedToProgramBySchool: false,
    dateAddedBySchoolToProgram: '',
    whoAssignedToProgram: '',
    approvalToUseImage: false,
    presidentsInformationClause: false,
    consortiumInformationClause: false,
    approvalToTransferOfInformation: false,
    approvalToIndependentReturnHome: false,
    dataAdministrators,
    // dataAdministrators && dataAdministrators.length > 1 ? dataAdministrators[0].id : null,
  };

  return (
    <Formik
      initialValues={initialVal}
      validationSchema={Yup.object().shape({
        dateAddedToProgram: Yup.string()
          .required('To pole jest wymagane!')
          .test(
            'date_start',
            'Data nie może być wcześniejsza niż start programu!',
            (val) => val >= program.date_start,
          ),
        beneficiaryType: Yup.string().required('To pole jest wymagane!'),
        dataAdministrators: Yup.array()
          .of(
            Yup.object().shape({
              isBeneficiaryAdministrator: Yup.boolean(),
            }),
          )
          .compact((v) => v.isBeneficiaryAdministrator === false)
          .min(1, 'To pole jest wymagane!'),
      })}
      enableReinitialize
      onSubmit={(values) => {
        saveNewBeneficiary(beneficiaryId > 0 ? beneficiary : newBeneficiary, {
          program: values,
        }).then((res) => {
          if (res && res.data && res.data.cwp_id) {
            if (actionCallback) {
              actionCallback({ beneficiaries: [{ cwp_id: res.data.cwp_id }] });
            }

            if (updateBeneficiaryId && res.data.beneficiary) {
              updateBeneficiaryId(res.data.beneficiary.beneficiary_id);
            }

            if (refreshBeneficieryList) {
              fetchProgramBeneficiaries(program.id);
            }

            if (refreshBeneficieryPrograms) {
              fetchBeneficiaryProgramsSimple(beneficiaryId || res.data.beneficiary.beneficiary_id);
              fetchBeneficiaryOrganizations(beneficiaryId || res.data.beneficiary.beneficiary_id);
            }
          }

          if (setNewProgramID) {
            setNewProgramID(null);
          }

          if (setOpenFn) {
            setOpenFn(false);
          }
        });
      }}
    >
      {({ values, handleChange, setFieldValue, handleSubmit }) => (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {headerTitle && (
              <StyledTitle>
                <Heading.Subtitle1>{headerTitle}</Heading.Subtitle1>
              </StyledTitle>
            )}
            {program && (
              <>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <StyledTitle>Administratorzy danych beneficjenta</StyledTitle>
                    {dataAdministrators ? (
                      dataAdministrators.map((adm, index) => {
                        return (
                          <Grid item xs={12} key={adm.id}>
                            <Checkbox
                              label={adm.name}
                              value={
                                values.dataAdministrators[index] &&
                                values.dataAdministrators[index].isBeneficiaryAdministrator
                              }
                              name={`dataAdministrators[${index}].isBeneficiaryAdministrator`}
                              onChange={handleChange}
                              isEditable={adm.isEditableAdministrator}
                            />
                          </Grid>
                        );
                      })
                    ) : (
                      <></>
                    )}
                    <ErrorMessage name="dataAdministrators">
                      {(msg) => <div className="error-txt">{msg}</div>}
                    </ErrorMessage>
                  </Grid>
                  <Grid item xs={3}>
                    <Select
                      label="Typ uczestnika"
                      data={beneficiaryType}
                      variant="filled"
                      name="beneficiaryType"
                      text="beneficiaryType"
                      selectValue={values.beneficiaryType}
                      onChange={setFieldValue}
                    />
                    <ErrorMessage name="beneficiaryType">
                      {(msg) => <div className="error-txt">{msg}</div>}
                    </ErrorMessage>
                  </Grid>
                  <Grid item xs={3}>
                    <Calendar
                      label="Data dodania"
                      variant="filled"
                      disableFuture
                      name="dateAddedToProgram"
                      onChange={setFieldValue}
                      value={values.dateAddedToProgram}
                      minDate={program.date_start}
                    />
                    <ErrorMessage name="dateAddedToProgram">
                      {(msg) => <div className="error-txt">{msg}</div>}
                    </ErrorMessage>
                  </Grid>
                  <Grid item xs={4}>
                    <Checkbox
                      label="Dziecko wytypowane do udziału w projekcie przez szkołę"
                      value={values.assignedToProgramBySchool}
                      name="assignedToProgramBySchool"
                      onChange={(e) => {
                        handleChange(e);
                        setFieldValue('dateAddedBySchoolToProgram', null);
                        setFieldValue('whoAssignedToProgram', null);
                      }}
                      isEditable
                    />
                  </Grid>
                  <Grid className="calendar-dodania" item xs={3}>
                    <Calendar
                      disabled={!values.assignedToProgramBySchool}
                      label="Data wytypowania przez szkołę"
                      variant="filled"
                      disableFuture
                      name="dateAddedBySchoolToProgram"
                      onChange={setFieldValue}
                      value={values.dateAddedBySchoolToProgram}
                    />
                  </Grid>
                  {educationalFacilitiesList && (
                    <Grid item xs={5}>
                      <Select
                        disabled={!values.assignedToProgramBySchool}
                        label="Szkoła"
                        data={educationalFacilitiesList}
                        variant="filled"
                        name="whoAssignedToProgram"
                        text="whoAssignedToProgram"
                        selectValue={values.whoAssignedToProgram}
                        onChange={setFieldValue}
                      />
                      <ErrorMessage name="whoAssignedToProgram">
                        {(msg) => <div className="error-txt">{msg}</div>}
                      </ErrorMessage>
                    </Grid>
                  )}
                  <Grid item xs={4}>
                    <Checkbox
                      label="Dziecko wytypowane do udziału w projekcie przez OPS"
                      value={values.assignedToProgramByOps}
                      name="assignedToProgramByOps"
                      onChange={(e) => {
                        handleChange(e);
                        setFieldValue('dateAddedByOpsToProgram', null);
                      }}
                      isEditable
                    />
                  </Grid>

                  <Grid className="calendar-dodania" item xs={3}>
                    <Calendar
                      disabled={!values.assignedToProgramByOps}
                      label="Data wytypowania przez OPS"
                      variant="filled"
                      disableFuture
                      name="dateAddedByOpsToProgram"
                      onChange={setFieldValue}
                      value={values.dateAddedByOpsToProgram}
                    />
                  </Grid>
                  <Grid item xs={5} />
                  <Grid item xs={4}>
                    <Checkbox
                      label="Dziecko wytypowane do udziału w projekcie przez sąd"
                      value={values.assignedToProgramByCourt}
                      name="assignedToProgramByCourt"
                      onChange={(e) => {
                        handleChange(e);
                        setFieldValue('dateAddedByCourtToProgram', null);
                        setFieldValue('whoAssignedToProgram', null);
                      }}
                      isEditable
                    />
                  </Grid>
                  <Grid className="calendar-dodania" item xs={3}>
                    <Calendar
                      disabled={!values.assignedToProgramByCourt}
                      label="Data wytypowania przez sąd"
                      variant="filled"
                      disableFuture
                      name="dateAddedByCourtToProgram"
                      onChange={setFieldValue}
                      value={values.dateAddedByCourtToProgram}
                    />
                  </Grid>
                  <Grid item xs={5} />
                </Grid>
                <StyledBorder>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <Checkbox
                        value={values.approvalToUseImage}
                        label="Zgoda na wykorzystanie wizerunku"
                        onChange={handleChange}
                        name="approvalToUseImage"
                        isEditable
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Checkbox
                        value={values.presidentsInformationClause}
                        label="Czy Beneficjent zapoznał się z klauzulą informacyjną prezydenta"
                        onChange={handleChange}
                        name="presidentsInformationClause"
                        isEditable
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Checkbox
                        value={values.consortiumInformationClause}
                        label="Czy Beneficjent zapoznał się z klauzulą informacyjną konsorcjum"
                        onChange={handleChange}
                        name="consortiumInformationClause"
                        isEditable
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Checkbox
                        value={values.approvalToTransferOfInformation}
                        label="Zgoda na wymianę informacji z innymi organizacjami na temat wykorzystywanego wsparcia"
                        onChange={handleChange}
                        name="approvalToTransferOfInformation"
                        isEditable
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Checkbox
                        value={values.approvalToIndependentReturnHome}
                        label="Zgoda na samodzielny powrót do domu"
                        onChange={handleChange}
                        name="approvalToIndependentReturnHome"
                        isEditable
                      />
                    </Grid>
                  </Grid>
                </StyledBorder>
                <Box display="flex" justifyContent="flex-end">
                  {setOpenFn && (
                    <Button
                      variant="outlined"
                      onClick={() => {
                        setOpenFn(false);

                        if (setNewProgramID) {
                          setNewProgramID(null);
                        }

                        if (onClose) {
                          onClose();
                        }
                      }}
                    >
                      Anuluj
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    style={{ marginLeft: setOpenFn ? 15 : 0 }}
                    onClick={handleSubmit}
                  >
                    Zapisz
                  </Button>
                </Box>
              </>
            )}
          </Grid>
        </Grid>
      )}
    </Formik>
  );
};

AddToProgram.propTypes = {
  refreshBeneficieryPrograms: PropTypes.bool,
  refreshBeneficieryList: PropTypes.bool,
  fetchProgramBeneficiaries: PropTypes.func,
  actionCallback: PropTypes.func,
  saveNewBeneficiary: PropTypes.func,
  fetchBeneficiary: PropTypes.func,
  beneficiary: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    surname: PropTypes.string,
    pesel: PropTypes.string,
    passport: PropTypes.string,
    dob: PropTypes.string,
    gender: PropTypes.string,
  }),
  beneficiaryId: PropTypes.number,
  setOpenFn: PropTypes.func,
  program: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    date_start: PropTypes.string,
  }),
  fetchEducationalFacilitiesList: PropTypes.func,
  educationalFacilitiesList: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      name: PropTypes.string,
    }),
  ),
  dataAdministrators: PropTypes.arrayOf(PropTypes.any),
  newBeneficiary: PropTypes.shape({
    name: PropTypes.string,
    surname: PropTypes.string,
    pesel: PropTypes.string,
    passport: PropTypes.string,
    gender: PropTypes.string,
    dob: PropTypes.string,
  }),
  headerTitle: PropTypes.string,
  fetchBeneficiaryProgramsSimple: PropTypes.func,
  fetchBeneficiaryOrganizations: PropTypes.func,
  setNewProgramID: PropTypes.func,
  addToProgramId: PropTypes.number,
  onClose: PropTypes.func,
  updateBeneficiaryId: PropTypes.func,
  fetchProgramDataAdministrators: PropTypes.func,
  fetchProgram: PropTypes.func,
};

AddToProgram.defaultProps = {
  refreshBeneficieryPrograms: false,
  refreshBeneficieryList: false,
  actionCallback: null,
  saveNewBeneficiary: null,
  fetchBeneficiary: null,
  beneficiary: null,
  beneficiaryId: null,
  program: null,
  fetchEducationalFacilitiesList: null,
  educationalFacilitiesList: [],
  fetchProgramBeneficiaries: null,
  newBeneficiary: null,
  setOpenFn: null,
  fetchBeneficiaryProgramsSimple: null,
  fetchBeneficiaryOrganizations: null,
  headerTitle: '',
  addToProgramId: null,
  setNewProgramID: null,
  onClose: null,
  updateBeneficiaryId: null,
  fetchProgramDataAdministrators: null,
  fetchProgram: null,
  dataAdministrators: [],
};

const mapStateToProps = ({ beneficiaryReducer, programReducer, selectReducer }) => ({
  beneficiary: beneficiaryReducer.beneficiary,
  program: programReducer.program,
  educationalFacilitiesList: selectReducer.educationalFacilities,
  dataAdministrators: programReducer.data_administrators,
});

const mapDispatchToProps = (dispatch) => ({
  saveNewBeneficiary: (beneficiary, connections) =>
    dispatch(saveNewBeneficiaryService(beneficiary, connections)),
  fetchBeneficiary: (id) => dispatch(fetchBeneficiaryService(id)),
  fetchEducationalFacilitiesList: () => dispatch(fetchEducationalFacilitiesListService()),
  fetchProgramBeneficiaries: (value) => dispatch(fetchProgramBeneficiariesService(value)),
  fetchBeneficiaryProgramsSimple: (id) => dispatch(fetchBeneficiaryProgramsSimpleService(id)),
  fetchBeneficiaryOrganizations: (id) => dispatch(fetchBeneficiaryOrganizationsService(id)),
  fetchProgramDataAdministrators: (id) => dispatch(fetchProgramDataAdministratorsService(id)),
  fetchProgram: (id) => dispatch(fetchProgramService(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddToProgram);
