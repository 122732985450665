import axiosInstance from 'utils/axios';
import { fetchRequest, fetchSuccess, fetchFailure } from 'actions/appActions';
import {
  showIndicators,
  fetchIndicatorAction,
  fetchPeriodsAction,
  fetchPeriodsSemesterAction,
} from 'actions/indicatorActions';
import { checkStatusCode } from 'utils/checkStatusCode';

export const fetchIndicatorList = () => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .get('/wskazniki/nowa_lista')
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(showIndicators(res.data));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
        }),
      );
    });
};

export const fetchIndicator = (id) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .get(`/wskazniki/${id}`)
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(fetchIndicatorAction(res.data));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
        }),
      );
    });
};

export const fetchPeriods = () => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .get(`/pomiary/nowa_lista`)
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(fetchPeriodsAction(res.data));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
        }),
      );
    });
};

export const fetchPeriodsSemester = () => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .get(`/pomiary/semestry`)
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(fetchPeriodsSemesterAction(res.data));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
        }),
      );
    });
};

export const editIndicator = (id, data) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .post(`/wskazniki/${id}/edytuj_wskaznik`, data)
    .then((res) => {
      dispatch(
        fetchSuccess({
          type: 'success',
          message: res.data.status,
          isActive: true,
        }),
      );
      return res;
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err, 'Nie udało się edytować wskaźniki'),
          isActive: true,
        }),
      );
    });
};

export const removeIndicator = (id) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .post(`/wskazniki/${id}/usun_wskaznik`)
    .then((res) => {
      dispatch(
        fetchSuccess({
          type: 'success',
          message: res.data.status,
          isActive: true,
        }),
      );
      return res;
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err, 'Nie udało się usunąć wskaźniki'),
          isActive: true,
        }),
      );
    });
};
