// React
import React from 'react';
import PropTypes from 'prop-types';

// Elements
import Heading from 'components/atoms/Heading/Heading';

// Styles
import { StyledBox } from './NoteItem.styles';

// Component
const NoteItem = ({ item: { who_added, content, name } }) => (
  <StyledBox>
    <Heading.Subtitle2>
      {name} - {who_added}
    </Heading.Subtitle2>
    <p>{content}</p>
  </StyledBox>
);

NoteItem.propTypes = {
  item: PropTypes.shape({
    who_added: PropTypes.string,
    name: PropTypes.string,
    content: PropTypes.string,
  }),
};

NoteItem.defaultProps = {
  item: {},
};

export default NoteItem;
