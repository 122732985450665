// React
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';

// Elements
import Button from 'components/atoms/Button/Button';
import NavLogo from 'components/organisms/Navbar/NavLogo';

// Styles
import { StyledStart } from './Start.styles';

// Component
const Start = ({ me }) => {
  const history = useHistory();
  const { id } = me;

  useEffect(() => {
    if (id > 0) {
      history.push('/dashboard');
    }
  }, [id]);

  return (
    <>
      <StyledStart>
        <NavLogo />
        <h2>Baza monitorująca</h2>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => {
            window.location.assign(`${process.env.REACT_APP_API_URL}/idp-init`);
          }}
        >
          zaloguj się
        </Button>
      </StyledStart>
    </>
  );
};

Start.propTypes = {
  me: PropTypes.shape({
    id: PropTypes.number,
  }),
};

Start.defaultProps = {
  me: {
    id: null,
  },
};

const mapStateToProps = ({ userReducer }) => ({
  me: userReducer.me,
});

export default connect(mapStateToProps)(Start);
