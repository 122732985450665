// React
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';

// Services
import {
  fetchBeneficiaryBindings as fetchBeneficiaryBindingsService,
  saveBeneficiaryContactDetails as saveBeneficiaryContactDetailsService,
  fetchBeneficiaryList as fetchBeneficiaryListService,
} from 'services/beneficiaryServices';
import { fetchEducationalFacilitiesList as fetchEducationalFacilitiesListService } from 'services/selectServices';

// Actions
import { clearEducationalFacilitiesList as clearEducationalFacilitiesListAction } from 'actions/selectActions';
import {
  clearBeneficiaries as clearBeneficiariesAction,
  clearBeneficiaryBindings as clearBeneficiaryBindingsAction,
} from 'actions/beneficiaryActions';

// Elements
import { Box, Grid } from '@material-ui/core';
import MyAccordion from 'components/atoms/Accordion/Accordion';
import Table from 'components/organisms/Table/Table';
import DashCardContact from 'components/molecules/DashCard/DashCardContact';
import DashAdd from 'components/molecules/DashCard/DashAdd';

// Styles
import { StyledHeading, StyledSubtitle } from './BeneficiaryBindings.styles';
import './BeneficiaryBindings.css';

// Data
const headCellsEduHistory = [
  { type: 'current_facility', label: 'Nowa placówka', id: 'beneficiary_history_id' },
  { type: 'current_class', label: 'Nowa klasa' },
  { type: 'previous_facility', label: 'Poprzednia placówka' },
  { type: 'previous_class', label: 'Poprzednia klasa' },
  { type: 'change_date', label: 'Data zmiany' },
  { type: 'type_of_change', label: 'Typ zmiany' },
  { type: 'reason_of_update', label: 'Powód zmiany' },
];

// Component
const BeneficiaryBindings = ({
  clearBeneficiaryBindings,
  fetchBindings,
  bindings,
  clearBeneficiaries,
  fetchBeneficiaryList,
  beneficiaries,
  clearEducationalFacilitiesList,
  fetchEducationalFacilitiesList,
  educationalFacilitiesList,
}) => {
  const { id } = useParams();
  const { contactDetails, family, administrative, educational } = bindings;
  const [dataBeneficiaries, setDataBeneficiaries] = useState([]);
  const [dataEducationalFacilitiesList, setDataEducationalFacilitiesList] = useState([]);
  const [selectForCopyOrgProg, setSelectForCopyOrgProg] = useState([]);

  useEffect(() => {
    clearBeneficiaryBindings();
    clearBeneficiaries();
    clearEducationalFacilitiesList();
  }, []);

  const triggerFetchBindings = useCallback((myid) => fetchBindings(myid), [fetchBindings]);

  useEffect(() => {
    triggerFetchBindings(id);
  }, [id, triggerFetchBindings]);

  const triggerFetchBeneficiaryList = useCallback(
    () => fetchBeneficiaryList(),
    [fetchBeneficiaryList],
  );

  useEffect(() => {
    triggerFetchBeneficiaryList();
  }, [id, triggerFetchBeneficiaryList]);

  useEffect(() => {
    const newData = [];

    beneficiaries.map((el) => {
      newData.push({ name: `${el.name} ${el.surname}`, value: el.id });

      return true;
    });

    setDataBeneficiaries(newData);
  }, [beneficiaries]);

  const triggerFetchEducationalFacilitiesList = useCallback(
    () => fetchEducationalFacilitiesList(),
    [fetchEducationalFacilitiesList],
  );

  useEffect(() => {
    triggerFetchEducationalFacilitiesList();
  }, [id, triggerFetchEducationalFacilitiesList]);

  useEffect(() => {
    const newData = [];

    educationalFacilitiesList.map((el) => {
      newData.push({
        name: `${el.name} (${el.street} ${el.house_number}, ${el.city})`,
        value: el.value,
      });

      return true;
    });

    setDataEducationalFacilitiesList(newData);
  }, [educationalFacilitiesList]);

  useEffect(() => {
    const newData =
      contactDetails && Object.keys(contactDetails).length > 0
        ? [
            ...(contactDetails.byOrganization ? contactDetails.byOrganization : []),
            ...(contactDetails.byProgram ? contactDetails.byProgram : []),
          ]
        : [];

    setSelectForCopyOrgProg([...newData]);
  }, [contactDetails]);

  return (
    <Box mt={4}>
      {/* ---------------------------------Dane kontaktowe ------------------------------------ */}
      {contactDetails && (
        <MyAccordion title="Dane kontaktowe">
          <Grid className="firstGrid" container spacing={2}>
            {contactDetails.byOrganization && (
              <>
                <Grid item xs={12}>
                  <StyledSubtitle>Dane bazowe w organizacji</StyledSubtitle>
                </Grid>
                {contactDetails.byOrganization.map((el) => {
                  return (
                    <Grid
                      key={el.connectionWithOrganization}
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      lg={4}
                      xl={3}
                    >
                      {!!el && (
                        <DashCardContact
                          program_organization={el}
                          organizations={contactDetails.byOrganization}
                          sub_name="Adres w organizacji"
                          buttons={{ edit: true, remove: true, share: true }}
                          dataOrgProg={selectForCopyOrgProg}
                        />
                      )}
                    </Grid>
                  );
                })}
              </>
            )}
            {contactDetails.byProgram && (
              <>
                <Grid item xs={12}>
                  <StyledSubtitle style={{ marginLeft: '16px', marginTop: '2rem' }}>
                    Dane w programach
                  </StyledSubtitle>
                </Grid>
                {contactDetails.byProgram.map((el) => {
                  return (
                    <Grid key={el.connectionWithProgram} item xs={12} sm={6} md={6} lg={3} xl={3}>
                      {!!el && (
                        <DashCardContact
                          program_organization={el}
                          organizations={contactDetails.byProgram}
                          sub_name="Adres w programie"
                          buttons={{ edit: true, remove: true, share: true }}
                          dataOrgProg={selectForCopyOrgProg}
                        />
                      )}
                    </Grid>
                  );
                })}
              </>
            )}
          </Grid>
        </MyAccordion>
      )}
      {/* ---------------------------------Powiązania rodzinne ------------------------------------ */}
      {family && (family.byProgram || family.byOrganization) && (
        <MyAccordion title="Powiązania rodzinne">
          <Grid className="firstGrid" container spacing={2}>
            {family.byOrganization && (
              <>
                <Grid item xs={12}>
                  <StyledSubtitle>Dane bazowe w organizacji</StyledSubtitle>
                </Grid>
                {family.byOrganization.map((el) => {
                  return (
                    <Grid item xs={12} key={el.organizationId}>
                      <StyledHeading>{el.name}</StyledHeading>
                      <Grid container spacing={2}>
                        {el.data &&
                          el.data.map((e) => {
                            return (
                              <Grid item key={e.id} xs={12} sm={6} md={6} lg={3} xl={3}>
                                <DashCardContact
                                  osoba_org_pro_name={el.name}
                                  osoba={e}
                                  connectionWithOrganization={el.connectionWithOrganization}
                                  sub_name={e.type.display}
                                  organizationId={el.organizationId}
                                  organizationName={el.name}
                                  buttons={{ edit: true, remove: true, share: true }}
                                  dataBeneficiaries={dataBeneficiaries}
                                  family
                                  program_organization={el}
                                  dataOrgProg={selectForCopyOrgProg}
                                />
                              </Grid>
                            );
                          })}
                        <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                          <DashAdd
                            osoba_org_pro_name={el.name}
                            connectionWithOrganization={el.connectionWithOrganization}
                            organizationId={el.organizationId}
                            organizationName={el.name}
                            dataBeneficiaries={dataBeneficiaries}
                            family
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                })}
              </>
            )}
            {family.byProgram && (
              <>
                <Grid item xs={12}>
                  <StyledSubtitle>Dane w programach</StyledSubtitle>
                </Grid>
                {family.byProgram.map((el) => {
                  return (
                    <Grid item xs={12} key={el.programId}>
                      <StyledHeading>{el.name}</StyledHeading>
                      <Grid container spacing={2}>
                        {el.data &&
                          el.data.map((e) => (
                            <Grid item key={e.id} xs={12} sm={6} md={6} lg={3} xl={3}>
                              <DashCardContact
                                family
                                osoba_org_pro_name={el.name}
                                osoba={e}
                                programId={el.programId}
                                programName={el.programName}
                                connectionWithProgram={el.connectionWithProgram}
                                sub_name={e.type.display}
                                buttons={{ edit: true, remove: true, share: true }}
                                dataBeneficiaries={dataBeneficiaries}
                                program_organization={el}
                                dataOrgProg={selectForCopyOrgProg}
                              />
                            </Grid>
                          ))}
                        <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                          <DashAdd
                            family
                            osoba_org_pro_name={el.name}
                            programId={el.programId}
                            programName={el.name}
                            connectionWithProgram={el.connectionWithProgram}
                            dataBeneficiaries={dataBeneficiaries}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                })}
              </>
            )}
          </Grid>
        </MyAccordion>
      )}
      {/* ------------------------------------powiązania administracyjne------------------------------------- */}
      {administrative && (administrative.byProgram || administrative.byOrganization) && (
        <MyAccordion title="Powiązania administracyjne">
          <Grid className="firstGrid" container spacing={2}>
            {administrative.byOrganization && (
              <>
                <Grid item xs={12}>
                  <StyledSubtitle>Dane bazowe w organizacji</StyledSubtitle>
                </Grid>

                {administrative.byOrganization.map((el) => {
                  return (
                    <Grid item xs={12} key={el.organizationId}>
                      <StyledHeading>
                        {el.name} {el.surname}
                      </StyledHeading>
                      <Grid container spacing={2}>
                        {el.data &&
                          el.data.map((e) => (
                            <Grid item key={e.id} xs={12} sm={6} md={6} lg={3} xl={3}>
                              <DashCardContact
                                osoba_org_pro_name={el.name}
                                osoba={e}
                                connectionWithOrganization={el.connectionWithOrganization}
                                sub_name={e.type.display}
                                organizationId={el.organizationId}
                                organizationName={el.name}
                                buttons={{ edit: true, remove: true, share: true }}
                                dataBeneficiaries={dataBeneficiaries}
                                program_organization={el}
                                dataOrgProg={selectForCopyOrgProg}
                              />
                            </Grid>
                          ))}
                        <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                          <DashAdd
                            osoba_org_pro_name={el.name}
                            connectionWithOrganization={el.connectionWithOrganization}
                            organizationId={el.organizationId}
                            organizationName={el.name}
                            dataBeneficiaries={dataBeneficiaries}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                })}
              </>
            )}
            {administrative.byProgram && (
              <>
                <Grid item xs={12}>
                  <StyledSubtitle>Dane w programach</StyledSubtitle>
                </Grid>
                {administrative.byProgram.map((el) => {
                  return (
                    <Grid item xs={12} key={el.programId}>
                      <StyledHeading>{el.name}</StyledHeading>
                      <Grid container spacing={2}>
                        {el.data !== null &&
                          el.data.map((e) => (
                            <Grid item key={e.id} xs={12} sm={6} md={6} lg={3} xl={3}>
                              <DashCardContact
                                osoba_org_pro_name={el.name}
                                osoba={e}
                                programId={el.programId}
                                programName={el.programName}
                                connectionWithProgram={el.connectionWithProgram}
                                sub_name={e.type.display}
                                buttons={{ edit: true, remove: true, share: true }}
                                dataBeneficiaries={dataBeneficiaries}
                                program_organization={el}
                                dataOrgProg={selectForCopyOrgProg}
                              />
                            </Grid>
                          ))}
                        <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                          <DashAdd
                            osoba_org_pro_name={el.name}
                            programId={el.programId}
                            programName={el.name}
                            connectionWithProgram={el.connectionWithProgram}
                            dataBeneficiaries={dataBeneficiaries}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                })}
              </>
            )}
          </Grid>
        </MyAccordion>
      )}
      {/* ---------------------------------Placowka edukacyjna ------------------------------------ */}
      {educational && (educational.byProgram || educational.byOrganization) && (
        <MyAccordion title="Placówki edukacyjne">
          <Grid className="firstGrid" container spacing={2}>
            {educational.byOrganization && (
              <>
                <Grid item xs={12}>
                  <StyledSubtitle>Dane bazowe w organizacji</StyledSubtitle>
                </Grid>
                {educational.byOrganization.map((el) => {
                  return (
                    <Grid item xs={12} key={el.organizationId}>
                      <StyledHeading>{el.name}</StyledHeading>
                      <Grid container spacing={2}>
                        <Grid item key={el.id} xs={12} sm={6} md={6} lg={3} xl={3}>
                          <DashCardContact
                            osoba_org_pro_name={el.name}
                            szkola={el.data}
                            connectionWithOrganization={el.connectionWithOrganization}
                            buttons={{ edit: true, remove: false, share: true }}
                            educationalFacilitiesList={dataEducationalFacilitiesList}
                            dataOrgProg={selectForCopyOrgProg}
                            program_organization={el}
                          />
                        </Grid>
                        <Grid item key={`history_${el.id}`} xs={12} sm={12} md={12} lg={9} xl={9}>
                          <Table
                            headCells={headCellsEduHistory}
                            data={el.eduHistory}
                            customOrderDirection="desc"
                            noSort
                            noPagination
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                })}
              </>
            )}
            {educational.byProgram && (
              <>
                <Grid item xs={12}>
                  <StyledSubtitle>Dane w programach</StyledSubtitle>
                </Grid>
                {educational.byProgram.map((el) => {
                  return (
                    <Grid item xs={12} key={el.programId}>
                      <StyledHeading>{el.name}</StyledHeading>
                      <Grid container spacing={2}>
                        <Grid item key={el.id} xs={12} sm={6} md={6} lg={3} xl={3}>
                          <DashCardContact
                            osoba_org_pro_name={el.name}
                            szkola={el.data}
                            connectionWithProgram={el.connectionWithProgram}
                            buttons={{ edit: true, remove: false, share: true }}
                            educationalFacilitiesList={dataEducationalFacilitiesList}
                            dataOrgProg={selectForCopyOrgProg}
                            program_organization={el}
                          />
                        </Grid>
                        <Grid item key={`history_${el.id}`} xs={12} sm={12} md={12} lg={9} xl={9}>
                          <Table
                            headCells={headCellsEduHistory}
                            data={el.eduHistory}
                            customOrderDirection="desc"
                            noSort
                            noPagination
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                })}
              </>
            )}
          </Grid>
        </MyAccordion>
      )}
    </Box>
  );
};

BeneficiaryBindings.propTypes = {
  clearBeneficiaryBindings: PropTypes.func,
  fetchBindings: PropTypes.func,
  bindings: PropTypes.shape({
    contactDetails: PropTypes.shape({
      byOrganization: PropTypes.arrayOf(
        PropTypes.shape({
          data: PropTypes.shape({
            street: PropTypes.string,
            house_number: PropTypes.string,
            apartment_number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            zip_code: PropTypes.string,
            district: PropTypes.string,
            city: PropTypes.string,
            province: PropTypes.string,
          }),
        }),
      ),
      byProgram: PropTypes.arrayOf(
        PropTypes.shape({
          data: PropTypes.shape({
            street: PropTypes.string,
            house_number: PropTypes.string,
            apartment_number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            zip_code: PropTypes.string,
            district: PropTypes.string,
            city: PropTypes.string,
            province: PropTypes.string,
          }),
        }),
      ),
    }),
    family: PropTypes.shape({
      byOrganization: PropTypes.arrayOf(PropTypes.any),
      byProgram: PropTypes.arrayOf(PropTypes.any),
    }),
    administrative: PropTypes.shape({
      byOrganization: PropTypes.arrayOf(PropTypes.any),
      byProgram: PropTypes.arrayOf(PropTypes.any),
    }),
    educational: PropTypes.shape({
      byOrganization: PropTypes.arrayOf(
        PropTypes.shape({
          data: PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            street: PropTypes.string,
            house_number: PropTypes.string,
            apartment_number: PropTypes.number,
            zip_code: PropTypes.string,
            city: PropTypes.string,
          }),
        }),
      ),
      byProgram: PropTypes.arrayOf(
        PropTypes.shape({
          data: PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            street: PropTypes.string,
            house_number: PropTypes.string,
            apartment_number: PropTypes.number,
            zip_code: PropTypes.string,
            city: PropTypes.string,
          }),
        }),
      ),
    }),
  }),
  clearBeneficiaries: PropTypes.func,
  fetchBeneficiaryList: PropTypes.func,
  beneficiaries: PropTypes.arrayOf(PropTypes.any),
  clearEducationalFacilitiesList: PropTypes.func,
  fetchEducationalFacilitiesList: PropTypes.func,
  educationalFacilitiesList: PropTypes.arrayOf(PropTypes.any),
};

BeneficiaryBindings.defaultProps = {
  clearBeneficiaryBindings: null,
  fetchBindings: null,
  bindings: {
    contactDetails: {
      byOrganization: [],
      byProgram: [],
    },
    family: {
      byOrganization: [],
      byProgram: [],
    },
    administrative: {
      byOrganization: [],
      byProgram: [],
    },
    educational: {
      byOrganization: [],
      byProgram: [],
    },
  },
  clearBeneficiaries: null,
  fetchBeneficiaryList: null,
  beneficiaries: [],
  clearEducationalFacilitiesList: null,
  fetchEducationalFacilitiesList: null,
  educationalFacilitiesList: [],
};

const mapStateToProps = ({ beneficiaryReducer, selectReducer }) => ({
  bindings: beneficiaryReducer.bindings,
  beneficiaries: beneficiaryReducer.beneficiaries,
  educationalFacilitiesList: selectReducer.educationalFacilities,
  EducationalFacilitiesPending: selectReducer.educationalFacilitiesPending,
});

const mapDispatchToProps = (dispatch) => ({
  clearBeneficiaryBindings: () => dispatch(clearBeneficiaryBindingsAction()),
  fetchBindings: (id) => dispatch(fetchBeneficiaryBindingsService(id)),
  clearBeneficiaries: () => dispatch(clearBeneficiariesAction()),
  fetchBeneficiaryList: () => dispatch(fetchBeneficiaryListService()),
  clearEducationalFacilitiesList: () => dispatch(clearEducationalFacilitiesListAction()),
  fetchEducationalFacilitiesList: () => dispatch(fetchEducationalFacilitiesListService()),
  saveBeneficiaryContactDetails: (id, data) =>
    dispatch(saveBeneficiaryContactDetailsService(id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BeneficiaryBindings);
