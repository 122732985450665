import React from 'react';
import PropTypes from 'prop-types';

export const checkStatusCode = (error, message = 'Błąd w pobieraniu danych') => {
  let status;
  let message_to_display = message;

  const ErrorMessage = ({ html }) => <span dangerouslySetInnerHTML={{ __html: html }} />;

  ErrorMessage.propTypes = {
    html: PropTypes.string.isRequired,
  };

  if (error && error.response) {
    status = error.response.status;

    if (error.response.data.status) {
      message_to_display = error.response.data.status;
    }
  } else {
    status = 'Network Error';
  }

  // rest of the code...

  switch (status) {
    case 500:
      return (
        <ErrorMessage
          html={
            'Wystąpił błąd. Zgłoś go <a href="/kontakt-z-administratorem" target="_blank">Administratorowi</a>. Podaj datę i opis zdarzenia.'
          }
        />
      ); // 'Wystąpił błąd. Zgłoś go <a href="/kontakt-z-administratorem" target="_blank">Administratorowi</a>. Podaj datę i opis zdarzenia.';
    case 400:
      if (error.response && error.response.data.status) {
        return error.response.data.status;
      }
      return message_to_display;
    case 404:
      return message_to_display || 'Dany zasób nie istnieje';
    case 401:
      return 'Zaloguj się, aby wyświetlić dane.';
    case 'Network Error':
      return 'Zaloguj się, aby wyświetlić dane.';
    default:
      return message_to_display || 'Błąd w pobieraniu danych';
  }
};
