// React
import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, Link } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';

// Services
import { fetchProgramsList as fetchProgramsListService } from 'services/programServices';
import { fetchAdministratedOrganizationList as fetchOrganizationListService } from 'services/organizationServices';
import { addUser as addUserService } from 'services/userServices';

// Actions
import { clearOrganizations as clearOrganizationsAction } from 'actions/organizationActions';
import { clearProgramAction } from 'actions/programActions';

// Utils
import { ParsePesel, isCoordinator } from 'utils/functions';

// Formik
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';

// Data
import { gender as genderTypes, organizationRoles, newUserRoleType } from 'constants/selectLists';

// Elements
import { Grid, Typography } from '@material-ui/core';
import Input from 'components/atoms/Input/Input';
import Select from 'components/atoms/Select/Select';
import Heading from 'components/atoms/Heading/Heading';
import Checkbox from 'components/atoms/Checkbox/Checkbox';

// Styles
import { StyledTitle, StyledGrid, StyledButton } from './AddUser.styles';

// Data
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

// Component
const AddUser = ({
  clearOrganizations,
  fetchOrganizationList,
  organizations,
  clearProgram,
  fetchProgramsList,
  programs,
  addUser,
  me,
}) => {
  const history = useHistory();
  const [dataOrganizations, setDataOrganizations] = useState([]);
  const [dataPrograms, setDataPrograms] = useState([]);

  useEffect(() => {
    const newData = [];
    organizations.map((el) => {
      newData.push({ name: el.name, value: el.id });
      return true;
    });
    setDataOrganizations(newData);
  }, [organizations]);

  useEffect(() => {
    const newData = [];
    programs.map((el) => {
      newData.push({ name: el.name, value: el.id });
      return true;
    });

    setDataPrograms(newData);
  }, [programs]);

  const triggerFetchOrganizationList = useCallback(() => {
    return fetchOrganizationList();
  }, [fetchOrganizationList]);

  const triggerFetchProgramsList = useCallback(() => {
    return fetchProgramsList();
  }, [fetchProgramsList]);

  useEffect(() => {
    clearOrganizations();
    clearProgram();
    if (!isCoordinator(me.user_role)) {
      triggerFetchOrganizationList();
    }
  }, []);

  return (
    <>
      <StyledTitle>
        <Heading.Subtitle1>Dodaj użytkownika</Heading.Subtitle1>
      </StyledTitle>
      <StyledGrid container>
        <Grid item xs={10}>
          <StyledGrid container spacing={2}>
            <Formik
              initialValues={{
                name: '',
                middle_name: '',
                surname: '',
                pesel: '',
                gender: '',
                phone: '',
                email: '',
                password_first: '',
                password_second: '',
                question: '',
                answer: '',
                user_type_program_organization: isCoordinator(me.user_role) ? null : 1,
                organization: '',
                organization_role: '',
                program: '',
                consent1: false,
                consent2: false,
              }}
              enableReinitialize
              validationSchema={Yup.object({
                name: Yup.string().min(2, 'minimum 2 znaki').required('pole wymagane!'),
                middle_name: Yup.string().min(2, 'minimum 2 znaki'),
                surname: Yup.string().min(2, 'minimum 2 znaki').required('pole wymagane!'),
                pesel: Yup.string()
                  .typeError('nieprawidłowy pesel!')
                  .test('pesel', 'nieprawidłowy numer', (pesel) => {
                    if (!/^[0-9]{11}$/.test(pesel)) {
                      return false;
                    }

                    const parsedPesel = ParsePesel(pesel);
                    if (parsedPesel.error) {
                      return false;
                    }

                    return true;
                  })
                  .required('pole wymagane!'),
                gender: Yup.string()
                  .test('gender', 'płeć nie pasuje do numeru pesel', (gender, schema) => {
                    if (!schema.parent.pesel) return true;

                    if (parseInt(schema.parent.pesel / 10, 10) % 2 === 0 && gender === 'female')
                      return true;
                    if (parseInt(schema.parent.pesel / 10, 10) % 2 === 1 && gender === 'male')
                      return true;

                    return false;
                  })
                  .required('pole wymagane!'),
                phone: Yup.string().matches(phoneRegExp, 'numer telefonu jest nieprawidłowy!'),
                email: Yup.string()
                  .email('adres email jest nieprawidłowy!')
                  .required('pole wymagane!'),
                password_first: Yup.string()
                  .matches(
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
                    'Hasło musi zawierać minimum: 8 znaków, jedną małą literę, jedną dużą literę i jedną cyfrę',
                  )
                  .required('pole wymagane!'),
                password_second: Yup.string()
                  .oneOf([Yup.ref('password_first'), null], 'podane hasła muszą być takie same!')
                  .required('pole wymagane!'),
                question: Yup.string().min(2, 'minimum 2 znaki').required('pole wymagane!'),
                answer: Yup.string().min(2, 'minimum 2 znaki').required('pole wymagane!'),
                organization: Yup.number().when('user_type_program_organization', {
                  is: 1,
                  then: Yup.number().nullable().required('pole wymagane!'),
                  otherwise: Yup.number(),
                }),
                organization_role: Yup.string().when('user_type_program_organization', {
                  is: 1,
                  then: Yup.string().required('pole wymagane!'),
                  otherwise: Yup.string(),
                }),
                user_type_program_organization: Yup.number().nullable().required('pole wyamgane!'),
                program: Yup.number().when('user_type_program_organization', {
                  is: 2,
                  then: Yup.number().required('pole wymagane!'),
                  otherwise: Yup.number(),
                }),
                consent1: Yup.boolean().required('pole wymagane!'),
                consent2: Yup.boolean().required('pole wymagane!'),
              })}
              onSubmit={(values) => {
                addUser(values).then((data) => {
                  if (data && data.data.result === 1) {
                    history.push(`/wnioski`);
                  }
                });
              }}
            >
              {({ values, setFieldValue, handleChange, handleSubmit }) => {
                return (
                  <>
                    <Grid item xs={6}>
                      <Input
                        label="Imię"
                        variant="filled"
                        name="name"
                        onChange={handleChange}
                        value={values.name}
                      />
                      <ErrorMessage name="name">
                        {(msg) => <div className="error-txt">{msg}</div>}
                      </ErrorMessage>
                    </Grid>
                    <Grid item xs={6}>
                      <Input
                        label="Drugie imię"
                        variant="filled"
                        name="middle_name"
                        onChange={handleChange}
                        value={values.middle_name}
                      />
                      <ErrorMessage name="middle_name">
                        {(msg) => <div className="error-txt">{msg}</div>}
                      </ErrorMessage>
                    </Grid>
                    <Grid item xs={12}>
                      <Input
                        label="Nazwisko"
                        variant="filled"
                        name="surname"
                        onChange={handleChange}
                        value={values.surname}
                      />
                      <ErrorMessage name="surname">
                        {(msg) => <div className="error-txt">{msg}</div>}
                      </ErrorMessage>
                    </Grid>
                    <Grid item xs={6}>
                      <Input
                        label="Pesel"
                        variant="filled"
                        name="pesel"
                        onChange={handleChange}
                        value={values.pesel}
                      />
                      <ErrorMessage name="pesel">
                        {(msg) => <div className="error-txt">{msg}</div>}
                      </ErrorMessage>
                    </Grid>
                    <Grid item xs={6}>
                      <Select
                        label="Płeć"
                        data={genderTypes}
                        variant="filled"
                        name="gender"
                        text="gender"
                        selectValue={values.gender}
                        onChange={setFieldValue}
                      />
                      <ErrorMessage name="gender">
                        {(msg) => <div className="error-txt">{msg}</div>}
                      </ErrorMessage>
                    </Grid>
                    <Grid item xs={6}>
                      <Input
                        label="Telefon kontaktowy"
                        variant="filled"
                        name="phone"
                        type="tel"
                        onChange={handleChange}
                        value={values.phone}
                      />
                      <ErrorMessage name="phone">
                        {(msg) => <div className="error-txt">{msg}</div>}
                      </ErrorMessage>
                    </Grid>
                    <Grid item xs={6}>
                      <Input
                        label="Adres e-mail"
                        variant="filled"
                        name="email"
                        type="email"
                        onChange={handleChange}
                        value={values.email}
                      />
                      <ErrorMessage name="email">
                        {(msg) => <div className="error-txt">{msg}</div>}
                      </ErrorMessage>
                    </Grid>
                    <Grid item xs={12}>
                      <Input
                        label="Hasło"
                        variant="filled"
                        name="password_first"
                        type="password"
                        onChange={handleChange}
                        value={values.password_first}
                      />
                      <ErrorMessage name="password_first">
                        {(msg) => <div className="error-txt">{msg}</div>}
                      </ErrorMessage>
                    </Grid>
                    <Grid item xs={12}>
                      <Input
                        label="Powtórz hasło"
                        variant="filled"
                        type="password"
                        name="password_second"
                        onChange={handleChange}
                        value={values.password_second}
                      />
                      <ErrorMessage name="password_second">
                        {(msg) => <div className="error-txt">{msg}</div>}
                      </ErrorMessage>
                    </Grid>
                    <Grid item xs={12}>
                      <Input
                        label="Pytanie kontrolne"
                        variant="filled"
                        multiline
                        rows={4}
                        name="question"
                        onChange={handleChange}
                        value={values.question}
                      />
                      <ErrorMessage name="question">
                        {(msg) => <div className="error-txt">{msg}</div>}
                      </ErrorMessage>
                    </Grid>
                    <Grid item xs={12}>
                      <Input
                        label="Odpowiedź na pytanie kontrolne"
                        variant="filled"
                        multiline
                        rows={4}
                        name="answer"
                        onChange={handleChange}
                        value={values.answer}
                      />
                      <ErrorMessage name="answer">
                        {(msg) => <div className="error-txt">{msg}</div>}
                      </ErrorMessage>
                    </Grid>
                    {isCoordinator(me.user_role) && (
                      <Grid item xs={12}>
                        <Select
                          label="Typ użytkownika"
                          data={newUserRoleType}
                          variant="filled"
                          name="user_type_program_organization"
                          text="user_type_program_organization"
                          selectValue={values.user_type_program_organization}
                          onChange={(_, newValue) => {
                            setFieldValue('user_type_program_organization', newValue);

                            if (newValue === 2) {
                              triggerFetchProgramsList();
                            } else {
                              triggerFetchOrganizationList();
                            }
                          }}
                        />
                        <ErrorMessage name="user_type_program_organization">
                          {(msg) => <div className="error-txt">{msg}</div>}
                        </ErrorMessage>
                      </Grid>
                    )}
                    {values.user_type_program_organization === 2 ? (
                      <>
                        <Grid item xs={12}>
                          <Select
                            disabled={!values.user_type_program_organization}
                            label="Powiązany program"
                            data={dataPrograms}
                            variant="filled"
                            name="program"
                            text="program"
                            selectValue={values.program}
                            onChange={setFieldValue}
                          />
                          <ErrorMessage name="program">
                            {(msg) => <div className="error-txt">{msg}</div>}
                          </ErrorMessage>
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid item xs={12}>
                          <Select
                            disabled={!values.user_type_program_organization}
                            label="Powiązana organizacja"
                            data={dataOrganizations}
                            variant="filled"
                            name="organization"
                            text="organization"
                            selectValue={values.organization}
                            onChange={setFieldValue}
                          />
                          <ErrorMessage name="organization">
                            {(msg) => <div className="error-txt">{msg}</div>}
                          </ErrorMessage>
                        </Grid>
                        <Grid item xs={12}>
                          <Select
                            disabled={!values.organization}
                            label="Rola w organizacji"
                            data={organizationRoles}
                            variant="filled"
                            name="organization_role"
                            text="organization_role"
                            selectValue={values.organization_role}
                            onChange={setFieldValue}
                          />
                          <ErrorMessage name="organization_role">
                            {(msg) => <div className="error-txt">{msg}</div>}
                          </ErrorMessage>
                        </Grid>
                      </>
                    )}
                    {/* <Grid item xs={12}>
                      <Checkbox
                        label="Potwierdzam, że użytkownik wyraził zgodę na przetwarzanie danych w związku rejestracją konta"
                        name="consent1"
                        value={values.consent1}
                        onChange={handleChange}
                        isEditable
                      />
                      <Link to="/files/polityka_prywatnosci.pdf" target="_blank">
                        polityka prywatności
                      </Link>
                      <ErrorMessage name="consent1">
                        {(msg) => <div className="error-txt">{msg}</div>}
                      </ErrorMessage>
                    </Grid> */}
                    <Grid item xs={12}>
                      <Typography component="label">
                        <Checkbox
                          name="consent1"
                          value={values.consent1}
                          onChange={handleChange}
                          isEditable
                        />
                        Potwierdzam, że użytkownik wyraził zgodę na przetwarzanie danych w związku
                        rejestracją konta{' '}
                        <Link to="/files/polityka_prywatnosci.pdf" target="_blank">
                          polityka prywatności
                        </Link>
                      </Typography>
                      <ErrorMessage name="consent1">
                        {(msg) => <div className="error-txt">{msg}</div>}
                      </ErrorMessage>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography component="label">
                        <Checkbox
                          name="consent2"
                          checked={values.consent2}
                          value={values.consent2}
                          onChange={handleChange}
                          isEditable
                        />
                        Potwierdzam, że użytkownik zapoznał się z{' '}
                        <Link
                          to="/files/Regulamin_korzystania_z_platformy_Baza_Monitorujaca.pdf"
                          target="_blank"
                        >
                          regulaminem korzystania z platformy
                        </Link>{' '}
                        i akceptuje jego postanowienia
                      </Typography>
                      <ErrorMessage name="consent2">
                        {(msg) => <div className="error-txt">{msg}</div>}
                      </ErrorMessage>
                    </Grid>
                    {/* <Grid item xs={12}>
                      <Checkbox
                        label="Potwierdzam, że użytkownik zapoznał się z regulaminem korzystania z platformy i akceptuje jego postanowienia"
                        name="consent2"
                        value={values.consent2}
                        onChange={handleChange}
                        isEditable
                      />
                      <Link
                        to="/files/Regulamin_korzystania_z_platformy_Baza_Monitorujaca.pdf"
                        target="_blank"
                      >
                        regulaminem korzystania z platformy
                      </Link>
                      <ErrorMessage name="consent2">
                        {(msg) => <div className="error-txt">{msg}</div>}
                      </ErrorMessage>
                    </Grid> */}
                    <Grid item xs={12}>
                      <StyledButton variant="contained" onClick={handleSubmit}>
                        Dodaj użytkownika
                      </StyledButton>
                    </Grid>
                  </>
                );
              }}
            </Formik>
          </StyledGrid>
        </Grid>
      </StyledGrid>
    </>
  );
};

AddUser.propTypes = {
  clearOrganizations: PropTypes.func,
  fetchOrganizationList: PropTypes.func,
  organizations: PropTypes.arrayOf(PropTypes.any),
  clearProgram: PropTypes.func,
  fetchProgramsList: PropTypes.func,
  programs: PropTypes.arrayOf(PropTypes.any),
  addUser: PropTypes.func,
  me: PropTypes.shape({
    user_role: PropTypes.arrayOf(PropTypes.string),
  }),
};

AddUser.defaultProps = {
  clearOrganizations: null,
  fetchOrganizationList: null,
  organizations: [],
  clearProgram: null,
  fetchProgramsList: null,
  programs: [],
  addUser: null,
  me: {
    user_role: [],
  },
};

const mapStateToProps = ({ organizationReducer, programReducer, userReducer }) => ({
  organizations: organizationReducer.organizations,
  programs: programReducer.programs,
  me: userReducer.me,
});

const mapDispatchToProps = (dispatch) => ({
  clearOrganizations: () => dispatch(clearOrganizationsAction()),
  fetchOrganizationList: () => dispatch(fetchOrganizationListService()),
  clearProgram: () => dispatch(clearProgramAction()),
  fetchProgramsList: () => dispatch(fetchProgramsListService()),
  addUser: (data) => dispatch(addUserService(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUser);
