import {
  PROGRAM_COORDINATOR,
  TASK_COORDINATOR,
  LECTURER_SENSITIVE_DATA,
  LECTURER,
  INSTRUCTOR,
  ORGANIZATION_ADMINISTRATOR,
  ORGANIZATION_EMPLOYEE,
  ORGANIZATION_EXTERNAL_COLLABORATOR,
} from 'constants/roles';

import { WORKING_WITH_FAMILY, COOPERATION_IN_SYSTEM_ASSISTANCE } from 'constants/ActionBuildInType';

export const gender = [
  { value: 'female', name: 'Kobieta', label: 'Kobieta' },
  { value: 'male', name: 'Mężczyzna', label: 'Mężczyzna' },
  {
    value: 'brak dostępu',
    name: 'brak dostępu do informacji',
    label: 'brak dostępu do informacji',
  },
];

export const beneficiaryType = [
  { value: 1, name: 'Dziecko' },
  { value: 2, name: 'Dorosły' },
  { value: 3, name: 'Młodzież (13-18)' },
];

export const status = [
  { value: 1, name: 'Aktywny' },
  { value: 2, name: 'Nieaktywny' },
  { value: 3, name: 'Użyty' },
  { value: 4, name: 'Czasowo zablokowany' },
  { value: 5, name: 'Zawieszony' },
  { value: 6, name: 'Niepotwierdzony' },
  { value: 7, name: 'Przed rozpatrzeniem' },
  { value: 8, name: 'Oczekuje' },
  { value: 9, name: 'Zaakceptowany' },
  { value: 10, name: 'Odrzucony' },
  { value: 11, name: 'Zablokowany' },
  { value: 12, name: 'Czasowo zablokowany z powodu błednych danych logowania' },
];

export const app_type = [
  { value: 'PROGRAM_GUARDIAN', name: 'Nowe konto opiekuna programu' },
  { value: 'ORGANIZATION_EXIST', name: 'Nowe konto powiazane z istniejącą organizacją' },
  { value: 'ORGANIZATION_NEW', name: 'Nowe konto + Nowa organizacja' },
  { value: 'ORGANIZATION_EMPTY', name: 'Nowe konto bez organizacji' },
];

export const newUserRoleType = [
  { name: 'Członek organizacji', value: 1 },
  { name: 'Opiekun programu', value: 2 },
];

export const organizationType = [
  { value: 1, name: 'Niezależna' },
  { value: 2, name: 'Koalicja' },
];

export const organizationStatus = [
  { value: 1, name: 'Aktywna' },
  { value: 2, name: 'Nieaktywna' },
];

export const organizationIdentificatorKind = [
  { value: 1, name: 'KRS' },
  { value: 2, name: 'Inny' },
];

export const actionType = [
  { value: 1, name: 'Zajęcia grupowe' },
  { value: 2, name: 'Zajęcia indywidualne' },
];

export const actionRecipients = [
  { id: 1, name: 'Dorośli' },
  { id: 2, name: 'Dzieci i młodzież' },
  { id: 3, name: 'Grupa wiekowa 0-6' },
  { id: 4, name: 'Grupa wiekowa 7-13' },
  { id: 5, name: 'Grupa wiekowa 13-18' },
  { id: 6, name: 'Grupa wiekowa 15-26' },
  { id: 7, name: 'Nauczyciele/Wychowawcy/Specjaliści' },
  { id: 8, name: 'Rodzice/Opiekunowie' },
  { id: 9, name: 'Rodziny' },
  { id: 10, name: 'Seniorzy' },
  { id: 11, name: 'Realizatorzy' },
];

export const ActionBuildInType = [
  { value: 1, name: 'Prowadzenie zajęć' },
  { value: 2, name: 'Obecność w ognisku / PWD' },
  { value: 3, name: 'Praca z rodziną' },
  { value: 4, name: 'Współpraca w systemie wsparcia' },
  // { value: 5, name: 'Inne' },
  { value: 6, name: 'Wydarzenie otwarte' },
];

export const actionKind = [
  { value: 1, name: 'Edukacyjne' },
  { value: 2, name: 'Kulturalne' },
  { value: 3, name: 'Opiekuńcze' },
  { value: 4, name: 'Społeczne' },
  { value: 5, name: 'Specjalistyczne' },
  { value: 6, name: 'Sportowe' },
  { value: 7, name: 'Terapeutyczne' },
  { value: 8, name: 'Wspierające rodzinę' },
  { value: 9, name: 'Usamodzielniające' },
  { value: 10, name: 'Wsparcie realizatorów' },
  { value: 999, name: 'Profilaktyczne' },
  { value: 998, name: 'Opiekuńczo-wychowawcze' },
  { value: 997, name: 'Korekcyjne' },
  { value: 996, name: 'Socjoterapeutyczne' },
  { value: 995, name: 'Rodzinne' },
  { value: 994, name: 'Wzmocnienie na rynku pracy' },
  { value: 993, name: 'Animacja lokalna' },
  { value: 992, name: 'Projekty młodzieżowe' },
  { value: 991, name: 'Inne' },
];

export const actionFrequency = [
  { value: 1, name: 'Jednorazowe' },
  { value: 2, name: 'Cykliczne' },
];

export const actionInterval = [];

export const actionAttendance = [
  { value: 1, name: 'Obecności' },
  { value: -1, name: 'Nieobecności' },
  { value: 2, name: 'Usprawiedliwione' },
];

export const actionCharacter = [
  { value: 1, name: 'Spotkanie' },
  { value: 2, name: 'Skierowanie do dalszego wsparcia' },
];

export const guardianType = [
  { value: 1, name: 'Rodzic' },
  // { value: 2, name: 'Opiekun prawny' },
  { value: 3, name: 'Kurator' },
  { value: 4, name: 'Asystent rodziny' },
  { value: 5, name: 'Pracownicy OPS/CPS/WCPR' },
  { value: 6, name: 'Osoba odbierająca dziecko' },
  { value: 7, name: 'Babcia / Dziadek' },
  { value: 8, name: 'Macocha / Ojczym' },
  { value: 9, name: 'Inny członek rodziny' },
  { value: 10, name: 'Niania' },
];

export const guardianTypeAdm = [
  // { value: 2, name: 'Opiekun prawny' },
  { value: 3, name: 'Kurator' },
  { value: 4, name: 'Asystent rodziny' },
  { value: 5, name: 'Pracownicy OPS/WCPR' },
];

export const guardianTypeFamily = [
  { value: 1, name: 'Rodzic' },
  { value: 6, name: 'Osoba odbierająca dziecko' },
  { value: 7, name: 'Babcia / Dziadek' },
  { value: 8, name: 'Macocha / Ojczym' },
  { value: 9, name: 'Inny członek rodziny' },
  { value: 10, name: 'Niania' },
];

export const identificatorKind = [
  { value: 1, name: 'Konkursowy' },
  { value: 2, name: 'Własny' },
];

export const aggregation = [
  { value: 1, name: 'Program' },
  { value: 2, name: 'Zadanie' },
  { value: 3, name: 'Działanie' },
];

export const indicatorParameters = [
  { value: 'bool', name: 'Tak / Nie' },
  { value: 'text', name: 'Tekstowy' },
  { value: 'number', name: 'Liczbowy' },
  { value: 'select', name: 'Select' },
];

export const districts = [
  { value: 'Mokotów', name: 'Mokotów' },
  { value: 'Praga-Południe', name: 'Praga-Południe' },
  { value: 'Ursynów', name: 'Ursynów' },
  { value: 'Wola', name: 'Wola' },
  { value: 'Bielany', name: 'Bielany' },
  { value: 'Targówek', name: 'Targówek' },
  { value: 'Bemowo', name: 'Bemowo' },
  { value: 'Śródmieście', name: 'Śródmieście' },
  { value: 'Białołęka', name: 'Białołęka' },
  { value: 'Ochota', name: 'Ochota' },
  { value: 'Wawer', name: 'Wawer' },
  { value: 'Praga-Północ', name: 'Praga-Północ' },
  { value: 'Ursus', name: 'Ursus' },
  { value: 'Żoliborz', name: 'Żoliborz' },
  { value: 'Włochy', name: 'Włochy' },
  { value: 'Wilanów', name: 'Wilanów' },
  { value: 'Wesoła', name: 'Wesoła' },
  { value: 'Rembertów', name: 'Rembertów' },
  { value: 'Inna', name: 'Inna' },
];

export const provinces = [
  { value: 'dolnośląskie', name: 'dolnośląskie' },
  { value: 'kujawsko-pomorskie', name: 'kujawsko-pomorskie' },
  { value: 'lubelskie', name: 'lubelskie' },
  { value: 'lubuskie', name: 'lubuskie' },
  { value: 'łódzkie', name: 'łódzkie' },
  { value: 'małopolskie', name: 'małopolskie' },
  { value: 'mazowieckie', name: 'mazowieckie' },
  { value: 'opolskie', name: 'opolskie' },
  { value: 'podkarpackie', name: 'podkarpackie' },
  { value: 'podlaskie', name: 'podlaskie' },
  { value: 'pomorskie', name: 'pomorskie' },
  { value: 'śląskie', name: 'śląskie' },
  { value: 'świętokrzyskie', name: 'świętokrzyskie' },
  { value: 'warmińsko-mazurskie', name: 'warmińsko-mazurskie' },
  { value: 'wielkopolskie', name: 'wielkopolskie' },
  { value: 'zachodniopomorskie', name: 'zachodniopomorskie' },
];

export const suspendTime = [
  { value: 'godzina', name: 'Godzina' },
  { value: 'dzien', name: 'Dzień' },
];

export const agregationLevel = [
  { value: 'INDICATOR_PROGRAM', name: 'program' },
  { value: 'INDICATOR_TASK', name: 'zadanie' },
  { value: 'INDICATOR_ACTION', name: 'działanie' },
];

export const indicatorKind = [
  { value: 'INDICATOR_CONTEST', name: 'konkursowy' },
  { value: 'INDICATOR_OWN', name: 'własny' },
];

export const indicatorType = [
  { value: 'INDICATOR_YESNO', name: 'tak / nie' },
  { value: 'INDICATOR_SELECT', name: 'pole wyboru' },
  { value: 'INDICATOR_TEXT', name: 'tekstowy' },
  { value: 'INDICATOR_NUMERICAL', name: 'liczbowy' },
];

export const indicatorCategory = [
  { value: 'BENEFICIARY_EDUCATIONAL', name: 'edukacyjny' },
  { value: 'BENEFICIARY_SOCIAL', name: 'społeczny' },
  { value: 'BENEFICIARY_OTHER', name: 'inny' },
];

export const indicatorNumericalType = [
  { value: 1, name: 'liczbowe' },
  { value: 2, name: 'procentowe' },
];

export const indicatorTimePeriod = [
  { value: 'INDICATOR_MONTH', name: 'miesięczny' },
  { value: 'INDICATOR_QUARTER', name: 'kwartalny' },
  { value: 'INDICATOR_BIANNUAL', name: 'półroczny' },
  { value: 'INDICATOR_ANNUAL', name: 'roczny' },
  { value: 'INDICATOR_SCHOOL_YEAR', name: 'rok szkolny' },
  { value: 'INDICATOR_SEMESTER', name: 'semestralny' },
];

export const programRoles = [
  {
    value: PROGRAM_COORDINATOR,
    name: 'koordynator programu',
  },
  {
    value: TASK_COORDINATOR,
    name: 'koordynator zadania',
  },
  {
    value: LECTURER_SENSITIVE_DATA,
    name: 'prowadzący - z wglądem w dane wrażliwe',
  },
  {
    value: LECTURER,
    name: 'prowadzący bez wglądu w dane wrażliwe',
  },
  {
    value: INSTRUCTOR,
    name: 'instruktor',
  },
];

export const organizationRoles = [
  {
    value: ORGANIZATION_ADMINISTRATOR,
    name: 'administrator organizacji',
  },
  {
    value: ORGANIZATION_EMPLOYEE,
    name: 'pracownik organizacji',
  },
  {
    value: ORGANIZATION_EXTERNAL_COLLABORATOR,
    name: 'współpracownik zewnętrzny',
  },
];

export const programType = [
  { value: 1, name: 'konsorcjalny' },
  { value: 2, name: 'indywidualny' },
];

// export const programColor = [
//   { value: '#EEC1B1', name: 'rózowy' },
//   { value: '#EEE8B1', name: 'żółty' },
//   { value: '#CDF2C2', name: 'miętowy' },
//   { value: '#076BB3', name: 'niebieski' },
//   { value: '#E3191B', name: 'czerwony' },
//   { value: '#F8B227', name: 'pomarańczowy' },
//   { value: '#BFBFBF', name: 'szary' },
//   { value: '#707070', name: 'ciemnoszary' },
// ];

export const updateInfoChangeType = [
  { value: 1, name: 'poprawa błędu' },
  { value: 2, name: 'promocja' },
  { value: 3, name: 'zmiana placówki lub klasy' },
];

export const userDesktopSettings = [
  { value: 'beneficiaries', name: 'Beneficjenci' },
  { value: 'attendance', name: 'Dziennik obecności' },
  // { value: 'calendar', name: 'Kalendarz' },
  // { value: 'summary', name: 'Szybkie podsumowanie' },
  { value: 'programs', name: 'Programy' },
  { value: 'diagnosis', name: 'Diagnozy' },
  { value: 'ipp', name: 'Indywidualny plan pracy' },
  { value: 'login', name: 'Logowanie' },
];

export const userDesktopSettingGuardian = [
  // { value: 'calendar', name: 'Kalendarz' },
  // { value: 'summary', name: 'Szybkie podsumowanie' },
  { value: 'programs', name: 'Programy' },
];

export const userDesktopSettingInstructor = [
  { value: 'beneficiaries', name: 'Beneficjenci' },
  { value: 'attendance', name: 'Dziennik obecności' },
  // { value: 'calendar', name: 'Kalendarz' },
  // { value: 'summary', name: 'Szybkie podsumowanie' },
  { value: 'programs', name: 'Programy' },
];

export const noteType = [
  { value: COOPERATION_IN_SYSTEM_ASSISTANCE, name: 'Współpraca w systemie' },
  { value: WORKING_WITH_FAMILY, name: 'Praca z rodziną' },
  { value: 'NOTE', name: 'Ogólna' },
];

export const surveyMeasurementNumbers = [
  { value: 1, name: 'pomiar bazowy' },
  { value: 2, name: 'pomiar 1' },
  { value: 3, name: 'pomiar 2' },
  { value: 4, name: 'pomiar 3' },
  { value: 5, name: 'pomiar 4' },
  { value: 6, name: 'pomiar 5' },
  { value: 7, name: 'pomiar 6' },
];
