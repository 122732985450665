// React
import React from 'react';

// Styles
import { StyledLogoText, StyledSVG } from './NavLogo.styles';

// Component
const NavLogo = () => {
  return (
    <StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width="129"
      height="53"
      viewBox="0 0 129.247 52.91"
    >
      <g transform="translate(-103.35 -28.06)">
        <g transform="translate(166.6 48.919)">
          <StyledLogoText
            d="M954.652-5457.361a1.054,1.054,0,0,1,.312.32.621.621,0,0,1,.078.289.633.633,0,0,1-.164.414,1.033,1.033,0,0,1-.32.272,2.164,2.164,0,0,0-.461-.39,3.934,3.934,0,0,0-.71-.367,5.625,5.625,0,0,0-.906-.273,4.923,4.923,0,0,0-1.046-.109,4.523,4.523,0,0,0-1.725.328,4.076,4.076,0,0,0-1.4.936,4.4,4.4,0,0,0-.937,1.483,5.335,5.335,0,0,0-.344,1.965,5.064,5.064,0,0,0,.367,1.991,4.261,4.261,0,0,0,.983,1.452,4.055,4.055,0,0,0,1.451.882,5.25,5.25,0,0,0,1.771.3,6.555,6.555,0,0,0,1.046-.078,6.557,6.557,0,0,0,.866-.194,4.921,4.921,0,0,0,.664-.251,4.08,4.08,0,0,0,.452-.241l.469.843a5.465,5.465,0,0,1-.484.28,4.779,4.779,0,0,1-.726.3,6.766,6.766,0,0,1-1.015.234,8.445,8.445,0,0,1-1.334.093,6.25,6.25,0,0,1-2.162-.374,5.38,5.38,0,0,1-1.8-1.093,5.219,5.219,0,0,1-1.24-1.756,5.767,5.767,0,0,1-.461-2.364,5.958,5.958,0,0,1,.453-2.38,5.366,5.366,0,0,1,1.217-1.788,5.261,5.261,0,0,1,1.787-1.124,5.972,5.972,0,0,1,2.146-.391,6.517,6.517,0,0,1,1.982.266A4.314,4.314,0,0,1,954.652-5457.361Z"
            transform="translate(-945.88 5458.158)"
          />
        </g>
        <StyledLogoText
          d="M946.414-5430.9a1.691,1.691,0,0,1,.093-.593,1.281,1.281,0,0,1,.242-.414,1.014,1.014,0,0,1,.344-.25,1.594,1.594,0,0,1,.4-.117c.1-.021.227-.042.367-.062s.318-.039.531-.055.474-.029.781-.039.679-.016,1.116-.016a7.691,7.691,0,0,1,2.342.3,4.141,4.141,0,0,1,1.507.82,2.946,2.946,0,0,1,.812,1.179,4,4,0,0,1,.242,1.382,3.678,3.678,0,0,1-.39,1.709,2.992,2.992,0,0,1-1.28,1.242c.156.249.328.541.515.874s.383.685.585,1.054.4.752.6,1.148.385.781.562,1.156H952.8q-.078-.173-.289-.648t-.484-1.031q-.273-.554-.562-1.085t-.492-.828a3.115,3.115,0,0,1-.445.031h-.476q-.094,0-.258-.007l-.336-.016c-.115-.005-.218-.01-.312-.017a.877.877,0,0,1-.187-.022v3.622h-2.545Zm2.545,3.482a.825.825,0,0,1,.133.016q.086.016.242.031c.1.011.236.019.4.023s.362.008.6.008a4.285,4.285,0,0,0,1.1-.117,1.842,1.842,0,0,0,.671-.312.983.983,0,0,0,.328-.453,1.65,1.65,0,0,0,.086-.523,1.754,1.754,0,0,0-.078-.507,1.062,1.062,0,0,0-.32-.477,1.864,1.864,0,0,0-.7-.359,4.19,4.19,0,0,0-1.2-.141q-.547,0-.867.023c-.213.016-.346.029-.4.04Z"
          transform="translate(-739.214 5481.486)"
        />
        <StyledLogoText
          d="M1101.3-5421.5q-.218-.5-.474-1.167t-.529-1.408q-.272-.738-.536-1.486t-.5-1.408c-.156-.441-.293-.834-.412-1.182s-.21-.62-.273-.816l-.358-1.128a3.155,3.155,0,0,1-.156-.957,1.067,1.067,0,0,1,.319-.824,1.337,1.337,0,0,1,.941-.3,2.553,2.553,0,0,1,.832.117,2.945,2.945,0,0,1,.412.164q.156.637.42,1.555t.568,1.905q.3.988.607,1.936t.536,1.633q.218-.746.444-1.579t.435-1.656q.21-.825.4-1.587t.349-1.368a1.764,1.764,0,0,1,.42-.809,1.328,1.328,0,0,1,1-.312,2.382,2.382,0,0,1,.832.14,3.828,3.828,0,0,1,.459.187q.124.559.334,1.454t.459,1.883q.248.987.49,1.951t.443,1.68q.249-.747.506-1.579t.49-1.649q.233-.817.436-1.555t.342-1.284a1.781,1.781,0,0,1,.506-.948,1.586,1.586,0,0,1,1.034-.28,2.572,2.572,0,0,1,.568.062,2.858,2.858,0,0,1,.474.148,2.739,2.739,0,0,1,.334.163c.088.052.143.083.164.093q-.607,2.208-1.525,4.822t-2.037,5.382h-2.769q-.14-.342-.342-1t-.42-1.462q-.219-.8-.444-1.641t-.381-1.54q-.187.7-.412,1.54t-.459,1.641q-.233.8-.435,1.462t-.342,1Z"
          transform="translate(-881.018 5481.421)"
        />
        <StyledLogoText
          d="M1195.719-5451.651q.406.548.8,1.148t.749,1.2q.358.6.671,1.17t.561,1.039h-1.311c-.115-.24-.286-.562-.515-.968s-.471-.824-.726-1.256-.508-.841-.757-1.225-.453-.676-.609-.874a6,6,0,0,1-.6.061c-.192.012-.377.017-.554.017q-.89,0-1.514-.039t-.936-.085v4.37h-1.062v-10.083q0-.546.625-.625c.115-.02.4-.052.843-.093s1.051-.063,1.81-.063a10.406,10.406,0,0,1,1.943.171,4.763,4.763,0,0,1,1.553.563,2.851,2.851,0,0,1,1.023,1.014,2.977,2.977,0,0,1,.367,1.53,3.29,3.29,0,0,1-.187,1.154,2.812,2.812,0,0,1-.508.866,2.855,2.855,0,0,1-.749.609A4.932,4.932,0,0,1,1195.719-5451.651Zm1.234-3.027a2.232,2.232,0,0,0-.2-.961,1.913,1.913,0,0,0-.648-.742,3.41,3.41,0,0,0-1.171-.484,7.838,7.838,0,0,0-1.771-.171q-1,0-1.522.046c-.349.032-.569.057-.663.078v4.495c.187.032.462.066.827.1s.812.055,1.343.055a5.219,5.219,0,0,0,2.918-.625A2.057,2.057,0,0,0,1196.952-5454.678Z"
          transform="translate(-996.912 5507.002)"
        />
        <StyledLogoText
          d="M1056.907-5448.091q.234-.732.507-1.631t.547-1.818q.273-.921.523-1.811t.461-1.623q.211-.733.351-1.25t.187-.686a1.321,1.321,0,0,1,.3-.577.726.726,0,0,1,.561-.219,1.589,1.589,0,0,1,.422.047,1.026,1.026,0,0,1,.25.094q.5,2.121,1.155,4.518t1.435,4.972q.454-1.249.89-2.505t.819-2.419q.384-1.164.7-2.169t.539-1.756a1.559,1.559,0,0,1,.3-.585.632.632,0,0,1,.492-.2.934.934,0,0,1,.366.054,1.142,1.142,0,0,1,.179.1q-.172.624-.429,1.444t-.57,1.733q-.313.913-.655,1.9t-.695,1.943q-.352.961-.686,1.866t-.632,1.67h-1.374c-.073-.2-.169-.5-.289-.9s-.255-.869-.406-1.389-.315-1.083-.492-1.686-.349-1.213-.515-1.827-.326-1.211-.477-1.795-.278-1.113-.382-1.592q-.172.7-.405,1.561t-.492,1.756q-.258.9-.523,1.788t-.507,1.677q-.242.789-.452,1.413t-.336,1h-1.358q-.234-.546-.538-1.342t-.632-1.678q-.328-.881-.656-1.78t-.6-1.678q-.274-.781-.477-1.358t-.281-.812q-.172-.5-.273-.874a2.365,2.365,0,0,1-.1-.547.692.692,0,0,1,.149-.459.562.562,0,0,1,.46-.18.876.876,0,0,1,.312.054.6.6,0,0,1,.171.087q.172.669.538,1.817t.82,2.482q.452,1.334.928,2.7T1056.907-5448.091Z"
          transform="translate(-876.261 5506.904)"
        />
        <path
          d="M1143.557-5557.5a5.6,5.6,0,0,1,0-7.922,5.615,5.615,0,0,1,7.932,0,5.6,5.6,0,0,1,0,7.922,5.615,5.615,0,0,1-7.932,0"
          transform="translate(-1000.162 5619.647)"
          fill="#e3201c"
        />
        <path
          d="M1136.071-5599.6a2.1,2.1,0,0,0-2.34-1.835,25.9,25.9,0,0,0-14.352,6.914q-.395.365-.778.748a28.89,28.89,0,0,0-2.461,2.8,26.858,26.858,0,0,0-3.688,6.424,31,31,0,0,1,3.42,2.9,22.924,22.924,0,0,1,5.427-8.9c.188-.193.379-.384.573-.571a21.915,21.915,0,0,1,12.361-6.148,2.1,2.1,0,0,0,1.838-2.337"
          transform="translate(-979.825 5643.381)"
          fill="#086cb3"
        />
        <path
          d="M1074.962-5520.549a5.614,5.614,0,0,1,7.932,0,5.6,5.6,0,0,1,0,7.922,5.615,5.615,0,0,1-7.932,0,5.6,5.6,0,0,1,0-7.922"
          transform="translate(-952.813 5588.671)"
          fill="#e3201c"
        />
        <path
          d="M1099.774-5530.348a25.845,25.845,0,0,0-6.948-14.359c-.236-.253-.477-.505-.724-.751a28.924,28.924,0,0,0-2.834-2.479,26.914,26.914,0,0,0-6.389-3.654,30.945,30.945,0,0,1-2.913,3.411,22.988,22.988,0,0,1,8.993,5.505q.159.155.315.313a21.915,21.915,0,0,1,6.322,12.516,2.1,2.1,0,0,0,2.086,1.851,2.228,2.228,0,0,0,.254-.015,2.1,2.1,0,0,0,1.838-2.337"
          transform="translate(-957.401 5608.965)"
          fill="#f8b227"
        />
        <path
          d="M1038.018-5583.043a23.045,23.045,0,0,1-5.409,8.637c-.12.123-.241.242-.363.361a21.941,21.941,0,0,1-12.466,6.252,2.1,2.1,0,0,0-1.839,2.337,2.1,2.1,0,0,0,2.086,1.85,2.118,2.118,0,0,0,.253-.015,25.894,25.894,0,0,0,14.331-6.893q.405-.376.8-.769a28.868,28.868,0,0,0,2.446-2.78,27.085,27.085,0,0,0,3.407-5.737Z"
          transform="translate(-914.577 5630.677)"
          fill="#086cb3"
        />
        <path
          d="M1038.127-5589.2a5.6,5.6,0,0,1,0,7.922,5.615,5.615,0,0,1-7.932,0,5.6,5.6,0,0,1,0-7.922,5.614,5.614,0,0,1,7.932,0"
          transform="translate(-921.911 5636.058)"
          fill="#e3201c"
        />
        <path
          d="M1106.751-5626.066a5.615,5.615,0,0,1-7.932,0,5.6,5.6,0,0,1,0-7.922,5.614,5.614,0,0,1,7.932,0,5.6,5.6,0,0,1,0,7.922"
          transform="translate(-969.281 5666.976)"
          fill="#e3201c"
        />
        <path
          d="M1066.938-5644.389a2.1,2.1,0,0,0-2.34-1.835,2.1,2.1,0,0,0-1.838,2.336,26.061,26.061,0,0,0,7.672,15.111,27.575,27.575,0,0,0,9.2,6.127,31.021,31.021,0,0,1,2.909-3.412c-7.946-2.54-14.615-10.114-15.6-18.327"
          transform="translate(-945.514 5674.299)"
          fill="#f8b227"
        />
      </g>
    </StyledSVG>
  );
};

export default NavLogo;
