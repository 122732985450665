// React
import React, { useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Services
import { fetchTaskList } from 'services/taskServices';

// Actions
import { clearTasks as clearTasksAction } from 'actions/taskActions';

// Data
import { COORDINATOR, PROGRAM_COORDINATOR } from 'constants/roles';
import { filterTasks } from 'constants/tableFilters';

// Elements
import TableTemplate from 'templates/TableTemplate';
import FloatingButton from 'components/atoms/FloatingButton/FloatingButton';
import GuardedComponent from 'components/molecules/GuardedComponent/GuardedComponent';

// Data
const headCells = [
  { type: 'name', label: 'Nazwa zadania' },
  { type: 'program', label: 'Nazwa programu' },
  { type: 'task_owner', label: 'Twórca zadania' },
  { type: 'task_organizations', label: 'Organizacje przypisane do zadania' },
  { type: 'task_description', label: 'Opis zadania' },
  { type: 'hours', label: 'Godziny' },
  { type: 'coordinator', label: 'Koordynator' },
];

// Component
const TasksTable = ({ clearTasks, fetchList, tasks }) => {
  const history = useHistory();
  const triggerFetchList = useCallback(() => fetchList(), [fetchList]);

  useEffect(() => {
    clearTasks();
  }, []);

  useEffect(() => {
    triggerFetchList();
  }, [triggerFetchList]);

  return (
    <>
      <TableTemplate
        headCells={headCells}
        filters={filterTasks}
        data={tasks}
        isnav
        link="/zadania"
        tableName="Zadania"
      />
      <GuardedComponent allowed_user_roles={[COORDINATOR, PROGRAM_COORDINATOR]}>
        <FloatingButton onClick={() => history.push('/zadania-dodaj')} />
      </GuardedComponent>
    </>
  );
};

TasksTable.propTypes = {
  clearTasks: PropTypes.func,
  fetchList: PropTypes.func,
  tasks: PropTypes.arrayOf(PropTypes.any),
};

TasksTable.defaultProps = {
  clearTasks: null,
  fetchList: null,
  tasks: [],
};

const mapStateToProps = ({ taskReducer }) => ({
  tasks: taskReducer.tasks,
});

const mapDispatchToProps = (dispatch) => ({
  clearTasks: () => dispatch(clearTasksAction()),
  fetchList: () => dispatch(fetchTaskList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TasksTable);
