// Styles
import styled from 'styled-components';
import { Grid } from '@material-ui/core';

export const StyledTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 3rem;
  padding-right: 1px;
`;

export const StyledWrapper = styled(Grid)`
  && {
    margin-top: 4rem;
  }
`;
