// Elements
import { Grid } from '@material-ui/core';

// Styles
import styled from 'styled-components';
import { device } from 'theme/mainTheme';

export const StyledWrapper = styled(Grid)`
  && {
    margin-top: 2rem;
    max-width: ${({ desktop }) => desktop || '50%'};

    @media ${device.laptopL} {
      max-width: ${({ mobile }) => mobile || '30%'};
    }
  }
`;
