import axiosInstance from 'utils/axios';
import { fetchRequest, fetchSuccess, fetchFailure } from 'actions/appActions';
import {
  showUsers,
  fetchUsersLoginStatisticsAction,
  fetchUserAction,
  fetchMeAction,
  clearMeAction,
  fetchUserPermissionsAction,
  fetchUserNotificationsAction,
} from 'actions/userActions';
import { checkStatusCode } from 'utils/checkStatusCode';

export const fetchUserList = () => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .get('/uzytkownicy/nowa_lista')
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(showUsers(res.data));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
        }),
      );
    });
};

export const fetchUserSimpleList = () => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .get('/uzytkownicy/prosta_lista')
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(showUsers(res.data));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
        }),
      );
    });
};

export const fetchUsersLoginStatistics = () => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .get('/uzytkownicy/statystyki/logowanie')
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(fetchUsersLoginStatisticsAction(res.data));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
        }),
      );
    });
};

export const clearMe = () => (dispatch) => {
  dispatch(clearMeAction());
};

export const fetchMe = () => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .get(`/uzytkownicy/me`)
    .then((res) => {
      dispatch(fetchSuccess());
      window.localStorage.setItem('me', JSON.stringify(res.data.data));
      dispatch(fetchMeAction(res.data));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
        }),
      );
    });
};

export const fetchUserNotifications = () => (dispatch) => {
  return axiosInstance
    .get(`/uzytkownicy/me/powiadomienia`)
    .then((res) => {
      dispatch(fetchUserNotificationsAction(res.data));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
        }),
      );
    });
};

export const updateUserSession = () => (dispatch) => {
  return axiosInstance
    .get(`/uzytkownicy/me/sesja`)
    .then(() => {})
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
        }),
      );
    });
};
export const fetchUser = (id) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .get(`/uzytkownicy/${id}`)
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(fetchUserAction(res.data));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
        }),
      );
    });
};

export const fetchUserPermissions = (id) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .get(`/uzytkownicy/uprawnienia/${id}`)
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(fetchUserPermissionsAction(res.data));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
        }),
      );
    });
};

export const editRolaSystemowa = (id, data) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .post(`/uzytkownicy/ustaw_role/${id}`, { admin: data })
    .then((result) => {
      dispatch(
        fetchSuccess({
          type: 'success',
          message: result.data.status,
          isActive: true,
        }),
      );
      dispatch(fetchUserPermissions(id));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err, 'Nie udało się edytować użytkownika'),
          isActive: true,
        }),
      );
    });
};

export const initUserPasswordChange = (id) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .post(`/uzytkownicy/zainicjuj_zmiane_hasla/${id}`, {})
    .then((result) => {
      dispatch(
        fetchSuccess({
          type: 'success',
          message: result.data.status,
          isActive: true,
        }),
      );
      dispatch(fetchUser(id));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err, 'Nie udało się zainicjować zmiany hasła użytkownika'),
          isActive: true,
        }),
      );
    });
};

export const initMyPasswordChange = () => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .post(`/uzytkownicy/zainicjuj_zmiane_hasla/me`, {})
    .then((result) => {
      dispatch(
        fetchSuccess({
          type: 'success',
          message: result.data.status,
          isActive: true,
        }),
      );
      dispatch(fetchMe());
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err, 'Nie udało się zainicjować zmiany hasła użytkownika'),
          isActive: true,
        }),
      );
    });
};

export const userLockTime = (id, data) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .post(`/uzytkownicy/zablokuj_czasowo/${id}`, data)
    .then((result) => {
      dispatch(
        fetchSuccess({
          type: 'success',
          message: result.data.status,
          isActive: true,
        }),
      );
      dispatch(fetchUser(id));
      // dispatch(fetchProgramAction(res.data));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err, 'Nie udało się zablokować czasowo użytkownika'),
          isActive: true,
        }),
      );
    });
};

export const suspendMyAccount = () => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .post(`/uzytkownicy/zawies_uzytkownika/me`)
    .then((result) => {
      dispatch(
        fetchSuccess({
          type: 'success',
          message: result.data.status,
          isActive: true,
        }),
      );
      dispatch(fetchMe());
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err, 'Nie udało się zawiesić użytkownika'),
          isActive: true,
        }),
      );
    });
};

export const userSuspension = (id) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .post(`/uzytkownicy/zawies_uzytkownika/${id}`, {})
    .then((result) => {
      dispatch(
        fetchSuccess({
          type: 'success',
          message: result.data.status,
          isActive: true,
        }),
      );
      dispatch(fetchUser(id));
      // dispatch(fetchProgramAction(res.data));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err, 'Nie udało się zawiesić użytkownika'),
          isActive: true,
        }),
      );
    });
};

export const editUser = (id, data) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .post(`/uzytkownicy/${id}/edytuj_uzytkownika`, data)
    .then((result) => {
      dispatch(
        fetchSuccess({
          type: 'success',
          message: result.data.status,
          isActive: true,
        }),
      );
      dispatch(fetchUser(id));
      // dispatch(fetchProgramAction(res.data));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err, 'Nie udało się edytować użytkownika'),
          isActive: true,
        }),
      );
    });
};

export const mailToAdmin = (data) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .post(`/uzytkownicy/mail_to_admin`, data)
    .then((result) => {
      dispatch(
        fetchSuccess({
          type: 'success',
          message: result.data.status,
          isActive: true,
        }),
      );
      dispatch(fetchMe());
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err, 'Nie udało się wysłać wiadomości do administratorów'),
          isActive: true,
        }),
      );
    });
};

export const saveMySettings = (data) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .post(`/uzytkownicy/me/zapisz_ustawienia`, data)
    .then((result) => {
      dispatch(
        fetchSuccess({
          type: 'success',
          message: result.data.status,
          isActive: true,
        }),
      );
      dispatch(fetchMe());
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err, 'Nie udało się edytować użytkownika'),
          isActive: true,
        }),
      );
    });
};

export const editMe = (data) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .post(`/uzytkownicy/me/edytuj_uzytkownika`, data)
    .then((result) => {
      dispatch(
        fetchSuccess({
          type: 'success',
          message: result.data.status,
          isActive: true,
        }),
      );
      dispatch(fetchMe());
      // dispatch(fetchProgramAction(res.data));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err, 'Nie udało się edytować użytkownika'),
          isActive: true,
        }),
      );
    });
};

export const updateUserStatus = (id, data) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .post(`/uzytkownicy/${id}/edytuj_status`, data)
    .then((result) => {
      dispatch(
        fetchSuccess({
          type: 'success',
          message: result.data.status,
          isActive: true,
        }),
      );
      dispatch(fetchUser(id));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err, 'Nie udało się zmienić statusu użytkownika'),
          isActive: true,
        }),
      );
    });
};

export const manageUserRole = (id, type, data) => (dispatch) => {
  dispatch(fetchRequest());

  return axiosInstance
    .post(`/uzytkownicy/role/${type}/${id}/edytuj_uprawnienie`, data)
    .then((result) => {
      dispatch(
        fetchSuccess({
          type: 'success',
          message: result.data.status,
          isActive: true,
        }),
      );
      dispatch(fetchUserPermissions(id));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err, 'Nie udało się zmienić uprawnień'),
          isActive: true,
        }),
      );
    });
};
export const manageUserRoleGuardian = (id, data) => (dispatch) => {
  dispatch(fetchRequest());

  return axiosInstance
    .post(`/uzytkownicy/role/program/${id}/edytuj_uprawnienie_opiekun`, data)
    .then((result) => {
      dispatch(
        fetchSuccess({
          type: 'success',
          message: result.data.status,
          isActive: true,
        }),
      );
      dispatch(fetchUserPermissions(id));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err, 'Nie udało się zmienić uprawnień'),
          isActive: true,
        }),
      );
    });
};

export const removeUserRoleGuardian = (id, user_id) => (dispatch) => {
  dispatch(fetchRequest());

  return axiosInstance
    .post(`/uzytkownicy/role/program/${id}/usun_uprawnienie_opiekun`)
    .then((result) => {
      dispatch(
        fetchSuccess({
          type: 'success',
          message: result.data.status,
          isActive: true,
        }),
      );
      dispatch(fetchUserPermissions(user_id));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err, 'Nie udało się usunąć uprawnienia'),
          isActive: true,
        }),
      );
    });
};

export const removeUserRole = (id, type, user_id) => (dispatch) => {
  dispatch(fetchRequest());
  let url = `/uzytkownicy/role/${type}/${id}/usun_uprawnienie`;
  if (type === 'guardian') {
    url = `/uzytkownicy/role/program/${id}/usun_uprawnienie_opiekun`;
  }
  return axiosInstance
    .post(url)
    .then((result) => {
      dispatch(
        fetchSuccess({
          type: 'success',
          message: result.data.status,
          isActive: true,
        }),
      );
      dispatch(fetchUserPermissions(user_id));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err, 'Nie udało się usunąć uprawnienia'),
          isActive: true,
        }),
      );
    });
};

export const addUser = (data) => (dispatch) => {
  dispatch(fetchRequest());

  return axiosInstance
    .post(`/uzytkownicy/dodaj`, data)
    .then((result) => {
      dispatch(
        fetchSuccess({
          type: 'success',
          message: result.data.status,
          isActive: true,
        }),
      );
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err, 'Nie udało się dodać użytkownika'),
          isActive: true,
        }),
      );
    });
};
