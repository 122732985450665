import axiosInstance from 'utils/axios';
import { fetchFailure } from 'actions/appActions';
import {
  fetchSubjectListAction,
  setSubjectPendingAction,
  fetchPwdListAction,
  setPwdPendingAction,
  fetchEducationalFacilitiesListAction,
  setEducationalFacilitiesPendingAction,
  fetchBeneficiaryIndicatorPeriodAction,
} from 'actions/selectActions';
import { checkStatusCode } from 'utils/checkStatusCode';

export const fetchSubjectList = () => (dispatch) => {
  dispatch(setSubjectPendingAction(true));
  return axiosInstance
    .get('/przedmioty_szkolne/nowa_lista')
    .then((res) => {
      dispatch(setSubjectPendingAction(false));
      dispatch(fetchSubjectListAction(res.data));
    })
    .catch(() => {
      dispatch(setSubjectPendingAction(false));
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(null, 'Nie udało się pobrać listy przedmiotów'),
          isActive: true,
        }),
      );
    });
};

export const fetchPWDList = () => (dispatch) => {
  dispatch(setPwdPendingAction(true));
  return axiosInstance
    .get('/pwd/nowa_lista')
    .then((res) => {
      dispatch(setPwdPendingAction(false));
      dispatch(fetchPwdListAction(res.data));
    })
    .catch(() => {
      dispatch(setPwdPendingAction(false));
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(null, 'Nie udało się pobrać listy placówek wsparcia dziennego'),
          isActive: true,
        }),
      );
    });
};

export const fetchEducationalFacilitiesList = () => (dispatch) => {
  dispatch(setEducationalFacilitiesPendingAction(true));
  return axiosInstance
    .get('/placowki_edukacyjne/nowa_lista')
    .then((res) => {
      dispatch(setEducationalFacilitiesPendingAction(false));
      dispatch(fetchEducationalFacilitiesListAction(res.data));
    })
    .catch(() => {
      dispatch(setEducationalFacilitiesPendingAction(false));
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(null, 'Nie udało się pobrać listy placówek edukacyjnych'),
          isActive: true,
        }),
      );
    });
};

export const fetchBeneficiaryIndicatorPeriod = (id) => (dispatch) => {
  //  dispatch(setEducationalFacilitiesPendingAction(true));
  return axiosInstance
    .get(`/pomiary/wskaznik/${id}`)
    .then((res) => {
      // dispatch(setEducationalFacilitiesPendingAction(false));
      dispatch(fetchBeneficiaryIndicatorPeriodAction(res.data));
    })
    .catch(() => {
      // dispatch(setEducationalFacilitiesPendingAction(false));
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(null, 'Nie udało się pobrać listy placówek edukacyjnych'),
          isActive: true,
        }),
      );
    });
};
