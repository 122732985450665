// Elements
import { FormControlLabel } from '@material-ui/core';

// Styles
import styled from 'styled-components';

export const StyledOption = styled.div`
  && {
    display: flex;
    margin-right: 3.5rem;
  }
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
  && {
    font-family: ${({ theme }) => theme.fontFamily.base};
    color: ${({ theme }) => theme.color.darkGray};
    text-transform: lowercase;

    span {
      font-size: ${({ theme }) => theme.fontSize.body2};
    }

    .MuiSwitch-colorPrimary {
      color: ${({ theme }) => theme.color.gray};
    }

    .MuiSwitch-colorPrimary.Mui-checked {
      color: ${({ theme }) => theme.color.blue};
    }

    .MuiSwitch-switchBase + .MuiSwitch-track {
      background-color: ${({ theme }) => theme.color.black};
    }

    .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
      background-color: ${({ theme }) => theme.color.blue};
      opacity: 0.6;
    }
  }
`;
