// Elements
import { Grid } from '@material-ui/core';
import Button from 'components/atoms/Button/Button';

// Styles
import styled from 'styled-components';

export const StyledGrid = styled(Grid)`
  && {
    max-width: ${({ fullWidth }) => (fullWidth ? '100%' : '50%')};
    &.MuiGrid-spacing-xs-2 {
      margin-top: 2rem;
    }

    margin-top: ${({ margin }) => margin || '0rem'};
  }
`;

export const StyledButton = styled(Button)`
  && {
    padding: ${({ padding }) => padding || '1rem 2.5rem'};
  }
`;

export const StyledTitle = styled.div`
  && {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 3rem;
    padding: 0 3rem;
  }
`;
