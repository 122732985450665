// Assets
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Menu } from '@material-ui/core';

// Styles
import styled from 'styled-components';

export const StyledMoreVertIcon = styled(MoreVertIcon)`
  && {
    font-size: ${({ size }) => size || '2rem'};
    fill: ${({ theme }) => theme.color.darkGray};
  }
`;

export const StyledMenu = styled(Menu)`
  && {
    .MuiPaper-root {
      background: ${({ theme }) => theme.color.white};
    }
  }
`;
