// Elements
import { Grid } from '@material-ui/core';
import Button from 'components/atoms/Button/Button';

// Styles
import styled from 'styled-components';

export const StyledGrid = styled(Grid)`
  && {
    max-width: ${({ fullWidth }) => (fullWidth ? '100%' : '70%')};
    &.MuiGrid-spacing-xs-2 {
      margin-top: 2rem;
    }

    margin-top: ${({ margin }) => margin || '0rem'};
  }
`;

export const StyledTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5rem;
`;

export const StyledButton = styled(Button)`
  && {
    padding: 1rem 3rem;
  }
`;
