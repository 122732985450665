// React
import React from 'react';
import PropTypes from 'prop-types';

// Elements
import { DialogTitle, DialogContent } from '@material-ui/core';
import Heading from 'components/atoms/Heading/Heading';

// Styles
import { StyledDialog } from './Dialog.styles';

// Component
const Dialog = ({ open, setOpen, title, children, size, statusy, onClose }) => (
  <StyledDialog
    open={open}
    onClose={() => {
      if (onClose) {
        return onClose();
      }

      return setOpen ? setOpen(false) : false;
    }}
    fullWidth
    maxWidth={size}
  >
    <DialogTitle>
      <Heading.Subtitle1>{title}</Heading.Subtitle1>
      <div>
        <Heading.Subtitle1>{statusy}</Heading.Subtitle1>
      </div>
    </DialogTitle>
    <DialogContent>{children}</DialogContent>
  </StyledDialog>
);

Dialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  statusy: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  children: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.element])),
  ]),
  size: PropTypes.string,
  onClose: PropTypes.func,
};

Dialog.defaultProps = {
  statusy: '',
  open: false,
  setOpen: null,
  title: '',
  size: 'sm',
  children: [],
  onClose: null,
};

export default Dialog;
