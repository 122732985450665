// Elements
import { Box } from '@material-ui/core';

// Styles
import styled from 'styled-components';

export const StyledBox = styled(Box)`
  && {
    font-size: 1.4rem;
    background-color: ${({ theme }) => theme.color.lightGray};
    border-bottom: 0.2rem solid ${({ theme }) => theme.color.mediumGray};
    border-radius: 0.6rem 0.6rem 0 0;
    padding: 1.5rem;

    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }
`;
