// React
import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';

// Services
import { fetchProgramTasks } from 'services/programServices';

// Actions
import { clearProgramTasks as clearProgramTasksAction } from 'actions/programActions';

// Data
import { filterProgramsTask } from 'constants/tableFilters';
import { COORDINATOR, PROGRAM_COORDINATOR } from 'constants/roles';

// Elements
import TableTemplate from 'templates/TableTemplate';
import FloatingButton from 'components/atoms/FloatingButton/FloatingButton';
import GuardedComponent from 'components/molecules/GuardedComponent/GuardedComponent';

// Data
const headCells = [
  { type: 'task_name', label: 'Nazwa Zadania' },
  { type: 'organizations', label: 'Organizacje przypisane' },
  { type: 'coordinator', label: 'Koordynator ' },
  // { type: 'date_start', label: 'Data rozpoczęcia' },
  // { type: 'date_end', label: 'Data zakończenia' },
  { type: 'task_description', label: 'Opis Zadania' },
];

// Component
const ProgramTasks = ({ clearProgramTasks, fetchTasks, tasks }) => {
  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    clearProgramTasks();
  }, []);

  const triggerFetchTasks = useCallback((myid) => fetchTasks(myid), [fetchTasks]);

  useEffect(() => {
    triggerFetchTasks(id);
  }, [id, triggerFetchTasks]);

  return (
    <>
      <TableTemplate
        headCells={headCells}
        data={tasks}
        filters={filterProgramsTask}
        isnav
        link="/zadania"
        tableName="Zadania"
      />
      <GuardedComponent
        allowed_user_roles={[COORDINATOR]}
        program_id={parseInt(id, 10)}
        allowed_program_roles={[PROGRAM_COORDINATOR]}
      >
        <FloatingButton onClick={() => history.push(`/zadania-dodaj/program/${id}`)} />
      </GuardedComponent>
    </>
  );
};

ProgramTasks.propTypes = {
  clearProgramTasks: PropTypes.func,
  fetchTasks: PropTypes.func,
  tasks: PropTypes.arrayOf(PropTypes.any),
};

ProgramTasks.defaultProps = {
  clearProgramTasks: null,
  fetchTasks: null,
  tasks: [],
};

const mapStateToProps = ({ programReducer }) => ({
  tasks: programReducer.tasks,
});

const mapDispatchToProps = (dispatch) => ({
  clearProgramTasks: () => dispatch(clearProgramTasksAction()),
  fetchTasks: (id) => dispatch(fetchProgramTasks(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProgramTasks);
