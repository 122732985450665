// React
import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

// Redux
import { Provider } from 'react-redux';

// Store
import store from 'store';

// Elements
import MainTemplate from 'templates/MainTemplate';
import Dashboard from 'views/Dashboard/Dashboard';
import Login from 'views/Start/Start';

// Component
const Root = () => {
  return (
    <Provider store={store}>
      <MainTemplate>
        <BrowserRouter>
          <Switch>
            <Route exact path="/" component={Login}>
              <Redirect to="/start" />
            </Route>
            <Route exact path="/start" component={Login} />
            <Route path="/" component={Dashboard} />
          </Switch>
        </BrowserRouter>
      </MainTemplate>
    </Provider>
  );
};

export default Root;
