// React
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Breadcrumb
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { WORKING_WITH_FAMILY, COOPERATION_IN_SYSTEM_ASSISTANCE } from 'constants/ActionBuildInType';

//  Data
import { actionsTabs as renderViewArray } from 'constants/tabs';

// Elements
import Tabs from 'components/molecules/Tabs/Tabs';

// Component
const Action = ({ details }) => {
  const [filteredRenderViewArray, setFilteredRenderViewArray] = useState([renderViewArray[0]]);

  useEffect(() => {
    if (details && details.build_in_type) {
      if (
        details.build_in_type === WORKING_WITH_FAMILY ||
        details.build_in_type === COOPERATION_IN_SYSTEM_ASSISTANCE
      ) {
        setFilteredRenderViewArray(
          renderViewArray.filter((v) => {
            return v.key !== 'dziennik-obecnosci';
          }),
        );
      } else {
        setFilteredRenderViewArray(
          renderViewArray.filter((v) => {
            return v.key !== 'spotkania';
          }),
        );
      }
    }
    if (details.first_card_only) {
      setFilteredRenderViewArray([renderViewArray[0]]);
    }
  }, [details, renderViewArray]);

  return (
    <>
      {details && details.program && (
        <BreadcrumbsItem to={`/programy/${details.program.id}`} order={2}>
          Program: {details.program.name}
        </BreadcrumbsItem>
      )}
      {details && details.task && (
        <BreadcrumbsItem to={`/zadania/${details.task.id}`} order={3}>
          Zadanie: {details.task.name}
        </BreadcrumbsItem>
      )}
      {details && details.id && (
        <BreadcrumbsItem to={`/dzialania/${details.id}`} order={4}>
          Działanie: {details.name}
        </BreadcrumbsItem>
      )}
      <Tabs
        renderViewArray={filteredRenderViewArray}
        program_id={details && details.program && details.program.id}
      />
    </>
  );
};

Action.propTypes = {
  details: PropTypes.objectOf(PropTypes.any),
};

Action.defaultProps = {
  details: null,
};

const mapStateToProps = ({ actionReducer }) => ({
  details: actionReducer.action,
});

export default connect(mapStateToProps)(Action);
