// React
import React, { useEffect, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Services
import { fetchBeneficiaryList } from 'services/beneficiaryServices';

// Actions
import { clearBeneficiaries as clearBeneficiariesAction } from 'actions/beneficiaryActions';
import {
  addBeneficiaries as addBeneficiariesService,
  fetchPossibleBeneficiaryActionList as fetchActionsList,
  fetchBeneficiaryActionsList as fetchBeneficiaryActionsListService,
  endBeneficiaryParticipationInAction as endBeneficiaryParticipationInActionService,
} from 'services/actionServices';
import { clearActions as clearActionsAction } from 'actions/actionActions';

// Data
import { filterBeneficiary } from 'constants/tableFilters';
import {
  COORDINATOR,
  PROGRAM_COORDINATOR,
  TASK_COORDINATOR,
  LECTURER_SENSITIVE_DATA,
  LECTURER,
  ORGANIZATION_ADMINISTRATOR,
  ORGANIZATION_EMPLOYEE,
} from 'constants/roles';

// Elements
import TableTemplate from 'templates/TableTemplate';
import FloatingButton from 'components/atoms/FloatingButton/FloatingButton';
import DialogBeneficiaryLibrus from 'components/templates/DialogBeneficiaryLibrus/DialogBeneficiaryLibrus';
import Dialog from 'components/organisms/Dialog/Dialog';
import DialogConfirm from 'components/templates/DialogConfirm/DialogConfirm';
import GuardedComponent from 'components/molecules/GuardedComponent/GuardedComponent';

// Data
const headCells = [
  { type: 'name', label: 'Imię' },
  { type: 'surname', label: 'Nazwisko' },
  { type: 'gender', label: 'Płeć' },
  { type: 'pwd', label: 'PWD' },
  { type: 'date_add', label: 'Data dodania' },
  // { type: 'date_modified', label: 'Data ostatniej modyfikacji' },
  // { type: 'modified_by', label: 'Modyfikowany przez' },
];

const headCellsActions = [{ type: 'action_name', label: 'Nazwa działania', nav: true }];

// Component
const Beneficiary = ({
  clearBeneficiaries,
  fetchList,
  beneficiaries,
  clearActions,
  fetchActions,
  fetchBeneficiaryActions,
  actions,
  addBeneficiaries,
  endBeneficiaryParticipationInAction,
}) => {
  const history = useHistory();
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogActions, setOpenDialogActions] = useState(false);
  const [openDialogEndActions, setOpenDialogEndActions] = useState(false);
  const [currentActionToEnd, setCurrentActionToEnd] = useState(null);
  const [currentBeneficiary, setCurrentBeneficiary] = useState(null);
  const [openDialogConfirmEndAction, setOpenDialogConfirmEndAction] = useState(false);

  useEffect(() => {
    clearBeneficiaries();
  }, []);

  const triggerFetchList = useCallback(() => fetchList(), [fetchList]);

  const actionCellsActions = [
    {
      column: 'zapisz beneficjenta',
      action: (row) => {
        addBeneficiaries(row.id, {
          beneficiaries: [
            {
              cwp_id: row.cwp_id,
            },
          ],
        });
      },
      button: 'zapisz',
    },
  ];

  const actionCellsActionsEnd = [
    {
      column: 'wypisz beneficjenta',
      action: (row) => {
        setOpenDialogConfirmEndAction(true);
        setCurrentActionToEnd(row);
      },
      button: 'wypisz',
    },
  ];
  useEffect(() => {
    triggerFetchList();
  }, [triggerFetchList]);

  return (
    <>
      <TableTemplate
        headCells={headCells}
        data={beneficiaries && beneficiaries}
        navCells
        link="/beneficjenci"
        filters={filterBeneficiary}
        actions={[
          {
            name: 'Import z Librusa',
            action: () => setOpenDialog(true),
          },
        ]}
        tableName="Beneficjenci"
        rowActions={[
          {
            name: 'przypisz do działania',
            action: (row) => {
              clearActions();
              fetchActions(row.id);
              setOpenDialogActions(true);
            },
          },
          {
            name: 'przejdź do uzupełniania wskaźników',
            action: (row) => history.push(`/beneficjenci/${row.id}/tab/4-wskazniki`),
          },
          {
            name: 'wypisz z działania',
            action: (row) => {
              setCurrentBeneficiary(row);
              fetchBeneficiaryActions(row.id);
              setOpenDialogEndActions(true);
            },
          },
        ]}
        tableBene
      />
      <GuardedComponent
        allowed_user_roles={[
          COORDINATOR,
          PROGRAM_COORDINATOR,
          TASK_COORDINATOR,
          LECTURER_SENSITIVE_DATA,
          LECTURER,
          ORGANIZATION_EMPLOYEE,
          ORGANIZATION_ADMINISTRATOR,
        ]}
      >
        <FloatingButton onClick={() => history.push('/beneficjenci-dodaj')} />
      </GuardedComponent>
      <DialogBeneficiaryLibrus open={openDialog} setOpenFn={setOpenDialog} />
      <Dialog
        size="md"
        open={openDialogActions}
        setOpen={setOpenDialogActions}
        title="Lista działań z programów beneficjenta"
      >
        <>
          <TableTemplate
            headCells={headCellsActions}
            data={actions}
            tableName="Lista działań"
            navCells
            link="/dzialania"
            actionCells={actionCellsActions}
          />
        </>
        <></>
      </Dialog>
      <Dialog
        size="md"
        open={openDialogEndActions}
        setOpen={setOpenDialogEndActions}
        title="Lista działań beneficjenta"
      >
        <>
          <TableTemplate
            headCells={headCellsActions}
            data={actions}
            tableName="Lista działań"
            navCells
            link="/dzialania"
            actionCells={actionCellsActionsEnd}
          />
        </>
        <></>
      </Dialog>
      {currentActionToEnd && currentBeneficiary && (
        <DialogConfirm
          item={currentActionToEnd}
          removeFunction={() => {
            endBeneficiaryParticipationInAction(currentActionToEnd.id, currentActionToEnd.cwa_id);
            setCurrentActionToEnd(null);
          }}
          title={`Potwierdź wypisanie beneficjenta ${currentBeneficiary.name} ${currentBeneficiary.surname} z działania "${currentActionToEnd.action_name}"`}
          open={openDialogConfirmEndAction}
          setOpenFn={setOpenDialogConfirmEndAction}
        />
      )}
    </>
  );
};

Beneficiary.propTypes = {
  clearBeneficiaries: PropTypes.func,
  fetchList: PropTypes.func,
  beneficiaries: PropTypes.arrayOf(PropTypes.any),
  clearActions: PropTypes.func,
  fetchActions: PropTypes.func,
  fetchBeneficiaryActions: PropTypes.func,
  actions: PropTypes.arrayOf(PropTypes.any),
  addBeneficiaries: PropTypes.func,
  endBeneficiaryParticipationInAction: PropTypes.func,
};

Beneficiary.defaultProps = {
  clearBeneficiaries: null,
  fetchList: null,
  beneficiaries: [],
  clearActions: null,
  fetchActions: null,
  actions: [],
  addBeneficiaries: null,
  endBeneficiaryParticipationInAction: null,
  fetchBeneficiaryActions: null,
};

const mapStateToProps = ({ beneficiaryReducer, actionReducer }) => ({
  beneficiaries: beneficiaryReducer.beneficiaries,
  actions: actionReducer.actions,
});

const mapDispatchToProps = (dispatch) => ({
  clearBeneficiaries: () => dispatch(clearBeneficiariesAction()),
  fetchList: () => dispatch(fetchBeneficiaryList()),
  clearActions: () => dispatch(clearActionsAction()),
  fetchActions: (id) => dispatch(fetchActionsList(id)),
  fetchBeneficiaryActions: (id) => dispatch(fetchBeneficiaryActionsListService(id)),
  addBeneficiaries: (id, data) => dispatch(addBeneficiariesService(id, data)),
  endBeneficiaryParticipationInAction: (action_id, cwa_id) =>
    dispatch(endBeneficiaryParticipationInActionService(action_id, cwa_id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Beneficiary);
