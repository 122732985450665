// React
import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Services
import {
  fetchBeneficiaryDocuments as fetchBeneficiaryDocumentsService,
  removeBeneficiaryDocument as removeBeneficiaryDocumentService,
} from 'services/beneficiaryServices';

// Actions
import { clearBeneficiaryDocuments as clearBeneficiaryDocumentsAction } from 'actions/beneficiaryActions';

// Assets
import InputIcon from '@material-ui/icons/Input';

// Elements
import { Grid } from '@material-ui/core';
import Heading from 'components/atoms/Heading/Heading';
import TableTemplate from 'templates/TableTemplate';
import Checkbox from 'components/atoms/Checkbox/Checkbox';
import MyAccordion from 'components/atoms/Accordion/Accordion';
import DialogBeneficiaryDocuments from 'components/templates/DialogBeneficiaryDocuments/DialogBeneficiaryDocuments';
import DialogConfirm from 'components/templates/DialogConfirm/DialogConfirm';

import { showAlert as showAlertAction } from 'actions/appActions';

import { ZANONIMIZOWANO_ZAKAZ_EDYCJI } from 'constants/alerts';

// Styles
import { StyledWrapper, StyledButton, StyledBox } from './BeneficiaryDocuments.styles';
import './BeneficiaryDocs.css';

// Data
const headCells = [
  { type: 'name', label: 'Nazwa' },
  { type: 'whoAddedDisplay', label: 'Dodany przez' },
  { type: 'addDateString', label: 'Data' },

  // { type: 'organization', label: 'Organizacja' },
  { type: 'originalName', label: 'Plik' },
];

// Component
const BeneficiaryDocuments = ({
  clearBeneficiaryDocuments,
  fetchDocuments,
  documents,
  removeBeneficiaryDocument,
  beneficiary,
  showAlert,
}) => {
  const { id } = useParams();
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogConfirm, setOpenDialogConfirm] = useState(false);
  const [cwpID, setCwpID] = useState(null);
  const [isActiveProgram, setActiveProgram] = useState(false);
  const [isEndProgram, setEndProgram] = useState(false);
  const [currentItem, setCurrentItem] = useState({});
  const [onlyRead, setOnlyRead] = useState(false);

  useEffect(() => {
    clearBeneficiaryDocuments();
  }, []);

  const triggerFetchDocuments = useCallback((myid) => fetchDocuments(myid), [fetchDocuments]);

  useEffect(() => {
    triggerFetchDocuments(id);
  }, [id, triggerFetchDocuments]);

  const showAccordion = (ended) =>
    !((!isActiveProgram && !isEndProgram) || (isActiveProgram && !ended) || (isEndProgram && ended))
      ? 'true'
      : 'false';

  return (
    <>
      <StyledWrapper className="notes" container spacing={2}>
        <Grid item xs={2}>
          <Checkbox
            label="Aktualne"
            isEditable={!!true}
            value={isActiveProgram}
            onChange={() => setActiveProgram(!isActiveProgram)}
          />
        </Grid>
        <Grid item xs={2}>
          <Checkbox
            label="Zakończone"
            isEditable={!!true}
            value={isEndProgram}
            onChange={() => setEndProgram(!isEndProgram)}
          />
        </Grid>
      </StyledWrapper>
      {documents && documents.documents && documents.documents.length ? (
        documents.documents.map(({ cwp_id: itemProgramID, name, ended, documents: itemData }) => {
          return (
            <MyAccordion key={itemProgramID} title={name} hide={showAccordion(ended)}>
              <StyledBox>
                <TableTemplate
                  headCells={headCells}
                  data={itemData}
                  noFilter
                  rowActions={[
                    {
                      name: 'pobierz plik',
                      action: (row) => {
                        window.open(`/api/do-pobrania/${row.uuid}`, '_blank');
                      },
                    },
                    {
                      name: 'usuń',
                      action: (row) => {
                        if (beneficiary && beneficiary.name === 'anonimizacja') {
                          showAlert(ZANONIMIZOWANO_ZAKAZ_EDYCJI);
                        } else {
                          setCurrentItem(row);
                          setOpenDialogConfirm(true);
                        }
                      },
                    },
                  ]}
                />
                {(!beneficiary || beneficiary.name !== 'anonimizacja') && (
                  <StyledButton
                    startIcon={<InputIcon />}
                    onClick={() => {
                      setOpenDialog(true);
                      setCwpID(itemProgramID);
                      setCurrentItem({});
                      setOnlyRead(false);
                    }}
                  >
                    Importuj dokument
                  </StyledButton>
                )}
              </StyledBox>
            </MyAccordion>
          );
        })
      ) : (
        <Grid item xs={12} container justifyContent="center">
          <Heading.Subtitle1>Brak danych</Heading.Subtitle1>
        </Grid>
      )}
      <DialogBeneficiaryDocuments
        beneficiaryId={parseInt(id, 10)}
        cwpid={cwpID}
        item={currentItem}
        open={openDialog}
        setOpenFn={setOpenDialog}
        onlyRead={onlyRead}
      />
      {currentItem && (
        <DialogConfirm
          item={currentItem}
          removeFunction={() => removeBeneficiaryDocument(currentItem.id, id)}
          title={`Potwierdź usunięcie dokumentu "${currentItem.name}", dane zostaną utracone!`}
          open={openDialogConfirm}
          setOpenFn={setOpenDialogConfirm}
        />
      )}
    </>
  );
};

BeneficiaryDocuments.propTypes = {
  clearBeneficiaryDocuments: PropTypes.func,
  fetchDocuments: PropTypes.func,
  documents: PropTypes.shape({
    documents: PropTypes.arrayOf(PropTypes.any),
  }),
  removeBeneficiaryDocument: PropTypes.func,
  showAlert: PropTypes.func,
  beneficiary: PropTypes.shape({ name: PropTypes.string, surname: PropTypes.string }),
};

BeneficiaryDocuments.defaultProps = {
  clearBeneficiaryDocuments: null,
  fetchDocuments: null,
  documents: {},
  removeBeneficiaryDocument: null,
  showAlert: null,
  beneficiary: null,
};

const mapStateToProps = ({ beneficiaryReducer }) => ({
  documents: beneficiaryReducer.documents,
  beneficiary: beneficiaryReducer.beneficiary,
});

const mapDispatchToProps = (dispatch) => ({
  clearBeneficiaryDocuments: () => dispatch(clearBeneficiaryDocumentsAction()),
  fetchDocuments: (id) => dispatch(fetchBeneficiaryDocumentsService(id)),
  removeBeneficiaryDocument: (id, beneficiary_id) =>
    dispatch(removeBeneficiaryDocumentService(id, beneficiary_id)),
  showAlert: (data) => dispatch(showAlertAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BeneficiaryDocuments);
