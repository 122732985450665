// Assets
import AddIcon from '@material-ui/icons/Add';

// Elements
import { Fab, Tooltip } from '@material-ui/core';
import { SpeedDial, SpeedDialAction } from '@material-ui/lab';

// Styles
import { withStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

export const StyledFab = styled(Fab)`
  && {
    position: fixed;
    bottom: 4rem;
    right: 4rem;
    background-color: ${({ theme }) => theme.color.red};
    transition: 0.3s;
    z-index: 2;

    &:hover {
      background-color: ${({ theme }) => theme.color.red};
    }
  }
`;

export const StyledSpeedDial = styled(SpeedDial)`
  && {
    position: fixed;
    bottom: 4rem;
    right: ${(props) => (props.secondbutton ? '12rem' : '4rem')};
    transition: 0.3s;
    z-index: 2;

    & > button {
      background-color: ${(props) =>
        props.secondbutton ? props.theme.color.blue : props.theme.color.red};

      &:hover {
        background-color: ${(props) =>
          props.secondbutton ? props.theme.color.blue : props.theme.color.red};
      }
    }

    span.MuiSpeedDialAction-staticTooltipLabel {
      font-size: ${({ theme }) => theme.fontSize.overline};
      color: ${({ theme }) => theme.color.float};
      font-weight: ${({ theme }) => theme.fontWeight.bold};
      background-color: ${({ theme }) => theme.color.gray};
      white-space: nowrap;
    }

    svg {
      fill: ${({ theme }) => theme.color.white};
    }
  }
`;

export const StyledSpeedDialAction = styled(SpeedDialAction)`
  && {
    background-color: ${({ theme, color }) => (color ? theme.color[color] : theme.color.darkGray)};
  }
`;

export const StyledAddIcon = styled(AddIcon)`
  && {
    font-size: 2rem;
    fill: ${({ theme }) => theme.color.white};
  }
`;

export const StyledTooltip = withStyles(() => ({
  tooltip: {
    fontSize: 10,
  },
}))(Tooltip);
