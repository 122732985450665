// React
import React from 'react';

// Assets
import AddIcon from '@material-ui/icons/Add';

// Elements
import { Grid, AccordionSummary, AccordionDetails } from '@material-ui/core';
import Heading from 'components/atoms/Heading/Heading';

// Styles
import { StyledWrapper, StyledAccordion } from './Guide.styles';

// Component
const Guide = () => (
  <>
    <StyledWrapper container spacing={2}>
      <Grid item xs={12}>
        <Heading.Subtitle1>
          Pytania i odpowiedzi dotyczące użytkowania Bazy Monitorującej BM2
        </Heading.Subtitle1>
      </Grid>
      <Grid item xs={12}>
        <StyledAccordion>
          <AccordionSummary
            expandIcon={<AddIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Heading.Body1>1. Czym jest Baza Monitorująca 2 i do czego służy?</Heading.Body1>
          </AccordionSummary>
          <AccordionDetails>
            <Heading.Subtitle2>
              BM2 to platforma internetowa, która umożliwia gromadzenie, analizę i raportowanie
              danych o działaniach i odbiorcach projektów społecznych finansowanych lub
              współfinansowanych przez Miasto Stołeczne Warszawę. Służy ona do usprawnienia
              koordynacji, kontroli i monitoringu projektów, a także do oceny ich efektów.
            </Heading.Subtitle2>
          </AccordionDetails>
        </StyledAccordion>
      </Grid>
      <Grid item xs={12}>
        <StyledAccordion>
          <AccordionSummary
            expandIcon={<AddIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Heading.Body1>
              2. W jak sposób można uzyskać dostęp do Bazy Monitorującej 2?
            </Heading.Body1>
          </AccordionSummary>
          <AccordionDetails>
            <Heading.Subtitle2>
              <p>
                <strong>Jako organizacja indywidualna:</strong>
              </p>
              <ol>
                <li>
                  <p>
                    Osoba reprezentująca organizację kontaktuje się z administratorem BM2 w celu
                    uzyskania dostępu do platformy.
                  </p>
                </li>
                <li>
                  <p>CWR i organizacja podpisują stosowną dokumentację.</p>
                </li>
                <li>
                  <p>Organizacja dokonuje rejestracji w BM2.</p>
                </li>
                <li>
                  <p>
                    Następnie administrator BM2 zatwierdza konto organizacji w celu zalogowania
                    organizacji do BM2.
                  </p>
                </li>
              </ol>
              <p>
                <strong>Jako organizacja realizująca projekt w ramach konkursu:</strong>
              </p>
              <ol>
                <li>
                  <p>
                    Urząd Miasta przekazuje informacje dla zespołu BM2 o organizacji, dla której
                    należy założyć konto w BM2.
                  </p>
                </li>
                <li>
                  <p>Administrator zakłada konto dla organizacji w BM2.</p>
                </li>
                <li>
                  <p>
                    Administrator systemu przekazuje informacje dotyczące logowania dla
                    reprezentanta organizacji.
                  </p>
                </li>
                <li>
                  <p>Następnie reprezentant organizacji loguje się do BM2.</p>
                </li>
              </ol>
            </Heading.Subtitle2>
          </AccordionDetails>
        </StyledAccordion>
      </Grid>
      <Grid item xs={12}>
        <StyledAccordion>
          <AccordionSummary
            expandIcon={<AddIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Heading.Body1>
              3. Jakie są wymagania techniczne do korzystania z Bazy Monitorującej 2?
            </Heading.Body1>
          </AccordionSummary>
          <AccordionDetails>
            <Heading.Subtitle2>
              Aby korzystać z BM2, potrzebny jest komputer z dostępem do Internetu i przeglądarka
              internetowa. Zalecane jest używanie najnowszych wersji przeglądarek, takich jak Google
              Chrome, Mozilla Firefox, Microsoft Edge lub Safari. System jest dostosowany do różnych
              rozdzielczości ekranu i urządzeń mobilnych.
            </Heading.Subtitle2>
          </AccordionDetails>
        </StyledAccordion>
      </Grid>
      <Grid item xs={12}>
        <StyledAccordion>
          <AccordionSummary
            expandIcon={<AddIcon />}
            aria-controls="panel4a-content"
            id="panel4a-header"
          >
            <Heading.Body1>
              4. Gdzie znaleźć pomoc i wsparcie w korzystaniu z Bazy Monitorującej 2?
            </Heading.Body1>
          </AccordionSummary>
          <AccordionDetails>
            <Heading.Subtitle2>
              W razie problemów lub pytań dotyczących korzystania z BM2, można skorzystać z
              następujących źródeł pomocy i wsparcia:
              <ul>
                <li>
                  instrukcja obsługi systemu, dostępna na stronie w zakładce po lewej stronie
                  ekranu,
                </li>
                <li>szkolenia organizowane przez CWR,</li>
                <li>
                  kontakt telefoniczny lub e-mailowy z administratorem systemu lub koordynatorem
                  projektu.
                </li>
              </ul>
            </Heading.Subtitle2>
          </AccordionDetails>
        </StyledAccordion>
      </Grid>
      <Grid item xs={12}>
        <StyledAccordion>
          <AccordionSummary
            expandIcon={<AddIcon />}
            aria-controls="panel5a-content"
            id="panel5a-header"
          >
            <Heading.Body1>
              5. Czy mogę posiadać dwa konta używające jednego adresu e-mail?
            </Heading.Body1>
          </AccordionSummary>
          <AccordionDetails>
            <Heading.Subtitle2>
              Nie, dla jednego adresu e-mail jest możliwe utworzenie tylko jednego konta.
            </Heading.Subtitle2>
          </AccordionDetails>
        </StyledAccordion>
      </Grid>
      <Grid item xs={12}>
        <StyledAccordion>
          <AccordionSummary
            expandIcon={<AddIcon />}
            aria-controls="panel6a-content"
            id="panel6a-header"
          >
            <Heading.Body1>
              6. Czy na jednym koncie mogę mieć dostęp do różnych organizacji?
            </Heading.Body1>
          </AccordionSummary>
          <AccordionDetails>
            <Heading.Subtitle2>
              Tak. Na jednym koncie można mieć dostęp do wielu organizacji.
            </Heading.Subtitle2>
          </AccordionDetails>
        </StyledAccordion>
      </Grid>
      <Grid item xs={12}>
        <StyledAccordion>
          <AccordionSummary
            expandIcon={<AddIcon />}
            aria-controls="panel7a-content"
            id="panel7a-header"
          >
            <Heading.Body1>
              7. Czy Baza Monitorująca 2 jest bezpieczna i zgodna z RODO?
            </Heading.Body1>
          </AccordionSummary>
          <AccordionDetails>
            <Heading.Subtitle2>
              Tak, BM2 jest bezpieczna i zgodna z RODO. Dane osobowe uczestników projektów są
              przechowywane w sposób zaszyfrowany i chronione przed nieuprawnionym dostępem. System
              zapewnia anonimowość i poufność danych, a ich przetwarzanie odbywa się zgodnie z
              obowiązującymi przepisami i zasadami ochrony danych osobowych. Każdy użytkownik
              systemu ma prawo do dostępu, sprostowania, usunięcia lub ograniczenia przetwarzania
              swoich danych, a także do wniesienia sprzeciwu lub skargi.
            </Heading.Subtitle2>
          </AccordionDetails>
        </StyledAccordion>
      </Grid>
      <Grid item xs={12}>
        <StyledAccordion>
          <AccordionSummary
            expandIcon={<AddIcon />}
            aria-controls="panel8a-content"
            id="panel8a-header"
          >
            <Heading.Body1>
              8. Jak zgłosić błąd lub zasugerować zmianę w Bazie Monitorującej 2?
            </Heading.Body1>
          </AccordionSummary>
          <AccordionDetails>
            <Heading.Subtitle2>
              Jeśli napotkasz błąd lub chcesz zasugerować zmianę w BM2 możesz to zrobić korzystając
              z formularza zgłoszeniowego, dostępnego na stronie. Wejdź w okrągłą ikonę użytkownika
              (prawy górny róg). Kliknij w „Aplikacja”, następnie kliknij w „Kontakt z
              administratorem”.
            </Heading.Subtitle2>
          </AccordionDetails>
        </StyledAccordion>
      </Grid>
      <Grid item xs={12}>
        <StyledAccordion>
          <AccordionSummary
            expandIcon={<AddIcon />}
            aria-controls="panel9a-content"
            id="panel9a-header"
          >
            <Heading.Body1>
              9. Jak zmienić hasło lub swoje dane osobowe w Bazie Monitorującej 2?
            </Heading.Body1>
          </AccordionSummary>
          <AccordionDetails>
            <Heading.Subtitle2>
              Aby zmienić hasło lub dane osobowe w BM2, należy zalogować się do systemu i wejść w
              okrągłą ikonę użytkownika (prawy górny róg). Wybrać zakładkę “ustawienia konta”. Tam
              można edytować swoje dane, takie jak imię, nazwisko, e-mail, telefon, organizacja,
              konsorcjum itp. Aby zmienić hasło, należy kliknąć na link “Zmień hasło” i podać
              zarówno stare jak i nowe hasło.
            </Heading.Subtitle2>
          </AccordionDetails>
        </StyledAccordion>
      </Grid>
    </StyledWrapper>
  </>
);

export default Guide;
