// React
import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Context
import { Context } from 'context';

// Elements
import { IconButton, Tooltip } from '@material-ui/core';
import Heading from 'components/atoms/Heading/Heading';

// Styles
import {
  StyledMenu,
  StyledTooltip,
  StyledBadge,
  StyledNotificationsRoundedIcon,
  StyledNotification,
  StyledButton,
} from './NotificationIcon.styles';

// Data
// const notificationData = [
//   {
//     id: 1,
//     name: 'Zaproszenie do organizacji czekit sp z.o.o w roli: koordynator programu',
//     urlAccept: 'asd',
//     urlCancel: 'asd',
//   },
//   {
//     id: 2,
//     name: 'I kolejną wiadomość',
//     urlAccept: 'asd',
//     urlCancel: 'asd',
//   },
//   {
//     id: 3,
//     name: 'Organizacja próbuje się z tobą skontaktować',
//     urlAccept: 'asd',
//     urlCancel: 'asd',
//   },
// ];

// Component
const NotificationIcon = ({ notifications }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { setWhichItem } = useContext(Context);

  return (
    <>
      <Tooltip
        title={
          <StyledTooltip>
            <Heading.Body1 color="white">Powiadomienia</Heading.Body1>
          </StyledTooltip>
        }
        onClick={(e) => {
          setWhichItem('');
          setAnchorEl(e.currentTarget);
        }}
        aria-label="add"
      >
        <IconButton edge="start" aria-label="menu">
          <StyledBadge badgeContent={notifications.notifications.length} color="secondary">
            <StyledNotificationsRoundedIcon />
          </StyledBadge>
        </IconButton>
      </Tooltip>
      <StyledMenu
        id="notification-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {notifications.notifications.map((el) => (
          <StyledNotification key={el.id}>
            <div>
              <Heading.Body2>{el.name}</Heading.Body2>
            </div>
            <div style={{ zIndex: '10000' }}>
              <StyledButton
                onClick={() => {
                  window.location = el.url;
                }}
              >
                zobacz
              </StyledButton>
            </div>
          </StyledNotification>
        ))}
        {notifications.notifications.length === 0 && (
          <StyledNotification>
            <Heading.Body2>Brak powiadomień</Heading.Body2>
          </StyledNotification>
        )}
      </StyledMenu>
    </>
  );
};

NotificationIcon.propTypes = {
  notifications: PropTypes.shape({
    notifications: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      }),
    ),
  }),
};

NotificationIcon.defaultProps = {
  notifications: {
    notifications: [],
  },
};

const mapStateToProps = ({ userReducer }) => ({
  notifications: userReducer.notifications,
});

export default connect(mapStateToProps)(NotificationIcon);
