// React
import React, { useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Services
import { fetchUserList } from 'services/userServices';

// Actions
import { clearUsers as clearUsersAction } from 'actions/userActions';

// Data
import { filterUsers } from 'constants/tableFilters';
import { COORDINATOR, ORGANIZATION_ADMINISTRATOR } from 'constants/roles';

// Elements
import TableTemplate from 'templates/TableTemplate';
import FloatingButton from 'components/atoms/FloatingButton/FloatingButton';
import GuardedComponent from 'components/molecules/GuardedComponent/GuardedComponent';

// Data
const headCells = [
  { type: 'name', label: 'Imię' },
  { type: 'surname', label: 'Nazwisko' },
  { type: 'email', label: 'E-mail' },
  { type: 'phone', label: 'Telefon' },
  { type: 'add_date', label: 'Data dodania' },
  { type: 'latest_user_log', label: 'Ostatnie logowanie' },
  { type: 'organization_roles', label: 'Rola w organizacji' },
  { type: 'program_roles', label: 'Rola w programie' },
];

// Component
const Users = ({ clearUsers, fetchList, users }) => {
  useEffect(() => {
    clearUsers();
  }, []);

  const triggerFetchList = useCallback(() => {
    return fetchList();
  }, [fetchList]);

  useEffect(() => {
    triggerFetchList();
  }, [triggerFetchList]);

  return (
    <>
      {users.length !== 0 && users && (
        <TableTemplate
          headCells={headCells}
          data={users}
          filters={filterUsers}
          isnav
          tableName="Użytkownicy"
        />
      )}
      <GuardedComponent allowed_user_roles={[COORDINATOR, ORGANIZATION_ADMINISTRATOR]}>
        <FloatingButton
          onClick={null}
          component={React.forwardRef((props, ref) => (
            <Link to="/uzytkownicy-dodaj" {...props} ref={ref} />
          ))}
        />
      </GuardedComponent>
    </>
  );
};

Users.propTypes = {
  clearUsers: PropTypes.func,
  fetchList: PropTypes.func,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      surname: PropTypes.string,
      add_date: PropTypes.string,
      email: PropTypes.string,
      latest_user_log: PropTypes.string,
      phone: PropTypes.string,
      user_programs: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
        }),
      ),
      user_organizations: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
        }),
      ),
      user_roles: PropTypes.arrayOf(PropTypes.string),
    }),
  ),
};

Users.defaultProps = {
  clearUsers: null,
  fetchList: null,
  users: [],
};

const mapStateToProps = ({ userReducer }) => ({
  users: userReducer.users,
});

const mapDispatchToProps = (dispatch) => ({
  clearUsers: () => dispatch(clearUsersAction()),
  fetchList: () => dispatch(fetchUserList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Users);
