// Elements
import { FormControlLabel } from '@material-ui/core';

// Styles
import styled from 'styled-components';

export const StyledOption = styled.div`
  && {
    display: flex;
  }
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
  && {
    text-transform: lowercase;
    color: ${({ theme }) => theme.color.darkGray};
    font-family: ${({ theme }) => theme.fontFamily.base};

    span {
      font-size: ${({ theme }) => theme.fontSize.body2};
    }
    & .MuiSwitch-colorPrimary.Mui-checked {
      color: ${({ theme }) => theme.color.blue};
    }

    & .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
      background-color: ${({ theme }) => theme.color.blue};
      opacity: 0.6;
    }
  }
`;
