// React
import React from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Services
import { saveBeneficiaryEducationalFacility as saveBeneficiaryEducationalFacilityService } from 'services/beneficiaryServices';

// Formik
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';

// Data
import { updateInfoChangeType } from 'constants/selectLists';

// Assets
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';

// Elements
import { Box, RadioGroup, DialogActions, Grid } from '@material-ui/core';
import Checkbox from 'components/atoms/Checkbox/Checkbox';
import Dialog from 'components/organisms/Dialog/Dialog';
import Heading from 'components/atoms/Heading/Heading';
import Input from 'components/atoms/Input/Input';
import Select from 'components/atoms/Select/Select';

// Styles
import { StyledButton, StyledWrapper } from './BeneficiaryEducationalFacilityForm.styles';

// Component
const BeneficiaryEducationalFacilityForm = ({
  openDialog,
  setOpenDialog,
  szkola,
  osoba_org_pro_name,
  connectionWithProgram,
  connectionWithOrganization,
  saveBeneficiaryEducationalFacility,
  educationalFacilitiesList,
  firstTime,
}) => {
  const { id } = useParams();

  return (
    <>
      <Dialog open={openDialog} setOpen={setOpenDialog} size="md">
        <Formik
          initialValues={{
            educationalFacilityId:
              (szkola && szkola.educationalFacilty && szkola.educationalFacilty.value) || null,
            current_class: '',
            reasonOfUpdate: firstTime ? 4 : 1,
            typeOfChange: '',
            connectionWithProgram,
            connectionWithOrganization,
          }}
          enableReinitialize
          validationSchema={Yup.object({
            educationalFacilityId: Yup.number().required('pole wymagane!'),
            current_class: Yup.string().min(1, 'Minimum 1 znak').max(10, 'Maksymalnie 10 znaków'),
            reasonOfUpdate: Yup.number().required('pole wymagane!'),
            typeOfChange: firstTime ? null : Yup.string().required('pole wymagane!'),
          })}
          onSubmit={(values) => {
            setOpenDialog();
            saveBeneficiaryEducationalFacility(id, values);
          }}
        >
          {({ values, handleChange, setFieldValue, handleSubmit }) => (
            <StyledWrapper container alignItems="center" spacing={2}>
              <Grid item xs={12}>
                <Heading.H6>
                  Edycja placówki edukacyjnej beneficjenta
                  {osoba_org_pro_name && ` w ${osoba_org_pro_name}`}
                </Heading.H6>
              </Grid>
              <Grid item xs={6}>
                <Select
                  label="Typ zmiany"
                  data={
                    firstTime ? [{ value: 4, name: 'wprowadzenie danych' }] : updateInfoChangeType
                  }
                  readonly={firstTime}
                  variant="filled"
                  name="reasonOfUpdate"
                  text="reasonOfUpdate"
                  selectValue={values.reasonOfUpdate}
                  onChange={setFieldValue}
                />
                <ErrorMessage name="reasonOfUpdate">
                  {(msg) => <div className="error-txt">{msg}</div>}
                </ErrorMessage>
              </Grid>
              {values.reasonOfUpdate !== 2 ? (
                <>
                  {values.reasonOfUpdate !== 4 && (
                    <Grid item xs={6}>
                      <Input
                        label="Powód zmiany"
                        variant="filled"
                        name="typeOfChange"
                        value={values.typeOfChange}
                        onChange={handleChange}
                      />
                      <ErrorMessage name="typeOfChange">
                        {(msg) => <div className="error-txt">{msg}</div>}
                      </ErrorMessage>
                    </Grid>
                  )}
                </>
              ) : (
                <>
                  <Grid item xs={6} />
                  <Grid item xs={6}>
                    <RadioGroup
                      aria-label="Powód zmiany"
                      name="typeOfChange"
                      value={values.typeOfChange}
                    >
                      <Checkbox
                        radio
                        label="Otrzymuje promocję"
                        value={values.typeOfChange === 'Otrzymuje promocję'}
                        fieldValue="Otrzymuje promocję"
                        onChange={(e) => setFieldValue('typeOfChange', e.target.value)}
                        isEditable
                      />
                      <Checkbox
                        radio
                        label="Nie otrzymuje promocji"
                        value={values.typeOfChange === 'Nie otrzymuje promocji'}
                        fieldValue="Nie otrzymuje promocji"
                        onChange={(e) => setFieldValue('typeOfChange', e.target.value)}
                        isEditable
                      />
                    </RadioGroup>
                  </Grid>
                </>
              )}
              <Grid item xs={12} style={{ marginBottom: '2rem' }}>
                {educationalFacilitiesList && (
                  <Select
                    label="Nowa placówka"
                    data={educationalFacilitiesList}
                    variant="filled"
                    name="educationalFacilityId"
                    text="educationalFacilityId"
                    selectValue={values.educationalFacilityId}
                    onChange={setFieldValue}
                  />
                )}
                <ErrorMessage name="educationalFacilityId">
                  {(msg) => <div className="error-txt">{msg}</div>}
                </ErrorMessage>
              </Grid>
              <Grid item xs={2}>
                <Input
                  label="Klasa"
                  variant="filled"
                  value={szkola.current_class ? szkola.current_class : ''}
                  disabled
                />
              </Grid>
              <Grid item xs={1}>
                <Box display="flex" justifyContent="center">
                  <ChevronRightRoundedIcon style={{ fontSize: 30 }} />
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Input
                  label="Klasa"
                  variant="filled"
                  name="current_class"
                  value={values.current_class}
                  onChange={handleChange}
                />
                <ErrorMessage name="current_class">
                  {(msg) => <div className="error-txt">{msg}</div>}
                </ErrorMessage>
              </Grid>
              <Grid item xs={12} style={{ justifyContent: 'flex-end', display: 'flex' }}>
                <StyledButton variant="outlined" onClick={() => setOpenDialog()}>
                  Anuluj
                </StyledButton>
                <StyledButton
                  variant="outlined"
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  Zapisz
                </StyledButton>
              </Grid>
            </StyledWrapper>
          )}
        </Formik>
        <DialogActions />
      </Dialog>
    </>
  );
};

BeneficiaryEducationalFacilityForm.propTypes = {
  openDialog: PropTypes.bool,
  setOpenDialog: PropTypes.func,
  szkola: PropTypes.shape({
    previous_class: PropTypes.string,
    current_class: PropTypes.string,
    educationalFacilty: PropTypes.shape({
      value: PropTypes.number,
      name: PropTypes.string,
    }),
  }),
  firstTime: PropTypes.bool,
  osoba_org_pro_name: PropTypes.string,
  connectionWithProgram: PropTypes.number,
  connectionWithOrganization: PropTypes.number,
  saveBeneficiaryEducationalFacility: PropTypes.func,
  educationalFacilitiesList: PropTypes.arrayOf(PropTypes.any),
};

BeneficiaryEducationalFacilityForm.defaultProps = {
  openDialog: false,
  firstTime: false,
  setOpenDialog: null,
  szkola: null,
  osoba_org_pro_name: null,
  connectionWithProgram: null,
  connectionWithOrganization: null,
  saveBeneficiaryEducationalFacility: null,
  educationalFacilitiesList: [],
};

const mapDispatchToProps = (dispatch) => ({
  saveBeneficiaryEducationalFacility: (id, data) =>
    dispatch(saveBeneficiaryEducationalFacilityService(id, data)),
});

export default connect(null, mapDispatchToProps)(BeneficiaryEducationalFacilityForm);
