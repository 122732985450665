// React
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Services
import {
  saveBeneficiaryNote as saveBeneficiaryNoteService,
  getCWAbyCWP,
} from 'services/beneficiaryServices';

// Formik
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';

// Data
import { noteType } from 'constants/selectLists';

// Elements
import { Grid, DialogActions } from '@material-ui/core';
import Button from 'components/atoms/Button/Button';
import Select from 'components/atoms/Select/Select';
import Input from 'components/atoms/Input/Input';
import Dialog from 'components/organisms/Dialog/Dialog';
import Calendar from 'components/atoms/Calendar/Calendar';

// Styles
import { WORKING_WITH_FAMILY, COOPERATION_IN_SYSTEM_ASSISTANCE } from 'constants/ActionBuildInType';
import { StyledWrapper } from './DialogBeneficiaryNotes.styles';

// Component
const DialogBeneficiaryNotes = ({
  beneficiaryId,
  cwpid,
  cwaid,
  open,
  setOpenFn,
  onlyRead,
  item,
  saveBeneficiaryNote,
  fetchNotesAfterSave,
  meetingData,
  noteDate,
  buildInType,
}) => {
  const [actions, setActions] = useState([]);

  useEffect(() => {
    if (cwpid && !meetingData) {
      getCWAbyCWP(cwpid).then((res) => {
        if (res.data) {
          setActions(res.data);
        }
      });
    }
  }, [cwpid, meetingData]);

  return (
    <Dialog
      size="xs"
      open={open}
      setOpen={setOpenFn}
      title={
        item && item.id > 0 ? (onlyRead && 'Zobacz notatkę') || 'Edytuj notatkę' : 'Dodaj notatkę'
      }
    >
      {item && item.id > 0 && (
        <p>
          <small>
            Dodana {item.date_added} przez {item.who_added}
          </small>
          <br />
          {item.who_edited && (
            <small>
              Edytowana {item.date_edited} przez {item.who_edited}
            </small>
          )}
        </p>
      )}
      <Formik
        initialValues={{
          id: item.id || null,
          cwp_id: cwpid || null,
          cwa_id:
            cwaid ||
            item.cwa_id ||
            (actions.length === 1 &&
            item &&
            (item.type_value === COOPERATION_IN_SYSTEM_ASSISTANCE ||
              item.type_value === WORKING_WITH_FAMILY)
              ? actions[0].id
              : null),
          date: (meetingData && noteDate) || item.date || '',
          name: item.name || '',
          type: buildInType || item.type_value || '',
          content: item.content || '',
          meetingId: meetingData,
        }}
        validationSchema={Yup.object({
          name: Yup.string().min(2, 'Minimum 2 znaki').required('pole wymagane!'),
          content: Yup.string().min(2, 'Minimum 2 znaki').required('pole wymagane!'),
          type: Yup.string().required('pole wymagane!'),
          cwa_id: Yup.string()
            .nullable()
            .when('type', {
              is: (type) =>
                type === COOPERATION_IN_SYSTEM_ASSISTANCE || type === WORKING_WITH_FAMILY,
              then: Yup.string()
                .nullable()
                .required('pole wymagane!')
                .test(
                  'Is positive?',
                  'pole wymagane!',
                  (value) => value && parseInt(value, 10) > 0,
                ),
            }),
        })}
        enableReinitialize
        onSubmit={(values) => {
          saveBeneficiaryNote(beneficiaryId, values, item && item.id > 0, fetchNotesAfterSave);
          setOpenFn(false);
        }}
      >
        {({ handleSubmit, setFieldValue, handleChange, values }) => (
          <>
            <StyledWrapper container spacing={2} style={{ marginTop: 0 }}>
              {!meetingData && (
                <Grid item xs={12}>
                  <Calendar
                    disabled={onlyRead}
                    label="Data dodania"
                    onChange={setFieldValue}
                    value={values.date}
                    name="date"
                    variant="filled"
                    disableFuture
                  />
                  <ErrorMessage name="date">
                    {(msg) => <div className="error-txt">{msg}</div>}
                  </ErrorMessage>
                </Grid>
              )}
              {!meetingData && (
                <Grid item xs={12}>
                  <Select
                    label="Wybierz Działanie"
                    disabled={onlyRead || actions.length === 0 ? true : null}
                    data={actions.map((action) => {
                      return { value: action.id, name: action.name };
                    })}
                    selectValue={actions.length === 0 ? null : values.cwa_id}
                    text="cwa_id"
                    onChange={setFieldValue}
                    variant="filled"
                  />
                  <ErrorMessage name="cwa_id">
                    {(msg) => <div className="error-txt">{msg}</div>}
                  </ErrorMessage>
                </Grid>
              )}
              <Grid item xs={meetingData ? 12 : 6}>
                <Input
                  disabled={onlyRead}
                  label="Tytuł notatki"
                  name="name"
                  value={values.name}
                  variant="filled"
                  onChange={handleChange}
                />
                <ErrorMessage name="name">
                  {(msg) => <div className="error-txt">{msg}</div>}
                </ErrorMessage>
              </Grid>
              {!meetingData && (
                <Grid item xs={6}>
                  <Select
                    disabled={onlyRead}
                    label="Typ notatki"
                    data={noteType}
                    name="type"
                    text="type"
                    selectValue={values.type}
                    variant="filled"
                    onChange={setFieldValue}
                  />
                  <ErrorMessage name="type">
                    {(msg) => <div className="error-txt">{msg}</div>}
                  </ErrorMessage>
                </Grid>
              )}
              <Grid item xs={12}>
                <Input
                  disabled={onlyRead}
                  rows={8}
                  multiline
                  label="Treść notatki"
                  name="content"
                  value={values.content}
                  charCounter={1000}
                  variant="filled"
                  setField={setFieldValue}
                />
                <ErrorMessage name="content">
                  {(msg) => <div className="error-txt">{msg}</div>}
                </ErrorMessage>
              </Grid>
            </StyledWrapper>
            <DialogActions>
              <Button onClick={() => setOpenFn(false)} color="primary">
                Wróć
              </Button>
              {!onlyRead && (
                <Button onClick={handleSubmit} color="primary" variant="outlined">
                  {Object.keys(item).length > 0 ? 'Zapisz' : 'Dodaj'}
                </Button>
              )}
            </DialogActions>
          </>
        )}
      </Formik>
      <></>
    </Dialog>
  );
};

DialogBeneficiaryNotes.propTypes = {
  beneficiaryId: PropTypes.number,
  cwpid: PropTypes.number,
  cwaid: PropTypes.number,
  buildInType: PropTypes.string,
  open: PropTypes.bool.isRequired,
  setOpenFn: PropTypes.func.isRequired,
  onlyRead: PropTypes.bool,
  noteDate: PropTypes.string,
  fetchNotesAfterSave: PropTypes.bool,
  item: PropTypes.shape({
    id: PropTypes.number,
    cwa_id: PropTypes.number,
    date: PropTypes.string,
    name: PropTypes.string,
    content: PropTypes.string,
    date_added: PropTypes.string,
    type_value: PropTypes.string,
    who_added: PropTypes.string,
    who_edited: PropTypes.string,
    date_edited: PropTypes.string,
  }),
  saveBeneficiaryNote: PropTypes.func,
  meetingData: PropTypes.number,
};

DialogBeneficiaryNotes.defaultProps = {
  cwpid: null,
  cwaid: null,
  buildInType: null,
  noteDate: null,
  beneficiaryId: null,
  fetchNotesAfterSave: true,
  item: {},
  onlyRead: false,
  saveBeneficiaryNote: null,
  meetingData: null,
};

const mapDispatchToProps = (dispatch) => ({
  saveBeneficiaryNote: (id, data, edit, fetchNotesAfterSave) =>
    dispatch(saveBeneficiaryNoteService(id, data, edit, fetchNotesAfterSave)),
});

export default connect(null, mapDispatchToProps)(DialogBeneficiaryNotes);
