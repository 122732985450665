// React
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Services
import { fetchOrganization as fetchOrganizationService } from 'services/organizationServices';

// Elements
import { Box } from '@material-ui/core';
import Dialog from 'components/organisms/Dialog/Dialog';
import Select from 'components/atoms/Select/Select';
import AddToOrganization from 'components/molecules/AddToOrganization/AddToOrganization';

// Component
const DialogBeneficiaryOrganization = ({
  beneficiaryId,
  organizationid,
  fetchOrganization,
  organization,
  open,
  setOpenFn,
  currentBeneficiary,
  newOrganizationList,
  refreshBeneficieryOrganizations,
}) => {
  const [beneficiaryID, setBeneficiaryID] = useState(null);
  const [newBeneficiary, setNewBeneficiary] = useState(null);
  const [newOrganizationID, setnewOrganizationID] = useState(null);

  useEffect(() => {
    if (currentBeneficiary) {
      setNewBeneficiary(currentBeneficiary);
    }
  }, [currentBeneficiary]);

  useEffect(() => {
    if (beneficiaryId) {
      setBeneficiaryID(beneficiaryId);
    }
  }, [beneficiaryId]);

  useEffect(() => {
    if (organizationid || newOrganizationID) {
      fetchOrganization(organizationid || newOrganizationID);
    }
  }, [newOrganizationID, organizationid]);

  return (
    <Dialog
      size="lg"
      open={open}
      setOpen={setOpenFn}
      title={`Przypisz beneficjenta do organizacji ${organization.name}`}
    >
      <>
        {!organizationid && (
          <Box mb={3}>
            <Select
              label="Wybierz organizację"
              data={newOrganizationList}
              variant="filled"
              name="organization_id"
              text="organization_id"
              selectValue={newOrganizationID}
              onChange={(e, newValue) => {
                setnewOrganizationID(newValue);
              }}
            />
          </Box>
        )}
        {((beneficiaryID && beneficiaryID !== 0) ||
          (newBeneficiary && Object.keys(newBeneficiary).length > 0)) &&
        (organizationid || newOrganizationID) ? (
          <AddToOrganization
            beneficiaryId={beneficiaryID}
            newBeneficiary={newBeneficiary}
            organization={organization}
            setOpenFn={setOpenFn}
            setnewOrganizationID={setnewOrganizationID}
            refreshBeneficieryOrganizations={refreshBeneficieryOrganizations}
          />
        ) : null}
      </>
      <></>
    </Dialog>
  );
};

DialogBeneficiaryOrganization.propTypes = {
  beneficiaryId: PropTypes.number,
  organizationid: PropTypes.number,
  fetchOrganization: PropTypes.func,
  organization: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  open: PropTypes.bool.isRequired,
  setOpenFn: PropTypes.func.isRequired,
  currentBeneficiary: PropTypes.shape({
    beneficiary_id: PropTypes.number,
    beneficiary_name: PropTypes.string,
    beneficiary_last_name: PropTypes.string,
    beneficiary_gender: PropTypes.string,
    pwd: PropTypes.number,
    educational_facility: PropTypes.string,
    date_modified: PropTypes.string,
    modified_by: PropTypes.string,
  }),
  newOrganizationList: PropTypes.arrayOf(PropTypes.any),
  refreshBeneficieryOrganizations: PropTypes.bool,
};

DialogBeneficiaryOrganization.defaultProps = {
  beneficiaryId: null,
  organizationid: null,
  fetchOrganization: null,
  organization: null,
  currentBeneficiary: null,
  newOrganizationList: null,
  refreshBeneficieryOrganizations: false,
};

const mapStateToProps = (state) => ({
  organization: state.organizationReducer.organization,
});

const mapDispatchToProps = (dispatch) => ({
  fetchOrganization: (id) => dispatch(fetchOrganizationService(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DialogBeneficiaryOrganization);
