// React
import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Services
import {
  editOrganization as editOrganizationService,
  fetchOrganization as fetchOrganizationService,
  fetchRepresentativesList as fetchRepresentativesListService,
} from 'services/organizationServices';

import { ORGANIZATION_ADMINISTRATOR, COORDINATOR } from 'constants/roles';

// Actions
import {
  clearOrganizationAction,
  clearRepresentativeList as clearRepresentativeListAction,
} from 'actions/organizationActions';

// Utils
import { isCoordinator } from 'utils/functions';

// Data
import {
  organizationType,
  organizationStatus,
  organizationIdentificatorKind,
  districts,
  // programColor,
} from 'constants/selectLists';

// Formik
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';

// Elements
import { Grid } from '@material-ui/core';
import Heading from 'components/atoms/Heading/Heading';
import EditButton from 'components/atoms/EditButton/EditButton';
import Input from 'components/atoms/Input/Input';
import Select from 'components/atoms/Select/Select';
import Checkbox from 'components/atoms/Checkbox/Checkbox';
import GuardedComponent from 'components/molecules/GuardedComponent/GuardedComponent';

// Styles
import {
  StyledWrapper,
  StyledTitle,
  StyledTitleSmall,
  StyledButton,
} from './OrganizationCurrent.styles';

// Component
const OrganizationCurrent = ({
  clearOrganization,
  fetchOrganization,
  details,
  clearRepresentativeList,
  fetchRepresentativesList,
  representativesList,
  editOrganization,
  isNew,
  parentId,
  me,
  sub_organization = false,
  dont_clear = false,
}) => {
  const { id } = useParams();
  const history = useHistory();
  const [isEditable, setIsEditable] = useState(false);

  useEffect(() => {
    if (!dont_clear) {
      clearOrganization();
      clearRepresentativeList();
    }
  }, []);

  const triggerFetchOrganization = useCallback(
    (myid) => {
      if (myid !== undefined && !isNew) {
        fetchOrganization(myid);

        return true;
      }

      setIsEditable(true);

      return true;
    },
    [clearOrganization, setIsEditable],
  );

  useEffect(() => {
    if (isNew) {
      setIsEditable(true);

      return false;
    }

    setIsEditable(false);
    triggerFetchOrganization(id);

    return true;
  }, [id, triggerFetchOrganization]);

  useEffect(() => {
    if (isEditable && representativesList.length === 0 && id && !isNew) {
      fetchRepresentativesList(id);
    }
  }, [fetchRepresentativesList, id, isEditable]);

  return (
    <>
      <Formik
        initialValues={{
          name: details.name && !isNew ? details.name : '',
          parentId,
          type: details.type && !isNew ? details.type : 2,
          status: details.status && !isNew ? details.status : 0,
          identificatorKind: details.identificator_kind && !isNew ? details.identificator_kind : 0,
          identificatorNumber:
            details.identificator_number && !isNew ? details.identificator_number : '',
          //  color: details.color && !isNew ? details.color : '',
          currentRepresentative:
            details.representative &&
            (details.representative.name || details.representative.surname) &&
            !isNew
              ? `${details.representative.name} ${details.representative.surname}`
              : '',
          representativeName:
            details.representative && details.representative.name && !isNew
              ? details.representative.name
              : '',
          representativeSurname:
            details.representative && details.representative.surname && !isNew
              ? details.representative.surname
              : '',
          representativePhone:
            details.representative && details.representative.phone && !isNew
              ? details.representative.phone
              : '',
          representativeEmail:
            details.representative && details.representative.email && !isNew
              ? details.representative.email
              : '',
          organizationStreet:
            details.organization_street && !isNew ? details.organization_street : '',
          organizationHouse: details.organization_house && !isNew ? details.organization_house : '',
          organizationLocal: details.organization_local && !isNew ? details.organization_local : '',
          organizationCode:
            details.organization_zip_code && !isNew ? details.organization_zip_code : '',
          organizationCity: details.organization_city && !isNew ? details.organization_city : '',
          organizationDistrict:
            details.organization_district && !isNew ? details.organization_district : '',
          isSameOutpostAddress:
            details.is_same_outpost_address && !isNew ? details.is_same_outpost_address : false,
          outpostStreet: details.outpost && !isNew ? details.outpost.street : '',
          outpostHouse: details.outpost && !isNew ? details.outpost.houseNumber : '',
          outpostLocal: details.outpost && !isNew ? details.outpost.apartmentNumber : '',
          outpostCode: details.outpost && !isNew ? details.outpost.zipCode : '',
          outpostCity: details.outpost && !isNew ? details.outpost.city : '',
          outpostDistrict: details.outpost && !isNew ? details.outpost.district : '',
        }}
        enableReinitialize
        validationSchema={Yup.object({
          name: Yup.string()
            .min(2, 'Minimum 2 znaki')
            .max(255, 'Maksymalnie 255 znaków')
            .required('pole wymagane!'),
          type: Yup.number()
            .required('pole wymagane!')
            .test('Is positive?', 'pole wymagane!', (value) => value > 0),
          status: Yup.string()
            .required('pole wymagane!')
            .test('Is positive?', 'pole wymagane!', (value) => value > 0),
          organizationDistrict: Yup.string().nullable().required('pole wymagane!'),
          identificatorKind: sub_organization
            ? null
            : Yup.number()
                .required('pole wymagane!')
                .test('Is positive?', 'pole wymagane!', (value) => value > 0),
          identificatorNumber: sub_organization ? null : Yup.string().required('pole wymagane!'),
          //  color: Yup.string().required('pole wymagane!'),
          isSameOutpostAddress: Yup.boolean(),
          representativeName: Yup.string().required('pole wymagane!'),
          representativeSurname: Yup.string().required('pole wymagane!'),
          representativeEmail: Yup.string().email('Nieprawidłowy Email').required('pole wymagane!'),
          representativePhone: Yup.string().required('pole wymagane!'),
          organizationHouse: Yup.string()
            .min(1, 'Minimum 1 znaki')
            .max(10, 'Maksymalnie 10 znaków')
            .required('pole wymagane!'),
          organizationLocal: Yup.string()
            .min(1, 'Minimum 1 znaki')
            .max(10, 'Maksymalnie 10 znaków'),
          organizationCode: Yup.string()
            .min(1, 'Minimum 1 znaki')
            .max(10, 'Maksymalnie 10 znaków')
            .required('pole wymagane!'),
          organizationCity: Yup.string()
            .min(1, 'Minimum 1 znaki')
            .max(100, 'Maksymalnie 100 znaków')
            .required('pole wymagane!'),
          organizationStreet: Yup.string()
            .min(1, 'Minimum 1 znaki')
            .max(100, 'Maksymalnie 100 znaków')
            .required('pole wymagane!'),
          outpostCity: Yup.string().when('isSameOutpostAddress', {
            is: false,
            then: Yup.string()
              .min(1, 'Minimum 1 znak')
              .max(100, 'Maksymalnie 100 znaków')
              .required('pole wymagane!'),
          }),
          outpostStreet: Yup.string().when('isSameOutpostAddress', {
            is: false,
            then: Yup.string()
              .min(2, 'Minimum 2 znaki')
              .max(100, 'Maksymalnie 100 znaków')
              .required('pole wymagane!'),
          }),
          outpostHouse: Yup.string().when('isSameOutpostAddress', {
            is: false,
            then: Yup.string()
              .min(1, 'Minimum 1 znaki')
              .max(100, 'Maksymalnie 100 znaków')
              .required('pole wymagane!'),
          }),
          outpostLocal: Yup.string().when('isSameOutpostAddress', {
            is: false,
            then: Yup.string().min(1, 'Minimum 1 znak').max(100, 'Maksymalnie 100 znaków'),
          }),
          outpostCode: Yup.string().when('isSameOutpostAddress', {
            is: false,
            then: Yup.string()
              .min(2, 'Minimum 2 znaki')
              .max(100, 'Maksymalnie 100 znaków')
              .required('pole wymagane!'),
          }),
          outpostDistrict: Yup.string().when('isSameOutpostAddress', {
            is: false,
            then: Yup.string()
              .nullable()
              .min(1, 'Minimum 1 znak')
              .max(100, 'Maksymalnie 100 znaków')
              .required('pole wymagane!'),
          }),
        })}
        onSubmit={(values) => {
          editOrganization(id === undefined || isNew ? 'new' : id, values).then((data) => {
            if (data && (id === undefined || isNew) && data.data.id) {
              triggerFetchOrganization(data.data.id);
              history.push(`/organizacje/${data.data.id}`);
            }
          });
        }}
      >
        {({ values, setFieldValue, handleChange, handleSubmit }) => {
          return (
            <>
              {!isNew && (
                <StyledTitle>
                  <Heading.H6>{values.name}</Heading.H6>
                </StyledTitle>
              )}
              {isNew ||
                (id === undefined && (
                  <StyledTitle>
                    <Heading.Subtitle1>Nowa organizacja</Heading.Subtitle1>
                  </StyledTitle>
                ))}
              <StyledTitle>
                <Heading.Subtitle1>Dane podstawowe</Heading.Subtitle1>
                {!isNew && id !== undefined && (
                  <GuardedComponent
                    allowed_user_roles={[COORDINATOR]}
                    allowed_organization_roles={[ORGANIZATION_ADMINISTRATOR]}
                    organization_id={parseInt(id, 10)}
                    parent_organization_id={parentId || details.parent ? details.parent.id : null}
                  >
                    <EditButton isEditable={isEditable || false} setIsEditable={setIsEditable} />
                  </GuardedComponent>
                )}
              </StyledTitle>
              <StyledWrapper container spacing={2} fullwidth={sub_organization ? '1' : undefined}>
                <Grid item xs={6}>
                  <Select
                    disabled={!isEditable || sub_organization || false}
                    name="type"
                    label="Typ organizacji"
                    text="type"
                    selectValue={values.type}
                    data={organizationType}
                    variant="filled"
                    onChange={setFieldValue}
                  />
                  <ErrorMessage name="type">
                    {(msg) => <div className="error-txt">{msg}</div>}
                  </ErrorMessage>
                </Grid>
                <Grid item xs={6}>
                  <Select
                    name="status"
                    disabled={!isEditable || false}
                    label="Status organizacji"
                    data={organizationStatus}
                    selectValue={values.status}
                    variant="filled"
                    text="status"
                    onChange={setFieldValue}
                  />
                  <ErrorMessage name="status">
                    {(msg) => <div className="error-txt">{msg}</div>}
                  </ErrorMessage>
                </Grid>
                <Grid item xs={12}>
                  <Input
                    disabled={!isEditable || false}
                    label="Nazwa"
                    variant="filled"
                    type="text"
                    name="name"
                    onChange={handleChange}
                    value={values.name}
                    maxLength="255"
                  />
                  <ErrorMessage name="name">
                    {(msg) => <div className="error-txt">{msg}</div>}
                  </ErrorMessage>
                </Grid>
                {!sub_organization && (
                  <Grid item xs={6}>
                    <Select
                      name="identificatorKind"
                      disabled={!isEditable || (!isCoordinator(me.user_role) && !isNew)}
                      label="Rodzaj identyfikatora"
                      data={organizationIdentificatorKind}
                      selectValue={values.identificatorKind}
                      variant="filled"
                      text="identificatorKind"
                      onChange={setFieldValue}
                    />
                    <ErrorMessage name="identificatorKind">
                      {(msg) => <div className="error-txt">{msg}</div>}
                    </ErrorMessage>
                  </Grid>
                )}
                {!sub_organization && (
                  <Grid item xs={6}>
                    <Input
                      disabled={!isEditable || (!isCoordinator(me.user_role) && !isNew)}
                      label="Identyfikator"
                      variant="filled"
                      type="text"
                      name="identificatorNumber"
                      value={values.identificatorNumber}
                      onChange={handleChange}
                      maxLength="32"
                    />
                    <ErrorMessage name="identificatorNumber">
                      {(msg) => <div className="error-txt">{msg}</div>}
                    </ErrorMessage>
                  </Grid>
                )}
                {/* <Grid item xs={12}>
                  <Select
                    disabled={!isEditable}
                    name="color"
                    label="Kolor"
                    text="color"
                    selectValue={values.color}
                    data={programColor}
                    variant="filled"
                    onChange={setFieldValue}
                  />
                  <ErrorMessage name="color">
                    {(msg) => <div className="error-txt">{msg}</div>}
                  </ErrorMessage>
                </Grid> */}
              </StyledWrapper>
              {representativesList.length > 0 && (
                <GuardedComponent
                  allowed_user_roles={[COORDINATOR]}
                  allowed_organization_roles={[ORGANIZATION_ADMINISTRATOR]}
                  organization_id={parseInt(id, 10)}
                  parent_organization_id={parentId || details.parent ? details.parent.id : null}
                >
                  <StyledTitle>
                    <Heading.Subtitle1>Wybierz reprezentanta</Heading.Subtitle1>
                  </StyledTitle>
                  <StyledWrapper fullwidth={sub_organization ? '1' : undefined}>
                    <Grid item xs={12}>
                      <Select
                        disabled={!isEditable || false}
                        label="Wybierz reprezentanta z bazy"
                        text="currentRepresentative"
                        data={representativesList.map((user) => ({
                          value: user.id,
                          name: user.full_name,
                        }))}
                        name="currentRepresentative"
                        value={values.currentRepresentative}
                        variant="filled"
                        onChange={(_, user_id) => {
                          const user = representativesList.find((u) => u.id === user_id);
                          setFieldValue('representativeName', user.name ? user.name : '');
                          setFieldValue('representativeSurname', user.surname ? user.surname : '');
                          setFieldValue('representativePhone', user.phone ? user.phone : '');
                          setFieldValue('representativeEmail', user.email ? user.email : '');
                          setFieldValue('currentRepresentative', user.id);
                        }}
                      />
                    </Grid>
                  </StyledWrapper>
                </GuardedComponent>
              )}
              <StyledTitle>
                <Heading.Subtitle1>
                  Reprezentant organizacji {sub_organization ? 'koalicyjnej/oddziału' : 'głównej'}
                </Heading.Subtitle1>
              </StyledTitle>
              <StyledWrapper container spacing={2} fullwidth={sub_organization ? '1' : undefined}>
                {/*  desktop="75%" mobile="45%"  */}
                <Grid item xs={4}>
                  <Input
                    disabled={!isEditable || false}
                    label="Imię"
                    variant="filled"
                    type="text"
                    name="representativeName"
                    value={values.representativeName}
                    onChange={handleChange}
                  />
                  <ErrorMessage name="representativeName">
                    {(msg) => <div className="error-txt">{msg}</div>}
                  </ErrorMessage>
                </Grid>
                <Grid item xs={4}>
                  <Input
                    disabled={!isEditable || false}
                    label="Nazwisko"
                    variant="filled"
                    type="text"
                    name="representativeSurname"
                    value={values.representativeSurname}
                    onChange={handleChange}
                  />
                  <ErrorMessage name="representativeSurname">
                    {(msg) => <div className="error-txt">{msg}</div>}
                  </ErrorMessage>
                </Grid>
                <Grid item xs={4}>
                  <Input
                    disabled={!isEditable || false}
                    label="E-mail"
                    variant="filled"
                    type="text"
                    name="representativeEmail"
                    value={values.representativeEmail}
                    onChange={handleChange}
                  />
                  <ErrorMessage name="representativeEmail">
                    {(msg) => <div className="error-txt">{msg}</div>}
                  </ErrorMessage>
                </Grid>
                <Grid item xs={4}>
                  <Input
                    disabled={!isEditable || false}
                    label="Telefon kontaktowy"
                    variant="filled"
                    type="text"
                    name="representativePhone"
                    value={values.representativePhone}
                    onChange={handleChange}
                  />
                  <ErrorMessage name="representativePhone">
                    {(msg) => <div className="error-txt">{msg}</div>}
                  </ErrorMessage>
                </Grid>
              </StyledWrapper>
              <StyledTitle>
                <Heading.Subtitle1>
                  Adres organizacji {sub_organization ? 'koalicyjnej/oddziału' : 'głównej'}
                </Heading.Subtitle1>
              </StyledTitle>
              <StyledWrapper container spacing={2} fullwidth={sub_organization ? '1' : undefined}>
                <Grid item xs={6}>
                  <Input
                    disabled={!isEditable || false}
                    label="Ulica"
                    variant="filled"
                    type="text"
                    name="organizationStreet"
                    onChange={handleChange}
                    value={values.organizationStreet}
                    maxLength="100"
                  />{' '}
                  <ErrorMessage name="organizationStreet">
                    {(msg) => <div className="error-txt">{msg}</div>}
                  </ErrorMessage>
                </Grid>
                <Grid item xs={3}>
                  <Input
                    disabled={!isEditable || false}
                    label="Nr domu"
                    variant="filled"
                    type="text"
                    name="organizationHouse"
                    onChange={handleChange}
                    value={values.organizationHouse}
                    maxLength="16"
                  />
                  <ErrorMessage name="organizationHouse">
                    {(msg) => <div className="error-txt">{msg}</div>}
                  </ErrorMessage>
                </Grid>
                <Grid item xs={3}>
                  <Input
                    disabled={!isEditable || false}
                    label="Nr lokalu"
                    variant="filled"
                    type="text"
                    name="organizationLocal"
                    onChange={handleChange}
                    value={values.organizationLocal}
                    maxLength="6"
                  />
                  <ErrorMessage name="organizationLocal">
                    {(msg) => <div className="error-txt">{msg}</div>}
                  </ErrorMessage>
                </Grid>
                <Grid item xs={3}>
                  <Input
                    disabled={!isEditable || false}
                    label="Kod"
                    variant="filled"
                    type="text"
                    name="organizationCode"
                    onChange={handleChange}
                    value={values.organizationCode}
                    maxLength="6"
                  />
                  <ErrorMessage name="organizationCode">
                    {(msg) => <div className="error-txt">{msg}</div>}
                  </ErrorMessage>
                </Grid>
                <Grid item xs={6}>
                  <Input
                    disabled={!isEditable || false}
                    label="Miejscowość"
                    variant="filled"
                    type="text"
                    name="organizationCity"
                    onChange={handleChange}
                    value={values.organizationCity}
                    maxLength="100"
                  />
                  <ErrorMessage name="organizationCity">
                    {(msg) => <div className="error-txt">{msg}</div>}
                  </ErrorMessage>
                </Grid>
                <Grid item xs={6}>
                  <Select
                    disabled={!isEditable || false}
                    label="Dzielnica"
                    text="organizationDistrict"
                    selectValue={values.organizationDistrict}
                    name="organizationDistrict"
                    data={districts}
                    variant="filled"
                    onChange={setFieldValue}
                  />
                  <ErrorMessage name="organizationDistrict">
                    {(msg) => <div className="error-txt">{msg}</div>}
                  </ErrorMessage>
                </Grid>
                <Grid item xs={12}>
                  <Checkbox
                    isEditable={isEditable || false}
                    label="Adres organizacji jest zarazem adresem placówki"
                    value={values.isSameOutpostAddress}
                    name="isSameOutpostAddress"
                    onChange={handleChange}
                  />
                </Grid>
              </StyledWrapper>
              {!values.isSameOutpostAddress && (
                <>
                  <StyledTitle>
                    <Heading.Subtitle1>Adres placówki</Heading.Subtitle1>
                  </StyledTitle>
                  <StyledWrapper
                    container
                    spacing={2}
                    fullwidth={sub_organization ? '1' : undefined}
                  >
                    <Grid item xs={6}>
                      <Input
                        disabled={!isEditable || false}
                        label="Ulica"
                        variant="filled"
                        type="text"
                        name="outpostStreet"
                        onChange={handleChange}
                        value={values.outpostStreet}
                      />
                      <ErrorMessage name="outpostStreet">
                        {(msg) => <div className="error-txt">{msg}</div>}
                      </ErrorMessage>
                    </Grid>
                    <Grid item xs={3}>
                      <Input
                        disabled={!isEditable || false}
                        label="Nr domu"
                        variant="filled"
                        type="text"
                        name="outpostHouse"
                        onChange={handleChange}
                        value={values.outpostHouse}
                      />
                      <ErrorMessage name="outpostHouse">
                        {(msg) => <div className="error-txt">{msg}</div>}
                      </ErrorMessage>
                    </Grid>
                    <Grid item xs={3}>
                      <Input
                        disabled={!isEditable || false}
                        label="Nr lokalu"
                        variant="filled"
                        type="text"
                        name="outpostLocal"
                        onChange={handleChange}
                        value={values.outpostLocal}
                      />
                      <ErrorMessage name="outpostLocal">
                        {(msg) => <div className="error-txt">{msg}</div>}
                      </ErrorMessage>
                    </Grid>
                    <Grid item xs={3}>
                      <Input
                        disabled={!isEditable || false}
                        label="Kod"
                        variant="filled"
                        type="text"
                        name="outpostCode"
                        onChange={handleChange}
                        value={values.outpostCode}
                      />
                      <ErrorMessage name="outpostCode">
                        {(msg) => <div className="error-txt">{msg}</div>}
                      </ErrorMessage>
                    </Grid>
                    <Grid item xs={6}>
                      <Input
                        disabled={!isEditable || false}
                        label="Miejscowość"
                        variant="filled"
                        type="text"
                        name="outpostCity"
                        onChange={handleChange}
                        value={values.outpostCity}
                      />
                      <ErrorMessage name="outpostCity">
                        {(msg) => <div className="error-txt">{msg}</div>}
                      </ErrorMessage>
                    </Grid>
                    <Grid item xs={6}>
                      <Select
                        disabled={!isEditable || false}
                        label="Dzielnica"
                        text="outpostDistrict"
                        selectValue={values.outpostDistrict}
                        data={districts}
                        variant="filled"
                        onChange={setFieldValue}
                        maxLength="100"
                      />
                      <ErrorMessage name="outpostDistrict">
                        {(msg) => <div className="error-txt">{msg}</div>}
                      </ErrorMessage>
                    </Grid>
                  </StyledWrapper>
                </>
              )}
              {details.parent && (
                <>
                  <StyledWrapper
                    container
                    spacing={2}
                    fullwidth={sub_organization ? '1' : undefined}
                  >
                    <Grid item xs={12}>
                      <StyledTitle>
                        <Heading.Subtitle2>Organizacja {values.name} należy do</Heading.Subtitle2>
                      </StyledTitle>

                      <StyledTitle>
                        <Heading.Subtitle1>{details.parent.name}</Heading.Subtitle1>
                      </StyledTitle>

                      <StyledTitleSmall>
                        <Heading.Subtitle2>Adres Siedziby Koalicji</Heading.Subtitle2>
                        <Heading.Body2>
                          {details.parent.organization_street} {details.parent.organization_house}
                          {details.parent.organization_local && (
                            <>/ {details.parent.organization_local}</>
                          )}
                        </Heading.Body2>
                        <Heading.Body2>
                          {details.parent.organization_zip_code} {details.parent.organization_city}
                          {details.parent.organization_district && (
                            <>, {details.parent.organization_district}</>
                          )}
                        </Heading.Body2>
                      </StyledTitleSmall>
                      {details.parent.identificator_number && details.parent.identificator_kind && (
                        <StyledTitleSmall>
                          <Heading.Subtitle2>
                            {
                              organizationIdentificatorKind.find(
                                (el) => el.value === details.parent.identificator_kind,
                              ).name
                            }
                          </Heading.Subtitle2>
                          <Heading.Body2>{details.parent.identificator_number}</Heading.Body2>
                        </StyledTitleSmall>
                      )}
                      <StyledTitleSmall>
                        <Heading.Subtitle2>Reprezentant Koalicji</Heading.Subtitle2>
                        <Heading.Body2>
                          Imię i nazwisko: {details.parent.org_representative.name}{' '}
                          {details.parent.org_representative.surname}
                        </Heading.Body2>
                        <Heading.Body2>
                          E-mail: {details.parent.org_representative.email}
                        </Heading.Body2>
                        <Heading.Body2>
                          Telefon: {details.parent.org_representative.phone}
                        </Heading.Body2>
                      </StyledTitleSmall>
                    </Grid>
                  </StyledWrapper>
                  <StyledWrapper
                    container
                    spacing={2}
                    fullwidth={sub_organization ? '1' : undefined}
                  >
                    <StyledButton
                      type="link"
                      variant="outlined"
                      onClick={() => history.push(`/organizacje/${details.parent.id}`)}
                    >
                      Przejdź do organizazacji
                    </StyledButton>
                  </StyledWrapper>
                </>
              )}
              <GuardedComponent
                allowed_user_roles={[COORDINATOR]}
                allowed_organization_roles={[ORGANIZATION_ADMINISTRATOR]}
                organization_id={parseInt(id, 10)}
                parent_organization_id={parentId || details.parent ? details.parent?.id : null}
              >
                <StyledWrapper container spacing={2} fullwidth={sub_organization ? '1' : undefined}>
                  <Grid item xs={3}>
                    <StyledButton
                      disabled={!isEditable || false}
                      variant="outlined"
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      ZAPISZ
                    </StyledButton>
                  </Grid>
                  {/* <Grid item xs={3}>
                    <StyledButton disabled={!isEditable || false}>ANULUJ</StyledButton>
                  </Grid> */}
                </StyledWrapper>
              </GuardedComponent>
            </>
          );
        }}
      </Formik>
    </>
  );
};

OrganizationCurrent.propTypes = {
  clearOrganization: PropTypes.func,
  fetchOrganization: PropTypes.func,
  details: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    type: PropTypes.number,
    status: PropTypes.number,
    representative: PropTypes.shape({
      id: PropTypes.number,
      full_name: PropTypes.string,
      name: PropTypes.string,
      surname: PropTypes.string,
      email: PropTypes.string,
      phone: PropTypes.string,
    }),
    organization_children: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        identificator_number: PropTypes.string,
      }),
    ),
    parent: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      identificator_kind: PropTypes.number,
      identificator_number: PropTypes.string,
      organization_city: PropTypes.string,
      organization_district: PropTypes.string,
      organization_house: PropTypes.string,
      organization_local: PropTypes.string,
      organization_street: PropTypes.string,
      organization_zip_code: PropTypes.string,
      org_representative: PropTypes.shape({
        name: PropTypes.string,
        surname: PropTypes.string,
        email: PropTypes.string,
        phone: PropTypes.string,
      }),
    }),
    organization_street: PropTypes.string,
    organization_house: PropTypes.string,
    organization_local: PropTypes.string,
    organization_zip_code: PropTypes.string,
    organization_city: PropTypes.string,
    organization_district: PropTypes.string,
    outpost: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      street: PropTypes.string,
      houseNumber: PropTypes.string,
      apartmentNumber: PropTypes.string,
      district: PropTypes.string,
      zipCode: PropTypes.string,
      city: PropTypes.string,
    }),
    identificator_kind: PropTypes.number,
    identificator_number: PropTypes.string,
    // color: PropTypes.string,
    is_same_outpost_address: PropTypes.bool,
  }),
  clearRepresentativeList: PropTypes.func,
  fetchRepresentativesList: PropTypes.func,
  representativesList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      full_name: PropTypes.string,
      name: PropTypes.string,
      surname: PropTypes.string,
      email: PropTypes.string,
      phone: PropTypes.string,
    }),
  ),
  editOrganization: PropTypes.func,
  isNew: PropTypes.bool,
  sub_organization: PropTypes.bool,
  dont_clear: PropTypes.bool,
  parentId: PropTypes.number,
  me: PropTypes.shape({ user_role: PropTypes.arrayOf(PropTypes.string) }),
};

OrganizationCurrent.defaultProps = {
  clearOrganization: null,
  fetchOrganization: null,
  details: {
    id: null,
    name: '',
    type: 0,
    status: null,
    representative: null,
    identificator_kind: null,
    identificator_number: '',
    // color: '',
    organization_street: '',
    organization_house: '',
    organization_local: '',
    organization_zip_code: '',
    organization_city: '',
    organization_district: '',
    outpost: {},
    is_same_outpost_address: false,
    parent: null,
  },
  clearRepresentativeList: null,
  fetchRepresentativesList: null,
  representativesList: [
    {
      full_name: '',
    },
  ],
  editOrganization: null,
  isNew: false,
  sub_organization: false,
  parentId: 0,
  dont_clear: false,
  me: {
    user_role: [],
  },
};

const mapStateToProps = ({ userReducer, organizationReducer }) => ({
  details: organizationReducer.organization,
  representativesList: organizationReducer.representativesList,
  me: userReducer.me,
});

const mapDispatchToProps = (dispatch) => ({
  clearOrganization: () => dispatch(clearOrganizationAction()),
  fetchOrganization: (id) => dispatch(fetchOrganizationService(id)),
  clearRepresentativeList: () => dispatch(clearRepresentativeListAction()),
  fetchRepresentativesList: (id) => dispatch(fetchRepresentativesListService(id)),
  editOrganization: (id, data) => dispatch(editOrganizationService(id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationCurrent);
