// React
import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';

// Services
import { fetchProgramsListSelect as fetchProgramsListService } from 'services/programServices';
import { fetchOrganizationListDataAdministration as fetchOrganizationListService } from 'services/organizationServices';
import {
  fetchBeneficiaryProgramsSimple as fetchBeneficiaryProgramsSimpleService,
  fetchBeneficiaryOrganizations as fetchBeneficiaryOrganizationsService,
  fetchBeneficiary as fetchBeneficiaryService,
} from 'services/beneficiaryServices';

// Actions
import {
  clearBeneficiaryOrganizations as clearBeneficiaryOrganizationsAction,
  clearBeneficiaryProgramsSimple as clearBeneficiaryProgramsSimpleAction,
  clearBeneficiaryAction,
} from 'actions/beneficiaryActions';
import { clearOrganizations as clearOrganizationsAction } from 'actions/organizationActions';
import { clearPrograms as clearProgramsAction } from 'actions/programActions';

// Data
import {
  COORDINATOR,
  ORGANIZATION_ADMINISTRATOR,
  ORGANIZATION_EMPLOYEE,
  PROGRAM_COORDINATOR,
  TASK_COORDINATOR,
  ROLE_SONATA_LECTURER_SENSITIVE_DATA,
  ROLE_SONATA_LECTURER,
} from 'constants/roles';

// Elements
import { Box, Grid } from '@material-ui/core';
import Table from 'components/organisms/Table/Table';
import Heading from 'components/atoms/Heading/Heading';
import AddNewBeneficiary from 'components/molecules/AddNewBeneficiary/AddNewBeneficiary';
import GuardedComponent from 'components/molecules/GuardedComponent/GuardedComponent';
import DialogBeneficiaryProgram from 'components/templates/DialogBeneficiaryProgram/DialogBeneficiaryProgram';
import DialogBeneficiaryOrganization from 'components/templates/DialogBeneficiaryOrganization/DialogBeneficiaryOrganization';

// Styles
import Button from 'components/atoms/Button/Button';
import { StyledTitle } from './AddBeneficiary.styles';

// Data
const headCellsPrograms = [{ type: 'program_name', id: 'program_name', label: 'Program' }];

const headCellsOrganizations = [
  { type: 'organization_name', id: 'organization_name', label: 'Organizacja' },
];

// Component
const AddBeneficiary = ({
  clearPrograms,
  fetchProgramsList,
  programs,
  clearOrganizations,
  fetchOrganizationList,
  organizations_user,
  clearBeneficiaryProgramsSimple,
  fetchBeneficiaryProgramsSimple,
  programs_simple,
  clearBeneficiaryOrganizations,
  fetchBeneficiaryOrganizations,
  organizations,
  fetchBeneficiary,
  beneficiary,
  clearBeneficiary,
}) => {
  const { beneficiary_id } = useParams();
  const history = useHistory();

  const [newBeneficiary, setNewBeneficiary] = useState(null);
  const [programList, setProgramList] = useState([]);
  const [openDialogProgram, setOpenDialogProgram] = useState(false);
  const [newProgramList, setNewProgramList] = useState([]);

  const [organizationList, setOrganizationList] = useState([]);
  const [openDialogOrganization, setOpenDialogOrganization] = useState(false);
  const [newOrganizationList, setNewOrganizationList] = useState([]);

  useEffect(() => {
    clearBeneficiaryOrganizations();
    clearBeneficiaryProgramsSimple();
    clearOrganizations();
    clearPrograms();
    clearBeneficiary();
    setNewBeneficiary(null);
  }, []);

  useEffect(() => {
    if (beneficiary_id) {
      fetchBeneficiary(beneficiary_id).then((data) => {
        if (data && data.data && data.data.id)
          setNewBeneficiary({
            beneficiary_id: data.data.id,
            beneficiary_name: data.data.name,
            name: data.data.name,
            beneficiary_last_name: data.data.surname,
            surname: data.data.surname,
            beneficiary_gender: data.data.gender,
            gender: data.data.gender,
            pesel: data.data.pesel,
            passport: data.data.passport,
            dob: data.data.dob,
          });
      });
    }
  }, [beneficiary_id]);

  useEffect(() => {
    // console.log('beneficiary', newBeneficiary);
    if (
      beneficiary &&
      newBeneficiary &&
      ((beneficiary.id && beneficiary.name) ||
        (beneficiary.beneficiary_id && beneficiary.beneficiary_name))
    ) {
      if (beneficiary.id && beneficiary.name) {
        setNewBeneficiary((old_val) => ({
          ...old_val,
          beneficiary_id: beneficiary.id,
        }));
      } else if (beneficiary.beneficiary_id && beneficiary.beneficiary_name) {
        setNewBeneficiary((old_val) => ({
          ...old_val,
          beneficiary_id: beneficiary.beneficiary_id,
        }));
      }
    }
  }, [beneficiary]);

  useEffect(() => {
    if (newBeneficiary && newBeneficiary.beneficiary_id) {
      fetchBeneficiaryProgramsSimple(newBeneficiary.beneficiary_id);
      fetchBeneficiaryOrganizations(newBeneficiary.beneficiary_id);
    } else {
      clearPrograms();
      clearOrganizations();
      clearBeneficiaryProgramsSimple();
      clearBeneficiaryOrganizations();
      setNewProgramList([]);
      setNewOrganizationList([]);
      setProgramList([]);
      setOrganizationList([]);
    }
  }, [newBeneficiary]);

  useEffect(() => {
    if (newBeneficiary) {
      const progDataIds = [];
      if (programs_simple) {
        const progData = [];
        programs_simple.map((el) => {
          progData.push({
            program_name: el.name,
            id: el.programId,
          });
          progDataIds.push(el.programId);
          return true;
        });
        setProgramList(progData);
      }

      if (programs) {
        const newData = [];
        programs.map((el) => {
          if (!progDataIds.includes(el.id)) {
            newData.push({ name: el.name, value: el.id });
          }

          return true;
        });

        setNewProgramList(newData);
      }
    }
  }, [newBeneficiary, programs, programs_simple]);

  useEffect(() => {
    if (newBeneficiary) {
      const orgaDataIds = [];

      if (organizations) {
        const organizationsData = [];

        organizations.map((el) => {
          organizationsData.push({
            organization_name: el.name,
            id: el.organizationId,
          });

          orgaDataIds.push(el.organizationId);

          return true;
        });

        setOrganizationList(organizationsData);
      }

      if (organizations_user) {
        const newData = [];

        organizations_user.map((el) => {
          if (!orgaDataIds.includes(el.id)) {
            newData.push({ name: el.name, value: el.id });
          }

          return true;
        });

        setNewOrganizationList(newData);
      }
    }
  }, [newBeneficiary, organizations_user, organizations]);

  // console.log(newBeneficiary, beneficiary);

  return (
    <>
      {newBeneficiary && newBeneficiary.beneficiary_id && (
        <StyledTitle>
          <Grid item xs={8}>
            <Button
              variant="outlined"
              onClick={() => {
                history.push(`/beneficjenci/${newBeneficiary.beneficiary_id}`);
              }}
            >
              Przejdź do karty benfecjenta
            </Button>
          </Grid>
        </StyledTitle>
      )}
      <StyledTitle>
        <Heading.Subtitle1>Krok 1: Wprowadź dane podstawowe</Heading.Subtitle1>
      </StyledTitle>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Box mt={3}>
            <AddNewBeneficiary
              setNewBeneficiary={setNewBeneficiary}
              beneficiary={beneficiary_id && newBeneficiary}
              beneficiary_id={beneficiary_id && newBeneficiary && newBeneficiary.beneficiary_id}
            />
          </Box>
          {newBeneficiary && Object.keys(newBeneficiary).length > 0 && (
            <>
              <StyledTitle>
                <Heading.Subtitle1>
                  Krok 2: Aby zakończyć wprowadzanie beneficjenta do bazy przypisz do Organizacji
                  i/lub Programu.
                </Heading.Subtitle1>
              </StyledTitle>
              <Box mt={3}>
                <GuardedComponent
                  allowed_user_roles={[
                    COORDINATOR,
                    ORGANIZATION_ADMINISTRATOR,
                    PROGRAM_COORDINATOR,
                    TASK_COORDINATOR,
                    ROLE_SONATA_LECTURER_SENSITIVE_DATA,
                    ROLE_SONATA_LECTURER,
                  ]}
                  message="Nie masz uprawnień, aby dodać beneficjenta do programu."
                >
                  <Box mb={2}>
                    <Heading.Subtitle3>Programy:</Heading.Subtitle3>
                  </Box>
                  <Table headCells={headCellsPrograms} data={programList} tableBene />
                  <Box mt={1}>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        fetchProgramsList();
                        setOpenDialogProgram(true);
                      }}
                    >
                      Dodaj do programu
                    </Button>
                  </Box>

                  <DialogBeneficiaryProgram
                    beneficiaryId={newBeneficiary && newBeneficiary.beneficiary_id}
                    currentBeneficiary={
                      newBeneficiary && !newBeneficiary.beneficiary_id ? newBeneficiary : null
                    }
                    newProgramList={newProgramList}
                    open={openDialogProgram}
                    setOpenFn={setOpenDialogProgram}
                    refreshBeneficieryPrograms
                  />
                </GuardedComponent>
              </Box>
              <Box mt={3}>
                <GuardedComponent
                  allowed_user_roles={[
                    COORDINATOR,
                    ORGANIZATION_ADMINISTRATOR,
                    ORGANIZATION_EMPLOYEE,
                  ]}
                  message="Nie masz uprawnień, aby dodać beneficjenta do organizacji."
                >
                  <Box mb={2}>
                    <Heading.Subtitle3>Organizacje:</Heading.Subtitle3>
                  </Box>
                  <Table headCells={headCellsOrganizations} data={organizationList} tableBene />
                  <Box mt={1}>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        fetchOrganizationList();
                        setOpenDialogOrganization(true);
                      }}
                    >
                      Dodaj do organizacji, która jest administratorem danych osobowych beneficjenta
                    </Button>
                  </Box>
                  <DialogBeneficiaryOrganization
                    beneficiaryId={newBeneficiary && newBeneficiary.beneficiary_id}
                    currentBeneficiary={
                      newBeneficiary && !newBeneficiary.beneficiary_id ? newBeneficiary : null
                    }
                    newOrganizationList={newOrganizationList}
                    open={openDialogOrganization}
                    setOpenFn={setOpenDialogOrganization}
                    refreshBeneficieryOrganizations
                  />
                </GuardedComponent>
              </Box>
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
};

AddBeneficiary.propTypes = {
  clearPrograms: PropTypes.func,
  fetchProgramsList: PropTypes.func,
  programs: PropTypes.arrayOf(PropTypes.any),
  clearOrganizations: PropTypes.func,
  fetchOrganizationList: PropTypes.func,
  organizations_user: PropTypes.arrayOf(PropTypes.any),
  clearBeneficiaryProgramsSimple: PropTypes.func,
  fetchBeneficiaryProgramsSimple: PropTypes.func,
  programs_simple: PropTypes.arrayOf(PropTypes.any),
  clearBeneficiaryOrganizations: PropTypes.func,
  fetchBeneficiaryOrganizations: PropTypes.func,
  fetchBeneficiary: PropTypes.func,
  organizations: PropTypes.arrayOf(PropTypes.any),
  beneficiary: PropTypes.objectOf(PropTypes.any),
  clearBeneficiary: PropTypes.func,
};

AddBeneficiary.defaultProps = {
  clearPrograms: null,
  fetchProgramsList: null,
  programs: null,
  clearOrganizations: null,
  fetchOrganizationList: null,
  organizations_user: null,
  clearBeneficiaryProgramsSimple: null,
  fetchBeneficiaryProgramsSimple: null,
  programs_simple: null,
  clearBeneficiaryOrganizations: null,
  fetchBeneficiaryOrganizations: null,
  organizations: null,
  fetchBeneficiary: null,
  beneficiary: null,
  clearBeneficiary: null,
};

const mapStateToProps = ({ programReducer, beneficiaryReducer, organizationReducer }) => ({
  programs: programReducer.programs.filter((el) => el.was_anonymized === false),
  programs_simple: beneficiaryReducer.programs_simple,
  organizations: beneficiaryReducer.organizations,
  organizations_user: organizationReducer.organizations,
  beneficiary: beneficiaryReducer.beneficiary,
});

const mapDispatchToProps = (dispatch) => ({
  clearPrograms: () => dispatch(clearProgramsAction()),
  fetchProgramsList: () => dispatch(fetchProgramsListService()),
  clearOrganizations: () => dispatch(clearOrganizationsAction()),
  fetchOrganizationList: () => dispatch(fetchOrganizationListService()),
  clearBeneficiaryProgramsSimple: () => dispatch(clearBeneficiaryProgramsSimpleAction()),
  fetchBeneficiaryProgramsSimple: (id) => dispatch(fetchBeneficiaryProgramsSimpleService(id)),
  clearBeneficiaryOrganizations: () => dispatch(clearBeneficiaryOrganizationsAction()),
  fetchBeneficiaryOrganizations: (id) => dispatch(fetchBeneficiaryOrganizationsService(id)),
  fetchBeneficiary: (id) => dispatch(fetchBeneficiaryService(id)),
  clearBeneficiary: () => dispatch(clearBeneficiaryAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddBeneficiary);
