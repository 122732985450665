// React
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';

// Services
import { fetchProgramBeneficiaries } from 'services/programServices';

// Actions
import { clearProgramBeneficiaries as clearProgramBeneficiariesAction } from 'actions/programActions';
import { showAlert as showAlertAction } from 'actions/appActions';

import { ZANONIMIZOWANO_ZAKAZ_EDYCJI } from 'constants/alerts';

// Elements
import TableTemplate from 'templates/TableTemplate';
import DialogBeneficiaryProgram from 'components/templates/DialogBeneficiaryProgram/DialogBeneficiaryProgram';
import DialogEditDate from 'components/templates/DialogEditDate/DialogEditDate';
import FloatingButton from 'components/atoms/FloatingButton/FloatingButton';

// Data
const headCells = [
  { type: 'beneficiary_name', label: 'Imię' },
  { type: 'beneficiary_last_name', label: 'Nazwisko' },
  { type: 'beneficiary_gender', label: 'Płeć' },
  { type: 'pwd', label: 'PWD' },
  { type: 'date_added_to_program', label: 'Data dodania' },
  // { type: 'date_modified', label: 'Data ostatniej modyfikacji' },
  // { type: 'modified_by', label: 'Modyfikowany przez' },
];

// Component
const ProgramBeneficiary = ({ clearProgramBeneficiaries, fetchList, showAlert, data }) => {
  const history = useHistory();
  const { id } = useParams();
  const { program_beneficiaries, wasAnonymized } = data;

  const [openDialogProgram, setOpenDialogProgram] = useState(false);
  const [openDialogEditDate, setOpenDialogEditDate] = useState(false);
  const [beneficiaryData, setBeneficiaryData] = useState(null);

  const rowActions = [
    {
      name: 'zobacz',
      action: (row) => history.push(`/beneficjenci/${row.id}`),
    },
    {
      name: 'edytuj datę dodania',
      action: (row) => {
        if (row.beneficiary_name === 'anonimizacja') {
          showAlert(ZANONIMIZOWANO_ZAKAZ_EDYCJI);
        } else {
          setBeneficiaryData({ ...row, program_start: data.start_date });
          setOpenDialogEditDate(true);
        }
      },
    },
  ];

  useEffect(() => {
    clearProgramBeneficiaries();
  }, []);

  const triggerFetchList = useCallback((myid) => fetchList(myid), [fetchList]);

  useEffect(() => {
    triggerFetchList(id);
  }, [id, triggerFetchList]);

  return (
    <>
      <TableTemplate
        headCells={headCells}
        data={program_beneficiaries}
        navCells
        link="/beneficjenci"
        tableName="Beneficjenci"
        rowActions={rowActions}
      />
      <DialogBeneficiaryProgram
        programId={parseInt(id, 10)}
        programBeneficiaries={program_beneficiaries}
        open={openDialogProgram}
        setOpenFn={setOpenDialogProgram}
        refreshBeneficieryList
        stopClear
        stopFetch
      />
      <DialogEditDate
        dialogBeneficiary={{ ...beneficiaryData, program_id: parseInt(id, 10) }}
        open={openDialogEditDate}
        setOpenFn={setOpenDialogEditDate}
      />
      {/* {program_beneficiaries && program_beneficiaries.length > 0 && ( */}
      {!wasAnonymized && (
        <FloatingButton
          onClick={() => {
            setOpenDialogProgram(true);
          }}
        />
      )}
      {/* )} */}
    </>
  );
};

ProgramBeneficiary.propTypes = {
  clearProgramBeneficiaries: PropTypes.func,
  fetchList: PropTypes.func,
  showAlert: PropTypes.func,
  data: PropTypes.shape({
    id: PropTypes.number,
    start_date: PropTypes.string,
    program_name: PropTypes.string,
    wasAnonymized: PropTypes.bool,
    program_beneficiaries: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        beneficiary_name: PropTypes.string,
        beneficiary_last_name: PropTypes.string,
        beneficiary_gender: PropTypes.string,
        pwd: PropTypes.string,
        educational_facility: PropTypes.string,
        age_type: PropTypes.string,
        date_added_to_program: PropTypes.string,
        date_modified: PropTypes.string,
        modified_by: PropTypes.string,
        anonymized: PropTypes.number,
      }),
    ),
  }),
};

ProgramBeneficiary.defaultProps = {
  clearProgramBeneficiaries: null,
  fetchList: null,
  showAlert: null,
  data: {
    id: null,
    start_date: '',
    program_name: '',
    program_beneficiaries: [],
    wasAnonymized: false,
  },
};

const mapStateToProps = ({ programReducer }) => ({
  data: programReducer.beneficiaries,
});

const mapDispatchToProps = (dispatch) => ({
  clearProgramBeneficiaries: () => dispatch(clearProgramBeneficiariesAction()),
  fetchList: (id) => dispatch(fetchProgramBeneficiaries(id)),
  showAlert: (data) => dispatch(showAlertAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProgramBeneficiary);
