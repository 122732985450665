// Data
import { device } from 'theme/mainTheme';

// Assets
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';

// Elements
import { Grid } from '@material-ui/core';
import Button from 'components/atoms/Button/Button';

// Styles
import styled from 'styled-components';

export const StyledGrid = styled(Grid)`
  && {
    max-width: 75%;

    &.MuiGrid-spacing-xs-2 {
      margin-top: 3rem;
    }

    @media ${device.laptopL} {
      max-width: 60%;
    }
  }
`;

export const StyledButton = styled(Button)`
  && {
    padding: 1.5rem 2.5rem;
  }
`;

export const DropDownWrapper = styled.div`
  && {
    margin-bottom: 2rem;
    &:focus {
      outline: none;
    }
  }
`;

export const StyledPhotoLibraryOutlinedIcon = styled(PhotoLibraryIcon)`
  && {
    width: 12rem;
    height: 12rem;
    color: ${({ theme }) => theme.color.gray};
  }
`;

export const StyledTitle = styled.div`
  && {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 3rem;
    padding-right: 1px;
  }
`;

export const StyledImage = styled.img`
  && {
    max-width: 20rem;
  }
`;
