export const filterOrganizations = [
  { key: 'type', label: 'Typ organizacji' },
  { key: 'status', label: 'Status' },
  { key: 'district', label: 'Dzielnica' },
];

export const filterUsers = [
  { key: 'status', label: 'Status' },
  { key: 'gender', label: 'Płeć' },
  { key: 'user_role', label: 'Rola', type: 'array' },
  { key: 'user_programs', label: 'Programy', type: 'array' },
  { key: 'user_organizations', label: 'Organizacje', type: 'array' },
];

export const filterOrganizationUsers = [
  { key: 'status', label: 'Status' },
  { key: 'gender', label: 'Płeć' },
  { key: 'user_role', label: 'Rola' },
];

export const filterProgramUsers = [
  { key: 'status', label: 'Status' },
  { key: 'gender', label: 'Płeć' },
  { key: 'user_role', label: 'Rola' },
  { key: 'user_organization', label: 'Organizacja', type: 'array' },
];

export const filterCoaliton = [
  { key: 'name', label: 'Nazwa' },
  { key: 'type', label: 'Typ organizacji' },
];

export const filterBeneficiary = [
  { key: 'gender', label: 'Płeć' },
  { key: 'pwd', label: 'PWD' },
  // { key: 'modified_by', label: 'Modyfikowany przez' },
  { key: 'date_add_from', range_key: 'date_add', label: 'Data dodania od', type: 'range_from' },
  { key: 'date_add_to', range_key: 'date_add', label: 'Data dodania do', type: 'range_to' },
  {
    key: 'date_modified_from',
    range_key: 'date_modified',
    label: 'Data ostatniej modyfikacji od',
    type: 'range_from',
  },
  {
    key: 'date_modified_to',
    range_key: 'date_modified',
    label: 'Data ostatniej modyfikacji do',
    type: 'range_to',
  },
];

export const filterDiagnosis = [
  { key: 'program', label: 'Program' },
  { key: 'status', label: 'Status' },
];

export const filterIPP = [
  { key: 'program', label: 'Program' },
  { key: 'status', label: 'Status' },
];

export const filterTasks = [
  { key: 'task_owner', label: 'Właściciel zadania' },
  { key: 'program', label: 'Program' },
];

export const filterActions = [
  { key: 'lecturer', label: 'Prowadzący', type: 'array' },
  { key: 'created_by', label: 'Założyciel' },
  { key: 'action_type', label: 'Typ działania' },
  { key: 'program_name', label: 'Program' },
  { key: 'action_group', label: 'Grupa działań' },
];

export const filterIndicators = [
  { key: 'indicator_kind', label: 'Rodzaj wskaźnika' },
  { key: 'indicator_type', label: 'Rodzaj pomiaru' },
  { key: 'indicator_time_period', label: 'Przedział czasowy' },
  { key: 'assigned_to', label: 'Przypisanie' },
  { key: 'program_name', label: 'Program' },
];

export const filterApplications = [
  { key: 'organization', label: 'Organizacja' },
  { key: 'app_type', label: 'Rodzaj wniosku' },
  { key: 'status', label: 'Status' },
];

export const filterPrograms = [
  { key: 'type', label: 'Typ programu' },
  { key: 'coordinator', label: 'Koordynator' },
];

export const filterProgramsAction = [
  { key: 'action_name', label: 'Nazwa działania' },
  { key: 'lecturer', label: 'Prowadzący', type: 'array' },
  { key: 'modified_by', label: 'Modyfikowane przez' },
];

export const filterProgramsTask = [
  { key: 'task_type', label: 'Typ zadania' },
  { key: 'coordinator', label: 'Koordynator' },
];
