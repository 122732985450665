// React
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';

// Breadcrumb
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';

// Service
import { fetchSingleSurvey as fetchSingleSurveyService } from 'services/beneficiaryServices';

// Actions
import { clearSingleSurvey as clearSingleSurveyAction } from 'actions/beneficiaryActions';

// Data
import { ageRange } from 'constants/surveys';
import { surveyMeasurementNumbers } from 'constants/selectLists';

// Elements
import { Grid } from '@material-ui/core';
import Input from 'components/atoms/Input/Input';
import Heading from 'components/atoms/Heading/Heading';
import ResultSurveys from 'views/Dashboard/Beneficiary/ResultSurveys/ResultSurveys';

// Styles
import { StyledGrid, StyledButton } from './ResultBeneficiarySurveys.styles';

// View
const ResultBeneficiarySurveys = ({ clearSingleSurvey, fetchSingleSurvey, single_survey }) => {
  const history = useHistory();
  const { id_survey } = useParams();

  const [data, setData] = useState({});
  const [ageValue, setAgeValue] = useState(null);

  useEffect(() => {
    clearSingleSurvey();
  }, []);

  const triggerFetchSingleSurvey = useCallback(
    (myid) => fetchSingleSurvey(myid),
    [fetchSingleSurvey],
  );

  useEffect(() => {
    if (id_survey) {
      triggerFetchSingleSurvey(id_survey);
    }
  }, [id_survey]);

  useEffect(() => {
    if (single_survey) {
      setData(single_survey);

      if (single_survey.age) {
        const newData = ageRange.find((el) => el.value === single_survey.age);

        if (newData) {
          setAgeValue(newData.name);
        }
      }
    }
  }, [single_survey]);

  // const chartData = [
  //   { id: 0, name: '1st wykres', score: 1.25 },
  //   { id: 1, name: '2nd wykres', score: 3.14 },
  //   { id: 2, name: '3rd wykres', score: 1.72 },
  //   { id: 3, name: '4th wykres', score: 5.82 },
  //   { id: 4, name: '5th wykres', score: 4.42 },
  //   { id: 5, name: '6th wykres', score: 2.78 },
  //   { id: 6, name: '7th wykres', score: 3.21 },
  // ];
  // const gender = 'female';

  return (
    <>
      <Grid container>
        <Grid item xs={8}>
          {data.program && data.program.id && data.program.name && (
            <BreadcrumbsItem to={`/programy/${data.program.id}`} order={0}>
              Program: {data.program.name}
            </BreadcrumbsItem>
          )}
          {data.beneficiary && data.beneficiary.id && data.beneficiary.name && (
            <BreadcrumbsItem to={`/beneficjenci/${data.beneficiary.id}`} order={0}>
              Beneficjent: {data.beneficiary.name}
            </BreadcrumbsItem>
          )}
          {data && data.status === 2 ? (
            <>
              <StyledGrid container spacing={2}>
                {data.name && (
                  <Grid item xs={12}>
                    <Input disabled label="Tytuł" variant="filled" value={data.name} />
                  </Grid>
                )}
                {data.measurement_number && (
                  <Grid item xs={12}>
                    <Input
                      disabled
                      label="Numer pomiaru"
                      variant="filled"
                      value={
                        surveyMeasurementNumbers.find((el) => el.value === data.measurement_number)
                          ?.name
                      }
                    />
                  </Grid>
                )}
                {data.program && data.program.name && (
                  <Grid item xs={12}>
                    <Input
                      disabled
                      label="Nazwa programu"
                      variant="filled"
                      value={data.program.name}
                    />
                  </Grid>
                )}
                {data.author && data.author.name && (
                  <Grid item xs={12}>
                    <Input
                      disabled
                      label="Imię i nazwisko przeprowadzającego"
                      variant="filled"
                      value={data.author.name}
                    />
                  </Grid>
                )}
                {data.beneficiary && data.beneficiary.name && (
                  <Grid item xs={12}>
                    <Input
                      disabled
                      label="Imię i nazwisko beneficjenta"
                      variant="filled"
                      value={data.beneficiary.name}
                    />
                  </Grid>
                )}
                {ageValue && (
                  <Grid item xs={12}>
                    <Input disabled label="Przedział wiekowy" variant="filled" value={ageValue} />
                  </Grid>
                )}
              </StyledGrid>
              {Object.keys(data.result).length ? (
                <ResultSurveys
                  results={data.answers.map((a) => ({
                    id: a.id,
                    name: a.label,
                    score: data.result[parseInt(a.id, 10)].avg
                      ? data.result[parseInt(a.id, 10)].avg
                      : 0,
                  }))}
                  age={data.age}
                  gender={data.beneficiary?.gender || 'female'}
                />
              ) : (
                <StyledGrid container spacing={2}>
                  <Grid item xs={12}>
                    <Heading.Subtitle2>
                      Wynik ankiety nie został prawidłowo wyliczone prosimy o kontakt z
                      adminsitratorem systemu lub prosimy o powrót do edycji ankiety i ponowne
                      zapisanie jej:
                    </Heading.Subtitle2>
                    <StyledButton
                      padding="1rem 1.5rem"
                      variant="contained"
                      style={{ marginTop: 15 }}
                      onClick={() => {
                        history.push(`/ankieta/${data.id}/edycja/`);
                      }}
                    >
                      Przejdź do edycji
                    </StyledButton>
                  </Grid>
                </StyledGrid>
              )}
            </>
          ) : (
            <StyledGrid container spacing={2}>
              <Grid item xs={12}>
                <Heading.Subtitle2>
                  {data && Object.keys(data).length === 0
                    ? 'Ładowanie danych ankiety'
                    : 'Ankieta nie ma statusu zakończonej!'}
                </Heading.Subtitle2>
                {data.id && (
                  <StyledButton
                    padding="1rem 1.5rem"
                    variant="contained"
                    style={{ marginTop: 15 }}
                    onClick={() => {
                      history.push(`/ankieta/${data.id}/edycja/`);
                    }}
                  >
                    Przejdź do edycji
                  </StyledButton>
                )}
              </Grid>
            </StyledGrid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

ResultBeneficiarySurveys.propTypes = {
  clearSingleSurvey: PropTypes.func,
  fetchSingleSurvey: PropTypes.func,
  single_survey: PropTypes.objectOf(PropTypes.any),
};

ResultBeneficiarySurveys.defaultProps = {
  clearSingleSurvey: null,
  fetchSingleSurvey: null,
  single_survey: null,
};

const mapStateToProps = ({ beneficiaryReducer }) => ({
  single_survey: beneficiaryReducer.single_survey,
});

const mapDispatchToProps = (dispatch) => ({
  clearSingleSurvey: () => dispatch(clearSingleSurveyAction()),
  fetchSingleSurvey: (id) => dispatch(fetchSingleSurveyService(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResultBeneficiarySurveys);
