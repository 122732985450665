import {
  SHOW_TASKS,
  CLEAR_TASKS,
  FETCH_TASK,
  FETCH_TASK_ACTION,
  CLEAR_TASK_ACTION,
  SHOW_TASK_DIAGNOSIS,
  CLEAR_TASK_DIAGNOSIS,
  SHOW_TASK_SURVEYS,
  CLEAR_TASK_SURVEYS,
  SHOW_TASK_IPP,
  CLEAR_TASK_IPP,
  FETCH_TASK_BENEFICIARY,
  CLEAR_TASK_BENEFICIARY,
  FETCH_TASK_USERS,
  CLEAR_TASK_USERS,
  FETCH_TASK_INDICATORS,
  CLEAR_TASK_INDICATORS,
  FETCH_TASK_PROGRAM_ID,
  CLEAR_TASK_PROGRAM_ID,
  CLEAR_TASK,
  FETCH_TASK_COORDINATORS,
} from 'constants/actionTypes';

export function showTasks(payload) {
  return { type: SHOW_TASKS, payload };
}

export function clearTasks(payload) {
  return { type: CLEAR_TASKS, payload };
}

export function showTaskDiagnosisAction(payload) {
  return { type: SHOW_TASK_DIAGNOSIS, payload };
}

export function clearTaskDiagnosis(payload) {
  return { type: CLEAR_TASK_DIAGNOSIS, payload };
}

export function showTaskSurveysAction(payload) {
  return { type: SHOW_TASK_SURVEYS, payload };
}

export function clearTaskSurveys(payload) {
  return { type: CLEAR_TASK_SURVEYS, payload };
}

export function showTaskIPPAction(payload) {
  return { type: SHOW_TASK_IPP, payload };
}

export function clearTaskIPP(payload) {
  return { type: CLEAR_TASK_IPP, payload };
}

export function fetchTaskAction(payload) {
  return { type: FETCH_TASK, payload };
}

export function fetchTaskActionsAction(payload) {
  return { type: FETCH_TASK_ACTION, payload };
}

export function clearTaskActions(payload) {
  return { type: CLEAR_TASK_ACTION, payload };
}

export function fetchTaskBeneficiaryAction(payload) {
  return { type: FETCH_TASK_BENEFICIARY, payload };
}

export function clearTaskBeneficiary(payload) {
  return { type: CLEAR_TASK_BENEFICIARY, payload };
}

export function fetchTaskUsersAction(payload) {
  return { type: FETCH_TASK_USERS, payload };
}

export function clearTaskUsers(payload) {
  return { type: CLEAR_TASK_USERS, payload };
}

export function fetchTaskIndicatorsAction(payload) {
  return { type: FETCH_TASK_INDICATORS, payload };
}

export function clearTaskIndicators(payload) {
  return { type: CLEAR_TASK_INDICATORS, payload };
}

export function fetchTaskProgramIdAction(payload) {
  return { type: FETCH_TASK_PROGRAM_ID, payload };
}

export function clearTaskProgramId(payload) {
  return { type: CLEAR_TASK_PROGRAM_ID, payload };
}

export function fetchTaskCoordinatorsAction(payload) {
  return { type: FETCH_TASK_COORDINATORS, payload };
}

export function clearTaskAction() {
  return { type: CLEAR_TASK };
}
