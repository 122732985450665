// Assets
import image from 'img/syrenka.svg';
import imageBw from 'img/syrenka_bw.svg';

// Elements
import { Drawer, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';

// Styles
import styled from 'styled-components';
import { device } from 'theme/mainTheme';

export const StyledDrawer = styled(Drawer)`
  && {
    & > div {
      margin-top: 10rem;
      min-width: ${({ hide }) => (!hide ? '0vw' : '15vw')};
      transition: 0.4s;
      display: flex;
      flex-direction: column;
      background-color: ${({ theme }) => theme.color.white};
      justify-content: space-between;
      height: calc(100vh - 10rem);

      @media ${device.laptopL} {
        min-width: ${({ hide }) => (hide ? '0vw' : '15vw')};
      }
    }
  }
`;

export const StyledListItemText = styled(ListItemText)`
  && {
    width: 0;
    overflow: hidden;

    span {
      font-family: ${({ theme }) => theme.fontFamily.base};
      font-size: ${({ theme }) => theme.fontSize.body2};
      color: ${({ theme }) => theme.color.darkGray};
    }
  }
`;

export const StyledListItemIcon = styled(ListItemIcon)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      font-size: 2.5rem;
      fill: ${({ theme }) => theme.color.darkGray};
    }
  }
`;

export const StyledListItem = styled(ListItem)`
  && {
    border-radius: 50px;

    &.active {
      background-color: ${({ theme }) => theme.color.lightGray};
    }
  }
`;

// ${({ hide }) => (hide ? 'block' : 'none')};
export const StyledImage = styled.div`
  && {
    background-image: url(${image});
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    margin: 0 auto;
    height: 9.8rem;
    display: block;
    flex-shrink: 0;
    @media ${device.laptop} {
      display: ${({ hide }) => (!hide ? 'block' : 'none')};
      width: 100%;
      background-size: auto 7.8rem;
    }

    .contrast-on & {
      background-image: url(${imageBw});
      background-color: ${({ theme }) => theme.color.darkGray};
    }
  }
`;
