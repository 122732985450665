import {
  ORGANIZATION_EMPLOYEE,
  ORGANIZATION_ADMINISTRATOR,
  PROGRAM_COORDINATOR,
  PROGRAM_GUARDIAN,
  //
  TASK_COORDINATOR,
  LECTURER_SENSITIVE_DATA,
  LECTURER,
  INSTRUCTOR,
  NO_ACCESS,
} from 'constants/roles';

// OrganizationTabs
import OrganizationCurrent from 'views/Dashboard/Organizations/OrganizationCurrent/OrganizationCurrent';
import OrganizationCoaliton from 'views/Dashboard/Organizations/OrganizationCoalition/OrganizationCoaliton';
import OrganizationUsers from 'views/Dashboard/Organizations/OrganizationUsers/OrganizationUsers';
import OrganizationBeneficiaries from 'views/Dashboard/Organizations/OrganizationBeneficiaries/OrganizationBeneficiaries';
import OrganizationPrograms from 'views/Dashboard/Organizations/OrganizationPrograms/OrganizationPrograms';

// UserSettings
import PersonIcon from '@material-ui/icons/Person';
import DashboardIcon from '@material-ui/icons/Dashboard';
import UserInfo from 'views/Dashboard/UserSettings/UserInfo/UserInfo';
import UserDesktop from 'views/Dashboard/UserSettings/UserDesktop/UserDesktop';

// CurrentUser
import UserDescription from 'views/Dashboard/Users/UserDescription/UserDescription';
import UserPermissions from 'views/Dashboard/Users/UserPermissions/UserPermissions';

// BeneficiaryTabs
import BeneficiarySettings from 'views/Dashboard/Beneficiary/BeneficiarySettings/BeneficiarySettings';
import BeneficiaryBindings from 'views/Dashboard/Beneficiary/BeneficiaryBindings/BeneficiaryBindings';
import BeneficiaryPrograms from 'views/Dashboard/Beneficiary/BeneficiaryPrograms/BeneficiaryPrograms';
import BeneficiaryIPP from 'views/Dashboard/Beneficiary/BeneficiaryIPP/BeneficiaryIPP';
import BeneficiaryDocuments from 'views/Dashboard/Beneficiary/BeneficiaryDocuments/BeneficiaryDocuments';
import BeneficiaryNotes from 'views/Dashboard/Beneficiary/BeneficiaryNotes/BeneficiaryNotes';
import BeneficiaryDiagnosis from 'views/Dashboard/Beneficiary/BeneficiaryDiagnosis/BeneficiaryDiagnosis';
import BeneficiaryIndicators from 'views/Dashboard/Beneficiary/BeneficiaryIndicators/BeneficiaryIndicators';
import BeneficiarySupportedSubjects from 'views/Dashboard/Beneficiary/BeneficiarySupportedSubjects/BeneficiarySupportedSubjects';
import BeneficiaryRodo from 'views/Dashboard/Beneficiary/BeneficiaryRodo/BeneficiaryRodo';

// ProgramTabs
import ProgramSettings from 'views/Dashboard/Programs/ProgramSettings/ProgramSettings';
import ProgramConsortium from 'views/Dashboard/Programs/ProgramConsortium/ProgramConsortium';
import ProgramPWD from 'views/Dashboard/Programs/ProgramPWD/ProgramPWD';
import ProgramPresencePWD from 'views/Dashboard/Programs/ProgramPresencePWD/ProgramPresencePWD';
import ProgramTasks from 'views/Dashboard/Programs/ProgramTasks/ProgramTasks';
import ProgramActions from 'views/Dashboard/Programs/ProgramActions/ProgramActions';
import ProgramUsers from 'views/Dashboard/Programs/ProgramUsers/ProgramUsers';
import ProgramBeneficiary from 'views/Dashboard/Programs/ProgramBeneficiary/ProgramBeneficiary';
import ProgramIndicators from 'views/Dashboard/Programs/ProgramIndicators/ProgramIndicators';
import ProgramDiagnosis from 'views/Dashboard/Programs/ProgramDiagnosis/ProgramDiagnosis';
import ProgramIPP from 'views/Dashboard/Programs/ProgramIPP/ProgramIPP';

// Task
import TaskDescription from 'views/Dashboard/Tasks/TaskDescription/TaskDescription';
import TaskActions from 'views/Dashboard/Tasks/TaskActions/TaskActions';
import TaskBeneficiary from 'views/Dashboard/Tasks/TaskBeneficiary/TaskBeneficiary';
import TaskUsers from 'views/Dashboard/Tasks/TaskUsers/TaskUsers';
import TaskIndicators from 'views/Dashboard/Tasks/TaskIndicators/TaskIndicators';

// Tasks / Diag / IPP
import TasksTable from 'views/Dashboard/Tasks/TasksTable/TasksTable';
import TasksDiagnosis from 'views/Dashboard/Tasks/TasksDiagnosis/TasksDiagnosis';
import TasksSurveys from 'views/Dashboard/Tasks/TasksSurveys/TasksSurveys';
import TasksIPP from 'views/Dashboard/Tasks/TasksIPP/TasksIPP';

// Actions
import ActionDescription from 'views/Dashboard/Actions/ActionDescription/ActionDescription';
import ActionBeneficiaries from 'views/Dashboard/Actions/ActionBeneficiaries/ActionBeneficiaries';
import ActionPresence from 'views/Dashboard/Actions/ActionPresence/ActionPresence';
import ActionMeetings from 'views/Dashboard/Actions/ActionMeetings/ActionMeetings';

export const actionsTabs = [
  { label: 'Dane działania', key: 'dane', tabView: ActionDescription },
  {
    label: 'Uczestnicy',
    key: 'uczestnicy',
    tabView: ActionBeneficiaries,
    program_roles: [
      PROGRAM_COORDINATOR,
      TASK_COORDINATOR,
      LECTURER_SENSITIVE_DATA,
      LECTURER,
      INSTRUCTOR,
    ],
  },
  {
    label: 'Dziennik obecności',
    key: 'dziennik-obecnosci',
    tabView: ActionPresence,
    program_roles: [
      PROGRAM_COORDINATOR,
      TASK_COORDINATOR,
      LECTURER_SENSITIVE_DATA,
      LECTURER,
      INSTRUCTOR,
    ],
  },
  {
    label: 'Spotkania',
    key: 'spotkania',
    tabView: ActionMeetings,
    program_roles: [
      PROGRAM_COORDINATOR,
      TASK_COORDINATOR,
      LECTURER_SENSITIVE_DATA,
      LECTURER,
      INSTRUCTOR,
    ],
  },
];

export const tasksTabs = [
  { label: 'Zadania', key: 'zadania', tabView: TasksTable },
  {
    label: 'Diagnozy',
    key: 'diagnozy',
    tabView: TasksDiagnosis,
    user_roles: [PROGRAM_COORDINATOR, TASK_COORDINATOR, LECTURER_SENSITIVE_DATA],
  },
  {
    label: 'Ankiety Diag-Ewal',
    key: 'ankiety',
    tabView: TasksSurveys,
    user_roles: [PROGRAM_COORDINATOR, TASK_COORDINATOR, LECTURER_SENSITIVE_DATA],
  },
  {
    label: 'Indywidualne Plany Pracy',
    key: 'ipp',
    tabView: TasksIPP,
    user_roles: [PROGRAM_COORDINATOR, TASK_COORDINATOR, LECTURER_SENSITIVE_DATA],
  },
];

export const taskTabs = [
  { label: 'Dane zadania', key: 'dane', tabView: TaskDescription },
  {
    label: 'Działania',
    key: 'dzialania',
    tabView: TaskActions,
    program_roles: [
      PROGRAM_COORDINATOR,
      PROGRAM_GUARDIAN,
      LECTURER_SENSITIVE_DATA,
      LECTURER,
      TASK_COORDINATOR,
    ],
  },
  {
    label: 'Beneficjenci',
    key: 'beneficjenci',
    tabView: TaskBeneficiary,
    program_roles: [
      PROGRAM_COORDINATOR,
      TASK_COORDINATOR,
      LECTURER_SENSITIVE_DATA,
      LECTURER,
      INSTRUCTOR,
    ],
  },
  {
    label: 'Użytkownicy',
    key: 'uzytkownicy',
    tabView: TaskUsers,
  },
  {
    label: 'Wskaźniki',
    key: 'wskazniki',
    tabView: TaskIndicators,
    program_roles: [PROGRAM_COORDINATOR, LECTURER_SENSITIVE_DATA, LECTURER, TASK_COORDINATOR],
  },
];

export const programTabs = [
  { label: 'Dane Programu', key: 'dane', tabView: ProgramSettings },
  {
    label: 'Konsorcjum',
    key: 'konsorcjum',
    tabView: ProgramConsortium,
    program_roles: [PROGRAM_COORDINATOR, PROGRAM_GUARDIAN, TASK_COORDINATOR],
    organization_roles: [ORGANIZATION_ADMINISTRATOR],
  },
  {
    label: 'PWD',
    key: 'pwd',
    tabView: ProgramPWD,
    program_roles: [PROGRAM_COORDINATOR],
    organization_roles: [ORGANIZATION_ADMINISTRATOR],
  },
  {
    label: 'Obecność',
    key: 'obecnosc',
    tabView: ProgramPresencePWD,
    program_roles: [PROGRAM_COORDINATOR, LECTURER_SENSITIVE_DATA, LECTURER, TASK_COORDINATOR],
    organization_roles: [ORGANIZATION_ADMINISTRATOR],
  },
  {
    label: 'Zadania',
    key: 'zadania',
    tabView: ProgramTasks,
    program_roles: [
      PROGRAM_COORDINATOR,
      LECTURER_SENSITIVE_DATA,
      LECTURER,
      TASK_COORDINATOR,
      PROGRAM_GUARDIAN,
    ],
    organization_roles: [ORGANIZATION_ADMINISTRATOR],
  },
  {
    label: 'Diagnozy',
    key: 'diagnoza',
    tabView: ProgramDiagnosis,
    program_roles: [PROGRAM_COORDINATOR, TASK_COORDINATOR, LECTURER_SENSITIVE_DATA],
  },
  {
    label: 'Indywidualne Plany Pracy',
    key: 'ipp',
    tabView: ProgramIPP,
    program_roles: [PROGRAM_COORDINATOR, TASK_COORDINATOR, LECTURER_SENSITIVE_DATA],
  },
  {
    label: 'Działania',
    key: 'dzialania',
    tabView: ProgramActions,
    program_roles: [
      PROGRAM_COORDINATOR,
      LECTURER_SENSITIVE_DATA,
      LECTURER,
      TASK_COORDINATOR,
      PROGRAM_GUARDIAN,
    ],
    organization_roles: [ORGANIZATION_ADMINISTRATOR],
  },
  { label: 'Użytkownicy', key: 'uzytkownicy', tabView: ProgramUsers },
  {
    label: 'Beneficjenci',
    key: 'beneficjenci',
    tabView: ProgramBeneficiary,
    program_roles: [
      PROGRAM_COORDINATOR,

      TASK_COORDINATOR,
      LECTURER_SENSITIVE_DATA,
      LECTURER,
      INSTRUCTOR,
    ],
    organization_roles: [ORGANIZATION_ADMINISTRATOR],
  },
  {
    label: 'Wskaźniki',
    key: 'wskazniki',
    tabView: ProgramIndicators,
    program_roles: [
      PROGRAM_COORDINATOR,
      LECTURER_SENSITIVE_DATA,
      TASK_COORDINATOR,

      PROGRAM_GUARDIAN,
    ],
    organization_roles: [ORGANIZATION_ADMINISTRATOR],
  },
];

export const beneficiaryTabs = [
  {
    label: 'Dane Uczestnika',
    key: 'dane',
    tabView: BeneficiarySettings,
    order: 0,
    program_roles: [
      PROGRAM_COORDINATOR,
      PROGRAM_GUARDIAN,
      TASK_COORDINATOR,
      LECTURER_SENSITIVE_DATA,
      LECTURER,
      INSTRUCTOR,
    ],
    organization_roles: [ORGANIZATION_ADMINISTRATOR],
  },
  {
    label: 'Kontakty',
    key: 'kontakty',
    tabView: BeneficiaryBindings,
    program_roles: [
      PROGRAM_COORDINATOR,
      PROGRAM_GUARDIAN,
      TASK_COORDINATOR,
      LECTURER_SENSITIVE_DATA,
    ],
    organization_roles: [ORGANIZATION_ADMINISTRATOR, ORGANIZATION_EMPLOYEE],
    order: 1,
  },
  {
    label: 'Programy i Działania',
    key: 'programy-dzialania',
    tabView: BeneficiaryPrograms,
    program_roles: [
      PROGRAM_COORDINATOR,
      PROGRAM_GUARDIAN,
      TASK_COORDINATOR,
      LECTURER_SENSITIVE_DATA,
      LECTURER,
    ],
    order: 2,
  },
  {
    label: 'Przedmioty Wspierane',
    key: 'przedmioty-wspierane',
    tabView: BeneficiarySupportedSubjects,
    program_roles: [
      PROGRAM_COORDINATOR,
      PROGRAM_GUARDIAN,
      TASK_COORDINATOR,
      LECTURER_SENSITIVE_DATA,
    ],
    organization_roles: NO_ACCESS,
    order: 3,
  },
  {
    label: 'Wskaźniki',
    key: 'wskazniki',
    tabView: BeneficiaryIndicators,
    program_roles: [
      PROGRAM_COORDINATOR,
      PROGRAM_GUARDIAN,
      TASK_COORDINATOR,
      LECTURER_SENSITIVE_DATA,
    ],
    organization_roles: NO_ACCESS,
    order: 4,
  },
  {
    label: 'Diagnoza',
    key: 'diagnoza',
    tabView: BeneficiaryDiagnosis,
    program_roles: [
      PROGRAM_COORDINATOR,
      PROGRAM_GUARDIAN,
      TASK_COORDINATOR,
      LECTURER_SENSITIVE_DATA,
    ],
    organization_roles: NO_ACCESS,
    order: 5,
  },
  {
    label: 'Indywidualny Plan Pracy',
    key: 'ipp',
    tabView: BeneficiaryIPP,
    program_roles: [
      PROGRAM_COORDINATOR,
      PROGRAM_GUARDIAN,
      TASK_COORDINATOR,
      LECTURER_SENSITIVE_DATA,
    ],
    organization_roles: NO_ACCESS,
    order: 6,
  },
  {
    label: 'Dokumenty',
    key: 'dokumenty',
    tabView: BeneficiaryDocuments,
    order: 7,
    program_roles: [
      PROGRAM_COORDINATOR,
      PROGRAM_GUARDIAN,
      TASK_COORDINATOR,
      LECTURER_SENSITIVE_DATA,
    ],
    organization_roles: NO_ACCESS,
  },
  {
    label: 'Notatki',
    key: 'notatki',
    tabView: BeneficiaryNotes,
    program_roles: [
      PROGRAM_COORDINATOR,
      PROGRAM_GUARDIAN,
      TASK_COORDINATOR,
      LECTURER_SENSITIVE_DATA,
    ],
    order: 8,
    organization_roles: NO_ACCESS,
  },
  {
    label: 'RODO',
    key: 'rodo',
    tabView: BeneficiaryRodo,
    program_roles: [
      PROGRAM_COORDINATOR,
      PROGRAM_GUARDIAN,
      TASK_COORDINATOR,
      LECTURER_SENSITIVE_DATA,
      LECTURER,
    ],
    organization_roles: NO_ACCESS,
    order: 9,
  },
];

export const currentUserTabs = [
  { label: 'Dane Użytkownika', key: 'dane', tabView: UserDescription },
  {
    label: 'Uprawnienia',
    key: 'uprawnienia',
    tabView: UserPermissions,
    user_roles: [
      ORGANIZATION_ADMINISTRATOR,
      PROGRAM_COORDINATOR,
      TASK_COORDINATOR,
      LECTURER,
      LECTURER_SENSITIVE_DATA,
    ],
  },
];

export const organizationTabs = [
  { label: 'Dane Organizacji', key: 'dane-organizacji', tabView: OrganizationCurrent },
  {
    label: 'Koalicje',
    key: 'koalicje',
    tabView: OrganizationCoaliton,
  },
  {
    label: 'Użytkownicy',
    organization_roles: [ORGANIZATION_ADMINISTRATOR, ORGANIZATION_EMPLOYEE],
    user_roles: [PROGRAM_GUARDIAN],
    key: 'uzytkownicy',
    tabView: OrganizationUsers,
  },
  {
    label: 'Beneficjenci',
    organization_roles: [ORGANIZATION_ADMINISTRATOR, ORGANIZATION_EMPLOYEE],
    key: 'beneficjenci',
    tabView: OrganizationBeneficiaries,
  },
  {
    label: 'Programy',
    organization_roles: [ORGANIZATION_ADMINISTRATOR, ORGANIZATION_EMPLOYEE],
    user_roles: [PROGRAM_GUARDIAN],
    key: 'programy',
    tabView: OrganizationPrograms,
  },
];

export const userSettingsTabs = [
  { label: 'Twoje Konto', key: 'twoje-konto', icon: PersonIcon, tabView: UserInfo },
  { label: 'Pulpit', key: 'pulpit', icon: DashboardIcon, tabView: UserDesktop },
];
