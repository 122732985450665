// React
import React from 'react';

// Import
import slugify from 'slugify';

// Data
import { reportTypes } from 'views/Dashboard/Reports/Reports.data';

// Elements
import { Grid, List, CardContent } from '@material-ui/core';
import Heading from 'components/atoms/Heading/Heading';

// Styles
import {
  StyledWrapper,
  StyledTitle,
  StyledListItem,
  StyledTitleText,
  StyledLink,
  StyledCard,
  StyledIconWrapper,
} from './Reports.styles';

// Component
const Reports = () => {
  return (
    <>
      <StyledWrapper container spacing={2}>
        {reportTypes.map(({ id, label, color, icon, items }) => (
          <Grid key={id} item xs={4}>
            <StyledCard>
              <StyledTitle color={color}>
                <StyledIconWrapper>{icon}</StyledIconWrapper>
                <StyledTitleText>{label}</StyledTitleText>
              </StyledTitle>
              <CardContent>
                <List>
                  {items.map((item) => (
                    <StyledListItem key={item.id} color={color}>
                      <Heading.Body1>
                        <StyledLink
                          to={`/raporty-stworz/${slugify(item.label, {
                            lower: true,
                            locale: 'pl',
                            trim: true,
                          })}`}
                        >
                          {item.label}
                        </StyledLink>
                      </Heading.Body1>
                    </StyledListItem>
                  ))}
                </List>
              </CardContent>
            </StyledCard>
          </Grid>
        ))}
      </StyledWrapper>
    </>
  );
};

Reports.propTypes = {};

export default Reports;
