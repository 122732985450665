// Assets
import HomeIcon from '@material-ui/icons/Home';

// Styles
import styled from 'styled-components';

export const StyledWrapper = styled.div`
  && {
    background-color: ${({ theme }) => theme.color.white};
    min-height: calc(100% - 10rem);
  }
`;

export const StyledHomeIcon = styled(HomeIcon)`
  && {
    transition: 0.3s;

    &:focus,
    & {
      fill: ${({ theme }) => theme.color.darkGray};
    }

    &:hover {
      fill: ${({ theme }) => theme.color.gray};
    }
  }
`;
