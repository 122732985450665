// React
import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Breadcrumbs
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';

// Formik
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import {
  COORDINATOR,
  PROGRAM_COORDINATOR,
  ORGANIZATION_ADMINISTRATOR,
  PROGRAM_GUARDIAN,
  TASK_COORDINATOR,
} from 'constants/roles';

// Services
import {
  fetchIndicator as fetchIndicatorService,
  editIndicator as editIndicatorService,
  removeIndicator as removeIndicatorService,
} from 'services/indicatorServices';
// import { fetchOrganizationList as fetchOrganizationListService } from 'services/organizationServices';
import {
  fetchProgramsListSelect as fetchProgramsListService,
  fetchProgramTasks as fetchProgramTasksService,
} from 'services/programServices';
import {
  fetchTaskActions as fetchTaskActionsService,
  fetchProgramIdByTask as fetchProgramIdByTaskService,
} from 'services/taskServices';

// Actions
// import { clearOrganizations as clearOrganizationsAction } from 'actions/organizationActions';
import {
  clearTaskActions as clearTaskActionsAction,
  clearTaskProgramId as clearTaskProgramIdAction,
} from 'actions/taskActions';
import {
  clearProgramTasks as clearProgramTasksAction,
  clearPrograms as clearProgramsAction,
} from 'actions/programActions';
import { clearIndicatorAction } from 'actions/indicatorActions';

// Data
import {
  agregationLevel,
  indicatorType,
  indicatorCategory,
  indicatorKind,
  indicatorNumericalType,
  indicatorTimePeriod,
} from 'constants/selectLists';

// Assets
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

// Elements
import { Grid } from '@material-ui/core';
import Input from 'components/atoms/Input/Input';
import EditButton from 'components/atoms/EditButton/EditButton';
import Select from 'components/atoms/Select/Select';
import GuardedComponent from 'components/molecules/GuardedComponent/GuardedComponent';
import Heading from 'components/atoms/Heading/Heading';
import DialogConfirm from 'components/templates/DialogConfirm/DialogConfirm';

// Styles
import { StyledTitle, StyledWrapper, StyledHeading, StyledButton } from './EditIndocator.styles';

// Component
const EditIndicator = ({
  clearTaskProgramId,
  fetchProgramIdByTask,
  program_id_by_task,
  clearPrograms,
  fetchProgramsList,
  programs,
  clearProgramTasks,
  fetchProgramTasks,
  tasks,
  clearTaskActions,
  fetchTaskActions,
  actions,
  clearIndicator,
  fetchIndicator,
  details,
  editIndicator,
  removeIndicator,
}) => {
  const [isEditable, setIsEditable] = useState(false);
  const { id, program_id, task_id } = useParams();
  const history = useHistory();
  const [openDialogConfirmRemove, setOpenDialogConfirmRemove] = useState(false);

  useEffect(() => {
    clearIndicator();
    clearPrograms();
    clearProgramTasks();
    clearProgramTasks();
    clearTaskActions();
    clearTaskProgramId();
    clearProgramTasks();
  }, []);

  const triggerFetchList = useCallback(() => {
    return fetchProgramsList();
  }, [fetchProgramsList]);

  useEffect(() => {
    triggerFetchList();
  }, [triggerFetchList]);

  const triggerFetchIndicator = useCallback(
    (myid) => {
      if (myid !== undefined) {
        return fetchIndicator(myid);
      }

      setIsEditable(true);

      return true;
    },
    [fetchIndicator, clearIndicator],
  );

  useEffect(() => {
    triggerFetchIndicator(id);
  }, [id, triggerFetchIndicator]);

  useEffect(() => {
    if (details.program_id > 0) {
      fetchProgramTasks(details.program_id);
    }
  }, [details.program_id, fetchProgramTasks]);

  useEffect(() => {
    if (program_id > 0) {
      fetchProgramTasks(program_id);
    }
  }, [program_id, fetchProgramTasks]);

  useEffect(() => {
    if (details.task_id > 0) {
      fetchTaskActions(details.task_id);
    }
  }, [details.task_id, fetchTaskActions]);

  useEffect(() => {
    if (task_id > 0) {
      fetchProgramIdByTask(task_id);
      fetchTaskActions(task_id);
    }
  }, [task_id, fetchTaskActions, fetchProgramIdByTask]);

  useEffect(() => {
    if (program_id_by_task > 0) {
      fetchProgramTasks(program_id_by_task);
    }
  }, [program_id_by_task, fetchProgramTasks]);

  let param_agregationLevel = null;

  if (program_id) {
    param_agregationLevel = 'INDICATOR_PROGRAM';
  } else if (task_id) {
    param_agregationLevel = 'INDICATOR_TASK';
  }

  return (
    <>
      {details && id && (
        <BreadcrumbsItem
          to={`/wskazniki/${id}`}
          order={4}
        >{`Wskaźnik: ${details.indicator_name}`}</BreadcrumbsItem>
      )}
      <Formik
        initialValues={{
          name: details.indicator_name,
          indicatorType: details.indicator_type,
          timePeriod: details.indicator_time_period,
          category: details.indicator_category,
          kind: details.indicator_kind,
          description: details.description ? details.description : '',
          agregationLevel: details.agregationLevel
            ? details.agregationLevel
            : param_agregationLevel,
          programId: details.program_id || program_id || program_id_by_task,
          organization: details.organization,
          taskId: details.task_id || task_id,
          actionId: details.action_id,
          typeDetails: details.typeDetails
            ? details.typeDetails
            : {
                select: [],
                numerical: {
                  from: PropTypes.number,
                  to: PropTypes.number,
                  type: PropTypes.number,
                },
              },
          measurementNumber: details.planned_number_of_measurements
            ? details.planned_number_of_measurements
            : '',
        }}
        enableReinitialize
        validationSchema={Yup.object({
          name: Yup.string()
            .min(1, 'Minimum 1 znaki')
            .max(255, 'Maksymalnie 255 znaków')
            .required('pole wymagane!'),
          kind: Yup.string().required('pole wymagane!'),
          category: Yup.string().required('pole wymagane!'),
          indicatorType: Yup.string()
            .min(2, 'Minimum 2 znaki')
            .max(255, 'Maksymalnie 255 znaków')
            .required('pole wymagane!'),
          agregationLevel: Yup.string()
            .min(2, 'Minimum 2 znaki')
            .max(255, 'Maksymalnie 255 znaków')
            .nullable()
            .required('pole wymagane!'),
          programId: Yup.number()
            .required('pole wymagane!')
            .nullable()
            .test('Is positive?', 'pole wymagane!', (value) => value > 0),
          description: Yup.string()
            .min(2, 'Minimum 2 znaki')
            .max(1000, 'Maksymalnie 1000 znaków')
            .required('pole wymagane!'),
        })}
        onSubmit={(values) => {
          editIndicator(id !== undefined ? id : 'new', values).then((data) => {
            if (data && id === undefined && data.data.id) {
              triggerFetchIndicator(data.data.id);

              history.push(`/wskazniki/${data.data.id}`);
            }
          });
        }}
      >
        {({ values, handleChange, setFieldValue, handleSubmit }) => (
          <>
            <StyledTitle>
              <Heading.Subtitle1>Informacje podstawowe</Heading.Subtitle1>
              {id !== undefined && (
                <GuardedComponent
                  allowed_user_roles={[COORDINATOR, PROGRAM_GUARDIAN, ORGANIZATION_ADMINISTRATOR]}
                  program_id={
                    details.program_id || program_id || program_id_by_task || values.programId
                  }
                  allowed_program_roles={[PROGRAM_COORDINATOR, TASK_COORDINATOR]}
                  organization_id={details.organization_id}
                  allowed_organization_roles={[ORGANIZATION_ADMINISTRATOR]}
                >
                  <EditButton isEditable={isEditable || false} setIsEditable={setIsEditable} />
                </GuardedComponent>
              )}
            </StyledTitle>
            <StyledWrapper container spacing={2}>
              <Grid item xs={6}>
                <Input
                  disabled={!isEditable || false}
                  label="Nazwa wskaźnika"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  variant="filled"
                  type="text"
                  className="pogrubiona_nazwa"
                />
                <ErrorMessage name="name">
                  {(msg) => <div className="error-txt">{msg}</div>}
                </ErrorMessage>
              </Grid>
              <Grid item xs={6}>
                <Select
                  disabled={!isEditable || false}
                  label="Typ wskaźnika"
                  data={indicatorCategory}
                  text="category"
                  selectValue={values.category}
                  onChange={setFieldValue}
                  variant="filled"
                />
                <ErrorMessage name="category">
                  {(msg) => <div className="error-txt">{msg}</div>}
                </ErrorMessage>
              </Grid>
              <Grid item xs={6}>
                <Select
                  disabled={!isEditable || false}
                  label="Rodzaj wskaźnika"
                  data={indicatorKind}
                  text="kind"
                  selectValue={values.kind}
                  onChange={setFieldValue}
                  variant="filled"
                />
                <ErrorMessage name="kind">
                  {(msg) => <div className="error-txt">{msg}</div>}
                </ErrorMessage>
              </Grid>
              <Grid item xs={12}>
                <Input
                  disabled={!isEditable || false}
                  label="Opis wskaźnika"
                  multiline
                  rows={8}
                  name="description"
                  value={values.description}
                  onChange={handleChange}
                  variant="filled"
                  type="text"
                  setField={setFieldValue}
                  charCounter={1000}
                />
                <ErrorMessage name="description">
                  {(msg) => <div className="error-txt">{msg}</div>}
                </ErrorMessage>
              </Grid>
              <Grid item xs={12}>
                <Heading.Subtitle1>Agregacja</Heading.Subtitle1>
              </Grid>
              <Grid item xs={6}>
                <Select
                  disabled={!isEditable || false}
                  label="Przypisanie"
                  text="agregationLevel"
                  data={agregationLevel}
                  selectValue={values.agregationLevel}
                  onChange={setFieldValue}
                  variant="filled"
                />
                <ErrorMessage name="agregationLevel">
                  {(msg) => <div className="error-txt">{msg}</div>}
                </ErrorMessage>
              </Grid>
              <Grid item xs={6}>
                {programs.length ? (
                  <Select
                    disabled={!isEditable || false}
                    label="Wybierz Program"
                    data={programs.map((program) => {
                      return { value: program.id, name: program.name };
                    })}
                    variant="filled"
                    text="programId"
                    selectValue={values.programId}
                    onChange={(field, value) => {
                      setFieldValue(field, value);
                      setFieldValue('taskId', null);
                      setFieldValue('actionId', null);
                      fetchProgramTasks(value);
                    }}
                  />
                ) : (
                  <Select
                    disabled={!isEditable || false}
                    label="Wybierz Program"
                    variant="filled"
                  />
                )}
                <ErrorMessage name="programId">
                  {(msg) => <div className="error-txt">{msg}</div>}
                </ErrorMessage>
              </Grid>
              <Grid item xs={6}>
                {tasks.length ? (
                  <Select
                    label="Wybierz Zadanie"
                    data={tasks.map((task) => {
                      return { value: task.id, name: task.task_name };
                    })}
                    selectValue={values.taskId}
                    variant="filled"
                    text="taskId"
                    onChange={(field, value) => {
                      setFieldValue(field, value);
                      setFieldValue('actionId', null);
                      fetchTaskActions(value);
                    }}
                    disabled={
                      !isEditable ||
                      false ||
                      !values.programId ||
                      (values.agregationLevel !== 'INDICATOR_TASK' &&
                        values.agregationLevel !== 'INDICATOR_ACTION')
                    }
                  />
                ) : (
                  <Select
                    label="Wybierz Zadanie"
                    variant="filled"
                    text="taskId"
                    disabled={
                      !isEditable ||
                      false ||
                      !values.programId ||
                      (values.agregationLevel !== 'INDICATOR_TASK' &&
                        values.agregationLevel !== 'INDICATOR_ACTION')
                    }
                  />
                )}
              </Grid>
              <Grid item xs={6}>
                {actions.length ? (
                  <Select
                    label="Wybierz Działanie"
                    data={actions.map((action) => {
                      return { value: action.id, name: action.name };
                    })}
                    selectValue={values.actionId}
                    text="actionId"
                    onChange={setFieldValue}
                    variant="filled"
                    disabled={
                      !isEditable ||
                      false ||
                      !values.taskId ||
                      values.agregationLevel !== 'INDICATOR_ACTION'
                    }
                  />
                ) : (
                  <Select
                    label="Wybierz Działanie"
                    variant="filled"
                    disabled={
                      !isEditable ||
                      false ||
                      !values.taskId ||
                      values.agregationLevel !== 'INDICATOR_ACTION'
                    }
                  />
                )}
              </Grid>
              <Grid item xs={6}>
                <StyledHeading>Ustaw parametry wskaźnika</StyledHeading>
                <Select
                  disabled={!isEditable || false}
                  label="Rodzaj pomiaru"
                  text="indicatorType"
                  data={indicatorType}
                  selectValue={values.indicatorType}
                  onChange={setFieldValue}
                  variant="filled"
                />
              </Grid>
              {values.indicatorType === 'INDICATOR_SELECT' && (
                <Grid item container xs={6} spacing={2}>
                  <StyledHeading>Wartości pola wyboru</StyledHeading>
                  {values.typeDetails.select.map((el, index) => {
                    return (
                      <Grid key={el.id} item container xs={12} spacing={2}>
                        <Grid item xs={4}>
                          <Input
                            label="Wartość"
                            variant="filled"
                            type="text"
                            onChange={handleChange}
                            name={`typeDetails.select[${index}].value`}
                            value={values.typeDetails.select[index].value}
                            disabled={!isEditable || false}
                          />
                        </Grid>
                        <Grid item xs={7}>
                          <Input
                            label="Opis"
                            variant="filled"
                            type="text"
                            onChange={handleChange}
                            name={`typeDetails.select[${index}].name`}
                            value={values.typeDetails.select[index].name}
                            disabled={!isEditable || false}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <StyledButton
                            startIcon={<RemoveCircleIcon />}
                            disabled={!isEditable || false}
                            onClick={() => {
                              values.typeDetails.select.splice(index, 1);
                              setFieldValue('typeDetails.select', values.typeDetails.select);
                            }}
                          >
                            usuń
                          </StyledButton>
                        </Grid>
                      </Grid>
                    );
                  })}
                  <Grid item xs={12}>
                    <StyledButton
                      startIcon={<AddCircleIcon />}
                      disabled={!isEditable || false}
                      onClick={() => {
                        values.typeDetails.select.push({
                          value: '',
                          name: '',
                          id: values.typeDetails.select.length + 1,
                        });
                        setFieldValue('typeDetails.select', values.typeDetails.select);
                      }}
                    >
                      Dodaj kolejny
                    </StyledButton>
                  </Grid>
                </Grid>
              )}
              {values.indicatorType === 'INDICATOR_NUMERICAL' && (
                <>
                  <Grid item xs={2}>
                    <StyledHeading>Zakres</StyledHeading>
                    <Input
                      label="Od"
                      variant="filled"
                      type="text"
                      name="typeDetails.numerical.from"
                      value={values.typeDetails.numerical.from}
                      onChange={handleChange}
                      disabled={!isEditable || false}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <StyledHeading>
                      <br />
                    </StyledHeading>
                    <Input
                      label="Do"
                      variant="filled"
                      type="text"
                      name="typeDetails.numerical.to"
                      value={values.typeDetails.numerical.to}
                      onChange={handleChange}
                      disabled={!isEditable || false}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <StyledHeading>Wartości</StyledHeading>
                    <Select
                      label="rodzaj"
                      data={indicatorNumericalType}
                      variant="filled"
                      onChange={setFieldValue}
                      text="typeDetails.numerical.type"
                      selectValue={values.typeDetails.numerical.type}
                      disabled={!isEditable || false}
                    />
                  </Grid>
                </>
              )}
              {values.indicatorType === 'INDICATOR_YESNO' && <Grid item xs={6} />}
              <Grid item xs={6}>
                <Select
                  label="Przedział czasowy pomiaru"
                  data={indicatorTimePeriod}
                  selectValue={values.timePeriod}
                  text="timePeriod"
                  onChange={setFieldValue}
                  variant="filled"
                  disabled={!isEditable || false}
                />
              </Grid>
              <Grid item xs={4}>
                <Input
                  disabled={!isEditable || false}
                  label="Liczba pomiarów"
                  name="measurementNumber"
                  value={values.measurementNumber}
                  onChange={handleChange}
                  variant="filled"
                  type="text"
                />
              </Grid>
              <Grid item container justifyContent="flex-end" xs={12}>
                <StyledButton onClick={() => history.push(`/wskazniki`)}>WRÓĆ</StyledButton>

                <GuardedComponent
                  allowed_user_roles={[COORDINATOR, PROGRAM_GUARDIAN, ORGANIZATION_ADMINISTRATOR]}
                  program_id={
                    details.program_id || program_id || program_id_by_task || values.programId
                  }
                  allowed_program_roles={[PROGRAM_COORDINATOR, TASK_COORDINATOR]}
                  organization_id={details.organization_id}
                  allowed_organization_roles={[ORGANIZATION_ADMINISTRATOR]}
                >
                  <StyledButton
                    disabled={!isEditable || false}
                    variant="outlined"
                    onClick={handleSubmit}
                  >
                    ZAPISZ
                  </StyledButton>
                </GuardedComponent>

                {id !== undefined && (
                  <GuardedComponent
                    allowed_user_roles={[COORDINATOR, PROGRAM_GUARDIAN, ORGANIZATION_ADMINISTRATOR]}
                    program_id={
                      details.program_id || program_id || program_id_by_task || values.programId
                    }
                    allowed_program_roles={[PROGRAM_COORDINATOR, TASK_COORDINATOR]}
                    organization_id={details.organization_id}
                    allowed_organization_roles={[ORGANIZATION_ADMINISTRATOR]}
                  >
                    <StyledButton
                      disabled={!isEditable || false}
                      variant="outlined"
                      color="secondary"
                      onClick={() => setOpenDialogConfirmRemove(true)}
                    >
                      USUŃ WSKAŹNIK
                    </StyledButton>
                  </GuardedComponent>
                )}
              </Grid>
              <DialogConfirm
                removeFunction={() =>
                  removeIndicator(id).then(() => {
                    history.push(`/wskazniki`);
                  })
                }
                title="Wskaźnik zawiera dane! Usunięcie wskaźnika spowoduje również usunięcie wszystkich danych powiązanych z tym wskaźnikiem!"
                open={openDialogConfirmRemove}
                setOpenFn={setOpenDialogConfirmRemove}
              />
            </StyledWrapper>
          </>
        )}
      </Formik>
    </>
  );
};

EditIndicator.propTypes = {
  clearTaskProgramId: PropTypes.func,
  fetchProgramIdByTask: PropTypes.func,
  program_id_by_task: PropTypes.number,
  clearPrograms: PropTypes.func,
  fetchProgramsList: PropTypes.func,
  removeIndicator: PropTypes.func,
  programs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ),
  clearProgramTasks: PropTypes.func,
  fetchProgramTasks: PropTypes.func,
  tasks: PropTypes.arrayOf(PropTypes.any),
  clearTaskActions: PropTypes.func,
  fetchTaskActions: PropTypes.func,
  actions: PropTypes.arrayOf(PropTypes.any),
  // clearOrganizations: PropTypes.func,
  // fetchOrganizationList: PropTypes.func,
  clearIndicator: PropTypes.func,
  fetchIndicator: PropTypes.func,
  details: PropTypes.shape({
    agregationLevel: PropTypes.string,
    description: PropTypes.string,
    organization: PropTypes.number,
    indicator_category: PropTypes.string,
    indicator_id: PropTypes.number,
    indicator_name: PropTypes.string,
    indicator_time_period: PropTypes.string,
    indicator_type: PropTypes.string,
    indicator_kind: PropTypes.string,
    planned_number_of_measurements: PropTypes.number,
    organization_id: PropTypes.number,
    program_id: PropTypes.number,
    program_name: PropTypes.string,
    task_id: PropTypes.number,
    task_name: PropTypes.string,
    action_id: PropTypes.number,
    action_name: PropTypes.string,
    typeDetails: PropTypes.shape({
      select: PropTypes.arrayOf(PropTypes.any),
      numerical: PropTypes.oneOfType([
        PropTypes.shape({
          from: PropTypes.string,
          to: PropTypes.string,
          type: PropTypes.number,
        }),
        PropTypes.array,
      ]),
    }),
  }),
  editIndicator: PropTypes.func,
};

EditIndicator.defaultProps = {
  clearTaskProgramId: null,
  fetchProgramIdByTask: null,
  program_id_by_task: null,
  clearPrograms: null,
  fetchProgramsList: null,
  removeIndicator: null,
  programs: [],
  clearProgramTasks: null,
  fetchProgramTasks: null,
  tasks: [],
  clearTaskActions: null,
  fetchTaskActions: null,
  actions: [],
  // clearOrganizations: null,
  // fetchOrganizationList: null,
  clearIndicator: null,
  fetchIndicator: null,
  details: {
    agregationLevel: '',
    description: '',
    organization: null,
    indicator_category: null,
    indicator_kind: null,
    indicator_id: null,
    indicator_name: '',
    indicator_time_period: '',
    indicator_type: '',
    planned_number_of_measurements: null,
    organization_id: null,
    program_id: null,
    program_name: '',
    task_id: null,
    task_name: '',
    action_id: null,
    action_name: '',
    typeDetails: null,
  },
  editIndicator: null,
};

const mapStateToProps = ({
  indicatorReducer,
  programReducer,
  // organizationReducer,
  taskReducer,
}) => ({
  program_id_by_task: taskReducer.program_id_by_task,
  programs: programReducer.programs,
  tasks: programReducer.tasks,
  actions: taskReducer.actions,
  // organizations: organizationReducer.organizations,
  details: indicatorReducer.indicator,
});

const mapDispatchToProps = (dispatch) => ({
  removeIndicator: (id) => dispatch(removeIndicatorService(id)),
  clearTaskProgramId: () => dispatch(clearTaskProgramIdAction()),
  fetchProgramIdByTask: (id) => dispatch(fetchProgramIdByTaskService(id)),
  clearPrograms: () => dispatch(clearProgramsAction()),
  fetchProgramsList: () => dispatch(fetchProgramsListService()),
  clearProgramTasks: () => dispatch(clearProgramTasksAction()),
  fetchProgramTasks: (id) => dispatch(fetchProgramTasksService(id)),
  clearTaskActions: () => dispatch(clearTaskActionsAction()),
  fetchTaskActions: (id) => dispatch(fetchTaskActionsService(id)),
  // clearOrganizations: () => dispatch(clearOrganizationsAction()),
  // fetchOrganizationList: () => dispatch(fetchOrganizationListService()),
  clearIndicator: () => dispatch(clearIndicatorAction()),
  fetchIndicator: (id) => dispatch(fetchIndicatorService(id)),
  editIndicator: (id, data) => dispatch(editIndicatorService(id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditIndicator);
