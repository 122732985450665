// React
import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';

// Axios
import axiosInstance from 'utils/axios';

// Services
import { fetchTaskSurveysList as fetchTaskSurveysListService } from 'services/taskServices';

// Actions
import { clearTaskSurveys as clearTaskSurveysAction } from 'actions/taskActions';
import { showAlert as showAlertAction } from 'actions/appActions';

// Data
import { filterDiagnosis } from 'constants/tableFilters';
import { SURVEYS_NOT_COMPLETE, SURVEYS_COMPLETE } from 'constants/alerts';

// PDF
import {
  generateBeneficiaryEmptySurveyPDF,
  generateBeneficiaryInstructionSurveyPDF,
  generateBeneficiarySurveysPDF,
} from 'utils/functions';

// Elements
import TableTemplate from 'templates/TableTemplate';
import FloatingButton from 'components/atoms/FloatingButton/FloatingButton';

// Data
const headCells = [
  { type: 'beneficiary_name', label: 'Imię' },
  { type: 'beneficiary_surname', label: 'Nazwisko' },
  { type: 'title', label: 'Nazwa' },
  { type: 'measurement_number', label: 'Pomiar' },
  { type: 'author', label: 'Prowadzący' },
  { type: 'add_date', label: 'Data dodania' },
  { type: 'program', label: 'Program' },
  { type: 'status', label: 'Status' },
];

const optionsSurveys = [
  // {
  //   name: 'Pusta ankieta PDF [0-6]',
  //   action: () => generateBeneficiaryEmptySurveyPDF('CHILD_EVALUATION_QUESTIONNAIRE_0_6'),
  // },
  {
    name: 'Pusta ankieta PDF [6-12]',
    action: () => generateBeneficiaryEmptySurveyPDF('CHILD_EVALUATION_QUESTIONNAIRE_6_12'),
  },
  {
    name: 'Pusta ankieta PDF [13-18]',
    action: () => generateBeneficiaryEmptySurveyPDF('CHILD_EVALUATION_QUESTIONNAIRE_13_18'),
  },
  {
    name: 'Instrukcja PDF',
    action: () => generateBeneficiaryInstructionSurveyPDF(),
  },
];

// Component
const TasksSurveys = ({ clearTaskSurveys, fetchSurveys, surveys, showAlert }) => {
  const history = useHistory();

  useEffect(() => {
    clearTaskSurveys();
  }, []);

  const triggerFetchSurveys = useCallback(() => {
    return fetchSurveys();
  }, [fetchSurveys]);

  useEffect(() => {
    triggerFetchSurveys();
  }, [triggerFetchSurveys]);

  return (
    <>
      <TableTemplate
        headCells={headCells}
        data={surveys}
        link="/ankieta"
        tableName="Ankiety"
        navCells
        filters={filterDiagnosis}
        actions={optionsSurveys}
        rowActions={[
          {
            name: 'wyniki',
            action: (row) => {
              if (row.status === 'Zakończona') {
                history.push(`/ankieta-wynik/${row.id}`);
              } else {
                showAlert(SURVEYS_NOT_COMPLETE);
              }
            },
          },
          {
            name: 'zobacz',
            action: (row) => {
              if (row.status === 'Zakończona') {
                showAlert(SURVEYS_COMPLETE);
              } else {
                history.push(`/ankieta/${row.id}`);
              }
            },
          },
          {
            name: 'edytuj',
            action: (row) => {
              if (row.status === 'Zakończona') {
                showAlert(SURVEYS_COMPLETE);
              } else {
                history.push(`/ankieta/${row.id}/edit`);
              }
            },
          },
          {
            name: 'pobierz pdf',
            action: async (row) => {
              if (row.status === 'Zakończona') {
                axiosInstance.get(`/ankieta/${row.id}`).then(({ data }) => {
                  generateBeneficiarySurveysPDF(data.data);
                });
              } else {
                showAlert(SURVEYS_NOT_COMPLETE);
              }
            },
          },
        ]}
      />
      <FloatingButton onClick={() => history.push(`/ankieta-dodaj`)} />
    </>
  );
};

TasksSurveys.propTypes = {
  clearTaskSurveys: PropTypes.func,
  fetchSurveys: PropTypes.func,
  surveys: PropTypes.arrayOf(PropTypes.any),
  showAlert: PropTypes.func,
};

TasksSurveys.defaultProps = {
  clearTaskSurveys: null,
  fetchSurveys: null,
  showAlert: null,
  surveys: [],
};

const mapStateToProps = ({ taskReducer }) => ({
  surveys: taskReducer.surveys,
});

const mapDispatchToProps = (dispatch) => ({
  clearTaskSurveys: () => dispatch(clearTaskSurveysAction()),
  fetchSurveys: () => dispatch(fetchTaskSurveysListService()),
  showAlert: (data) => dispatch(showAlertAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TasksSurveys);
