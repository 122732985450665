import Button from 'components/atoms/Button/Button';
import styled from 'styled-components';
import Heading from 'components/atoms/Heading/Heading';
import { Grid } from '@material-ui/core';

export const StyledWrapper = styled(Grid)`
  && {
    margin-top: 1rem;
    max-width: 50%;
  }
`;

export const StyledButton = styled(Button)`
  && {
    padding: 1rem 2rem;
    margin-left: 1.5rem;
  }
`;

export const StyledHeading = styled(Heading.Subtitle1)`
  && {
    margin-bottom: 1rem;
  }
`;

export const StyledTitle = styled.div`
  && {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 6rem;
    padding-right: 1px;
  }
`;
