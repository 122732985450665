// Redux
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Breadcrumbs
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';

// Services
import {
  fetchBeneficiary as fetchBeneficiaryService,
  fetchBeneficiaryProgramsSimple as fetchBeneficiaryProgramsService,
} from 'services/beneficiaryServices';

// Actions
import {
  clearBeneficiaryProgramsSimple as clearBeneficiaryProgramsSimpleAction,
  clearBeneficiaries as clearBeneficiariesAction,
  clearBeneficiaryAction,
} from 'actions/beneficiaryActions';

// Data
import { beneficiaryTabs as renderViewArray } from 'constants/tabs';

// Elements
import Tabs from 'components/molecules/Tabs/Tabs';

// Component
const BeneficiaryTabs = ({
  clearBeneficiaries,
  clearBeneficiary,
  fetchBeneficiary,
  details,
  clearBeneficiaryProgramsSimple,
  fetchPrograms,
  beneficiary_programs,
}) => {
  const { id } = useParams();

  useEffect(() => {
    clearBeneficiary();
    clearBeneficiaries();
    clearBeneficiaryProgramsSimple();
  }, []);

  useEffect(() => {
    fetchBeneficiary(id);
  }, [id, fetchBeneficiary]);

  useEffect(() => {
    fetchPrograms(id);
  }, [id, fetchPrograms]);

  return (
    <>
      {details.id && (
        <BreadcrumbsItem to={`/beneficjenci/${id}`} order={4}>
          {`Beneficjent: ${details.name} ${details.surname}`}
        </BreadcrumbsItem>
      )}
      <Tabs
        renderViewArray={renderViewArray}
        program_ids={beneficiary_programs.map((program) => program.programId)}
      />
    </>
  );
};

BeneficiaryTabs.propTypes = {
  clearBeneficiaries: PropTypes.func,
  fetchBeneficiary: PropTypes.func,
  details: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    surname: PropTypes.string,
  }),
  clearBeneficiary: PropTypes.func,
  clearBeneficiaryProgramsSimple: PropTypes.func,
  fetchPrograms: PropTypes.func,
  beneficiary_programs: PropTypes.arrayOf(PropTypes.any),
  me: PropTypes.shape({
    user_role: PropTypes.arrayOf(PropTypes.string),
    user_programs: PropTypes.arrayOf(PropTypes.any),
  }),
};

BeneficiaryTabs.defaultProps = {
  clearBeneficiaries: null,
  fetchBeneficiary: null,
  details: null,
  clearBeneficiaryProgramsSimple: null,
  clearBeneficiary: null,
  fetchPrograms: null,
  beneficiary_programs: [],
  me: {
    user_role: [],
    user_programs: [],
  },
};

const mapStateToProps = ({ beneficiaryReducer, userReducer }) => ({
  details: beneficiaryReducer.beneficiary,
  beneficiary_programs: beneficiaryReducer.programs_simple,
  me: userReducer.me,
});

const mapDispatchToProps = (dispatch) => ({
  clearBeneficiaries: () => dispatch(clearBeneficiariesAction()),
  fetchBeneficiary: (id) => dispatch(fetchBeneficiaryService(id)),
  clearBeneficiaryProgramsSimple: () => dispatch(clearBeneficiaryProgramsSimpleAction()),
  clearBeneficiary: () => dispatch(clearBeneficiaryAction()),
  fetchPrograms: (id) => dispatch(fetchBeneficiaryProgramsService(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BeneficiaryTabs);
