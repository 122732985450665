// Elements
import { Grid } from '@material-ui/core';
import Button from 'components/atoms/Button/Button';

// Styles
import styled from 'styled-components';

export const StyledButton = styled(Button)`
  && {
    padding: ${({ padding }) => padding || '1rem 2.5rem'};
    margin: 1rem;
  }
`;

export const StyledWrapper = styled(Grid)`
  && {
    margin-top: 0rem;
    width: 100% !important;
  }
`;
