import {
  FETCH_INDICATOR,
  CLEAR_INDICATORS,
  SHOW_INDICATORS,
  CLEAR_INDICATOR,
} from 'constants/actionTypes';

const initialState = {
  indicators: [],
  indicator: {
    assigned_to: '',
    indicator_category: '',
    indicator_id: null,
    indicator_name: '',
    indicator_time_period: '',
    indicator_type: '',
    planned_number_of_measurements: null,
    program_id: null,
    program_name: '',
  },
};

const indicatorReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SHOW_INDICATORS:
      return { ...state, indicators: payload.data };
    case CLEAR_INDICATORS:
      return { ...state, indicators: initialState.indicators };
    case FETCH_INDICATOR:
      return { ...state, indicator: payload.data };
    case CLEAR_INDICATOR:
      return { ...state, indicator: initialState.indicator };
    default:
      return state;
  }
};

export default indicatorReducer;
