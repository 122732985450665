// React
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';

// Services
import {
  fetchBeneficiaryPrograms as fetchBeneficiaryProgramsService,
  saveConnectionWithProgram as saveConnectionWithProgramService,
  removeCWP as removeCWPService,
  endBeneficiaryParticipationInProgram as endBeneficiaryParticipationInProgramService,
} from 'services/beneficiaryServices';
import { fetchEducationalFacilitiesList as fetchEducationalFacilitiesListService } from 'services/selectServices';
import {
  removeBeneficiaryFromAction as removeBeneficiaryFromActionService,
  endBeneficiaryParticipationInAction as endBeneficiaryParticipationInActionService,
} from 'services/actionServices';

// Actions
import { clearEducationalFacilitiesList as clearEducationalFacilitiesListAction } from 'actions/selectActions';
import { clearBeneficiaryPrograms as clearBeneficiaryProgramsAction } from 'actions/beneficiaryActions';

// Data
import { beneficiaryType } from 'constants/selectLists';

// Formik
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';

// Moment
import * as moment from 'moment';

// Elements
import { Box, Grid } from '@material-ui/core';
import EditButton from 'components/atoms/EditButton/EditButton';
import MyAccordion from 'components/atoms/Accordion/Accordion';
import FloatingButton from 'components/atoms/FloatingButton/FloatingButton';
import Heading from 'components/atoms/Heading/Heading';
import TableTemplate from 'templates/TableTemplate';
import DialogConfirm from 'components/templates/DialogConfirm/DialogConfirm';
import Select from 'components/atoms/Select/Select';
import Calendar from 'components/atoms/Calendar/Calendar';
import Autocomplete from 'components/atoms/Autocomplete/Autocomplete';
import Checkbox from 'components/atoms/Checkbox/Checkbox';

// Styles
import {
  StyledWrapper,
  StyledButton,
  StyledTextWrapper,
  StyledEditWrapper,
  StyledBox,
} from './BeneficiaryPrograms.styles';
import './Programs.css';
import './BeneficiaryProgram.scss';

// Data
const headCells = [
  { type: 'action_name', label: 'Nazwa Działania', id: 'cwa_id' },
  { type: 'action_type', label: 'Typ Działania' },
  { type: 'description', label: 'Opis Działania' },
  { type: 'lecturer', label: 'Prowadzący' },
  { type: 'date_start', label: 'Data rozpoczęcia' },
  { type: 'date_end', label: 'Data zakończenia' },
  { type: 'hours_number', label: 'Godziny zaplanowane' },
  { type: 'modified_by', label: 'Modyfikowane przez' },
];

// Component
const BeneficiaryPrograms = ({
  clearBeneficiaryPrograms,
  fetchPrograms,
  related_programs,
  clearEducationalFacilitiesList,
  fetchEducationalFacilitiesList,
  educationalFacilitiesList,
  beneficiary,
  saveConnectionWithProgram,
  removeBeneficiaryFromAction,
  endBeneficiaryParticipationInAction,
  endBeneficiaryParticipationInProgram,
  removeCWP,
}) => {
  const [currentActionToRemove, setCurrentActionToRemove] = useState(null);
  const [openDialogConfirmRemoveAction, setOpenDialogConfirmRemoveAction] = useState(false);

  const [dateEnd, setDateEnd] = useState(moment().format('YYYY-MM-DDD'));

  const [currentActionToEnd, setCurrentActionToEnd] = useState(null);
  const [openDialogConfirmEndAction, setOpenDialogConfirmEndAction] = useState(false);

  const [currentProgramToEnd, setCurrentProgramToEnd] = useState(null);
  const [openDialogConfirmEndProgram, setOpenDialogConfirmEndProgram] = useState(false);

  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [currentCWP, setCurrentCWP] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [isActiveProgram, setActiveProgram] = useState(false);
  const [isEndProgram, setEndProgram] = useState(false);
  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    clearBeneficiaryPrograms();
    clearEducationalFacilitiesList();
  }, []);

  const triggerFetchPrograms = useCallback((myid) => fetchPrograms(myid), [fetchPrograms]);

  useEffect(() => {
    triggerFetchPrograms(id);
    fetchEducationalFacilitiesList();
  }, [id]);

  const showAccordion = (connection) =>
    !(
      (!isActiveProgram && !isEndProgram) ||
      (isActiveProgram &&
        connection.program_date_end &&
        moment().diff(new Date(connection.program_date_end)) < 0) ||
      (isEndProgram &&
        connection.program_date_end &&
        moment().diff(new Date(connection.program_date_end)) > 0)
    )
      ? 'true'
      : 'false';

  return (
    <div className="beneprogram">
      <StyledWrapper className="programs" container spacing={2}>
        <Grid item xs={2}>
          <Checkbox
            label="Aktualne"
            isEditable={!!true}
            value={isActiveProgram}
            onChange={() => setActiveProgram(!isActiveProgram)}
          />
        </Grid>
        <Grid item xs={2}>
          <Checkbox
            label="Zakończone"
            isEditable={!!true}
            value={isEndProgram}
            onChange={() => setEndProgram(!isEndProgram)}
          />
        </Grid>
        {beneficiary.name !== 'anonimizacja' && (
          <StyledEditWrapper item xs={8}>
            <EditButton isEditable={isEditable || false} setIsEditable={setIsEditable} />
          </StyledEditWrapper>
        )}
        {related_programs && related_programs.length ? (
          related_programs.map(({ programId, programType, actions, connection }) => {
            if (connection)
              return (
                <MyAccordion
                  key={programId}
                  title={`${connection.program_name} (${programType})`}
                  hide={showAccordion(connection)}
                >
                  {beneficiary && beneficiary.name === 'anonimizacja' ? (
                    <StyledTextWrapper>
                      <Heading.Subtitle1>Beneficjent został zanonimizwany</Heading.Subtitle1>
                    </StyledTextWrapper>
                  ) : (
                    <>
                      <StyledTextWrapper>
                        <Heading.Subtitle1>Działania w obrębie Programu</Heading.Subtitle1>
                      </StyledTextWrapper>
                      {actions && actions.length ? (
                        <TableTemplate
                          headCells={headCells}
                          isnav={false}
                          noFilter
                          data={actions}
                          tableName="Działania w obrębie Programu"
                          rowActions={
                            connection.was_anonymized
                              ? [
                                  {
                                    name: 'zobacz szczegóły działania',
                                    action: (row) => {
                                      history.push(`/dzialania/${row.action_id}`);
                                    },
                                  },
                                ]
                              : [
                                  {
                                    name: 'zobacz szczegóły działania',
                                    action: (row) => {
                                      history.push(`/dzialania/${row.action_id}`);
                                    },
                                  },
                                  {
                                    name: 'wypisz beneficjenta z działania',
                                    action: (row) => {
                                      setOpenDialogConfirmEndAction(true);
                                      setCurrentActionToEnd(row);
                                    },
                                  },
                                  {
                                    name: 'usuń beneficjenta z działania',
                                    action: (row) => {
                                      setOpenDialogConfirmRemoveAction(true);
                                      setCurrentActionToRemove(row);
                                    },
                                  },
                                ]
                          }
                        />
                      ) : (
                        <StyledTextWrapper>
                          <Heading.Body2>Brak danych</Heading.Body2>
                        </StyledTextWrapper>
                      )}
                    </>
                  )}
                  <Formik
                    initialValues={{
                      approvalToUseImage: connection.approvals.approvalToUseImage,
                      presidentsInformationClause: connection.approvals.presidentsInformationClause,
                      consortiumInformationClause: connection.approvals.consortiumInformationClause,
                      approvalToTransferOfInformation:
                        connection.approvals.approvalToTransferOfInformation,
                      approvalToIndependentReturnHome:
                        connection.approvals.approvalToIndependentReturnHome,
                      whoAssignedToProgram: connection.who_assigned,
                      dateAddedToProgram: connection.date_added || '',
                      dateEndedProgram: connection.date_ended || '',
                      dateAddedBySchoolToProgram: connection.date_added_by_school_to_program || '',
                      assignedToProgramBySchool: connection.assigned_by_school,
                      dateAddedByOpsToProgram: connection.date_added_by_ops_to_program || '',
                      assignedToProgramByOps: connection.assigned_by_ops,
                      dateAddedByCourtToProgram: connection.date_added_by_court_to_program || '',
                      assignedToProgramByCourt: connection.assigned_by_court,

                      beneficiaryType: connection.beneficiaryType || 1,
                    }}
                    validationSchema={Yup.object().shape({
                      dateAddedToProgram: Yup.string()
                        .required('To pole jest wymagane!')
                        .test(
                          'date_start',
                          'Data nie może być wcześniejsza niż start programu!',
                          (val) => val >= connection.program_date_start,
                        ),
                    })}
                    enableReinitialize
                    onSubmit={(values) => {
                      saveConnectionWithProgram(id, connection.connection_id, values);
                    }}
                  >
                    {({ values, handleChange, setFieldValue, handleSubmit }) => (
                      <>
                        <StyledBox>
                          <Grid container>
                            <Grid item xs={3}>
                              <Checkbox
                                value={values.approvalToUseImage}
                                isEditable={isEditable || false}
                                label="Zgoda na wykorzystanie wizerunku"
                                onChange={handleChange}
                                name="approvalToUseImage"
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <Checkbox
                                value={values.presidentsInformationClause}
                                isEditable={isEditable || false}
                                label="Czy Beneficjent zapoznał się z klauzulą informacyjną prezydenta"
                                onChange={handleChange}
                                name="presidentsInformationClause"
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <Checkbox
                                value={values.consortiumInformationClause}
                                isEditable={isEditable || false}
                                label="Czy Beneficjent zapoznał się z klauzulą informacyjną projektu"
                                onChange={handleChange}
                                name="consortiumInformationClause"
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <Checkbox
                                value={values.approvalToTransferOfInformation}
                                isEditable={isEditable || false}
                                label="Zgoda na wymianę informacji z innymi organizacjami na temat wykorzystywanego wsparcia"
                                onChange={handleChange}
                                name="approvalToTransferOfInformation"
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <Checkbox
                                value={values.approvalToIndependentReturnHome}
                                isEditable={isEditable || false}
                                label="Zgoda na samodzielny powrót do domu"
                                onChange={handleChange}
                                name="approvalToIndependentReturnHome"
                              />
                            </Grid>
                          </Grid>
                        </StyledBox>
                        <Grid container spacing={2} margin="4rem">
                          <Grid className="calendar-dodania" item xs={2}>
                            <Calendar
                              disabled={!isEditable || false}
                              label="Data dodania"
                              variant="filled"
                              disableFuture
                              name="dateAddedToProgram"
                              onChange={setFieldValue}
                              value={values.dateAddedToProgram}
                              minDate={connection.program_date_start}
                            />
                            <ErrorMessage name="dateAddedToProgram">
                              {(msg) => <div className="error-txt">{msg}</div>}
                            </ErrorMessage>
                          </Grid>
                          <Grid className="calendar-dodania" item xs={2}>
                            <Calendar
                              disabled={!values.dateEndedProgram || !isEditable || false}
                              label="Data zakończenia udziału"
                              variant="filled"
                              disableFuture
                              name="dateEndedProgram"
                              onChange={setFieldValue}
                              value={values.dateEndedProgram}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <Select
                              disabled={!isEditable || false}
                              label="Typ uczestnika"
                              data={beneficiaryType}
                              variant="filled"
                              name="beneficiaryType"
                              text="beneficiaryType"
                              selectValue={values.beneficiaryType}
                              onChange={setFieldValue}
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={2} style={{ marginBottom: '4rem' }}>
                          <Grid item xs={6} style={{ marginBottom: '2rem' }}>
                            {educationalFacilitiesList && (
                              <Autocomplete
                                disabled={!isEditable || false}
                                label="Szkoła"
                                city
                                options={educationalFacilitiesList}
                                variant="filled"
                                title="whoAssignedToProgram"
                                selectValue={values.whoAssignedToProgram}
                                setSelectValue={setFieldValue}
                              />
                            )}
                          </Grid>
                          <Grid item xs={6} />
                          <Grid item xs={3}>
                            <Checkbox
                              isEditable={isEditable || false}
                              label="Dziecko wytypowane do udziału w projekcie przez szkołę"
                              value={values.assignedToProgramBySchool}
                              name="assignedToProgramBySchool"
                              onChange={handleChange}
                            />
                          </Grid>
                          <Grid className="calendar-dodania" item xs={3}>
                            <Calendar
                              disabled={!isEditable || false}
                              label="Data wytypowania przez szkołę"
                              variant="filled"
                              disableFuture
                              name="dateAddedBySchoolToProgram"
                              onChange={setFieldValue}
                              value={values.dateAddedBySchoolToProgram}
                            />
                          </Grid>
                          <Grid item xs={6} />
                          <Grid item xs={3}>
                            <Checkbox
                              isEditable={isEditable || false}
                              label="Dziecko wytypowane do udziału w projekcie przez OPS"
                              value={values.assignedToProgramByOps}
                              name="assignedToProgramByOps"
                              onChange={handleChange}
                            />
                          </Grid>
                          <Grid className="calendar-dodania" item xs={3}>
                            <Calendar
                              disabled={!isEditable || false}
                              label="Data wytypowania przez OPS"
                              variant="filled"
                              disableFuture
                              name="dateAddedByOpsToProgram"
                              onChange={setFieldValue}
                              value={values.dateAddedByOpsToProgram}
                            />
                          </Grid>
                          <Grid item xs={6} />
                          <Grid item xs={3}>
                            <Checkbox
                              isEditable={isEditable || false}
                              label="Dziecko wytypowane do udziału w projekcie przez sąd"
                              value={values.assignedToProgramByCourt}
                              name="assignedToProgramByCourt"
                              onChange={handleChange}
                            />
                          </Grid>
                          <Grid className="calendar-dodania" item xs={3}>
                            <Calendar
                              disabled={!isEditable || false}
                              label="Data wytypowania przez sąd"
                              variant="filled"
                              disableFuture
                              name="dateAddedByCourtToProgram"
                              onChange={setFieldValue}
                              value={values.dateAddedByCourtToProgram}
                            />
                          </Grid>
                        </Grid>
                        {!connection.was_anonymized && (
                          <Grid item xs={8}>
                            <Box
                              display="flex"
                              flexWrap="wrap"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Box>
                                <StyledButton
                                  disabled={!isEditable || false}
                                  variant="outlined"
                                  onClick={handleSubmit}
                                >
                                  Zapisz
                                </StyledButton>
                                <StyledButton
                                  variant="outlined"
                                  disabled={!!values.dateEndedProgram || !isEditable || false}
                                  style={{ marginLeft: 15 }}
                                  onClick={() => {
                                    setCurrentProgramToEnd(connection);
                                    setOpenDialogConfirmEndProgram(true);
                                  }}
                                >
                                  zakończ uczestnictwo Beneficjenta w Programie
                                </StyledButton>
                              </Box>
                              <StyledButton
                                disabled={!isEditable || false}
                                variant="outlined"
                                color="secondary"
                                onClick={() => {
                                  setCurrentCWP(connection);
                                  setIsOpenDialog(true);
                                }}
                              >
                                usuń Beneficjenta z Programu
                              </StyledButton>
                            </Box>
                          </Grid>
                        )}
                      </>
                    )}
                  </Formik>
                </MyAccordion>
              );
            return <div key={programId} />;
          })
        ) : (
          <Grid item xs={12} container justifyContent="center">
            <Heading.Subtitle1>Brak danych</Heading.Subtitle1>
          </Grid>
        )}
      </StyledWrapper>

      {beneficiary.name !== 'anonimizacja' && (
        <FloatingButton onClick={() => history.push(`/beneficjenci-dodaj/${id}`)} />
      )}
      {currentActionToRemove && (
        <DialogConfirm
          item={currentActionToRemove}
          removeFunction={() => {
            removeBeneficiaryFromAction(
              currentActionToRemove.action_id,
              currentActionToRemove.cwa_id,
            ).then(() => {
              fetchPrograms(parseInt(id, 10));
            });
            setCurrentActionToRemove(null);
          }}
          title={`Potwierdź usunięcie beneficjenta ${beneficiary.name} ${beneficiary.surname} z działania "${currentActionToRemove.action_name}", jego dane w działaniu zostaną utracone!`}
          open={openDialogConfirmRemoveAction}
          setOpenFn={setOpenDialogConfirmRemoveAction}
        />
      )}

      {currentActionToEnd && (
        <DialogConfirm
          item={currentActionToEnd}
          removeFunction={() => {
            endBeneficiaryParticipationInAction(
              currentActionToEnd.action_id,
              currentActionToEnd.cwa_id,
            );
            setCurrentActionToEnd(null);
          }}
          title={`Potwierdź wypisanie beneficjenta ${beneficiary.name} ${beneficiary.surname} z działania "${currentActionToEnd.action_name}"`}
          open={openDialogConfirmEndAction}
          setOpenFn={setOpenDialogConfirmEndAction}
        />
      )}

      {currentProgramToEnd && (
        <DialogConfirm
          open={openDialogConfirmEndProgram}
          setOpenFn={setOpenDialogConfirmEndProgram}
          title={`Potwierdź zakończenie udziału w programie "${currentProgramToEnd.program_name}".`}
          size="xs"
          body={
            <Calendar
              label="Data zakończenia udziału"
              variant="filled"
              disableFuture
              name="dateEndedProgram"
              onChange={(name, value) => setDateEnd(value)}
              value={dateEnd}
            />
          }
          removeFunction={() =>
            endBeneficiaryParticipationInProgram(id, currentProgramToEnd.connection_id, {
              dateEnd,
            })
          }
        />
      )}

      <DialogConfirm
        open={isOpenDialog}
        setOpenFn={setIsOpenDialog}
        title={`Potwierdź usunięcie z programu "${currentCWP.program_name}", dane beneficjenta zgromadzone w programie zostaną utracone!`}
        size="xs"
        removeFunction={() => removeCWP(id, currentCWP.connection_id)}
      />
    </div>
  );
};

BeneficiaryPrograms.propTypes = {
  clearBeneficiaryPrograms: PropTypes.func,
  fetchPrograms: PropTypes.func,
  related_programs: PropTypes.arrayOf(
    PropTypes.shape({
      actions: PropTypes.arrayOf(PropTypes.any),
      program_id: PropTypes.number,
      program_name: PropTypes.string,
      connection: PropTypes.shape({
        connection_id: PropTypes.number,
        approvals: PropTypes.shape({
          approvalToIndependentReturnHome: PropTypes.bool,
          approvalToParticipationInProgram: PropTypes.bool,
          approvalToProcessingContactData: PropTypes.bool,
          approvalToProcessingGuardiansData: PropTypes.bool,
          approvalToProcessingOfPersonalData: PropTypes.bool,
          approvalToTransferOfInformation: PropTypes.bool,
          approvalToUseImage: PropTypes.bool,
        }),
        who_assigned: PropTypes.shape({}),
        date_added: PropTypes.string,
        assigned_by_school: PropTypes.bool,
      }),
    }),
  ),
  clearEducationalFacilitiesList: PropTypes.func,
  fetchEducationalFacilitiesList: PropTypes.func,
  educationalFacilitiesList: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      name: PropTypes.string,
    }),
  ),
  beneficiary: PropTypes.shape({ name: PropTypes.string, surname: PropTypes.string }),
  saveConnectionWithProgram: PropTypes.func,
  removeBeneficiaryFromAction: PropTypes.func,
  endBeneficiaryParticipationInAction: PropTypes.func,
  endBeneficiaryParticipationInProgram: PropTypes.func,
  removeCWP: PropTypes.func,
};

BeneficiaryPrograms.defaultProps = {
  clearBeneficiaryPrograms: null,
  fetchPrograms: null,
  related_programs: [],
  clearEducationalFacilitiesList: null,
  fetchEducationalFacilitiesList: null,
  educationalFacilitiesList: [],
  beneficiary: [],
  saveConnectionWithProgram: null,
  removeBeneficiaryFromAction: null,
  endBeneficiaryParticipationInAction: null,
  endBeneficiaryParticipationInProgram: null,
  removeCWP: null,
};

const mapStateToProps = ({ beneficiaryReducer, selectReducer }) => ({
  beneficiary: beneficiaryReducer.beneficiary,
  related_programs: beneficiaryReducer.programs,
  educationalFacilitiesList: selectReducer.educationalFacilities,
});

const mapDispatchToProps = (dispatch) => ({
  clearBeneficiaryPrograms: () => dispatch(clearBeneficiaryProgramsAction()),
  fetchPrograms: (id) => dispatch(fetchBeneficiaryProgramsService(id)),
  clearEducationalFacilitiesList: () => dispatch(clearEducationalFacilitiesListAction()),
  fetchEducationalFacilitiesList: () => dispatch(fetchEducationalFacilitiesListService()),
  saveConnectionWithProgram: (id, connectionId, data) =>
    dispatch(saveConnectionWithProgramService(id, connectionId, data)),
  removeBeneficiaryFromAction: (action_id, cwa_id) =>
    dispatch(removeBeneficiaryFromActionService(action_id, cwa_id)),
  endBeneficiaryParticipationInAction: (action_id, cwa_id) =>
    dispatch(endBeneficiaryParticipationInActionService(action_id, cwa_id)),
  removeCWP: (beneficiary_id, id) => dispatch(removeCWPService(beneficiary_id, id)),
  endBeneficiaryParticipationInProgram: (beneficiary_id, id, data) =>
    dispatch(endBeneficiaryParticipationInProgramService(beneficiary_id, id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BeneficiaryPrograms);
