// Elements
import Button from 'components/atoms/Button/Button';

// Styles
import styled from 'styled-components';

export const StyledButton = styled(Button)`
  && {
    width: auto;
    margin: 1rem;
  }
`;

export const StyledEditWrapper = styled.div`
  && {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
`;
