// React
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';

// Breadcrumbs
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';

// Service
import {
  sendMessage as sendMessageService,
  fetchMessageSingle as fetchMessageSingleService,
} from 'services/messageServices';

// Actions
import { clearMessageSingle as clearMessageSingleAction } from 'actions/messageActions';

// Formik
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';

// Elements
import { Box, Grid } from '@material-ui/core';
import Heading from 'components/atoms/Heading/Heading';
import Input from 'components/atoms/Input/Input';

// Styles
import { StyledGrid, StyledTitle, StyledButton } from './MessagesSingle.styles';

// Component
const MessagesSingle = ({ clearMessageSingle, fetchMessageSingle, message, sendMessage }) => {
  const { id } = useParams();
  const [initialContent, setInitialContent] = useState('');

  useEffect(() => {
    clearMessageSingle();
  }, []);

  useEffect(() => {
    if (id) {
      fetchMessageSingle(id);
    }
  }, [id, fetchMessageSingle]);

  return (
    <>
      <BreadcrumbsItem to={`/wiadomosci/${id}`} order={4}>
        Temat: {message.subject}
      </BreadcrumbsItem>
      <StyledTitle>
        <Heading.Subtitle1>{message.subject}</Heading.Subtitle1>
      </StyledTitle>
      <StyledTitle>
        <Heading.Subtitle2>
          Uczestnicy:{' '}
          {message.participants &&
            message.participants.map((part, index) => `${index ? ', ' : ' '} ${part.name}`)}
        </Heading.Subtitle2>
      </StyledTitle>
      <StyledGrid container spacing={2}>
        <Grid item xs={6}>
          <Formik
            initialValues={{
              thread_id: id,
              content: initialContent,
            }}
            enableReinitialize
            validationSchema={Yup.object({
              content: Yup.string().min(2, 'Minimum 2 znaki').required('pole wymagane!'),
            })}
            onSubmit={(values) => {
              sendMessage(id, values).then((res) => {
                if (res > 0) {
                  setInitialContent('');
                }
              });
            }}
          >
            {({ values, setFieldValue, handleSubmit }) => {
              return (
                <>
                  {message.messages &&
                    message.messages.map((el) => (
                      <Box key={el.id} mb={2}>
                        <Input
                          disabled
                          label={`${el.sender} – ${el.created_at}`}
                          variant="filled"
                          rows={10}
                          multiline
                          setField={setFieldValue}
                          value={el.body}
                        />
                      </Box>
                    ))}
                  {message.subject && (
                    <>
                      <StyledTitle>
                        <Heading.Subtitle3>Dodaj odpowiedź</Heading.Subtitle3>
                      </StyledTitle>
                      <Box mt={2}>
                        <Input
                          label="Wiadomość"
                          variant="filled"
                          name="content"
                          rows={10}
                          multiline
                          charCounter={2000}
                          setField={(name, value) => {
                            setFieldValue(name, value);
                            setInitialContent(value);
                          }}
                          value={values.content}
                        />
                        <ErrorMessage name="content">
                          {(msg) => <div className="error-txt">{msg}</div>}
                        </ErrorMessage>
                      </Box>
                      <Box>
                        <StyledButton variant="outlined" onClick={handleSubmit}>
                          Wyślij
                        </StyledButton>
                      </Box>
                    </>
                  )}
                </>
              );
            }}
          </Formik>
        </Grid>
      </StyledGrid>
    </>
  );
};

MessagesSingle.propTypes = {
  clearMessageSingle: PropTypes.func,
  fetchMessageSingle: PropTypes.func,
  message: PropTypes.shape({
    subject: PropTypes.string,
    participants: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      }),
    ),
    messages: PropTypes.arrayOf(PropTypes.any),
  }),
  sendMessage: PropTypes.func,
};

MessagesSingle.defaultProps = {
  clearMessageSingle: null,
  fetchMessageSingle: null,
  message: {},
  sendMessage: null,
};

const mapStateToProps = ({ messageReducer }) => ({
  message: messageReducer.message,
});

const mapDispatchToProps = (dispatch) => ({
  clearMessageSingle: () => dispatch(clearMessageSingleAction()),
  fetchMessageSingle: (id) => dispatch(fetchMessageSingleService(id)),
  sendMessage: (id, data) => dispatch(sendMessageService(id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MessagesSingle);
