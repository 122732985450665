import {
  SHOW_TASKS,
  CLEAR_TASKS,
  FETCH_TASK,
  FETCH_TASK_ACTION,
  CLEAR_TASK_ACTION,
  SHOW_TASK_DIAGNOSIS,
  CLEAR_TASK_DIAGNOSIS,
  SHOW_TASK_SURVEYS,
  CLEAR_TASK_SURVEYS,
  SHOW_TASK_IPP,
  CLEAR_TASK_IPP,
  FETCH_TASK_BENEFICIARY,
  CLEAR_TASK_BENEFICIARY,
  FETCH_TASK_USERS,
  CLEAR_TASK_USERS,
  FETCH_TASK_INDICATORS,
  CLEAR_TASK_INDICATORS,
  FETCH_TASK_PROGRAM_ID,
  CLEAR_TASK_PROGRAM_ID,
  CLEAR_TASK,
  FETCH_TASK_COORDINATORS,
} from 'constants/actionTypes';

const initialState = {
  tasks: [],
  diagnosis: [],
  ipp: [],
  program_id_by_task: null,
  task: {
    id: 0,
    name: '',
    description: '',
    owner: { id: null, name: '' },
    program: { id: null, name: '' },
    organizations: [],
    coordinator: null,
    type: {
      value: null,
      display: '',
    },
  },
  actions: [],
  beneficiary: [],
  coordinatorsList: [],
  users: [],
};

const taskReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SHOW_TASKS:
      return { ...state, tasks: payload.data };
    case CLEAR_TASKS:
      return { ...state, tasks: initialState.tasks };
    case SHOW_TASK_DIAGNOSIS:
      return { ...state, diagnosis: payload.data };
    case CLEAR_TASK_DIAGNOSIS:
      return { ...state, diagnosis: initialState.diagnosis };
    case SHOW_TASK_SURVEYS:
      return { ...state, surveys: payload.data };
    case CLEAR_TASK_SURVEYS:
      return { ...state, surveys: initialState.surveys };
    case SHOW_TASK_IPP:
      return { ...state, ipp: payload.data };
    case CLEAR_TASK_IPP:
      return { ...state, ipp: initialState.ipp };
    case FETCH_TASK:
      return { ...state, task: payload.data };
    case FETCH_TASK_ACTION:
      return { ...state, actions: payload.data };
    case CLEAR_TASK_ACTION:
      return { ...state, actions: initialState.actions };
    case FETCH_TASK_BENEFICIARY:
      return { ...state, beneficiary: payload.data };
    case CLEAR_TASK_BENEFICIARY:
      return { ...state, beneficiary: initialState.beneficiary };
    case FETCH_TASK_USERS:
      return { ...state, users: payload.data };
    case CLEAR_TASK_USERS:
      return { ...state, users: initialState.users };
    case FETCH_TASK_COORDINATORS:
      return { ...state, coordinatorsList: payload.data };
    case FETCH_TASK_INDICATORS:
      return { ...state, indicators: payload.data };
    case CLEAR_TASK_INDICATORS:
      return { ...state, indicators: initialState.indicators };
    case FETCH_TASK_PROGRAM_ID:
      return { ...state, program_id_by_task: payload.data };
    case CLEAR_TASK_PROGRAM_ID:
      return { ...state, program_id_by_task: initialState.program_id_by_task };
    case CLEAR_TASK:
      return { ...initialState };
    default:
      return state;
  }
};

export default taskReducer;
