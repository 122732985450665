// React
import React from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Formik
import { Formik, ErrorMessage } from 'formik';

// Services
import {
  saveBeneficiarySubjectValue as saveBeneficiarySubjectValueService,
  setBeneficiarySSPeriodBase as setBeneficiarySSPeriodBaseService,
} from 'services/beneficiaryServices';

// Elements
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import Checkbox from 'components/atoms/Checkbox/Checkbox';
import Heading from 'components/atoms/Heading/Heading';
import Button from 'components/atoms/Button/Button';

import { StyledPaper } from 'components/styled/StyledComponents';

// Styles
import { StyledTableCell, StyledInput } from './EditDegreeTable.styles';

// Component
const EditDegreeTable = ({
  isEditable,
  subject,
  beneficiaryId,
  saveBeneficiarySubjectValue,
  setBeneficiarySSPeriodBase,
}) => {
  const { periods, name } = subject;

  return (
    <>
      <TableContainer className="tabela" component={StyledPaper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell title={1} column="name" />
              {periods.length > 0 &&
                periods.map((col) => (
                  <StyledTableCell key={col.time_period_id} number={1}>
                    <Checkbox
                      isEditable={isEditable}
                      label="pomiar bazowy"
                      name={`base_measurement_${col.id}`}
                      value={col.base_measurement}
                      onChange={() => {
                        console.log(col);
                        setBeneficiarySSPeriodBase(beneficiaryId, col.supp_subject_period_id, {
                          base_measurement: !col.base_measurement,
                          // cwpId: periods.,
                        });
                      }}
                    />
                    <br />
                    <Heading.Overline>{col.time_period_name}</Heading.Overline>
                  </StyledTableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <StyledTableCell title={1} component="th" scope="row">
                <Heading.Body2>{name || '-'}</Heading.Body2>
              </StyledTableCell>
              {periods &&
                periods.map((item) => (
                  <StyledTableCell number={1} key={item.supp_subject_period_id}>
                    {isEditable ? (
                      <Formik
                        initialValues={{ value: item.degree }}
                        onSubmit={(values) => {
                          saveBeneficiarySubjectValue(
                            beneficiaryId,
                            item.supp_subject_period_id,
                            values,
                          );
                        }}
                      >
                        {({
                          values,
                          handleChange,
                          handleSubmit,
                          touched,
                          errors,
                          setFieldTouched,
                        }) => (
                          <>
                            <StyledInput
                              variant="outlined"
                              name="value"
                              type="number"
                              defaultValue={values.value}
                              onChange={(e) => {
                                handleChange(e);
                                setFieldTouched('value', true);
                              }}
                              InputProps={{
                                inputProps: {
                                  min: 0,
                                  max: 6,
                                },
                              }}
                              onKeyUp={() => setFieldTouched('value', true)}
                            />

                            <ErrorMessage name="value">
                              {(msg) => <div className="error-txt">{msg}</div>}
                            </ErrorMessage>
                            {touched.value && !errors.value && (
                              <Button
                                onClick={(e) => {
                                  handleSubmit(e);
                                  setFieldTouched('value', false);
                                }}
                              >
                                zapisz
                              </Button>
                            )}
                          </>
                        )}
                      </Formik>
                    ) : (
                      <>
                        <Heading.Body2 key={item.supp_subject_period_id}>
                          {item.degree || '-'}
                        </Heading.Body2>
                      </>
                    )}
                  </StyledTableCell>
                ))}
              <TableCell colSpan={2} />
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

EditDegreeTable.propTypes = {
  saveBeneficiarySubjectValue: PropTypes.func,
  setBeneficiarySSPeriodBase: PropTypes.func,
  isEditable: PropTypes.bool,
  subject: PropTypes.shape({
    periods: PropTypes.arrayOf(PropTypes.any),
    subject_id: PropTypes.number,
    name: PropTypes.string,
    // cwpId: PropTypes.number,
  }),
  beneficiaryId: PropTypes.number.isRequired,
};

EditDegreeTable.defaultProps = {
  saveBeneficiarySubjectValue: null,
  setBeneficiarySSPeriodBase: null,
  isEditable: false,
  subject: null,
};

const mapStateToProps = ({ selectReducer }) => ({
  indicatorPeriods: selectReducer.indicatorPeriods,
});

const mapDispatchToProps = (dispatch) => ({
  saveBeneficiarySubjectValue: (beneficjentId, indicatorId, data) =>
    dispatch(saveBeneficiarySubjectValueService(beneficjentId, indicatorId, data)),
  setBeneficiarySSPeriodBase: (beneficiaryId, indicatorId, data) =>
    dispatch(setBeneficiarySSPeriodBaseService(beneficiaryId, indicatorId, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditDegreeTable);
