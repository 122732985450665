// Elements
import Button from 'components/atoms/Button/Button';

// Styles
import styled from 'styled-components';

export const StyledButton = styled(Button)`
  && {
    padding: ${({ padding }) => padding || '1rem 2.5rem'};
  }
`;
