import {
  SHOW_ORGANIZATIONS,
  CLEAR_ORGANIZATIONS,
  FETCH_ORGANIZATION,
  CLEAR_ORGANIZATION,
  FETCH_REPRESENTATIVE_LIST,
  CLEAR_REPRESENTATIVE_LIST,
  FETCH_ORGANIZATION_FOR_PROGRAM,
  FETCH_ORGANIZATION_BENEFICIARIES_LIST,
  CLEAR_ORGANIZATION_BENEFICIARIES_LIST,
  FETCH_ORGANIZATION_PROGRAMS_LIST,
  CLEAR_ORGANIZATION_PROGRAMS_LIST,
  FETCH_ORGANIZATION_COORDINATORS_LIST,
  CLEAR_ORGANIZATION_COORDINATORS_LIST,
} from 'constants/actionTypes';

const initialState = {
  autoComplete: '',
  details: [],
  organizations: [],
  organization: {
    id: null,
    name: '',
    business_id: '',
    type: 0,
    status: null,
    representative: {
      name: '',
      surname: '',
      phone: '',
      email: '',
    },

    identificator_kind: null,
    identificator_number: '',
    organization_street: '',
    organization_house: '',
    organization_local: '',
    organization_zip_code: '',
    organization_city: '',
    organization_district: '',
    organization_children: null,
    parent: null,
    outpost: {
      id: null,
      street: '',
      houseNumber: '',
      apartmentNumber: '',
      district: '',
      zipCode: '',
      city: '',
    },
    is_same_outpost_address: false,
  },
  organizationForProgram: [],
  representativesList: [],
};

const organizationReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SHOW_ORGANIZATIONS:
      return { ...state, organizations: payload.data };
    case CLEAR_ORGANIZATIONS:
      return { ...state, organizations: initialState.organizations };
    case FETCH_ORGANIZATION:
      return { ...state, organization: payload.data };
    case CLEAR_ORGANIZATION:
      return { ...state, organization: initialState.organization };
    case FETCH_REPRESENTATIVE_LIST:
      return { ...state, representativesList: payload.data };
    case CLEAR_REPRESENTATIVE_LIST:
      return { ...state, representativesList: initialState.representativesList };
    case FETCH_ORGANIZATION_FOR_PROGRAM:
      return { ...state, organizationForProgram: payload.data };
    case FETCH_ORGANIZATION_BENEFICIARIES_LIST:
      return { ...state, beneficiaries: payload.data };
    case CLEAR_ORGANIZATION_BENEFICIARIES_LIST:
      return { ...state, beneficiaries: initialState.beneficiaries };
    case FETCH_ORGANIZATION_PROGRAMS_LIST:
      return { ...state, programs: payload.data };
    case CLEAR_ORGANIZATION_PROGRAMS_LIST:
      return { ...state, programs: initialState.programs };
    case FETCH_ORGANIZATION_COORDINATORS_LIST:
      return { ...state, coordinators: payload.data };
    case CLEAR_ORGANIZATION_COORDINATORS_LIST:
      return { ...state, coordinators: initialState.coordinators };
    default:
      return state;
  }
};

export default organizationReducer;
