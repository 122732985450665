// Elements
import { Card, CardActions } from '@material-ui/core';
import Button from 'components/atoms/Button/Button';

// Styles
import styled from 'styled-components';

export const StyledCard = styled(Card)`
  && {
    position: relative;
    background-color: ${({ theme }) => theme.color.white};
    min-height: 30rem;
    // max-height: 30rem;
    padding-bottom: 5rem;
    &:hover {
      box-shadow: 0 2.8px 2.2px ${({ shadows }) => shadows},
        0 6.7px 5.3px ${({ shadows }) => shadows}, 0 12.5px 10px ${({ shadows }) => shadows},
        0 22.3px 17.9px ${({ shadows }) => shadows}, 0 41.8px 33.4px ${({ shadows }) => shadows};
      transition: 0.3s;
    }
  }
`;

export const StyledButton = styled(Button)`
  && {
    margin: 0.5rem;
  }
`;

export const StyledTitle = styled.div`
  && {
    padding: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      margin-left: 1rem;
    }
  }
`;

export const StyledCardActions = styled(CardActions)`
  && {
    width: 100%;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    right: 0;
  }
`;

export const StyledStreetContainer = styled.div`
  && {
    display: flex;
    flex-direction: column;
  }
`;

export const StyledStreet = styled.div`
  && {
    display: flex;
  }
`;
