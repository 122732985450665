import { Paper, FormControlLabel } from '@material-ui/core';

import styled from 'styled-components';

export const StyledPaper = styled(Paper)`
  && {
    background: ${({ theme }) => theme.color.white};
  }
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
  && {
    font-family: ${({ theme }) => theme.fontFamily.base};
    color: ${({ theme }) => theme.color.darkGray};

    span {
      font-size: ${({ theme }) => theme.fontSize.body2};
    }

    .MuiSwitch-colorPrimary {
      color: ${({ theme }) => theme.color.gray};
    }

    .MuiSwitch-colorPrimary.Mui-checked {
      color: ${({ theme }) => theme.color.blue};
    }

    .MuiSwitch-switchBase + .MuiSwitch-track {
      background-color: ${({ theme }) => theme.color.black};
    }

    .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
      background-color: ${({ theme }) => theme.color.blue};
      opacity: 0.6;
    }
  }
`;
