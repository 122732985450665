// React
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Utils
import { isCoordinator } from 'utils/functions';
import { NO_ACCESS } from 'constants/roles';

// Styles
import { StyledWrapper, StyledTabs, StyledTab } from './Tabs.styles';

// Component
const Tabs = ({
  me,
  organization_id,
  parent_organization_id,
  program_id,
  program_ids,
  renderViewArray,
  tabsUrl,
}) => {
  const history = useHistory();
  const { goto_tab } = useParams();
  const [tabPage, setTabPage] = useState(0);
  const [filteredRenderViewArray, setFilteredRenderViewArray] = useState([renderViewArray[0]]);

  useEffect(() => {
    if (goto_tab) {
      setTabPage(parseInt(goto_tab, 10));
    } else {
      setTabPage(0);
    }
  }, [goto_tab]);

  useEffect(() => {
    if (isCoordinator(me.user_role)) {
      return setFilteredRenderViewArray(renderViewArray);
    }

    let tabs = [];
    if (
      !organization_id &&
      !parent_organization_id &&
      !program_id &&
      program_ids.length === 0 &&
      !renderViewArray.find((view) => view.user_roles)
      // && tabs.length === renderViewArray.length
    ) {
      return setFilteredRenderViewArray(renderViewArray);
    }

    if (organization_id || parent_organization_id) {
      const foundOrg = me.user_organizations.find(
        (org) => org.id === organization_id || org.id === parent_organization_id,
      );
      if (foundOrg) {
        tabs = renderViewArray.filter(
          (view) => !view.organization_roles || view.organization_roles.includes(foundOrg.role),
        );

        return setFilteredRenderViewArray(tabs);
      }
    }

    if (program_id) {
      const foundProg =
        me.user_programs.find((prog) => prog.program_id === program_id) ||
        me.user_guardians.find((ug) => ug.program.id === program_id) ||
        false;
      if (foundProg || me.user_role.includes('ROLE_SONATA_ORGANIZATION_ADMINISTRATOR')) {
        tabs = renderViewArray.filter(
          (view) =>
            !view.program_roles ||
            (foundProg && view.program_roles.includes(foundProg.role)) ||
            (view.organization_roles &&
              view.organization_roles.includes('ROLE_SONATA_ORGANIZATION_ADMINISTRATOR') &&
              me.user_role.includes('ROLE_SONATA_ORGANIZATION_ADMINISTRATOR')),
        );
        return setFilteredRenderViewArray(tabs);
      }
    }

    if (program_ids && program_ids.length > 0) {
      renderViewArray.map((view) => {
        if (
          typeof (
            view.program_roles &&
            view.program_roles.find(
              (role) =>
                !!me.user_programs
                  .filter((up) => program_ids.includes(up.program_id))
                  .find((up) => role === up.role),
            )
          ) !== 'undefined' ||
          me.organization_programs.map((org) => {
            const foundAdminProgs = org.programs.find((op) => op.program_id === program_id);
            if (foundAdminProgs) return foundAdminProgs.map((p) => ({ ...p, admin: true }));
            return false;
          }).length > 0
        ) {
          tabs.push(view);
        }

        return true;
      });

      return setFilteredRenderViewArray(tabs);
    }

    renderViewArray.map((view) => {
      if (
        (!view.user_roles && !view.program_roles && !view.organization_roles) ||
        (view.user_roles && view.user_roles.find((role) => me.user_role.includes(role)))
      ) {
        tabs.push(view);
      }

      return true;
    });

    tabs = tabs.sort((a, b) => a.order - b.order);

    // ???? tabs = renderViewArray.filter((view) => view.organization_roles !== NO_ACCESS);
    tabs = tabs.filter((view) => view.organization_roles !== NO_ACCESS);

    return setFilteredRenderViewArray(tabs);
  }, [me, renderViewArray, program_ids, program_id, organization_id, parent_organization_id]);

  const TabView = filteredRenderViewArray[tabPage]
    ? filteredRenderViewArray[tabPage].tabView
    : null;

  return (
    <>
      <StyledWrapper container>
        <StyledTabs
          value={filteredRenderViewArray.length > tabPage ? tabPage : 0}
          onChange={(event, value) => {
            history.push(
              `${
                history.location.pathname.search(tabsUrl ? '/tabs/goto/tab/' : '/tab/') > 0
                  ? history.location.pathname.substr(
                      0,
                      history.location.pathname.search(tabsUrl ? '/tabs/goto/tab/' : '/tab/'),
                    )
                  : history.location.pathname
              }${tabsUrl ? '/tabs/goto/tab/' : '/tab/'}${value}-${
                filteredRenderViewArray[value].key
              }`,
            );
            setTabPage(value);
          }}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
        >
          {filteredRenderViewArray.map((view) => {
            const Icon = view.icon;
            return view.label ? (
              (view.icon && <StyledTab key={view.key} icon={<Icon />} label={view.label} />) || (
                <StyledTab key={view.key} label={view.label} />
              )
            ) : (
              <></>
            );
          })}
        </StyledTabs>
      </StyledWrapper>
      {TabView && <TabView />}
    </>
  );
};

Tabs.propTypes = {
  renderViewArray: PropTypes.arrayOf(PropTypes.any),
  tabsUrl: PropTypes.bool,
  organization_id: PropTypes.number,
  parent_organization_id: PropTypes.number,
  program_id: PropTypes.number,
  program_ids: PropTypes.arrayOf(PropTypes.number),
  me: PropTypes.shape({
    user_role: PropTypes.arrayOf(PropTypes.string),
    user_programs: PropTypes.arrayOf(PropTypes.any),
    user_organizations: PropTypes.arrayOf(PropTypes.any),
    user_guardians: PropTypes.arrayOf(PropTypes.any),
    organization_programs: PropTypes.arrayOf(PropTypes.any),
  }),
};

Tabs.defaultProps = {
  renderViewArray: [],
  tabsUrl: false,
  organization_id: null,
  parent_organization_id: null,
  program_id: null,
  program_ids: [],
  me: {
    user_role: [],
    user_programs: [],
    user_organizations: [],
    user_guardians: [],
    organization_programs: [],
  },
};

const mapStateToProps = ({ userReducer }) => ({
  me: userReducer.me,
});

export default connect(mapStateToProps)(Tabs);
