// Elements
import { TableCell, DialogActions } from '@material-ui/core';
import Button from 'components/atoms/Button/Button';
import Input from 'components/atoms/Input/Input';

// Styles
import styled, { css } from 'styled-components';

export const StyledButton = styled(Button)`
  && {
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    font-size: ${({ theme }) => theme.fontSize.overline};
    color: ${({ theme }) => theme.color.gray};
    transition: 0.3s;

    &:hover {
      color: ${({ theme }) => theme.color.darkGray};
    }
  }
`;
export const StyledTableCell = styled(TableCell)`
  && {
    ${({ title }) =>
      title &&
      css`
        max-width: 15rem;
      `}
    ${({ number }) =>
      number &&
      css`
        max-width: 8rem;
      `}
  }
`;
export const StyledDialogActions = styled(DialogActions)`
  && {
    margin-top: 1rem;
  }
`;

export const StyledInput = styled(Input)`
  && {
    width: auto;

    input {
      background-color: rgba(0, 0, 0, 0);
      padding: 1rem;
    }
  }
`;
