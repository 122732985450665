import { SHOW_APPLIACTIONS, CLEAR_APPLIACTIONS } from 'constants/actionTypes';

const initialState = {
  applications: [],
};

const applicationReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SHOW_APPLIACTIONS:
      return { ...state, applications: payload.data };
    case CLEAR_APPLIACTIONS:
      return { ...state, applications: initialState.applications };
    default:
      return state;
  }
};

export default applicationReducer;
