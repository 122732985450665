// React
import React from 'react';
import PropTypes from 'prop-types';

// Moment
import * as moment from 'moment';

// PDF
import { Page, Text, View, Document, Font } from '@react-pdf/renderer';

// Fonts
import robotoRegularSRC from 'font/Roboto-Regular.ttf';
import robotoBoldSRC from 'font/Roboto-Bold.ttf';

// Data
import {
  diagnosisBasicData,
  diagnosisRecruitmentSource,
  diagnosisChildSituation,
  diagnosisProgramsData,
  diagnosisFamilySituation,
  diagnosisInstitutionCare,
  diagnosisFamilyType,
  diagnosisTextareaFields,
} from 'constants/diagnosis';

// Styles
import { styles } from 'utils/pdfStyles';

Font.register({
  family: 'Roboto',
  fonts: [{ src: robotoRegularSRC }, { src: robotoBoldSRC, fontWeight: 700 }],
});

const BeneficiaryDiagnosisPDF = ({ data }) => {
  const diagnosisRecruitmentSource_found = diagnosisRecruitmentSource.find(
    (item) => item.id === data.rows.recruitment_soruce,
  );
  return data && Object.keys(data).length > 0 ? (
    <Document language="pl">
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.title}>Informacje podstawowe</Text>
          <View style={styles.textFlex}>
            <Text style={styles.textBold}>Tytuł: </Text>
            <Text>{data.name}</Text>
          </View>
          <View style={styles.textFlex}>
            <Text style={styles.textBold}>Data przeprowadzenia: </Text>
            <Text>{moment(data.execution_date).format('DD-MM-YYYY')}</Text>
          </View>
          <View style={styles.textFlex}>
            <Text style={styles.textBold}>Imię i nazwisko beneficjenta: </Text>
            <Text>{data.beneficiary.name}</Text>
          </View>
          <View style={styles.textFlex}>
            <Text style={styles.textBold}>Nazwa programu: </Text>
            <Text>{data.program.name}</Text>
          </View>
          <View style={styles.textFlex}>
            <Text style={styles.textBold}>Imię i nazwisko przeprowadzającego: </Text>
            <Text>{data.author.name}</Text>
          </View>
          <Text style={styles.title}>Podstawa diagnozy</Text>
          <Text style={styles.text}>
            {data.rows.basic_data.map(
              (el) =>
                el.value &&
                diagnosisBasicData.find((item) => item.id === el.id) &&
                `${diagnosisBasicData.find((item) => item.id === el.id).label} ${
                  el.textValue ? el.textValue : ''
                }`,
            )}
          </Text>
          <Text style={styles.title}>Źródło rekrutacji dziecka</Text>
          <Text style={styles.text}>
            {`${diagnosisRecruitmentSource_found.label} 
                ${data.rows.recruitment_soruce_text}`}
          </Text>
          <Text style={styles.title}>Sytuacja dziecka</Text>
          <Text style={styles.text}>
            {data.rows.child_situation.map(
              (el) =>
                el.value &&
                diagnosisChildSituation.find((item) => item.id === el.id) &&
                `${diagnosisChildSituation.find((item) => item.id === el.id).label} `,
            )}
          </Text>
          <Text style={styles.title}>Orzeczenia i opinie</Text>
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Program</Text>
              </View>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Orzeczenie</Text>
              </View>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Opinia</Text>
              </View>
            </View>
            {data.rows.programs.map(
              (el) =>
                (el.certificate || el.opinion) &&
                diagnosisProgramsData.find((item) => item.id === el.id) && (
                  <>
                    <View style={styles.tableRow}>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>
                          {`${diagnosisProgramsData.find((item) => item.id === el.id).label} ${
                            el.textValue ? el.textValue : ''
                          }`}
                        </Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{el.certificate ? 'x' : ''}</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{el.opinion ? 'x' : ''}</Text>
                      </View>
                    </View>
                  </>
                ),
            )}
          </View>
          <Text style={styles.title}>Sytuacja rodziny</Text>
          <Text style={styles.text}>
            {data.rows.family_situation.map(
              (el) =>
                el.value &&
                diagnosisFamilySituation.find((item) => item.id === el.id) &&
                `${diagnosisFamilySituation.find((item) => item.id === el.id).label}  ${
                  el.textValue ? el.textValue : ''
                } `,
            )}
          </Text>
          <Text style={styles.title}>Czy rodzina jest pod opieką instytucji wspierającej?</Text>
          <Text style={styles.text}>
            {data.rows.institution_care.map(
              (el) =>
                el.value &&
                diagnosisInstitutionCare.find((item) => item.id === el.id) &&
                `${diagnosisInstitutionCare.find((item) => item.id === el.id).label} ${
                  el.textValue ? el.textValue : ''
                } `,
            )}
          </Text>
          <Text style={styles.title}>Typ rodziny</Text>
          <Text style={styles.text}>
            {diagnosisFamilyType.find((item) => item.id === data.rows.family_type) &&
              diagnosisFamilyType.find((item) => item.id === data.rows.family_type).label}
          </Text>
          {data.rows.textarea_fields.map(
            (el) =>
              el.value &&
              diagnosisTextareaFields.find((item) => item.id === el.id) && (
                <>
                  <Text style={styles.title}>
                    {diagnosisTextareaFields.find((item) => item.id === el.id).label}
                  </Text>
                  <Text style={styles.text}>{el.value}</Text>
                </>
              ),
          )}
        </View>
      </Page>
    </Document>
  ) : (
    <Document>
      <Page size="A4" style={styles.page} />
    </Document>
  );
};

BeneficiaryDiagnosisPDF.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]),
};

BeneficiaryDiagnosisPDF.defaultProps = {
  data: {},
};

export default BeneficiaryDiagnosisPDF;
