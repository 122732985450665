// Elements
import { Grid } from '@material-ui/core';
import Button from 'components/atoms/Button/Button';

// Styles
import styled from 'styled-components';

export const StyledWrapper = styled(Grid)`
  && {
    margin-top: 4rem;
    margin-bottom: 1rem;
  }
`;

export const StyledButton = styled(Button)`
  && {
    width: auto;
    margin: 1rem;
  }
`;

export const StyledBox = styled.div`
  && {
    max-width: 60%;
  }
`;
