// Elements
import { Chip } from '@material-ui/core';

// Styles
import styled from 'styled-components';

export const StyledChip = styled(Chip)`
  && {
    background: ${({ theme }) => theme.color.blue};
    color: ${({ theme }) => theme.color.white};
    .MuiChip-label {
      font-size: 1.2rem;
    }
    .MuiChip-deleteIconColorPrimary {
      color: ${({ theme }) => theme.color.white};
    }
  }
`;
