// Elements
import { Grid } from '@material-ui/core';

// Styles
import styled from 'styled-components';

export const StyledWrapper = styled(Grid)`
  && {
    margin-top: 4rem;
    margin-bottom: 1rem;
  }
`;
