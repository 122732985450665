// React
import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Services
import {
  fetchCWPInfo as fetchCWPInfoService,
  saveBeneficiaryIPP as saveBeneficiaryIPPService,
  fetchSingleIPP as fetchSingleIPPService,
} from 'services/beneficiaryServices';
import {
  fetchProgramsList as fetchProgramsListService,
  fetchProgramBeneficiaries as fetchProgramBeneficiariesService,
} from 'services/programServices';

// Actions
import {
  clearSingleIPPAction as clearSingleIPPService,
  clearCWPInfo as clearCWPInfoAction,
} from 'actions/beneficiaryActions';
import {
  clearPrograms as clearProgramsAction,
  clearProgramBeneficiaries as clearProgramBeneficiariesAction,
} from 'actions/programActions';

// Utils
import moment from 'moment';
import { generateBeneficiaryIPPPDF } from 'utils/functions';

// Constants
import { ippPlan, ippSummary } from 'constants/other';

// Formik
import { ErrorMessage, Formik } from 'formik';
import * as Yup from 'yup';

// Assets
import AddCircleIcon from '@material-ui/icons/AddCircle';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

// Elements
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { Box, Grid } from '@material-ui/core';
import Select from 'components/atoms/Select/Select';
import Heading from 'components/atoms/Heading/Heading';
import EditButton from 'components/atoms/EditButton/EditButton';
import Input from 'components/atoms/Input/Input';
import Checkbox from 'components/atoms/Checkbox/Checkbox';
import Calendar from 'components/atoms/Calendar/Calendar';
// import CalendarRange from 'components/atoms/CalendarRange/CalendarRange';

// Styles
import { StyledButton, StyledEditWrapper } from './AddBeneficiaryIPP.styles';

// View
const AddBeneficiaryIPP = ({
  clearSingleIPP,
  fetchSingleIPP,
  single_ipp,
  clearCWPInfo,
  fetchCWPInfo,
  cwp_info,
  clearProgramBeneficiaries,
  fetchProgramBeneficiaries,
  beneficiaries,
  clearPrograms,
  fetchProgramsList,
  programs,
  saveBeneficiaryIPP,
}) => {
  const history = useHistory();
  const { id, id_ipp, edit, program_id } = useParams();
  const [isEditable, setIsEditable] = useState(false);
  const [summaryView, setSummaryView] = useState(false);
  // const [rangeCalendarOpen, setRangeCalendarOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState({ id: id_ipp || null });
  const [redirectView, setRedirectView] = useState(true);
  const [programName, setProgramName] = useState('');
  const [programID, setProgramID] = useState(null);
  const [programSelectData, setProgramSelectData] = useState([]);
  const [programBeneficiariesData, setProgramBeneficiariesData] = useState([]);

  useEffect(() => {
    clearSingleIPP();
    clearProgramBeneficiaries();
    clearPrograms();
    clearCWPInfo();
  }, []);

  useEffect(() => {
    if ((id_ipp && !!edit) || !id_ipp) {
      setIsEditable(true);
    }
  }, [id_ipp, edit]);

  useEffect(() => {
    if (Object.keys(single_ipp).length > 0 && single_ipp.status > 0) {
      setSummaryView(true);
    }
  }, [single_ipp]);

  const triggerFetchProgramBeneficiaries = useCallback(
    (myid) => fetchProgramBeneficiaries(myid),
    [fetchProgramBeneficiaries],
  );

  useEffect(() => {
    if (program_id) {
      fetchProgramBeneficiaries(program_id);
    }
  }, [program_id]);

  useEffect(() => {
    if (Object.keys(cwp_info).length > 0 || (Object.keys(single_ipp).length > 0 && isEditable)) {
      setProgramID(cwp_info.program_id || single_ipp.program.id);
    }
  }, [single_ipp, cwp_info.program_id, isEditable]);

  useEffect(() => {
    if (programID) {
      triggerFetchProgramBeneficiaries(programID);
    }
  }, [programID, cwp_info.program_id, program_id, triggerFetchProgramBeneficiaries]);

  const triggerFetchCWPInfo = useCallback((myid) => fetchCWPInfo(myid), [fetchCWPInfo]);

  const triggerFetchProgramsList = useCallback(() => fetchProgramsList(), [fetchProgramsList]);

  useEffect(() => {
    triggerFetchProgramsList();

    if (id) {
      triggerFetchCWPInfo(id);
    }
  }, [id, triggerFetchCWPInfo, triggerFetchProgramsList]);

  const triggerFetchSingleIPP = useCallback((myid) => fetchSingleIPP(myid), [fetchSingleIPP]);

  useEffect(() => {
    if (id_ipp) {
      triggerFetchSingleIPP(id_ipp);
    }
  }, [id_ipp, triggerFetchSingleIPP]);

  useEffect(() => {
    const newData = [];

    programs.map((el) => {
      if (el.beneficiaries_number > 0) {
        newData.push({ name: el.name, value: el.id });
      }

      if (program_id && el.id === parseInt(program_id, 10)) {
        setProgramName(el.name);
      }

      return true;
    });

    setProgramSelectData(newData);
  }, [programs, program_id]);

  useEffect(() => {
    const newData = [];

    beneficiaries.map((el) => {
      newData.push({
        name: `${el.beneficiary_name} ${el.beneficiary_last_name}`,
        value: el.id,
        cwp_id: el.cwp_id,
      });

      return true;
    });

    setProgramBeneficiariesData(newData);
  }, [beneficiaries]);

  const newIppPlan =
    (Object.keys(single_ipp).length > 0 && single_ipp.rows && single_ipp.rows.plan) ||
    (Object.keys(currentItem).length > 0 && currentItem.rows && currentItem.rows.plan) ||
    [];
  const newIppSummary =
    (Object.keys(single_ipp).length > 0 && single_ipp.rows && single_ipp.rows.summary) ||
    (Object.keys(currentItem).length > 0 && currentItem.rows && currentItem.rows.summary) ||
    [];

  useEffect(() => {
    if ((id_ipp && edit) || !id_ipp) {
      setIsEditable(true);
    }
  }, [id_ipp, edit]);

  const nextView = async (validateForm, setFn, status) => {
    const errors = await validateForm();

    if (Object.keys(errors).length === 0) {
      setSummaryView(true);
      setFn('status', status);

      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  return (
    <>
      <BreadcrumbsItem to="/indywidualny-plan-pracy-dodaj" order={4}>
        Dodaj indywidualny plan pracy
      </BreadcrumbsItem>
      {((id_ipp && single_ipp.id) || (!id_ipp && !id) || (id && cwp_info.beneficiary)) && (
        <Formik
          initialValues={{
            id: single_ipp.id || currentItem.id || null,
            title: single_ipp.title || currentItem.title || '',
            cwp_id: single_ipp.cwp_id || currentItem.cwp_id || cwp_info.connection_id || null,
            program:
              (Object.keys(single_ipp).length > 0 && single_ipp.program.name) ||
              cwp_info.program_name ||
              programName ||
              '',
            beneficiary:
              (Object.keys(single_ipp).length > 0 && single_ipp.beneficiary.name) ||
              cwp_info.beneficiary ||
              '',
            program_id:
              (Object.keys(single_ipp).length > 0 && single_ipp.program.id) ||
              cwp_info.program_id ||
              parseInt(program_id, 10) ||
              null,
            beneficiary_id:
              (Object.keys(single_ipp).length > 0 && single_ipp.beneficiary.id) ||
              cwp_info.beneficiary_id ||
              null,
            execution_date:
              single_ipp.execution_date ||
              currentItem.execution_date ||
              moment().format('YYYY-MM-DD'),
            // range_date: {
            //   startDate:
            //     single_ipp && single_ipp.date_start ? new Date(single_ipp.date_start) : new Date(),
            //   endDate:
            //     single_ipp && single_ipp.date_end ? new Date(single_ipp.date_end) : new Date(),
            // },
            date_start:
              single_ipp && single_ipp.date_start
                ? moment(single_ipp.date_start).format('YYYY-MM-DD')
                : moment().format('YYYY-MM-DD'),
            date_end:
              single_ipp && single_ipp.date_end
                ? moment(single_ipp.date_end).format('YYYY-MM-DD')
                : moment().format('YYYY-MM-DD'),
            rows: {
              plan: ippPlan.map((item) => ({
                ...(newIppPlan.find((el) => el.id === item.id) || {
                  id: item.id,
                  value: '',
                }),
                optional: item.optional,
              })),
              summary: ippSummary.map(
                (item) =>
                  newIppSummary.find((el) => el.id === item.id) || { id: item.id, value: '' },
              ),
            },
            completed: !!single_ipp.completed || !!currentItem.completed || false,
            status: single_ipp.status || currentItem.status || 0,
          }}
          validationSchema={Yup.object({
            title: Yup.string().required('pole wymagane!'),
            program_id: Yup.number()
              .nullable(true)
              .required('pole wymagane!')
              .test('Is positive?', 'pole wymagane!', (value) => value > 0),
            beneficiary_id: Yup.number()
              .nullable(true)
              .required('pole wymagane!')
              .test('Is positive?', 'pole wymagane!', (value) => value > 0),
            execution_date: Yup.string().nullable().required('pole wymagane!'),
            // range_date: Yup.object().shape({
            //   startDate: Yup.date().nullable().required('pole wymagane!'),
            //   endDate: Yup.date().nullable().required('pole wymagane!'),
            // }),
            date_start: Yup.date().nullable().required('pole wymagane!'),
            date_end: Yup.date().nullable().required('pole wymagane!'),
            status: Yup.number(),
            rows: Yup.object().when('status', {
              is: 0,
              then: Yup.object().shape({
                plan: Yup.array().of(
                  Yup.object().shape({
                    optional: Yup.boolean(),
                    value: Yup.string().when('optional', {
                      is: true,
                      then: Yup.string(),
                      otherwise: Yup.string().required('pole wymagane!'),
                    }),
                  }),
                ),
                summary: Yup.array().of(
                  Yup.object().shape({
                    value: Yup.string(),
                  }),
                ),
              }),
              otherwise: Yup.object().shape({
                plan: Yup.array().of(
                  Yup.object().shape({
                    value: Yup.string().required('pole wymagane!'),
                  }),
                ),
                summary: Yup.array().of(
                  Yup.object().shape({
                    value: Yup.string().required('pole wymagane!'),
                  }),
                ),
              }),
            }),
          })}
          onSubmit={(values) => {
            if ((Object.keys(currentItem).length > 0 || id_ipp) && !isEditable) {
              return;
            }

            let isEditView = false;

            if ((Object.keys(currentItem).length > 0 || id_ipp) && isEditable) {
              isEditView = true;
            }

            // setCurrentItem((prevState) => ({ ...prevState, ...values }));

            const newValues = { ...values, id: currentItem.id };

            newValues.id = currentItem.id;

            const data = saveBeneficiaryIPP(
              values.beneficiary_id,
              newValues,
              currentItem.id && isEditView,
              redirectView,
            );

            if (!redirectView) {
              data.then((res) => {
                setCurrentItem((prevState) => ({ ...prevState, id: res }));
              });
            } else {
              history.push(
                `/beneficjenci/${values.beneficiary_id}/tab/6-Indywidualny%20Plan%20Pracy`,
              );
            }
          }}
        >
          {({ values, handleChange, setFieldValue, handleSubmit, validateForm }) => (
            <Box mt={!id_ipp ? 8 : 0}>
              {values.beneficiary_id && (
                <BreadcrumbsItem
                  to={`/beneficjenci/${values.beneficiary_id}`}
                  order={program_id ? 3 : 2}
                >
                  Beneficjent: {values.beneficiary}
                </BreadcrumbsItem>
              )}
              {values.program_id && (
                <BreadcrumbsItem to={`/programy/${values.program_id}`} order={program_id ? 2 : 3}>
                  Program: {values.program}
                </BreadcrumbsItem>
              )}
              {id_ipp && (
                <StyledEditWrapper>
                  <EditButton isEditable={isEditable || false} setIsEditable={setIsEditable} />
                </StyledEditWrapper>
              )}
              <Grid container spacing={4}>
                <Grid item xs={12} md={7} lg={5} xl={4}>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={12}>
                      <Heading.Subtitle1>Informacje podstawowe</Heading.Subtitle1>
                    </Grid>
                    <Grid item xs={12}>
                      <Input
                        disabled={!isEditable}
                        label="Tytuł planu"
                        name="title"
                        value={values.title}
                        variant="filled"
                        onChange={handleChange}
                      />
                      <ErrorMessage name="title">
                        {(msg) => <div className="error-txt">{msg}</div>}
                      </ErrorMessage>
                    </Grid>
                    <Grid item xs={12}>
                      {id_ipp && !isEditable ? (
                        <Input
                          disabled={!isEditable}
                          label="Nazwa programu"
                          name="program"
                          value={values.program}
                          variant="filled"
                          onChange={handleChange}
                        />
                      ) : (
                        <Select
                          disabled={
                            programSelectData.length === 0 || id || program_id || !isEditable
                              ? true
                              : null
                          }
                          label="Nazwa programu"
                          data={programSelectData}
                          variant="filled"
                          selectValue={programSelectData.length > 0 ? values.program_id : null}
                          text="program_id"
                          id="program_id"
                          onChange={(e, newValue) => {
                            setFieldValue(e, newValue);
                            const found = programSelectData.find((el) => el.value === newValue);
                            setFieldValue('program', found && found.name);
                          }}
                          setProgramIDFn={setProgramID}
                        />
                      )}
                      <ErrorMessage name="program_id">
                        {(msg) => <div className="error-txt">{msg}</div>}
                      </ErrorMessage>
                    </Grid>
                    <Grid item xs={12}>
                      {id_ipp && !isEditable ? (
                        <Input
                          disabled={!isEditable}
                          label="Imię i nazwisko beneficjenta"
                          name="beneficiary"
                          value={values.beneficiary}
                          variant="filled"
                          onChange={handleChange}
                        />
                      ) : (
                        <Select
                          disabled={
                            programBeneficiariesData.length === 0 || id || !isEditable ? true : null
                          }
                          label="Imię i nazwisko beneficjenta"
                          data={programBeneficiariesData}
                          variant="filled"
                          selectValue={
                            programBeneficiariesData.length > 0 ? values.beneficiary_id : null
                          }
                          text="beneficiary_id"
                          id="beneficiary_id"
                          onChange={(e, newValue) => {
                            setFieldValue(e, newValue);
                            const found = programBeneficiariesData.find(
                              (el) => el.value === newValue,
                            );
                            setFieldValue('beneficiary', found && found.name);
                            setFieldValue('cwp_id', found && found.cwp_id);
                          }}
                        />
                      )}
                      <ErrorMessage name="beneficiary_id">
                        {(msg) => <div className="error-txt">{msg}</div>}
                      </ErrorMessage>
                    </Grid>
                    <Grid item xs={6}>
                      <Calendar
                        disabled={!isEditable}
                        label="Data przeprowadzenia"
                        onChange={setFieldValue}
                        value={values.execution_date}
                        name="execution_date"
                        variant="filled"
                        disableFuture
                      />
                      <ErrorMessage name="execution_date">
                        {(msg) => <div className="error-txt">{msg}</div>}
                      </ErrorMessage>
                    </Grid>
                    <Grid item xs={6}>
                      <Checkbox
                        isEditable={isEditable}
                        label="Plan został zrealizowany"
                        onChange={handleChange}
                        name="completed"
                        value={values.completed}
                      />
                    </Grid>
                    {/* <Grid item xs={12}>
                      <CalendarRange
                        disabled={!isEditable}
                        text="Okres obowiązywania"
                        initialDateRange={values.range_date}
                        open={rangeCalendarOpen}
                        setRangeCalendarOpen={setRangeCalendarOpen}
                        setRangeCalendarDates={(e) => setFieldValue('range_date', e)}
                        removemargin="true"
                      />
                    </Grid> */}
                    <Grid item xs={6}>
                      <Calendar
                        disabled={!isEditable}
                        label="Data obowiązywania od"
                        onChange={setFieldValue}
                        value={values.date_start}
                        name="date_start"
                        variant="filled"
                      />
                      <ErrorMessage name="date_start">
                        {(msg) => <div className="error-txt">{msg}</div>}
                      </ErrorMessage>
                    </Grid>
                    <Grid item xs={6}>
                      <Calendar
                        disabled={!isEditable}
                        label="Data obowiązywania do"
                        onChange={setFieldValue}
                        value={values.date_end}
                        name="date_end"
                        variant="filled"
                      />
                      <ErrorMessage name="date_end">
                        {(msg) => <div className="error-txt">{msg}</div>}
                      </ErrorMessage>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid item xs={12} md={7} lg={5} xl={4}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Heading.Subtitle1>Cel i działania</Heading.Subtitle1>
                    </Grid>
                    {ippPlan.map((el, index) => (
                      <Grid key={el.id} item xs={12}>
                        <Input
                          setField={setFieldValue}
                          name={`rows.plan[${index}].value`}
                          value={values.rows.plan[index].value}
                          disabled={!isEditable}
                          label={el.label}
                          rows={10}
                          variant="filled"
                          multiline
                          type="text"
                          charCounter={el.charCounter}
                          onChange={handleChange}
                        />
                        <ErrorMessage name={`rows.plan[${index}].value`}>
                          {(msg) => <div className="error-txt">{msg}</div>}
                        </ErrorMessage>
                      </Grid>
                    ))}
                    {!summaryView && isEditable && (
                      <>
                        <Grid item xs={6}>
                          <StyledButton
                            variant="contained"
                            onClick={() => {
                              setRedirectView(true);
                              handleSubmit();
                            }}
                          >
                            ZAPISZ, aby powrócić do edycji
                          </StyledButton>
                          <StyledButton onClick={() => history.goBack()}>ODRZUĆ</StyledButton>
                        </Grid>
                        <Grid item xs={6}>
                          <Box display="flex" justifyContent="flex-end">
                            <StyledButton
                              variant="outlined"
                              startIcon={<AddCircleIcon />}
                              onClick={() => {
                                nextView(validateForm, setFieldValue, 1);
                                setRedirectView(false);
                                handleSubmit();
                              }}
                            >
                              PODSUMUJ
                            </StyledButton>
                          </Box>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Grid>
                {summaryView && (
                  <>
                    <Grid item xl={1} />
                    <Grid item xs={12} md={7} lg={6} xl={4}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Heading.Subtitle1>Podsumowanie realizacji planu</Heading.Subtitle1>
                        </Grid>
                        {ippSummary.map((el, index) => (
                          <Grid key={el.id} item xs={12}>
                            <Input
                              setField={setFieldValue}
                              name={`rows.summary[${index}].value`}
                              value={values.rows.summary[index].value}
                              disabled={!isEditable}
                              label={el.label}
                              rows={10}
                              variant="filled"
                              multiline
                              type="text"
                              charCounter={el.charCounter}
                              onChange={handleChange}
                            />
                            <ErrorMessage name={`rows.summary[${index}].value`}>
                              {(msg) => <div className="error-txt">{msg}</div>}
                            </ErrorMessage>
                          </Grid>
                        ))}
                        <Grid item xs={9}>
                          {isEditable && (
                            <StyledButton
                              variant="outlined"
                              onClick={() => {
                                setRedirectView(true);
                                handleSubmit();
                              }}
                            >
                              ZAPISZ, aby powrócić do edycji
                            </StyledButton>
                          )}
                          <StyledButton
                            variant="contained"
                            startIcon={<PictureAsPdfIcon />}
                            onClick={() => {
                              setFieldValue('status', 2);
                              setRedirectView(true);
                              handleSubmit();
                              generateBeneficiaryIPPPDF(values);
                            }}
                          >
                            {Object.keys(single_ipp).length > 0 &&
                            single_ipp.status === 2 &&
                            !isEditable
                              ? 'POBIERZ PDF'
                              : 'ZAPISZ,i zakończ I POBIERZ PDF'}
                          </StyledButton>
                        </Grid>
                        {isEditable && (
                          <Grid item xs={3}>
                            <StyledButton onClick={() => history.goBack()}>ODRZUĆ</StyledButton>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </>
                )}
              </Grid>
            </Box>
          )}
        </Formik>
      )}
    </>
  );
};

AddBeneficiaryIPP.propTypes = {
  clearSingleIPP: PropTypes.func,
  fetchSingleIPP: PropTypes.func,
  single_ipp: PropTypes.shape({
    completed: PropTypes.number,
    cwp_id: PropTypes.number,
    date_edited: PropTypes.string,
    date_end: PropTypes.string,
    date_start: PropTypes.string,
    execution_date: PropTypes.string,
    id: PropTypes.number,
    status: PropTypes.number,
    title: PropTypes.string,
    who_added: PropTypes.string,
    beneficiary: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    program: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    rows: PropTypes.shape({
      plan: PropTypes.arrayOf(PropTypes.any),
      summary: PropTypes.arrayOf(PropTypes.any),
    }),
  }),
  clearCWPInfo: PropTypes.func,
  fetchCWPInfo: PropTypes.func,
  cwp_info: PropTypes.shape({
    approvals: PropTypes.shape({
      approvalToIndependentReturnHome: PropTypes.bool,
      approvalToTransferOfInformation: PropTypes.bool,
      approvalToUseImage: PropTypes.bool,
      consortiumInformationClause: PropTypes.bool,
      presidentsInformationClause: PropTypes.bool,
    }),
    assigned_by_school: PropTypes.bool,
    beneficiary: PropTypes.string,
    beneficiaryType: PropTypes.number,
    beneficiary_id: PropTypes.number,
    beneficiary_name: PropTypes.string,
    connection_id: PropTypes.number,
    date_added: PropTypes.string,
    date_added_by_school_to_program: PropTypes.string,
    program_date_end: PropTypes.string,
    program_id: PropTypes.number,
    program_name: PropTypes.string,
    program_date_start: PropTypes.string,
    who_assigned: PropTypes.shape({
      city: PropTypes.string,
      name: PropTypes.string,
      value: PropTypes.number,
    }),
  }),
  clearProgramBeneficiaries: PropTypes.func,
  fetchProgramBeneficiaries: PropTypes.func,
  beneficiaries: PropTypes.arrayOf(PropTypes.any),
  clearPrograms: PropTypes.func,
  fetchProgramsList: PropTypes.func,
  programs: PropTypes.arrayOf(PropTypes.any),
  saveBeneficiaryIPP: PropTypes.func,
};

AddBeneficiaryIPP.defaultProps = {
  clearSingleIPP: null,
  fetchSingleIPP: null,
  single_ipp: {},
  clearCWPInfo: null,
  fetchCWPInfo: null,
  cwp_info: {},
  clearProgramBeneficiaries: null,
  fetchProgramBeneficiaries: null,
  beneficiaries: [],
  clearPrograms: null,
  fetchProgramsList: null,
  programs: [],
  saveBeneficiaryIPP: null,
};

const mapStateToProps = ({ beneficiaryReducer, programReducer }) => ({
  single_ipp: beneficiaryReducer.single_ipp,
  cwp_info: beneficiaryReducer.cwp_info,
  programs: programReducer.programs,
  beneficiaries: programReducer.beneficiaries.program_beneficiaries,
});

const mapDispatchToProps = (dispatch) => ({
  clearSingleIPP: () => dispatch(clearSingleIPPService()),
  fetchSingleIPP: (id) => dispatch(fetchSingleIPPService(id)),
  clearCWPInfo: () => dispatch(clearCWPInfoAction()),
  fetchCWPInfo: (id) => dispatch(fetchCWPInfoService(id)),
  clearProgramBeneficiaries: () => dispatch(clearProgramBeneficiariesAction()),
  fetchProgramBeneficiaries: (id) => dispatch(fetchProgramBeneficiariesService(id)),
  clearPrograms: () => dispatch(clearProgramsAction()),
  fetchProgramsList: () => dispatch(fetchProgramsListService()),
  saveBeneficiaryIPP: (id, data, edit, redirectView) =>
    dispatch(saveBeneficiaryIPPService(id, data, edit, redirectView)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddBeneficiaryIPP);
