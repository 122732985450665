// Elements
import { Grid } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';

// Styles
import styled from 'styled-components';

export const StyledWrapper = styled(Grid)`
  && {
    margin-top: 4rem;
  }
`;

export const StyledChip = styled(Chip)`
  && {
    .MuiChip-label {
      font-size: 1.2rem;
    }
  }
`;
