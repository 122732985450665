import {
  SHOW_INDICATORS,
  CLEAR_INDICATORS,
  FETCH_INDICATOR,
  FETCH_PERIODS,
  FETCH_PERIODS_SEMESTER,
  CLEAR_PERIODS_SEMESTER,
  CLEAR_INDICATOR,
} from 'constants/actionTypes';

export function showIndicators(payload) {
  return { type: SHOW_INDICATORS, payload };
}

export function clearIndicators(payload) {
  return { type: CLEAR_INDICATORS, payload };
}

export function fetchIndicatorAction(payload) {
  return { type: FETCH_INDICATOR, payload };
}

export function fetchPeriodsAction(payload) {
  return { type: FETCH_PERIODS, payload };
}

export function fetchPeriodsSemesterAction(payload) {
  return { type: FETCH_PERIODS_SEMESTER, payload };
}

export function clearPeriodsSemester(payload) {
  return { type: CLEAR_PERIODS_SEMESTER, payload };
}

export function clearIndicatorAction() {
  return { type: CLEAR_INDICATOR };
}
