// Elements
import { Grid, FormControlLabel } from '@material-ui/core';
import Button from 'components/atoms/Button/Button';

// Styles
import styled from 'styled-components';

export const StyledGrid = styled(Grid)`
  && {
    max-width: ${({ fullWidth }) => (fullWidth ? '100%' : '70%')};
    @media (max-width: 767px) {
      max-width: '100%';
    }
    margin-top: ${({ margin }) => margin || '0rem'};

    &.MuiGrid-spacing-xs-2 {
      margin-top: 2rem;
    }

    .MuiFormHelperText-root {
      font-size: 1rem;
      margin-left: 0;
      margin-right: 0;
    }
  }
`;

export const StyledButton = styled(Button)`
  && {
    padding: 1rem 2rem;
    margin-top: 2rem;
  }
`;

export const StyledTitle = styled.div`
  && {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5rem;
  }
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
  && {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
`;

export const StyledTooltip = styled.div`
  && {
    padding: 1.5rem 1rem;
    line-height: 1.1;

    > * {
      margin-bottom: 1rem;
    }

    p {
      &:first-child {
        margin-bottom: 1.5rem;
      }

      &:last-child {
        margin-top: 1rem;
      }
    }
  }
`;
