// React
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

// Assets
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// Elements
import { CardContent, Collapse, CardActionArea } from '@material-ui/core';
import Button from 'components/atoms/Button/Button';
import Heading from 'components/atoms/Heading/Heading';

// Styles
import {
  StyledCard,
  StyledCardMedia,
  StyledDivider,
  StyledTitle,
  StyledBadge,
  StyledCardActions,
  StyledIconButton,
} from './DashCard.styles';

// Component
const DashCard = ({
  data,
  bigImage,
  image,
  title,
  collapse,
  badge,
  dividerColor,
  actionCard,
  link,
  id,
  buttons,
  children,
}) => {
  const history = useHistory();
  const [expanded, setExpanded] = useState(false);
  const linkTo = () => {
    history.push(`${link}/${id}`);
  };
  const getId = data.map((obj) => obj.id);
  const toggleCard = (e) => {
    e.stopPropagation();

    setExpanded(!expanded);
  };

  return (
    <StyledCard>
      {actionCard ? (
        <CardActionArea key={getId} component="div" onClick={linkTo}>
          {badge && <StyledBadge badge={badge} />}
          {image && <StyledCardMedia image={image} />}
          {title && (
            <StyledTitle onClick={toggleCard}>
              <Heading.H6>{title}</Heading.H6>
              {collapse && (
                <StyledIconButton
                  expanded={expanded ? 1 : 0}
                  onClick={toggleCard}
                  aria-expanded={expanded}
                  aria-label="show more"
                >
                  <ExpandMoreIcon />
                </StyledIconButton>
              )}
            </StyledTitle>
          )}
          {collapse ? (
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <CardContent>{children}</CardContent>
            </Collapse>
          ) : (
            <>
              {dividerColor ? <StyledDivider color={dividerColor} /> : null}
              <CardContent>{children}</CardContent>
            </>
          )}
          {!collapse && buttons && (
            <StyledCardActions>
              {buttons.map((button) => {
                return (
                  <Button
                    size="small"
                    key={button.link}
                    onClick={() => {
                      history.push(button.link);
                    }}
                  >
                    {button.text}
                  </Button>
                );
              })}
            </StyledCardActions>
          )}
        </CardActionArea>
      ) : (
        <>
          {badge && <StyledBadge badge={badge} />}
          {bigImage}
          {image && <StyledCardMedia image={image} />}
          {title && (
            <StyledTitle>
              <Heading.H6 onClick={toggleCard}>{title}</Heading.H6>
              {collapse && (
                <StyledIconButton
                  expanded={expanded ? 1 : 0}
                  onClick={toggleCard}
                  aria-expanded={expanded}
                  aria-label="show more"
                >
                  <ExpandMoreIcon />
                </StyledIconButton>
              )}
            </StyledTitle>
          )}
          {collapse ? (
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <CardContent>{children}</CardContent>
            </Collapse>
          ) : (
            <>
              {dividerColor ? <StyledDivider color={dividerColor} /> : null}
              <CardContent>{children}</CardContent>
            </>
          )}
          {!collapse && buttons && (
            <StyledCardActions>
              {buttons.map((button) => {
                return (
                  <Button
                    size="small"
                    key={button.link}
                    onClick={() => {
                      history.push(button.link);
                    }}
                  >
                    {button.text}
                  </Button>
                );
              })}
            </StyledCardActions>
          )}
        </>
      )}
    </StyledCard>
  );
};

DashCard.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any),
  bigImage: PropTypes.objectOf(PropTypes.any),
  image: PropTypes.string,
  title: PropTypes.string,
  collapse: PropTypes.bool,
  badge: PropTypes.string,
  dividerColor: PropTypes.string,
  actionCard: PropTypes.bool,
  link: PropTypes.string,
  id: PropTypes.number,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      text: PropTypes.string,
    }),
  ),
  children: PropTypes.element,
};

DashCard.defaultProps = {
  data: [''],
  bigImage: null,
  image: '',
  title: '',
  collapse: false,
  badge: '',
  dividerColor: '',
  actionCard: false,
  link: '',
  id: 0,
  buttons: [],
  children: null,
};

export default DashCard;
