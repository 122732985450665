/* eslint-disable react/prop-types */

// React
import React from 'react';
import ReactDOM from 'react-dom';

// Styles
import { StyledButton, StyledGridItem } from './Modal.styles';
import './Modal.scss';

// Component
const Modal = ({ isShowing, yes, no, text }) =>
  isShowing
    ? ReactDOM.createPortal(
        <>
          <div className="modal-overlay" />
          <div className="modal-wrapper" aria-modal aria-hidden tabIndex={-1} role="dialog">
            <div className="modal">
              <StyledGridItem item xs={12}>
                {text}
              </StyledGridItem>
              <StyledGridItem item xs={12}>
                <StyledButton
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  variant="outlined"
                  onClick={yes}
                >
                  TAK
                </StyledButton>
                <StyledButton
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  variant="outlined"
                  onClick={no}
                >
                  NIE
                </StyledButton>
              </StyledGridItem>
            </div>
          </div>
        </>,
        document.body,
      )
    : null;

export default Modal;
