// React
import React from 'react'; // , { useState }
import PropTypes from 'prop-types';

// Elements
import Button from 'components/atoms/Button/Button';
import Heading from 'components/atoms/Heading/Heading';
import Dialog from 'components/organisms/Dialog/Dialog';
// import Checkbox from 'components/atoms/Checkbox/Checkbox';

// Styles
import { StyledWrapper, StyledDialogActions } from './DialogBeneficiarySurveys.styles';

// Component
const DialogBeneficiarySurveys = ({ open, setOpenFn, title }) => {
  // const [value, setValue] = useState(true);

  return (
    <Dialog open={open} setOpen={setOpenFn} title={title} size="md">
      <StyledWrapper>
        <Heading.Body1>
          Ankieta powinna być wypełniona w danym okresie przez 1 i tylko 1 osobę która:
        </Heading.Body1>
        <div>
          <ul>
            <li>bezpośrednio pracuje z beneficjentem w formie grupowej lub indywidualnej</li>
            <li>
              osoba która pracuje z beneficjentem przez okres nie krótszy niż 1 miesiąc (4
              tygodnie).
            </li>
          </ul>
        </div>
        <Heading.Body1>
          Jeśli z beneficjentem pracuje stale więcej niż jedna osoba, koordynator wskazuje kto
          powinien wypełnić ankietę na temat danego beneficjenta.
        </Heading.Body1>
        <Heading.Body1>
          Niedopuszczalna jest sytuacja, gdy dwóch pracowników projektu nanosi informacje do tej
          samej ankiety odnoszącej się do tego samego beneficjenta w tej samej fazie projektu.
        </Heading.Body1>

        <Heading.Body1>
          Bardzo proszę o scharakteryzowanie dziecka poprzez odpowiedzi na kolejne pytania, na ile
          dany opis trafnie pasuje do danego dziecka. Proszę nie wpisywać danych osobowych dziecka,
          ale przez cały czas wypełniania odnosić się do tego konkretnego dziecka. Proszę opisać
          funkcjonowanie dziecka w obecnym czasie (w ciągu ostatniego miesiąca). Odpowiadając na
          pytania, można zachowanie dziecka porównać z innymi dziećmi w grupie.
        </Heading.Body1>
        {/* <Checkbox
          isEditable
          label="Nie pokazuj więcej"
          value={!value}
          onChange={() => {
            if (typeof window !== 'undefined') {
              setValue(!value);
              window.localStorage.setItem('showSurveyInstruction', !value);
            }
          }}
        /> */}
      </StyledWrapper>
      <StyledDialogActions>
        <Button
          onClick={() => {
            setOpenFn(false);
          }}
          color="primary"
          variant="outlined"
        >
          OK
        </Button>
      </StyledDialogActions>
    </Dialog>
  );
};

DialogBeneficiarySurveys.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpenFn: PropTypes.func.isRequired,
  title: PropTypes.string,
};

DialogBeneficiarySurveys.defaultProps = {
  title: '',
};

export default DialogBeneficiarySurveys;
