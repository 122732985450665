// React
import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';

// Axios
import axiosInstance from 'utils/axios';

// Redux
import { connect } from 'react-redux';

// Services
import { fetchBeneficiaryDiagnosis as fetchBeneficiaryDiagnosisService } from 'services/beneficiaryServices';

// Actions
import { clearBeneficiaryDiagnosis as clearBeneficiaryDiagnosisAction } from 'actions/beneficiaryActions';
import { showAlert as showAlertAction } from 'actions/appActions';

// Data
// import { getDiagnosisList } from 'constants/other';
import {
  DIAGNOSIS_NOT_COMPLETE,
  SURVEYS_NOT_COMPLETE,
  ZANONIMIZOWANO_ZAKAZ_EDYCJI,
} from 'constants/alerts';

// Assets
import AddCircleIcon from '@material-ui/icons/AddCircle';

// Elements
import { Grid } from '@material-ui/core';
import MyAccordion from 'components/atoms/Accordion/Accordion';
import Checkbox from 'components/atoms/Checkbox/Checkbox';
import Heading from 'components/atoms/Heading/Heading';
import TableTemplate from 'templates/TableTemplate';
// import FloatingButton from 'components/atoms/FloatingButton/FloatingButton';

// PDF
import {
  generateBeneficiaryDiagnosisPDF,
  generateBeneficiarySurveysPDF,
  generateBeneficiaryEmptySurveyPDF,
  generateBeneficiaryInstructionSurveyPDF,
} from 'utils/functions';

// Styles
import { StyledTitle, StyledBox, StyledButton, StyledWrapper } from './BeneficiaryDiagnosis.styles';
import './Diagnosis.css';

// Data
const headCells = [
  { type: 'name', label: 'Nazwa' },
  { type: 'author', label: 'Prowadzący' },
  { type: 'execution_date', label: 'Data' },
  { type: 'status', label: 'Status' },
];
const headCellsSurveys = [
  { type: 'name', label: 'Nazwa' },
  { type: 'author', label: 'Prowadzący' },
  { type: 'measurement_number', label: 'Pomiar' },
  { type: 'execution_date', label: 'Data' },
  { type: 'status', label: 'Status' },
];

const optionsSurveys = [
  // {
  //   name: 'Pusta ankieta PDF [0-6]',
  //   action: () => generateBeneficiaryEmptySurveyPDF('CHILD_EVALUATION_QUESTIONNAIRE_0_6'),
  // },
  {
    name: 'Pusta ankieta PDF [6-12]',
    action: () => generateBeneficiaryEmptySurveyPDF('CHILD_EVALUATION_QUESTIONNAIRE_6_12'),
  },
  {
    name: 'Pusta ankieta PDF [13-18]',
    action: () => generateBeneficiaryEmptySurveyPDF('CHILD_EVALUATION_QUESTIONNAIRE_13_18'),
  },
  {
    name: 'Instrukcja PDF',
    action: () => generateBeneficiaryInstructionSurveyPDF(),
  },
];

// View
const BeneficiaryDiagnosis = ({
  clearBeneficiaryDiagnosis,
  fetchDiagnosis,
  details,
  showAlert,
  beneficiary,
}) => {
  const history = useHistory();
  const { id } = useParams();

  const [isActiveProgram, setActiveProgram] = useState(false);
  const [isEndProgram, setEndProgram] = useState(false);

  useEffect(() => {
    clearBeneficiaryDiagnosis();
  }, []);

  const actionCellsDiagnosis = [
    {
      column: 'Pobierz',
      action: async (row) => {
        if (row.status === 'Zakończona') {
          axiosInstance.get(`/diagnoza/${row.id}`).then(({ data }) => {
            generateBeneficiaryDiagnosisPDF(data.data);
          });
        } else {
          showAlert(DIAGNOSIS_NOT_COMPLETE);
        }
      },
      button: 'pdf',
    },
  ];

  const actionCellsSurveys = [
    {
      column: 'Pobierz',
      action: async (row) => {
        if (row.status === 'Zakończona') {
          axiosInstance.get(`/ankieta/${row.id}`).then(({ data }) => {
            generateBeneficiarySurveysPDF(data.data);
          });
        } else {
          showAlert(SURVEYS_NOT_COMPLETE);
        }
      },
      button: 'pdf',
    },
  ];

  const triggerFetchDiagnosis = useCallback((myid) => fetchDiagnosis(myid), [fetchDiagnosis]);

  useEffect(() => {
    triggerFetchDiagnosis(id);
  }, [id, triggerFetchDiagnosis]);

  const showAccordion = (ended) =>
    !((!isActiveProgram && !isEndProgram) || (isActiveProgram && !ended) || (isEndProgram && ended))
      ? 'true'
      : 'false';

  return (
    <>
      <div className="diagnosis">
        <StyledWrapper className="notes" container spacing={2}>
          <Grid item xs={2}>
            <Checkbox
              label="Aktualne"
              isEditable={!!true}
              value={isActiveProgram}
              onChange={() => setActiveProgram(!isActiveProgram)}
            />
          </Grid>
          <Grid item xs={2}>
            <Checkbox
              label="Zakończone"
              isEditable={!!true}
              value={isEndProgram}
              onChange={() => setEndProgram(!isEndProgram)}
            />
          </Grid>
        </StyledWrapper>
        {details && details.length ? (
          details.map((el) => (
            <MyAccordion key={el.id} title={el.name} hide={showAccordion(el.ended)}>
              <StyledTitle>
                <Heading.Subtitle1>Historia diagnoz</Heading.Subtitle1>
              </StyledTitle>
              <StyledBox>
                <TableTemplate
                  headCells={headCells}
                  data={el.diagnosis}
                  noSort
                  noPagination
                  noFilter
                  actionCells={actionCellsDiagnosis}
                  rowActions={[
                    {
                      name: 'zobacz',
                      action: (row) => {
                        history.push(`/diagnoza/${row.id}`);
                      },
                    },
                    {
                      name: 'edytuj',
                      action: (row) => {
                        if (beneficiary && beneficiary.name === 'anonimizacja') {
                          showAlert(ZANONIMIZOWANO_ZAKAZ_EDYCJI);
                        } else {
                          history.push(`/diagnoza/${row.id}/edit`);
                        }
                      },
                    },
                  ]}
                />

                {(!beneficiary || beneficiary.name !== 'anonimizacja') && (
                  <StyledButton
                    startIcon={<AddCircleIcon />}
                    onClick={() => history.push(`/diagnoza-dodaj/${el.cwp_id}`)}
                  >
                    Utwórz Diagnozę
                  </StyledButton>
                )}
              </StyledBox>
              <StyledTitle>
                <Heading.Subtitle1>Historia ankiet diagnostyczno-ewaluacyjnych</Heading.Subtitle1>
              </StyledTitle>
              <StyledBox>
                <TableTemplate
                  headCells={headCellsSurveys}
                  data={el.surveys}
                  noSort
                  noPagination
                  noFilter
                  actions={optionsSurveys}
                  actionCells={actionCellsSurveys}
                  rowActions={[
                    {
                      name: 'wyniki',
                      action: (row) => {
                        history.push(`/ankieta-wynik/${row.id}`);
                      },
                    },
                    {
                      name: 'zobacz',
                      action: (row) => {
                        history.push(`/ankieta/${row.id}`);
                      },
                    },
                    {
                      name: 'edytuj',
                      action: (row) => {
                        if (beneficiary && beneficiary.name === 'anonimizacja') {
                          showAlert(ZANONIMIZOWANO_ZAKAZ_EDYCJI);
                        } else {
                          history.push(`/ankieta/${row.id}/edytuj`);
                        }
                      },
                    },
                  ]}
                />

                {(!beneficiary || beneficiary.name !== 'anonimizacja') && (
                  <StyledButton
                    startIcon={<AddCircleIcon />}
                    onClick={() => history.push(`/ankieta-dodaj/${el.cwp_id}`)}
                  >
                    Utwórz ankietę diagnostyczno-ewaluacyjną
                  </StyledButton>
                )}
              </StyledBox>
            </MyAccordion>
          ))
        ) : (
          <Grid item xs={12} container justifyContent="center">
            <Heading.Subtitle1>Brak danych</Heading.Subtitle1>
          </Grid>
        )}
      </div>
      {/* <FloatingButton actions={getDiagnosisList()} /> */}
    </>
  );
};

BeneficiaryDiagnosis.propTypes = {
  clearBeneficiaryDiagnosis: PropTypes.func,
  fetchDiagnosis: PropTypes.func,
  details: PropTypes.arrayOf(PropTypes.any),
  showAlert: PropTypes.func,
  beneficiary: PropTypes.shape({ name: PropTypes.string, surname: PropTypes.string }),
};

BeneficiaryDiagnosis.defaultProps = {
  clearBeneficiaryDiagnosis: null,
  fetchDiagnosis: null,
  details: [],
  showAlert: null,
  beneficiary: null,
};

const mapStateToProps = ({ beneficiaryReducer }) => ({
  single_diagnose: beneficiaryReducer.single_diagnose,
  details: beneficiaryReducer.diagnosis,
  beneficiary: beneficiaryReducer.beneficiary,
});

const mapDispatchToProps = (dispatch) => ({
  clearBeneficiaryDiagnosis: () => dispatch(clearBeneficiaryDiagnosisAction()),
  fetchDiagnosis: (id) => dispatch(fetchBeneficiaryDiagnosisService(id)),
  showAlert: (data) => dispatch(showAlertAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BeneficiaryDiagnosis);
