// Elements
import { Grid } from '@material-ui/core';
import Button from 'components/atoms/Button/Button';

// Styles
import styled from 'styled-components';

export const StyledGrid = styled(Grid)`
  && {
    max-width: ${({ fullWidth }) => (fullWidth ? '100%' : '70%')};
    margin-top: ${({ margin }) => margin || '0rem'};

    &.MuiGrid-spacing-xs-2 {
      margin-top: 2rem;
    }

    .MuiFormHelperText-root {
      font-size: 1rem;
      margin-left: 0;
      margin-right: 0;
    }
  }
`;

export const StyledButton = styled(Button)`
  && {
    padding: 1rem 2rem;
    margin-top: 2rem;
  }
`;

export const StyledTitle = styled.div`
  && {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5rem;
  }
`;
