import {
  SHOW_PROGRAMS,
  CLEAR_PROGRAMS,
  FETCH_PROGRAM,
  FETCH_PROGRAM_CONSORTIUM,
  CLEAR_PROGRAM_CONSORTIUM,
  FETCH_PROGRAM_TASKS,
  CLEAR_PROGRAM_TASKS,
  FETCH_PROGRAM_IPP,
  CLEAR_PROGRAM_IPP,
  FETCH_PROGRAM_ACTIONS,
  CLEAR_PROGRAM_ACTIONS,
  FETCH_PROGRAM_USERS,
  CLEAR_PROGRAM_USERS,
  FETCH_PROGRAM_BENEFICIARES,
  CLEAR_PROGRAM_BENEFICIARES,
  FETCH_PROGRAM_INDICATORS,
  CLEAR_PROGRAM_INDICATORS,
  FETCH_PROGRAM_DIAGNOSIS,
  CLEAR_PROGRAM_DIAGNOSIS,
  FETCH_PROGRAM_COORDINATORS,
  CLEAR_PROGRAM_COORDINATORS,
  FETCH_PROGRAM_ORGANIZATIONS,
  FETCH_PROGRAM_TASK_COORDINATORS,
  FETCH_PROGRAM_PWD_PRESENCE,
  CLEAR_PROGRAM,
  CLEAR_PWD_PRESENCE,
  FETCH_PROGRAM_DATA_ADMINISTRATORS,
  FETCH_ACTION_GROUPS_SUCCESS,
  ADD_ACTION_GROUPS_SUCCESS,
} from 'constants/actionTypes';

export function fetchActionGroupsSuccess(payload) {
  return { type: FETCH_ACTION_GROUPS_SUCCESS, payload };
}

export function addActionGroupSuccess(payload) {
  return { type: ADD_ACTION_GROUPS_SUCCESS, payload };
}

export function fetchProgramDataAdministratorsAction(payload) {
  return { type: FETCH_PROGRAM_DATA_ADMINISTRATORS, payload };
}

export function fetchProgramTaskCoordinatorsAction(payload) {
  return { type: FETCH_PROGRAM_TASK_COORDINATORS, payload };
}

export function fetchPWDPresenceAction(payload) {
  return { type: FETCH_PROGRAM_PWD_PRESENCE, payload };
}

export function showProgramsAction(payload) {
  return { type: SHOW_PROGRAMS, payload };
}

export function clearPrograms(payload) {
  return { type: CLEAR_PROGRAMS, payload };
}

export function fetchProgramAction(payload) {
  return { type: FETCH_PROGRAM, payload };
}

export function fetchProgramConsortiumAction(payload) {
  return { type: FETCH_PROGRAM_CONSORTIUM, payload };
}

export function clearProgramConsortium(payload) {
  return { type: CLEAR_PROGRAM_CONSORTIUM, payload };
}

export function fetchProgramOrganizationsAction(payload) {
  return { type: FETCH_PROGRAM_ORGANIZATIONS, payload };
}

export function fetchProgramCoordinatorsListAction(payload) {
  return { type: FETCH_PROGRAM_COORDINATORS, payload };
}

export function clearProgramCoordinatorsList(payload) {
  return { type: CLEAR_PROGRAM_COORDINATORS, payload };
}

export function fetchProgramTasksAction(payload) {
  return { type: FETCH_PROGRAM_TASKS, payload };
}

export function clearProgramTasks(payload) {
  return { type: CLEAR_PROGRAM_TASKS, payload };
}

export function fetchProgramIPPAction(payload) {
  return { type: FETCH_PROGRAM_IPP, payload };
}

export function clearProgramIPP(payload) {
  return { type: CLEAR_PROGRAM_IPP, payload };
}

export function fetchProgramActionsAction(payload) {
  return { type: FETCH_PROGRAM_ACTIONS, payload };
}

export function clearProgramActions(payload) {
  return { type: CLEAR_PROGRAM_ACTIONS, payload };
}

export function fetchProgramUsersAction(payload) {
  return { type: FETCH_PROGRAM_USERS, payload };
}

export function clearProgramUsers(payload) {
  return { type: CLEAR_PROGRAM_USERS, payload };
}

export function fetchProgramBeneficiariesAction(payload) {
  return { type: FETCH_PROGRAM_BENEFICIARES, payload };
}

export function clearProgramBeneficiaries(payload) {
  return { type: CLEAR_PROGRAM_BENEFICIARES, payload };
}

export function fetchProgramIndicatorsAction(payload) {
  return { type: FETCH_PROGRAM_INDICATORS, payload };
}

export function clearProgramIndicators(payload) {
  return { type: CLEAR_PROGRAM_INDICATORS, payload };
}

export function fetchProgramDiegnosisAction(payload) {
  return { type: FETCH_PROGRAM_DIAGNOSIS, payload };
}

export function clearProgramDiegnosis(payload) {
  return { type: CLEAR_PROGRAM_DIAGNOSIS, payload };
}

export function clearPWDPresenceAction() {
  return { type: CLEAR_PWD_PRESENCE };
}

export function clearProgramAction() {
  return { type: CLEAR_PROGRAM };
}
