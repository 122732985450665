// React
import React from 'react';
import PropTypes from 'prop-types';

// Elements
import { Grid } from '@material-ui/core';
import Heading from 'components/atoms/Heading/Heading';
import Select from 'components/atoms/Select/Select';

// Styles
import { StyledChip } from './MultiSelectChips.styles';

// Component
const MultiSelectChips = ({
  data,
  values_data,
  setFieldValue,
  isEditable,
  excludeId,
  fieldName,
  label,
  labelChosen,
}) => (
  <>
    {data && (
      <Select
        disabled={!isEditable}
        data={data
          .filter((org) => {
            const is_there =
              values_data &&
              values_data.find((el) => {
                return el.id === org.id;
              });

            if (!is_there && org.id !== excludeId) {
              return true;
            }

            return false;
          })
          .map((org) => {
            return { value: org.id, name: org.name };
          })}
        label={label}
        variant="filled"
        onChange={(e, v) => {
          const org = data.find((el) => {
            return el.id === v;
          });
          const is_there =
            values_data &&
            values_data.find((el) => {
              return el.id === v;
            });

          if (!is_there && values_data) {
            values_data.push(org);

            setFieldValue(fieldName, values_data);
          }
        }}
      />
    )}
    {values_data && (
      <>
        <Grid item xs={12}>
          <Heading.Subtitle2>{labelChosen || 'Przypisane'}</Heading.Subtitle2>
        </Grid>
        <Grid item xs={12}>
          {values_data &&
            values_data.map((org) => {
              return (
                <StyledChip
                  disabled={!isEditable}
                  key={org.id}
                  label={org.name}
                  onDelete={() => {
                    const deleteIndex = values_data && values_data.indexOf(org);

                    if (values_data.length > 0) {
                      values_data.splice(deleteIndex, 1);

                      setFieldValue(fieldName, values_data);
                    }
                  }}
                  color="primary"
                />
              );
            })}
        </Grid>
      </>
    )}
  </>
);

MultiSelectChips.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ),
  values_data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ),
  isEditable: PropTypes.bool,
  setFieldValue: PropTypes.func,
  excludeId: PropTypes.number,
  label: PropTypes.string,
  fieldName: PropTypes.string,
  labelChosen: PropTypes.string,
};

MultiSelectChips.defaultProps = {
  setFieldValue: null,
  fieldName: '',
  isEditable: false,
  excludeId: null,
  label: 'Wybierz',
  labelChosen: null,
  data: [],
  values_data: [],
};

export default MultiSelectChips;
