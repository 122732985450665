// Elements
import Heading from 'components/atoms/Heading/Heading';

// Styles
import styled from 'styled-components';

export const StyledHeading = styled(Heading.Subtitle2)`
  && {
    margin: 2rem 0 2rem 16px;
  }
`;

export const StyledSubtitle = styled(Heading.H6)`
  && {
    margin: 2rem 0 0 16px;
  }
`;
