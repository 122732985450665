// React
import React from 'react';

// PDF
import { Page, Text, View, Document, Font } from '@react-pdf/renderer';

// Fonts
import robotoRegularSRC from 'font/Roboto-Regular.ttf';
import robotoBoldSRC from 'font/Roboto-Bold.ttf';

// Styles
import { styles } from 'utils/pdfStyles';

Font.register({
  family: 'Roboto',
  fonts: [{ src: robotoRegularSRC }, { src: robotoBoldSRC, fontWeight: 700 }],
});

const BeneficiaryInstructionSurveysPDF = () => {
  return (
    <Document language="pl">
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.title}>Instrukcja do ankiety</Text>
          <Text style={styles.text}>
            Ankieta powinna być wypełniona w danym okresie przez 1 i tylko 1 osobę która:
          </Text>
          <Text style={styles.text}>
            • bezpośrednio pracuje z beneficjentem w formie grupowej lub indywidualnej
          </Text>
          <Text style={styles.text}>
            • osoba która pracuje z beneficjentem przez okres nie krótszy niż 1 miesiąc (4
            tygodnie).
          </Text>
          <Text style={styles.text}>
            Jeśli z beneficjentem pracuje stale więcej niż jedna osoba, koordynator wskazuje kto
            powinien wypełnić ankietę na temat danego beneficjenta. Niedopuszczalna jest sytuacja,
            gdy dwóch pracowników projektu nanosi informacje do tej samej ankiety odnoszącej się do
            tego samego beneficjenta w tej samej fazie projektu.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.title} />
          <Text style={styles.text}>
            Wypełniając narzędzie, należy kierować się następującymi ogólnymi zasadami:
          </Text>
          <Text style={styles.text}>
            1. Należy starać się udzielać szczerych odpowiedzi, opisujących swoje prawdziwe sądy,
            oceny zachowań czy postaw.
          </Text>
          <Text style={styles.text}>
            2. Należy starać się odnosić do swoich własnych doświadczeń w kontakcie z beneficjentem
            lub jego rodzicami.
          </Text>
          <Text style={styles.text}>
            3. Odpowiadając na poszczególne pytania, należy wziąć pod uwagę ogół swoich doświadczeń
            z beneficjentem z ostatniego czasu współpracy z nim/nią (ostatni miesiąc). Uprawnione
            jest kierowanie się swoim wrażeniem. Nie należy poprzedzać wypełniania ankiety próbami
            pozyskania jakichś dodatkowych informacji o beneficjencie niż te, które pozyskuje się w
            trakcie standardowej pracy z nim/nią.
          </Text>
          <Text style={styles.text}>
            4. Należy starać się odpowiedzieć na wszystkie pytania. W przypadku trudności z
            odpowiedzą można wybrać odpowiedź bliską tej ze środka skali.
          </Text>
          <Text style={styles.text}>
            5. Opisując daną osobę należy odnieść się do porównania z innymi osobami, które są
            podobne do tej opisywanej, np. są w podobnym wieku i sytuacji życiowej. Optymalną grupą
            odniesienia są inni beneficjenci danego projektu w zbliżonym wieku.
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export default BeneficiaryInstructionSurveysPDF;
