// Elements
import { Grid } from '@material-ui/core';
import Button from 'components/atoms/Button/Button';

// Styles
import styled from 'styled-components';
import { device } from 'theme/mainTheme';

export const StyledWrapper = styled(Grid)`
  && {
    margin-top: 2rem;
    max-width: ${({ fullwidth }) => (fullwidth ? '100%' : '50%')};

    @media ${device.laptopL} {
      max-width: ${({ fullwidth }) => (fullwidth ? '100%' : '30%')};
    }
  }
`;

export const StyledTitle = styled.div`
  && {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5rem;
    padding-right: 1px;
  }
`;

export const StyledTitleSmall = styled.div`
  && {
    margin-top: 1.5rem;
    padding-right: 1px;

    * {
      &:not(:last-child) {
        margin-bottom: 1rem;
      }
    }
  }
`;

export const StyledButton = styled(Button)`
  && {
    font-size: 1.6rem;
    padding: ${({ padding }) => padding || '1rem 2.5rem'};
  }
`;
