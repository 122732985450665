import {
  SHOW_ACTIONS,
  CLEAR_ACTIONS,
  FETCH_ACTION,
  CLEAR_SINGLE_ACTION,
  FETCH_ACTION_MEMBERS,
  CLEAR_ACTION_MEMBERS,
  FETCH_ACTION_PRESENCE,
  FETCH_LECTURERS_LIST,
  FETCH_MEETINGS_LIST,
  CLEAR_MEETINGS_LIST,
  CLEAR_ACTION,
  CLEAR_PRESENCE,
} from 'constants/actionTypes';

const initialState = {
  actions: [],
  action: {
    id: null,
    name: '',
    organization: '',
    kind: '',
    build_in_type: null,
    action_kind: null,
    is_key_action: false,
    action_type: '',
    action_recipients: [],
    lecturers: [],
    description: '',
    action_time: null,
    action_frequency: null,
    start_date: '',
    end_date: '',
    is_open_action: false,
    presence: { beneficiaries: [], dates: [] },
    meetings: [],
  },
  lecturers: [],
  members: null,
  membersLoaded: false,
  log: null,
  meetings: {},
};

const actionReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SHOW_ACTIONS:
      return { ...state, actions: payload.data };
    case CLEAR_ACTIONS:
      return { ...state, actions: initialState.actions };
    case FETCH_ACTION:
      return { ...state, action: payload.data };
    case CLEAR_SINGLE_ACTION:
      return { ...state, action: initialState.action };
    case FETCH_ACTION_MEMBERS:
      return { ...state, members: payload.data, membersLoaded: true };
    case CLEAR_ACTION_MEMBERS:
      return { ...state, members: initialState.members, membersLoaded: initialState.membersLoaded };
    case FETCH_ACTION_PRESENCE:
      return { ...state, presence: payload.data };
    case FETCH_LECTURERS_LIST:
      return { ...state, lecturers: payload.data };
    case FETCH_MEETINGS_LIST:
      return { ...state, meetings: payload.data };
    case CLEAR_MEETINGS_LIST:
      return { ...state, meetings: initialState.meetings };
    case CLEAR_ACTION:
      return { ...initialState };
    case CLEAR_PRESENCE:
      return { ...state, presence: initialState.presence };
    default:
      return state;
  }
};

export default actionReducer;
