// React
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

// Elements
import { Zoom } from '@material-ui/core';

// Styles
import {
  StyledFab,
  StyledSpeedDial,
  StyledSpeedDialAction,
  StyledAddIcon,
  StyledTooltip,
} from './FloatingButton.styles';

// Component
const FloatingButton = ({
  onClick,
  component,
  actions,
  floatcolor,
  program_id,
  task_id,
  secondbutton,
  tooltip,
}) => {
  const [open, setOpen] = useState(false);
  const history = useHistory();

  let program_task_add = '';

  if (program_id) {
    program_task_add = `/program/${program_id}`;
  } else if (task_id) {
    program_task_add = `/zadanie/${task_id}`;
  }

  return (
    <>
      {actions.length ? (
        <StyledTooltip title={tooltip}>
          <StyledSpeedDial
            floatcolor={floatcolor}
            ariaLabel={tooltip}
            icon={<StyledAddIcon />}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            secondbutton={secondbutton ? 1 : 0}
          >
            {actions.map(({ name, icon, color, link, root = false, componentLink }) =>
              componentLink ? (
                <StyledSpeedDialAction
                  key={name}
                  icon={icon}
                  tooltipTitle={name}
                  tooltipOpen
                  color={color || ''}
                  FabProps={{ component: componentLink }}
                  onClick={null}
                />
              ) : (
                <StyledSpeedDialAction
                  key={name}
                  icon={icon}
                  tooltipTitle={name}
                  tooltipOpen
                  color={color || ''}
                  onClick={() =>
                    history.push(
                      root
                        ? `${link}${program_task_add}`
                        : `${history.location.pathname}/${link}${program_task_add}`,
                    )
                  }
                />
              ),
            )}
          </StyledSpeedDial>
        </StyledTooltip>
      ) : (
        <Zoom
          in
          style={{
            transitionDelay: `.3s`,
          }}
          unmountOnExit
        >
          {component ? (
            <StyledFab onClick={onClick} component={component}>
              <StyledAddIcon />
            </StyledFab>
          ) : (
            <StyledFab onClick={onClick}>
              <StyledAddIcon />
            </StyledFab>
          )}
        </Zoom>
      )}
    </>
  );
};

FloatingButton.propTypes = {
  floatcolor: PropTypes.string,
  program_id: PropTypes.number,
  task_id: PropTypes.number,
  onClick: PropTypes.func,
  component: PropTypes.elementType,
  actions: PropTypes.arrayOf(PropTypes.any),
  secondbutton: PropTypes.bool,
  tooltip: PropTypes.string,
};

FloatingButton.defaultProps = {
  floatcolor: '#707070',
  component: null,
  onClick: null,
  program_id: null,
  task_id: null,
  secondbutton: false,
  actions: [],
  tooltip: '',
};

export default FloatingButton;
