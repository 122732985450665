// React
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Actions
import { changeContrast, changeFontSize } from 'actions/appActions';

// Data
import { theme, normalColor, contrastColor, standardfontSize } from 'theme/mainTheme';

// Styles
import { ThemeProvider } from 'styled-components';
import GlobalStyle from 'theme/GlobalStyle';

// Component
const MainTemplate = ({ contrast, setContrast, fontSize, setFontSize, children }) => {
  useEffect(() => {
    const localFontSize = parseInt(window.localStorage.getItem('font-size'), 10) || 10;

    setFontSize(localFontSize);
    window.localStorage.setItem('font-size', localFontSize);

    const localContrast = window.localStorage.getItem('contrast') || false;

    window.localStorage.setItem('contrast', localContrast);
    setContrast(localContrast);

    if (localContrast === 'true') {
      document.body.classList.add('contrast-on');
      document.cookie = `bcc=c1;max-age=604800;domain=.bm2.rodzinnawarszawa.pl`;
    } else {
      document.body.classList.remove('contrast-on');
      document.cookie = `bcc=;max-age=604800;domain=.bm2.rodzinnawarszawa.pl`;
    }
  }, []);

  const checkFontSize = () => {
    const newSize = {};

    Object.keys(standardfontSize).map((el) => {
      const valueSize = standardfontSize[el].replace('rem', '');

      newSize[el] = `${(valueSize * fontSize) / 10}rem`;

      return true;
    });

    return newSize;
  };

  return (
    <div className="mainCol">
      <ThemeProvider
        theme={{
          ...theme,
          color: contrast === 'true' ? contrastColor : normalColor,
          fontSize: checkFontSize(),
        }}
      >
        <GlobalStyle />
        {children}
      </ThemeProvider>
    </div>
  );
};

MainTemplate.propTypes = {
  contrast: PropTypes.string,
  setContrast: PropTypes.func,
  fontSize: PropTypes.number,
  setFontSize: PropTypes.func,
  children: PropTypes.element.isRequired,
};

MainTemplate.defaultProps = {
  contrast: 'false',
  setContrast: null,
  fontSize: 10,
  setFontSize: null,
};

const mapStateToProps = ({ appReducer }) => ({
  contrast: appReducer.contrast,
  fontSize: appReducer.fontSize,
});

const mapDispatchToProps = (dispatch) => ({
  setContrast: (e) => dispatch(changeContrast(e)),
  setFontSize: (e) => dispatch(changeFontSize(e)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MainTemplate);
