import {
  SHOW_ACTIONS,
  CLEAR_ACTIONS,
  FETCH_ACTION,
  CLEAR_SINGLE_ACTION,
  FETCH_ACTION_MEMBERS,
  CLEAR_ACTION_MEMBERS,
  FETCH_ACTION_PRESENCE,
  FETCH_LECTURERS_LIST,
  FETCH_MEETINGS_LIST,
  CLEAR_MEETINGS_LIST,
  CLEAR_ACTION,
  CLEAR_PRESENCE,
} from 'constants/actionTypes';

export function showActions(payload) {
  return { type: SHOW_ACTIONS, payload };
}

export function clearActions(payload) {
  return { type: CLEAR_ACTIONS, payload };
}

export function fetchActionAction(payload) {
  return { type: FETCH_ACTION, payload };
}

export function clearSingleAction(payload) {
  return { type: CLEAR_SINGLE_ACTION, payload };
}

export function fetchActionMembersAction(payload) {
  return { type: FETCH_ACTION_MEMBERS, payload };
}

export function clearActionMembers(payload) {
  return { type: CLEAR_ACTION_MEMBERS, payload };
}

export function fetchActionPresenceAction(payload) {
  return { type: FETCH_ACTION_PRESENCE, payload };
}

export function fetchLecturersListAction(payload) {
  return { type: FETCH_LECTURERS_LIST, payload };
}

export function fetchMeetingsListAction(payload) {
  return { type: FETCH_MEETINGS_LIST, payload };
}

export function clearMeetingsList(payload) {
  return { type: CLEAR_MEETINGS_LIST, payload };
}

export function clearActionAction() {
  return { type: CLEAR_ACTION };
}

export function clearPresenceAction() {
  return { type: CLEAR_PRESENCE };
}
