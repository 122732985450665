// React
import React, { useEffect, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Services
import { fetchProgramUsers } from 'services/programServices';

// Actions
import { clearProgramUsers as clearProgramUsersAction } from 'actions/programActions';

// Utils
import { isGuardianInProgram } from 'utils/functions';

// Data
import { COORDINATOR } from 'constants/roles';
import { filterProgramUsers } from 'constants/tableFilters';

// Elements
import TableTemplate from 'templates/TableTemplate';
import FloatingButton from 'components/atoms/FloatingButton/FloatingButton';
import GuardedComponent from 'components/molecules/GuardedComponent/GuardedComponent';

// Data
const headCells = [
  { type: 'user_name', label: 'Imię' },
  { type: 'user_last_name', label: 'Nazwisko' },
  { type: 'user_phone', label: 'Telefon' },
  { type: 'user_email', label: 'E-mail' },
  { type: 'user_organization', label: 'Organizacja' },
  { type: 'user_role', label: 'Rola w Programie' },
  { type: 'last_login', label: 'Data ostatniego logowania' },
  { type: 'modified_by', label: 'Modyfikowany przez' },
];

// Component
const ProgramUsers = ({ clearProgramUsers, fetchUsers, program_users, me }) => {
  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    clearProgramUsers();
  }, []);

  const triggerFetchUsers = useCallback((myid) => fetchUsers(myid), [fetchUsers]);

  useEffect(() => {
    triggerFetchUsers(id);
  }, [id, triggerFetchUsers]);

  return (
    <>
      <TableTemplate
        headCells={headCells}
        data={program_users}
        filters={filterProgramUsers}
        isnav={!isGuardianInProgram(me, parseInt(id, 10))}
        link="/uzytkownicy"
        tableName="Użytkownicy"
      />
      <GuardedComponent allowed_user_roles={[COORDINATOR]}>
        <FloatingButton onClick={() => history.push('/uzytkownicy-dodaj')} />
      </GuardedComponent>
    </>
  );
};

ProgramUsers.propTypes = {
  clearProgramUsers: PropTypes.func,
  fetchUsers: PropTypes.func,
  program_users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      user_name: PropTypes.string,
      user_last_name: PropTypes.string,
      user_phone: PropTypes.string,
      user_email: PropTypes.string,
      user_organization: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
        }),
      ),
      user_role: PropTypes.string,
      last_login: PropTypes.string,
      modified_by: PropTypes.string,
    }),
  ),
  me: PropTypes.shape({
    program_roles: PropTypes.arrayOf(PropTypes.any),
  }),
};

ProgramUsers.defaultProps = {
  clearProgramUsers: null,
  fetchUsers: null,
  program_users: [],
  me: null,
};

const mapStateToProps = ({ programReducer, userReducer }) => ({
  program_users: programReducer.users,
  me: userReducer.me,
});

const mapDispatchToProps = (dispatch) => ({
  clearProgramUsers: () => dispatch(clearProgramUsersAction()),
  fetchUsers: (id) => dispatch(fetchProgramUsers(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProgramUsers);
