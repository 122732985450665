// Assets
import FilterListIcon from '@material-ui/icons/FilterList';

// Elements
import Button from 'components/atoms/Button/Button';

// Styles
import styled, { css } from 'styled-components';

const iconStyles = css`
  && {
    font-size: 2.5rem;
    fill: ${({ theme }) => theme.color.darkGray};
  }
`;

export const StyledOption = styled.div`
  && {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    min-width: 10rem;
  }
`;

export const StyledFilterListIcon = styled(FilterListIcon)`
  ${iconStyles}
`;

export const StyledButton = styled(Button)`
  && {
    font-size: 1.4rem;
    text-transform: lowercase;
    color: ${({ theme }) => theme.color.darkGray};
    font-family: ${({ theme }) => theme.fontFamily.base};
  }
`;
