// Elements
import {
  Select as AppSelect,
  FormControl,
  InputLabel,
  MenuItem,
  Chip,
  FormHelperText,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

// Styles
import styled, { css, keyframes } from 'styled-components';

export const pulse = keyframes`
  0% {
    border-bottom: 1px solid #E3191B;
  }

  100% {
    border-bottom: 1px solid #DEDEDE;
  }
`;

export const StyledSelect = styled(AppSelect)`
  && {
    font-size: ${({ theme }) => theme.fontSize.body2};
    .MuiSelect-selectMenu {
      color: ${({ theme }) => theme.color.darkGray};
    }
  }
`;

export const StyledFormHelperText = styled(FormHelperText)`
  && {
    ${({ theme }) => css`
      font-size: ${theme.fontSize.caption};
    `}
    color: ${({ theme }) => theme.color.darkGray};
  }
`;

export const StyledAutocomplete = styled(Autocomplete)`
  && {
    .MuiAutocomplete-input {
      color: ${({ theme }) => theme.color.darkGray};
    }
    .MuiIconButton-root {
      color: ${({ theme }) => theme.color.darkGray};
    }
  }
`;

export const StyledFormControl = styled(FormControl)`
  && {
    width: 100%;

    label {
      color: ${({ theme }) => theme.color.darkGray};
    }
    &.Mui-focused {
      background-color: ${({ theme }) => theme.color.greyToBlack};
    }
    .MuiFilledInput-root {
      background-color: ${({ theme }) => theme.color.lightGray};

      &.Mui-disabled {
        background-color: ${({ theme }) => theme.color.white};
        color: ${({ theme }) => theme.color.darkGray};
      }
    }

    .MuiInputBase-root {
      &::after,
      &::before {
        display: none;
      }

      &:disabled {
        border-bottom: 1px dotted rgba(0, 0, 0, 0.38);
      }

      ${({ loading }) =>
        loading &&
        css`
          animation: 1s ${pulse} ease-out infinite alternate;
        `}
    }
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  && {
    font-size: ${({ theme }) => theme.fontSize.body2};
  }
`;

export const StyledInputLabel = styled(InputLabel)`
  && {
    font-size: ${({ theme }) => theme.fontSize.body2};
    color: ${({ theme }) => theme.color.darkGray};
  }
`;

export const StyledChip = styled(Chip)`
  && {
    font-size: ${({ theme }) => theme.fontSize.body2};
  }
`;
