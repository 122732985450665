// Elements
// import { KeyboardDatePicker } from '@material-ui/pickers';
/// import DatePicker from '@mui/lab/DatePicker';
// eslint-disable-next-line import/no-extraneous-dependencies
import { DatePicker } from '@mui/x-date-pickers';

import { TextField } from '@material-ui/core';

// Styles
import styled, { css } from 'styled-components';

export const StyledDatePicker = styled(DatePicker)`
  && {
    width: 100%;
    font-size: ${({ theme }) => theme.fontSize.body2};
    margin: 0;

    input,
    label,
    button {
      font-size: ${({ theme }) => theme.fontSize.body2};
    }

    &.MuiFormControl-root {
      background-color: ${({ theme }) => theme.color.lightGray};
    }

    .MuiInputBase-root {
      background-color: ${({ theme }) => theme.color.lightGray};

      &.Mui-disabled {
        background-color: ${({ theme }) => theme.color.white};
        color: ${({ theme }) => theme.color.darkGray};
      }

      &.Mui-focused {
        background-color: ${({ theme }) => theme.color.gray};
      }
    }

    .MuiPickersBasePicker-container {
      background-color: red;
    }
  }
`;

export const StyledTextField = styled(TextField)`
  && {
    width: 100%;
    input,
    label,
    textarea {
      font-size: 1.4rem;
      color: ${({ theme }) => theme.color.darkGray};
    }

    .MuiFilledInput-root {
      color: ${({ theme }) => theme.color.black};

      input {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }

      &.Mui-disabled {
        color: ${({ theme }) => theme.color.darkGray};
      }

      &.Mui-focused {
        color: ${({ theme }) => theme.color.black};
      }
    }

    textarea {
      line-height: 1.4;
    }

    ${({ right }) =>
      right &&
      css`
        .MuiFormHelperText-root {
          text-align: right;
        }
      `}

    input, textarea {
      &:disabled {
        color: ${({ theme }) => theme.color.darkGray};
      }
    }
    .MuiInput-underline:before {
      border-color: ${({ theme }) => theme.color.darkGray};
    }
    .MuiSvgIcon-root {
      fill: ${({ theme }) => theme.color.darkGray};
    }
    p {
      font-size: ${({ theme }) => theme.fontSize.caption};
    }
  }
`;
